import React from 'react';

import { LabelCell } from '../../../common/components/label/LabelCell';
import { getAssociatedLabelsForDomain } from '../../../common/store/api-slice-utils';

export const LabelsCell = ({
  params: { associationsData, labelsData, row }
}) => {
  const labelsArray =
    labelsData &&
    associationsData &&
    getAssociatedLabelsForDomain(
      row.domainName,
      labelsData.flat,
      associationsData
    );
  return labelsArray?.length > 0 && <LabelCell value={labelsArray} />;
};
