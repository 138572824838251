import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { noop } from 'lodash-es';
import {
  bindDialog,
  bindToggle,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React, { useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { ValidOrNotMemoized } from './ValidOrNotIcon';
import { formSchema } from './set-dot-no-special-requirements-schema';
import {
  selectSpecialRequirementsValidityById,
  setSpecialRequirementsValidity,
  updateById
} from './transfer-slice';

export const SetDotNoSpecialRequirements = ({ domainName, id }) => {
  const dispatch = useDispatch();
  const [validationErrors, setValidationErrors] = useState(false);
  const dialogState = usePopupState({
    popupId: 'setNameServersDialog',
    variant: 'dialog'
  });

  const isValid = useSelector(state =>
    selectSpecialRequirementsValidityById(state, id)
  );

  const handleClose = noop;

  const handleSubmit = event => {
    event.preventDefault();
    setValidationErrors(false);
    formSchema({
      handleAcceptanceNameMatch: handleAcceptanceNameMatch(true),
      handleDateValidation: handleDateValidation(true),
      handleRequiredValidation: handleRequiredValidation(true),
      handleVersionNumberMatch: handleVersionNumberMatch(true)
    })
      .validate(formData)
      .then(() =>
        dispatch(
          updateById({
            changes: [
              {
                key: 'transferSpecialRequirement',
                value: {
                  acceptDate: formData.acceptanceDate.value.toISOString(),
                  acceptName: formData.acceptanceName.value,
                  transferSpecialRequirementType: 'DOTNO',
                  versionNumber: formData.versionNumber.value
                }
              }
            ],
            id
          })
        )
      )
      .then(() =>
        dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }))
      )
      .then(dialogState.close)
      .catch(e => {
        window.console.error(e);
        setValidationErrors(true);
        setSpecialRequirementsValidity({ domainName, isValid: false });
      });
  };

  const handleRequiredValidation =
    forceTouch =>
    ({ path }) => {
      formDataDispatch({
        errorMessage: 'This field is required',
        forceTouch,
        path,
        type: 'SET_ERROR'
      });
    };
  const handleDateValidation =
    forceTouch =>
    ({ path }) => {
      formDataDispatch({
        errorMessage: 'The date is invalid.',
        forceTouch,
        path,
        type: 'SET_ERROR'
      });
    };

  const handleVersionNumberMatch =
    forceTouch =>
    ({ path }) =>
      formDataDispatch({
        errorMessage: 'The version number format is incorrect.',
        forceTouch,
        path,
        type: 'SET_ERROR'
      });

  const handleAcceptanceNameMatch =
    forceTouch =>
    ({ path }) =>
      formDataDispatch({
        errorMessage: 'The acceptance name format is incorrect',
        forceTouch,
        path,
        type: 'SET_ERROR'
      });

  const [formData, formDataDispatch] = useReducer((state, action) => {
    if (action.path) {
      action.path = action.path.split('.')[0];
    }
    switch (action.type) {
      case 'UPDATE':
        state[action.path].value = action.value;
        state[action.path].touched = true;
        state[action.path].errorMessage = '';
        state[action.path].hasError = false;
        break;
      case 'SET_ERROR':
        state[action.path].hasError =
          state[action.path].touched || action.forceTouch;
        state[action.path].errorMessage =
          state[action.path].hasError && action.errorMessage;
        break;
      default:
        break;
    }

    return { ...state };
  }, formSchema({}).getDefault());

  const debouncedValidation = useDebouncedCallback(
    formSchema =>
      formSchema({
        handleAcceptanceNameMatch: handleAcceptanceNameMatch(false),
        handleDateValidation: handleDateValidation(false),
        handleRequiredValidation: handleRequiredValidation(false),
        handleVersionNumberMatch: handleVersionNumberMatch(false)
      })
        .validate(formData)
        .catch(window.console.error),
    1500
  );

  const handleChange = (event, path) => {
    formDataDispatch({
      path,
      type: 'UPDATE',
      value: event.target.value
    });
    debouncedValidation(formSchema);
  };

  const handleDateChange = (value, path) => {
    formDataDispatch({
      path,
      type: 'UPDATE',
      value
    });
    debouncedValidation(formSchema);
  };

  return (
    <>
      <Stack alignItems="center" direction="row" spacing={1}>
        <Button size="small" {...bindTrigger(dialogState)}>
          Set requirements
        </Button>
        <ValidOrNotMemoized isValid={isValid} />
      </Stack>
      <Dialog {...bindDialog(dialogState)} maxWidth="xs">
        <form noValidate={true} onSubmit={handleSubmit}>
          <DialogTitle>Set Special Requirements for {domainName}</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              {validationErrors && (
                <Alert severity="error">
                  Fix form errors before submitting.
                </Alert>
              )}
              <TextField
                error={formData.versionNumber?.hasError}
                fullWidth
                helperText={formData.versionNumber?.errorMessage}
                label="Version Number"
                margin="none"
                onChange={event => handleChange(event, 'versionNumber')}
                value={formData.versionNumber.value}
                variant="standard"
              />
              <TextField
                error={formData.acceptanceName?.hasError}
                fullWidth
                helperText={formData.acceptanceName?.errorMessage}
                label="Acceptance Name"
                margin="none"
                onChange={event => handleChange(event, 'acceptanceName')}
                value={formData.acceptanceName.value}
                variant="standard"
              />
              <DatePicker
                label="Acceptance Date"
                onChange={newValue =>
                  handleDateChange(newValue, 'acceptanceDate')
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    error={formData.acceptanceDate?.hasError}
                    helperText={formData.acceptanceDate?.errorMessage}
                    margin="none"
                    variant="standard"
                  />
                )}
                value={formData.acceptanceDate.value}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} {...bindToggle(dialogState)}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export const SetDotNoSpecialRequirementsMemoized = React.memo(
  SetDotNoSpecialRequirements
);
