/* eslint-disable max-params */
import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { getAssociatedLabelsForDomain } from '../../common/store/api-slice-utils';
import { selectCustomerName } from '../../common/store/customers-slice';
import {
  useAddLabelAssociationsMutation,
  useDeleteLabelAssociationsMutation,
  useGetLabelAssociationsQuery,
  useGetLabelsQuery
} from '../../common/store/labels-api-slice';
import { selectReseller } from '../../common/store/reseller';

export const LabelsCell = ({ domainName }) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);

  const [addLabelAssociations, { isLoading: isAddLabelLoading }] =
    useAddLabelAssociationsMutation();
  const [deleteLabelAssociations, { isLoading: isDeleteLabelLoading }] =
    useDeleteLabelAssociationsMutation();

  const {
    data: associationsData,
    fulfilledTimeStamp,
    isSuccess: isGetLabelAssociationsSuccess
  } = useGetLabelAssociationsQuery(
    {
      customerName,
      reseller
    },
    { skip: !customerName }
  );

  const {
    associatedLabels,
    data: labelsData,
    isLoading: isLabelsLoading
  } = useGetLabelsQuery(
    {
      customerName,
      fulfilledTimeStamp,
      reseller
    },
    {
      selectFromResult: ({ data, isLoading }) => ({
        associatedLabels:
          getAssociatedLabelsForDomain(
            domainName,
            data?.flat,
            associationsData
          ) ?? [],
        data,
        isLoading
      }),
      skip: [customerName, isGetLabelAssociationsSuccess].some(
        isTruthy => !isTruthy
      )
    }
  );

  const handleLabelAssociationCheckboxChange = (
    event,
    labels,
    action,
    { option: label = null } = {}
  ) => {
    switch (action) {
      case 'clear':
        const associations = associationsData.filter(
          association => association.domainName === domainName
        );
        if (associations.length) {
          deleteLabelAssociations({
            associationIds: associations.map(association => association.id),
            customerName,
            reseller
          });
        }
        break;
      case 'removeOption':
        const association = associationsData.find(
          association =>
            association.domainName === domainName &&
            association.label.id === label?.id
        );
        if (association?.id) {
          deleteLabelAssociations({
            associationIds: [association.id],
            customerName,
            reseller
          });
        }
        break;
      case 'selectOption':
        labels
          .filter(
            label =>
              !associationsData.some(
                association =>
                  association.domainName === domainName &&
                  association.label.id === label.id
              )
          )
          .forEach(({ id: labelId }) =>
            addLabelAssociations({
              customerName,
              domainNames: [domainName],
              labelId,
              reseller
            })
          );
        break;
      default:
        break;
    }
  };

  return (
    <Autocomplete
      groupBy={option => option.labelGroupName}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      limitTags={1}
      loading={isLabelsLoading || isAddLabelLoading || isDeleteLabelLoading}
      multiple
      onChange={handleLabelAssociationCheckboxChange}
      options={labelsData?.flat ?? []}
      renderInput={params => <TextField {...params} variant="outlined" />}
      size="small"
      sx={{ width: '100%' }}
      value={associatedLabels ?? []}
    />
  );
};

export const LabelsCellMemoized = React.memo(LabelsCell);
