import { apiSlice } from '../../common/store/api-slice';
import { formatPrice } from '../utils/number-price-text';

const domainTransferLineItem = ({
  authCode,
  clientLineItemId,
  customerContactSetId,
  domain,
  lineItemNum,
  lineItemType,
  price,
  privateWhoIs = false,
  useLocalPresence = false,
  years
}) => ({
  authCode,
  clientLineItemId,
  customerContactSetId,
  domain,
  // TODO should be able to use index
  lineItemNum,
  lineItemType,
  price,
  privateWhoIs,
  token_password: null,
  useLocalPresence,
  years
});

const domainRenewalLineItem = (
  {
    clientLineItemId,
    customerProductExpirationDate,
    domain,
    price = '1',
    years
  },
  lineItemNum
) => ({
  clientLineItemId,
  customerProductExpirationDate,
  domain,
  lineItemNum,
  lineItemType: 'DOMAIN_RENEWAL',
  price,
  token_password: null,
  years
});

const generateLineItems = lineItems =>
  // eslint-disable-next-line complexity
  lineItems.map((lineItem, index) => {
    switch (lineItem.lineItemType) {
      case 'DOMAIN_TRANSFER':
        return domainTransferLineItem(lineItem);
      case 'DOMAIN_RENEWAL':
        return domainRenewalLineItem(lineItem, index);
      case 'DOMAIN_REGISTRATION':
      case 'DOMAIN_PREREGISTRATION':
      case 'WEB3_REGISTRATION':
      case 'CUSTOMER_TRANSFER_DELETE':
      case 'UPDATE_DOMAIN_STATUSES':
      case 'UPDATE_DOMAIN_CONTACTS':
      case 'DOMAIN_REGISTRY_LOCK':
      case 'CHANGE_NAMESERVER':
      default:
        return lineItem;
    }
  });

const order = (
  {
    currency = 'USD',
    domainServiceAgreementAccepted = false,
    domainServiceAgreementAcceptedDate = null,
    paymentType = 'FUTURE'
  },
  lineItems
) => ({
  currency,
  domainServiceAgreementAccepted,
  domainServiceAgreementAcceptedDate,
  lineItems: generateLineItems(lineItems),
  paymentType
});

export const ordersApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createOrder: builder.mutation({
      query: ({
        clientId,
        customerName,
        lineItems,
        orderOptions,
        reseller,
        signOffForceUpdate
      }) => ({
        body: {
          clientId,
          order: order(orderOptions, lineItems),
          signOffForceUpdate
        },
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/orders`
      })
    }),
    priceCheck: builder.mutation({
      query: ({
        clientId,
        customerName,
        lineItems,
        orderOptions,
        reseller
      }) => ({
        body: {
          clientId,
          order: order(orderOptions, lineItems)
        },
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/orders/price-check`
      }),
      transformResponse: response => {
        return {
          ...response,
          // TODO is this ever not in USD
          $totalPriceFormatted: `$${formatPrice(response.totalPrice)}`
        };
      }
    })
  })
});

export const { useCreateOrderMutation, usePriceCheckMutation } = ordersApiSlice;
