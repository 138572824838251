import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';

import type { ApiUserResponse } from '../../common/types/user';

import { selectActiveCustomer } from '../../common/store/customers-slice';
import { SignOffContextPermissionSignerSelect } from './SignOffContextPermissionSignerSelect';
import { SignOffContextPermissionSignersConfirmButton } from './SignOffContextPermissionSignersConfirmButton';
import {
  SignOffContextSignerFormSchema,
  signOffContextSignerFormSchema
} from './schema';
import {
  useGetApprovedSignersForCustomerAndContextIdQuery,
  useGetAvailableApproversQuery
} from './sign-off-context-api-slice';
import {
  emptyFormState,
  signOffContextSignerFormReducer
} from './sign-off-context-signer-form-reducer';
import { selectSelectedSignOffContext } from './sign-off-context-slice';

type SignOffContextPermissionSignersDialogProps = {
  closeDialog: () => void;
  isOpen: boolean;
};

export const SignOffContextPermissionSignersDialog = ({
  closeDialog,
  isOpen
}: SignOffContextPermissionSignersDialogProps) => {
  const selectedSignOffContext = useSelector(selectSelectedSignOffContext);
  const selectedCustomer = useSelector(selectActiveCustomer);

  const [userOptions, setUserOptions] = useState([]);

  const [formData, formDataDispatch] = useReducer(
    signOffContextSignerFormReducer,
    emptyFormState()
  );

  const {
    data: approvedSignersApiResponse,
    isLoading: isApprovedSignersLoading
  } = useGetApprovedSignersForCustomerAndContextIdQuery(
    {
      accountName: selectedCustomer.accountName,
      contextId: selectedSignOffContext?.id
    },
    {
      skip: selectedSignOffContext?.id === undefined
    }
  );

  const { data: availableApprovers, isLoading: isAvailableApproversLoading } =
    useGetAvailableApproversQuery({
      accountName: selectedCustomer.accountName
    });

  useEffect(() => {
    if (approvedSignersApiResponse && !isApprovedSignersLoading) {
      formDataDispatch({
        errorMessage: null,
        path: null,
        type: 'INIT',
        value: approvedSignersApiResponse
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvedSignersApiResponse]);

  useEffect(() => {
    if (
      availableApprovers &&
      approvedSignersApiResponse &&
      formData.approvedSigners
    ) {
      const options = availableApprovers.reduce(
        (users: ApiUserResponse[], user: ApiUserResponse) => {
          if (
            !formData.approvedSigners.some(
              (approvedSigner: ApiUserResponse) => approvedSigner.id === user.id
            )
          ) {
            users.push(user);
          }
          return users;
        },
        []
      );

      setUserOptions(options);
    }
  }, [
    availableApprovers,
    approvedSignersApiResponse,
    formData.approvedSigners
  ]);

  const handleSubmit = (event: React.FormEvent) => event.preventDefault();

  const handleValidation = () =>
    signOffContextSignerFormSchema().validate(
      formData
    ) as Promise<SignOffContextSignerFormSchema>;

  const handleClose = () => {
    formDataDispatch({
      errorMessage: null,
      path: null,
      type: 'INIT',
      value: approvedSignersApiResponse
    });

    closeDialog();
  };

  return (
    <Dialog fullWidth onClose={handleClose} open={isOpen}>
      <DialogTitle>
        Approved Signers -{' '}
        {selectedSignOffContext?.$signOffContextNameFormatted}
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        {(isApprovedSignersLoading || isAvailableApproversLoading) && (
          <CircularProgress />
        )}
        {!isApprovedSignersLoading && !isAvailableApproversLoading && (
          <form onSubmit={handleSubmit}>
            <Stack
              spacing={1}
              sx={{
                maxHeight: '400px',
                overflowX: 'visible',
                overflowY: 'auto',
                px: 3,
                py: 2
              }}
            >
              <SignOffContextPermissionSignerSelect
                formData={formData}
                formDataDispatch={formDataDispatch}
                isLoading={
                  isAvailableApproversLoading || isApprovedSignersLoading
                }
                userOptions={userOptions}
              />
            </Stack>
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <SignOffContextPermissionSignersConfirmButton
          handleClose={handleClose}
          validateForm={handleValidation}
        />
      </DialogActions>
    </Dialog>
  );
};
