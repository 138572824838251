import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { config } from '../config/config';
import { envProduction } from '../config/env-production';
import { envStaging } from '../config/env-staging';
import { envTest } from '../config/env-test';
import { initializers } from './initializers';

const bugsnagInitializer = () => {
  Bugsnag.start({
    apiKey: 'ceea7ddd935abef80842b436431fa991',
    enabledReleaseStages: [envTest.ENV, envStaging.ENV, envProduction.ENV],
    plugins: [new BugsnagPluginReact()],
    releaseStage: config.ENV
  });
};

initializers.push(bugsnagInitializer);
