/**
 * Generate unique id
 * @returns {String} unique id
 */
export const generateId = (): string => {
  let uuid;
  try {
    // this will fail on http or insecure contexts
    uuid = window.crypto.randomUUID();
  } catch (e) {
    uuid = `id-${Math.random().toString().slice(2)}`;
  }
  return uuid;
};
