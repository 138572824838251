import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { updateById } from './transfer-slice';

export const DomainNameCell = ({
  domainName,
  domainNameAvailability,
  id,
  isCheckingTransferAvailability
}) => {
  const [fieldValue, setFieldValue] = useState(domainName ?? '');
  const isDomainNameUnavailable = domainName
    ? domainNameAvailability?.isError
    : false;
  const dispatch = useDispatch();

  // There's a network request associated with this change,
  // therefore we want to debounce for performance
  const debouncedDomainNameChange = useDebouncedCallback(value => {
    dispatch(
      updateById({
        changes: [
          { key: 'domainName', value: value },
          // Reset years when the domain name changes
          { key: 'years', value: null }
        ],
        id
      })
    );
  }, 1000);

  const handleDomainNameChange = event => {
    setFieldValue(event.target.value);
    debouncedDomainNameChange(event.target.value);
  };

  return (
    <TextField
      error={!isCheckingTransferAvailability && isDomainNameUnavailable}
      fullWidth
      onChange={handleDomainNameChange}
      size="small"
      value={fieldValue}
    />
  );
};

export const DomainNameCellMemoized = React.memo(DomainNameCell);
