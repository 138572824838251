import { TextField } from '@mui/material';
import React from 'react';

import { formatForDisplay } from '../../../../utils/number-price-text';

export const DotCnForm = ({ specialRequirements }) => {
  const idTypes = Object.freeze({
    CHINESE_BUSINESS_LICENSE: 'Chinese business license',
    CHINESE_ID_CARD: 'Chinese ID card',
    CHINESE_OFFICER_CERT: 'Chinese officer certificate',
    CHINESE_ORG_CERT: 'Chinese organization code certificate',
    CHINESE_PASSPORT: 'Chinese Passport',
    OTHERS: 'Other'
  });

  return (
    <>
      <TextField
        disabled
        label="Contact Type"
        value={formatForDisplay(specialRequirements.DOTCN.contactType)}
      />
      <TextField
        disabled
        label="Identification Type"
        value={idTypes[specialRequirements.DOTCN.idType]}
      />
      <TextField
        disabled
        label="ID Number"
        value={specialRequirements.DOTCN.idNumber}
      />
    </>
  );
};
