import { createSlice } from '@reduxjs/toolkit';

const initialState = { selectedTldCostIds: [], selectedTldCosts: [] };

const selectedTldCostsSlice = createSlice({
  initialState,
  name: 'selectedTldCosts',
  reducers: {
    removeSelectedTldCost: (state, { payload }) => {
      // expected payload: id of the tld cost to unselect
      state.selectedTldCostIds = state.selectedTldCostIds.filter(
        tldCostId => tldCostId !== payload
      );
      state.selectedTldCosts = state.selectedTldCosts.filter(
        tldCost => tldCost.id !== payload
      );
    },
    setSelectedTldCosts: (state, { payload }) => {
      // expected payload: array of selected tld cost objects
      state.selectedTldCosts = payload;
      state.selectedTldCostIds = payload.map(tldCost => tldCost?.id);
    }
  }
});

export const selectedTldCostsReducer = selectedTldCostsSlice.reducer;

export const { removeSelectedTldCost, setSelectedTldCosts } =
  selectedTldCostsSlice.actions;

export const selectSelectedTldCostIds = state =>
  state.selectedTldCosts?.selectedTldCostIds;
export const selectSelectedTldCosts = state =>
  state.selectedTldCosts?.selectedTldCosts;

export const selectIsNoneSelected = state =>
  state.selectedTldCosts?.selectedTldCosts.length === 0;
export const selectIsOneSelected = state =>
  state.selectedTldCosts?.selectedTldCosts.length === 1;
export const selectIsAnySelected = state =>
  state.selectedTldCosts?.selectedTldCosts.length > 0;
export const selectIsMultipleSelected = state =>
  state.selectedTldCosts?.selectedTldCosts.length > 1;
