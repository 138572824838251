import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  displayedContactSetHasChanged: false,
  displayedContactSetId: null,
  selectedTldForSpecialRequirements: null
};

const contactSetsSlice = createSlice({
  initialState,
  name: 'transfer',
  reducers: {
    setDisplayedContactSetHasChanged: (state, { payload }) => {
      state.displayedContactSetHasChanged = payload;
    },
    setDisplayedContactSetId: (state, { payload }) => {
      state.displayedContactSetId = payload;
    },
    setSelectedTldForSpecialRequirments: (state, { payload }) => {
      state.selectedTldForSpecialRequirements = payload;
    }
  }
});

export const {
  setDisplayedContactSetHasChanged,
  setDisplayedContactSetId,
  setSelectedTldForSpecialRequirments
} = contactSetsSlice.actions;

export const contactSetsReducer = contactSetsSlice.reducer;

export const selectDisplayedContactSetId = state =>
  state.contactSets.displayedContactSetId;

export const selectSelectedTldForSpecialRequirements = state =>
  state.contactSets.selectedTldForSpecialRequirements;

export const selectDisplayedContactSetHasChanged = state =>
  state.contactSets.displayedContactSetHasChanged;
