import {
  signOffContextSignerFormSchema,
  SignOffContextSignerFormSchema
} from './schema';
import type { FormAction } from '../../common/types/form';
import type { ApiUserResponse } from '../../common/types/user';
import type { ApiApprovedSignersResponse } from './sign-off-context';

export const emptyFormState = () =>
  signOffContextSignerFormSchema().getDefault();

export const signOffContextSignerFormReducer = (
  state: SignOffContextSignerFormSchema,
  action: FormAction
) => {
  switch (action.type) {
    case 'INIT':
      state.approvedSigners = [];

      const actionValue = action.value as [ApiApprovedSignersResponse] | null;

      if (actionValue != null) {
        state.approvedSigners = actionValue.map(
          (signerResponse: ApiApprovedSignersResponse) => signerResponse.user
        );
      }

      break;
    case 'UPDATE':
      state.approvedSigners = state.approvedSigners.map(
        (signer: ApiUserResponse) => {
          const currentSignerIsNoUserOption = signer.id === -1;

          if (currentSignerIsNoUserOption) {
            // replace no user option with newly added signer
            return <ApiUserResponse>action.value;
          } else {
            // signer is valid, let it stay in array
            return signer;
          }
        }
      );

      break;
    case 'ADD':
      const emptyValueAddedAlready = state.approvedSigners.some(
        (signer: ApiUserResponse) => signer.id === -1
      );

      if (!emptyValueAddedAlready) {
        state.approvedSigners.push(<ApiUserResponse>action.value);
      }

      break;
    case 'REMOVE':
      state.approvedSigners = state.approvedSigners.filter(
        (signer: ApiUserResponse) =>
          signer.id !== (<ApiUserResponse>action.value).id
      );

      break;
    default:
      break;
  }

  return { ...state };
};
