import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  CircularProgress,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import type { ApiSignOffContextResponse } from './sign-off-context';

import { selectActiveCustomer } from '../../common/store/customers-slice';
import { useGetApprovedSignersForCustomerAndContextIdQuery } from './sign-off-context-api-slice';

type SignOffContextPermissionCountCellProps = {
  row: ApiSignOffContextResponse;
};

// eslint-disable-next-line complexity
export const SignOffContextPermissionCountCell = ({
  row
}: SignOffContextPermissionCountCellProps) => {
  const theme = useTheme();
  const selectedCustomer = useSelector(selectActiveCustomer);

  const {
    data: approvedSignersApiResponse,
    isLoading: isApprovedSignersLoading,
    isUninitialized: isApprovedSignersUninitialized
  } = useGetApprovedSignersForCustomerAndContextIdQuery(
    {
      accountName: selectedCustomer.accountName,
      contextId: row?.id
    },
    {
      skip: row.id === undefined
    }
  );

  const showSpinner = () =>
    selectedCustomer !== undefined &&
    (isApprovedSignersLoading || isApprovedSignersUninitialized);

  const showSignerCount = () =>
    selectedCustomer !== undefined &&
    !isApprovedSignersLoading &&
    !isApprovedSignersUninitialized &&
    row.hasSpecifiedApprovers;

  const invalidSignerCount = () =>
    row.numApprovalsRequired > approvedSignersApiResponse?.length;

  return (
    <>
      {showSpinner() && <CircularProgress size={20} />}

      {showSignerCount() && !showSpinner() && (
        <>
          <Typography>
            {approvedSignersApiResponse?.length} / {row.numApprovalsRequired}
          </Typography>
          {invalidSignerCount() && (
            <Box sx={{ ml: 1 }}>
              <Tooltip title="Insufficient Number of Approvers">
                <FontAwesomeIcon
                  aria-label="signer-count-status-icon"
                  color={theme.palette.error.main}
                  icon={faTriangleExclamation}
                />
              </Tooltip>
            </Box>
          )}
        </>
      )}
      {!showSignerCount() && !showSpinner() && <Typography>-</Typography>}
    </>
  );
};
