import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  apiFilters: {},
  filterModel: {}
};

const getSearchStringApiFilters = ({ newFilterModel }) => {
  const searchFormatted = newFilterModel?.quickFilterValues.flatMap(term =>
    term.split(',')
  );
  return (searchFormatted ?? []).join(' ');
};

const tldResourcesFiltersSlice = createSlice({
  initialState,
  name: 'tldResourcesFilters',
  reducers: {
    updateFilters: (state, { payload }) => {
      const { newFilterModel } = payload;
      state.apiFilters = {
        searchTerm: getSearchStringApiFilters({ newFilterModel })
      };
    }
  }
});

export const tldResourcesFilterReducer = tldResourcesFiltersSlice.reducer;

export const { updateFilters } = tldResourcesFiltersSlice.actions;
export const selectApiFilters = state => state.tldResourcesFilters.apiFilters;
