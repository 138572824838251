import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { uniq } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SnackbarCloseAction } from '../../../../common/components/snackbar-close-action/SnackbarCloseAction';
import { selectCustomerName } from '../../../../common/store/customers-slice';
import { selectReseller } from '../../../../common/store/reseller';
import {
  useGetTldsPricingByCustomerQuery,
  useGetTldsQuery
} from '../../../../common/store/tlds-api-slice';
import {
  DOMAIN_REGISTRY_PATTERNS,
  isIdnSearchTerm
} from '../../../../common/utils/domains-regex';
import { getTldNameForDomain } from '../../../../common/utils/get-tld-name-for-domain';
import { useDomainCheckMutation } from '../../register-domains-api-slice';
import {
  selectIdnLangsMap,
  selectSearchString,
  selectSelectedSearchGroups,
  setSearchResultsTableData,
  setSearchString
} from '../../register-domains-slice';
import {
  domainAvailability,
  formatDomainAvailability,
  getCategorySearchTerms,
  getDomainCheckPayload,
  getTargetedSearchTerms,
  getTldPhaseName
} from '../../register-domains-utils';
import { delayedRegistrationValues } from '../domain-details/special-requirements';
import { CategoryTargetedSearchError } from './CategoryTargetedSearchError';
import { IdnCheckDialog } from './IdnCheckDialog';
import { RegisterDomainSearchGroupsDropdown } from './RegisterDomainsSearchGroupsDropdown';
import { RegisterDomainsSearchResultsTable } from './RegisterDomainsSearchResultsTable';
import { RegisterDomainsSearchTipsModal } from './RegisterDomainsSearchTipsModal';
import { domainCheckAvailabilities } from './domain-check-availabilities';
import {
  SEARCH_ERROR_MESSAGES,
  allEntriesAreTargeted,
  hasMixedEntries,
  payloadSchema
} from './schema';

export const RegisterDomainsSearch = () => {
  const dispatch = useDispatch();
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const searchString = useSelector(selectSearchString);
  const idnLangsMap = useSelector(selectIdnLangsMap);
  const { enqueueSnackbar } = useSnackbar();
  const [domainCheck] = useDomainCheckMutation({
    fixedCacheKey: 'domain-check-search-results'
  });
  const { data: supportedTlds } = useGetTldsQuery();
  const { data: tldsData } = useGetTldsPricingByCustomerQuery(
    {
      customerName,
      reseller
    },
    { skip: !customerName }
  );
  const idnTldsArray =
    tldsData?.filter(tld => tld.idn).map(tld => tld.name) ?? [];
  const [tableData, setTableData] = useState([]);
  //TODO: We may want to handle the individual table row loading states instead
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idnDomains, setIdnDomains] = useState([]);
  const [searchStringErrors, setSearchStringErrors] = useState({
    errorMessage: null,
    isError: false
  });
  const selectedSearchGroups = useSelector(selectSelectedSearchGroups);

  const isPreSunrise = domain =>
    delayedRegistrationValues.includes(getTldPhaseName(domain, tldsData));

  const isManual = domain => domain.$tldObject.manual;

  const onStreamValueNextHandler = streamValue => {
    let newTableData = [];
    let index;
    // This is going to update the state for every record we get back. This could lead to a lot of state updates in a short period of time.
    setTableData(prevTableData => {
      const itemToReplace = prevTableData.find(
        value => value.name === streamValue.domain
      );

      if (isPreSunrise(streamValue.domain)) {
        return [...prevTableData];
      } else if (isManual(streamValue)) {
        newTableData = [...prevTableData];
        const index = prevTableData.indexOf(itemToReplace);
        newTableData[index] = {
          ...streamValue,
          available: domainCheckAvailabilities.UNKNOWN_MANUAL
        };
        return newTableData;
      } else if (itemToReplace) {
        newTableData = [...prevTableData];
        index = prevTableData.indexOf(itemToReplace);
        newTableData[index] = streamValue;
        return newTableData;
      } else {
        return [...prevTableData, streamValue];
      }
    });
  };

  const onStreamValueCompleteHandler = returnValues => {
    const modifiedReturnedValues = returnValues.map(returnedValue => {
      const isManual = returnedValue.$tldObject?.manual ?? false;
      if (
        domainAvailability.includes(returnedValue.available) &&
        isPreSunrise(returnedValue.domain)
      ) {
        return {
          ...returnedValue,
          available: domainCheckAvailabilities.PRE_SUNRISE
        };
      } else if (isManual) {
        return {
          ...returnedValue,
          available: domainCheckAvailabilities.UNKNOWN_MANUAL
        };
      }

      return returnedValue;
    });
    const formattedValues = modifiedReturnedValues.map(value => ({
      ...value,
      $availableFormatted: formatDomainAvailability(value)
    }));

    dispatch(setSearchResultsTableData(formattedValues));
  };

  const getDomainAvailability = domain => {
    const tldPhaseName = getTldPhaseName(domain, tldsData);
    return delayedRegistrationValues.includes(tldPhaseName)
      ? domainCheckAvailabilities.PRE_SUNRISE
      : domainCheckAvailabilities.CHECKING;
  };

  const clearTableData = () => {
    setTableData([]);
    dispatch(setSearchResultsTableData([]));
  };

  const openSearchErrorSnackbar = (
    targetedSearchTerms,
    categorySearchTerms,
    errorMessage
  ) => {
    enqueueSnackbar(
      <CategoryTargetedSearchError
        categorySearchTerms={categorySearchTerms}
        errorMessage={errorMessage}
        targetedSearchTerms={targetedSearchTerms}
      />,
      {
        action: snackbarId => <SnackbarCloseAction snackbarId={snackbarId} />,
        persist: true,
        variant: 'error'
      }
    );
  };

  const getSearchStrings = () => {
    //Set searchString to a variable
    const displayedSearchString = searchString?.trim();
    //dispatch to update the string that is set in redux
    dispatch(setSearchString(displayedSearchString));
    //exit and clear table data if no search string
    if (!displayedSearchString) {
      clearTableData();
      return { splitSearchTerm: [], trimmedSearchString: '' };
    }
    const trimmedSearchString = displayedSearchString.replace(/,/g, ' ').trim();
    const splitSearchTerm = trimmedSearchString.split(
      DOMAIN_REGISTRY_PATTERNS.domainSearchSplitPattern
    );
    return { splitSearchTerm, trimmedSearchString };
  };

  const getTldList = () => {
    const currentSearchGroups = selectedSearchGroups ?? [];
    return uniq(currentSearchGroups.map(category => category.tlds).flat());
  };

  const getIdnDomains = (
    {
      idnTldList,
      searchStringHasTld,
      searchedDomains,
      splitSearchTerm,
      trimmedSearchString
    }
    // eslint-disable-next-line max-params
  ) => {
    let domains;
    if (searchStringHasTld) {
      domains =
        searchedDomains.length > 1 ? splitSearchTerm : [trimmedSearchString];
    } else {
      domains = idnTldList.flatMap(tld =>
        splitSearchTerm.map(domain => `${domain}.${tld}`)
      );
    }
    const idns = domains.filter(domain => isIdnSearchTerm(domain));
    return [...new Set(idns)];
  };

  const checkForIdns = event => {
    event.preventDefault();
    const { splitSearchTerm, trimmedSearchString } = getSearchStrings();

    if (hasMixedEntries(splitSearchTerm)) {
      handleSubmit();
      return;
    }

    const searchStringHasTld = trimmedSearchString.includes('.');
    const searchedDomains =
      splitSearchTerm.length > 1 ? splitSearchTerm : [trimmedSearchString];

    const tldList = searchStringHasTld
      ? searchedDomains.map(domain => getTldNameForDomain(domain))
      : getTldList();

    const hasIdns =
      searchedDomains.some(domain => isIdnSearchTerm(domain)) &&
      idnTldsArray.some(tld => tldList.includes(tld));

    if (!hasIdns) {
      handleSubmit();
      return;
    }

    const idnTldList = idnTldsArray.filter(tld => tldList.includes(tld));
    const idns = getIdnDomains({
      idnTldList,
      searchStringHasTld,
      searchedDomains,
      splitSearchTerm,
      trimmedSearchString
    });
    // Assigning values to IdnDomains triggers the IDN dialog, to assign IDN languages before performing the search.
    setIdnDomains(idns);
  };

  const handleSubmit = async () => {
    const { splitSearchTerm, trimmedSearchString } = getSearchStrings();
    const currentSearchGroups = selectedSearchGroups;
    const tldList = getTldList();
    if (
      !currentSearchGroups?.length &&
      !allEntriesAreTargeted(splitSearchTerm)
    ) {
      clearTableData();
      return;
    }

    payloadSchema()
      .validate(splitSearchTerm)
      .then(async () => {
        //Clear out any previous table data
        clearTableData();

        const payload = getDomainCheckPayload(
          tldList,
          trimmedSearchString,
          supportedTlds,
          idnLangsMap
        );
        //Add placeholders to tableData
        payload.responseBody.names.map(domain => {
          domain.domainName = domain.name;
          domain.years = '';
          domain.price = '';
          domain.available = getDomainAvailability(domain.name);
          return domain;
        });

        setTableData([
          ...payload.responseBody.names,
          ...payload.invalidDomainArray
        ]);

        return await domainCheck({
          customerName,
          idnLangsMap,
          // Placeholder function that gets called once all the domain results are returned
          onStreamValueCompleteHandler,
          onStreamValueNextHandler,
          reseller,
          searchString: trimmedSearchString,
          supportedTlds,
          tldList,
          tldsData
        });
      })
      .catch(error => {
        if (error.message === SEARCH_ERROR_MESSAGES.sameSearchType) {
          openSearchErrorSnackbar(
            getTargetedSearchTerms(trimmedSearchString),
            getCategorySearchTerms(trimmedSearchString),
            SEARCH_ERROR_MESSAGES.sameSearchType
          );
        } else if (
          error.message === SEARCH_ERROR_MESSAGES.invalidSearchString
        ) {
          setSearchStringErrors({
            errorMessage: SEARCH_ERROR_MESSAGES.invalidSearchString,
            isError: true
          });
        } else {
          setSearchStringErrors({
            errorMessage: 'An unknown error occurred. Try again.',
            isError: true
          });
        }

        clearTableData();
        return;
      });
  };

  const handleSearchInput = event => {
    if (event.target.value !== searchString && searchStringErrors.isError) {
      setSearchStringErrors({ errorMessage: null, isError: false });
    }
    dispatch(setSearchString(event.target.value));
  };

  const handleIdnDialogClose = () => {
    setIdnDomains([]);
  };

  useEffect(() => {
    // This useEffect triggers search submission once the IDN language tags are assigned to each domain.
    if (idnLangsMap && idnDomains.length > 0) {
      handleSubmit();
      setIdnDomains([]);
    }
  }, [idnLangsMap]);

  return (
    <Paper sx={{ height: '90%' }} variant="outlined">
      <Box sx={{ height: 'calc(100% - 60px)', padding: 2, pb: 5 }}>
        <Box>
          <Stack direction="row" justifyContent="space-between" mb={2}>
            <Typography variant="h5">Find and Register Domains</Typography>
            <Link
              component="button"
              onClick={() => setIsModalOpen(true)}
              underline="none"
            >
              Search Tips
            </Link>
          </Stack>
          <form onSubmit={checkForIdns}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton data-testid="searchButton" type="submit">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                autoComplete="off"
                error={searchStringErrors.isError}
                fullWidth
                helperText={searchStringErrors.errorMessage}
                name="domainSearch"
                onChange={handleSearchInput}
                placeholder="Search"
                size="small"
                sx={{ pr: 0, width: '50%' }}
                value={searchString ?? ''}
              />
              <RegisterDomainSearchGroupsDropdown />
            </Box>
          </form>
        </Box>

        <Box
          sx={{ display: 'flex', height: 'calc(100% - 116px)', marginTop: 4 }}
        >
          <RegisterDomainsSearchResultsTable
            streamingTableData={tableData}
            tldsData={tldsData}
          />
        </Box>
      </Box>

      <RegisterDomainsSearchTipsModal
        handleClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      />

      <IdnCheckDialog
        domains={idnDomains}
        handleClose={handleIdnDialogClose}
        isOpen={idnDomains.length > 0}
      />
    </Paper>
  );
};
