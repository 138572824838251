import { apiSlice } from '../../common/store/api-slice';

const whoIsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getWhoIs: builder.query({
      query: ({ domain }) => `/whois/${domain}`,
      transformResponse: response => {
        if (response.message) {
          return response.message.split('\n');
        }
        return [];
      }
    })
  })
});

export const { useGetWhoIsQuery } = whoIsApiSlice;
