import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField
} from '@mui/material';
import React, { ChangeEvent, FormEvent, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';

import { SignOffContextEditConfirmButton } from './SignOffContextEditConfirmButton';
import { signOffContextFormSchema } from './schema';
import { signOffContextEditFormReducer } from './sign-off-context-edit-form-reducer';
import { selectSelectedSignOffContext } from './sign-off-context-slice';

type SignOffContextEditDialogProps = {
  closeDialog: () => void;
  isOpen: boolean;
};

export const SignOffContextEditDialog = ({
  closeDialog,
  isOpen
}: SignOffContextEditDialogProps) => {
  const signOffContext = useSelector(selectSelectedSignOffContext);

  useEffect(() => {
    if (signOffContext) {
      formDataDispatch({
        errorMessage: null,
        path: null,
        type: 'INIT',
        value: signOffContext
      });
    }
  }, [signOffContext]);

  const handleRequiredValidation = ({ path }: { path: string }) =>
    formDataDispatch({
      errorMessage: 'This field is required',
      path,
      type: 'SET_ERROR',
      value: null
    });

  const handleMinimumApproverValidation = ({ path }: { path: string }) =>
    formDataDispatch({
      errorMessage: 'A minimum value of 1 is required',
      path,
      type: 'SET_ERROR',
      value: null
    });

  const handleSwitchChange = (
    event: ChangeEvent<HTMLInputElement>,
    path: string
  ) =>
    formDataDispatch({
      errorMessage: null,
      path,
      type: 'UPDATE',
      value: event.target.checked
    });

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    path: string
  ) =>
    formDataDispatch({
      errorMessage: null,
      path,
      type: 'UPDATE',
      value: event.target.value
    });

  const emptyFormState = () =>
    signOffContextFormSchema(
      handleRequiredValidation,
      handleMinimumApproverValidation
    ).getDefault();

  const [formData, formDataDispatch] = useReducer(
    signOffContextEditFormReducer,
    emptyFormState()
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const validateForm = () =>
    signOffContextFormSchema(
      handleRequiredValidation,
      handleMinimumApproverValidation
    ).validate(formData);

  const domainDeleteIsSelected = () =>
    signOffContext?.signOffContextType.signOffContext === 'DOMAIN_DELETE';

  return (
    <Dialog fullWidth onClose={closeDialog} open={isOpen}>
      <DialogTitle>{signOffContext?.$signOffContextNameFormatted}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.active}
                  data-testid="active-switch-sign-off-context-edit"
                  disabled={domainDeleteIsSelected()}
                  onChange={event => handleSwitchChange(event, 'active')}
                />
              }
              label="Active"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formData.forceForAllUsers}
                  disabled={domainDeleteIsSelected()}
                  onChange={event =>
                    handleSwitchChange(event, 'forceForAllUsers')
                  }
                />
              }
              label="Force for all users"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formData.hasSpecifiedApprovers}
                  disabled={domainDeleteIsSelected()}
                  onChange={event =>
                    handleSwitchChange(event, 'hasSpecifiedApprovers')
                  }
                />
              }
              label="Has Specified Approvers"
            />
            {formData.hasSpecifiedApprovers && (
              <TextField
                error={formData.numApprovalsRequired.hasError}
                helperText={formData.numApprovalsRequired.errorMessage}
                inputProps={{ type: 'number' }}
                label="Number of Approvals Required"
                onChange={event => handleChange(event, 'numApprovalsRequired')}
                size="small"
                sx={{ mt: 2, width: '60%' }}
                value={formData.numApprovalsRequired.value}
                variant="outlined"
              />
            )}
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <SignOffContextEditConfirmButton
          handleClose={closeDialog}
          validateForm={validateForm}
        />
      </DialogActions>
    </Dialog>
  );
};
