import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import React from 'react';

export const ContactSetsDialogTrigger = props => (
  <IconButton {...props}>
    <FontAwesomeIcon icon={faEye} />
  </IconButton>
);
