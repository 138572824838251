import { createSlice } from '@reduxjs/toolkit';

import { getDatesApiFilters } from '../../common/utils/get-dates-api-filters';
import { getLabelsApiFilters } from '../../common/utils/get-labels-api-filters';
import { getSearchStringApiFilters } from '../../common/utils/get-search-string-api-filters';

const initialState = { apiFilters: {}, filterModel: {} };

const web3FiltersSlice = createSlice({
  initialState,
  name: 'web3Filters',
  reducers: {
    updateFilters: (state, { payload }) => {
      const { newFilterModel } = payload;
      const { fromDate, toDate } = getDatesApiFilters(
        newFilterModel,
        'addedDate'
      );

      state.apiFilters = {
        find: getSearchStringApiFilters({ newFilterModel }),
        fromDate,
        labelIds: getLabelsApiFilters({ newFilterModel }).map(labelId =>
          labelId.toString()
        ),
        toDate
      };
    }
  }
});

export const web3FiltersReducer = web3FiltersSlice.reducer;

export const { updateFilters } = web3FiltersSlice.actions;

export const selectFilterModel = state => state.web3Filters.filterModel;
export const selectApiFilters = state => state.web3Filters.apiFilters;
