import { uniq } from 'lodash-es';

import {
  DOMAIN_REGISTRY_PATTERNS,
  isDomainNameWithoutTld,
  isDotNameDomainNameWithoutTld
} from '../../common/utils/domains-regex';
import { getTld } from '../../common/utils/get-tld';
import {
  formatForDisplay,
  formatPrice
} from '../../common/utils/number-price-text';
import {
  delayedRegistrationValues,
  getSpecialRequirements
} from './register-domains-steps/domain-details/special-requirements';
import { domainCheckAvailabilities } from './register-domains-steps/search/domain-check-availabilities';

const getInvalidDomainRow = domain => ({
  available: domainCheckAvailabilities['-'],
  country: '-',
  dnsConfigured: false,
  domain,
  localPresenceNexusProvidedByAppdetex: null,
  localPresenceNexusRequired: 'null',
  price: null,
  priceMethod: '-',
  region: '-',
  registryCost: null,
  registryCostCurrency: '-',
  restricted: false,
  restrictionRequirementNexus: null,
  tld: '-',
  transferRestrictionRequirements: '-',
  type: '-',
  unsupported: true,
  years: '-'
});

const getDomainNameObject = (name, tld, idnLangsMap) => {
  const domainName = `${name}.${tld}`;
  const idnLangTag = idnLangsMap[domainName];
  return idnLangTag ? { idnLangTag, name: domainName } : { name: domainName };
};

/**
 * Function to parse user search and chosen tlds into the domain check request payload
 * @param {String[]} tldList array of tld strings
 * @param {String} searchString string containing domain search term(s)
 * @param {Object[]} supportedTlds array of supported tld objects
 * @returns Object object containing domain check terms
 */

export const getDomainCheckPayload = (
  tldList,
  searchString,
  supportedTlds,
  idnLangsMap = {}
  // eslint-disable-next-line max-params
) => {
  const formattedSearchString = searchString.toLowerCase();

  const payload = {
    invalidDomainArray: [],
    responseBody: { names: [] }
  };
  const hasTlds = formattedSearchString.includes('.');
  const parsedSearchString = formattedSearchString.split(
    DOMAIN_REGISTRY_PATTERNS.domainSearchSplitPattern
  );

  // Map targeted search and filter out invalid domains, map targeted search, add tlds to category search
  if (hasTlds && supportedTlds) {
    payload.responseBody.names = uniq(parsedSearchString)
      .map(name => ({
        idnLangTag: idnLangsMap[name],
        name
      }))
      .filter(domain => {
        const enteredTld = domain.name.slice(domain.name.indexOf('.') + 1);
        return supportedTlds?.some(
          supportedTld => supportedTld.name === enteredTld
        );
      });
    payload.invalidDomainArray = parsedSearchString
      .filter(domain => {
        const userEnteredTld = domain.slice(domain.indexOf('.') + 1);
        return !supportedTlds.some(
          supportedTld => supportedTld.name === userEnteredTld
        );
      })
      .map(domain => getInvalidDomainRow(domain));
  } else {
    payload.responseBody.names = uniq(parsedSearchString)
      .map(name =>
        tldList.map(tld => {
          // the previous name validation was made against a .com tld.
          // verifying now against the specific search group tlds just in case
          if (isValidDomainName(name, tld)) {
            const domainNameObject = getDomainNameObject(
              name,
              tld,
              idnLangsMap
            );
            return domainNameObject;
          }
        })
      )
      .flat()
      .filter(Boolean);
  }
  return payload;
};

export const getDomainRegistrationValue = tldData => {
  const isDelayedRegistration = delayedRegistrationValues.includes(
    tldData.phase
  );
  if (tldData.manual) {
    return 'Manual';
  } else if (!tldData.manual && !isDelayedRegistration) {
    return 'Automated';
  } else if (isDelayedRegistration) {
    return 'Pre-Order';
  } else {
    return '';
  }
};

/**
 * Function that returns the tldPhaseName given the tldPhaseId
 * @param {number} tldPhaseId - the tldPhaseId
 * @param {Object[]} allPhases - the all phases object of the $tldObject
 * @returns {String} TldPhaseName capitalized
 */
export const getTldPhaseNameByPhaseId = (tldPhaseId, allPhases) => {
  return allPhases.find(phase => phase.id === tldPhaseId).name;
};

/**
 * Function to map cartItem values for display, merges data from api with data that
 * needs to be retained with the cartItem through the registration process
 * @param {Object} cartItem - The cartItem to receive mapped values
 * @param {Object} updatedDomainBody - The domain object associated with the cartItem
 * @returns {Object} Updated cartItem with mapped values
 */
export const getCartItemViewModel = (cartItem, updatedDomainBody) => ({
  ...cartItem,
  $dnssecSupported:
    updatedDomainBody.$tldObject.dnssecSupportType !== 'UNSUPPORTED',
  $isPremium: updatedDomainBody.$isPremium,
  $localPresencePrice: updatedDomainBody.$localPresencePrice,
  $registration: getDomainRegistrationValue(updatedDomainBody.$tldObject),
  $service: updatedDomainBody.$tldObject.web3
    ? 'Web3 Registration'
    : 'Registration',
  $statusFormatted: formatForDisplay(cartItem.status),
  $tldObject: updatedDomainBody.$tldObject,
  algorithmType: updatedDomainBody.algorithmType,
  digest: updatedDomainBody.digest,
  digestType: updatedDomainBody.digestType,
  keyTag: updatedDomainBody.keyTag,
  specialRequirements:
    updatedDomainBody.specialRequirements ??
    getSpecialRequirements(updatedDomainBody),
  tldPhaseName: getTldPhaseNameByPhaseId(
    cartItem.tldPhaseId,
    updatedDomainBody.$tldObject.allPhases
  )
});

/**
 * Function to map domain-check item with values for display
 * @param {Object} domainObject - The domain item being mapped
 * @param {Object[]} tldsData - Array of tld objects
 */
export const mapDomainObject = (domainObject, tldsData) => {
  domainObject.$tldObject = tldsData.find(tld => tld.name === domainObject.tld);
  domainObject.$localPresencePrice =
    domainObject.$tldObject.localPresenceOffered &&
    domainObject.$tldObject.prices?.Live?.localpresence
      ? domainObject.$tldObject.prices.Live.localpresence['1']
      : undefined;
  domainObject.$isPremium = domainObject.priceMethod?.endsWith('-premium');
  domainObject.$registryCost = domainObject.registryCost
    ? `${formatPrice(domainObject.registryCost)} ${
        domainObject.registryCostCurrency
      }`
    : '';
};

/**
 * Function to map cartItem in the resume order process
 * @param {Object} cartItem - The cartItem to receive mapped values
 * @param {Object[]} tldsData - Array of tld objects
 */
export const mapResumeCartItem = (cartItem, tldsData) => {
  const domain = cartItem.domainName;
  const domainTld = domain.slice(domain.indexOf('.') + 1);
  const tldObject = tldsData.find(tld => tld.name === domainTld);
  const isWeb3 = tldObject.web3;
  cartItem.tld = domainTld;
  cartItem.$tldObject = tldObject;
  cartItem.$localPresencePrice =
    cartItem.$tldObject.localPresenceOffered &&
    cartItem.$tldObject.prices?.Live?.localpresence
      ? cartItem.$tldObject.prices.Live.localpresence['1']
      : undefined;
  cartItem.$isPremium = cartItem.registrationPriceMethod?.endsWith('-premium');
  cartItem.$dnssecSupported =
    cartItem.$tldObject.dnssecSupportType !== 'UNSUPPORTED';
  cartItem.$registration = getDomainRegistrationValue(cartItem.$tldObject);
  cartItem.$service = isWeb3 ? 'Web3 Registration' : 'Registration';
  cartItem.tldPhaseName = getTldPhaseNameByPhaseId(
    cartItem.tldPhaseId,
    tldObject.allPhases
  );
  cartItem.$statusFormatted = formatForDisplay(cartItem.status);
  cartItem.specialRequirements = getSpecialRequirements(cartItem);
  return cartItem;
};

export const getTargetedSearchTerms = searchString =>
  searchString
    .split(DOMAIN_REGISTRY_PATTERNS.domainSearchSplitPattern)
    .filter(item => item.includes('.'));
export const getCategorySearchTerms = searchString =>
  searchString
    .split(DOMAIN_REGISTRY_PATTERNS.domainSearchSplitPattern)
    .filter(item => !item.includes('.'));

/**
 * @param params {object} - selected domain object
 * @param tldPhaseName {string} - tldPhaseName for which we want to get selectable years
 * @returns {Array} - array with the available number of years to select for a domain
 */
export const getSelectableYears = ({ params, tldPhaseName }) => {
  const isWeb3Domain = params.$tldObject.web3;
  const yearPriceObj = isWeb3Domain
    ? params.$tldObject.$prices[tldPhaseName]['web3 registration']
    : params.$tldObject.$prices[tldPhaseName].registration;
  return yearPriceObj.map(yearPrice => yearPrice.years);
};

/**
 * @param {String} domain - The domain name
 * @param {Object[]} tldsData - Array of tld objects
 * @returns {String} tldPhaseName - returns the domain's tld phase name
 */
export const getTldPhaseName = (domain, tldsData) =>
  getTld(domain, tldsData).phase;

// Used to count which domains are available to continue the registration process
export const domainAvailability = [
  domainCheckAvailabilities.YES,
  domainCheckAvailabilities.PRE_SUNRISE,
  domainCheckAvailabilities.UNKNOWN_MANUAL,
  domainCheckAvailabilities.UNKNOWN_WEB3
];

/**
 * @param {String} domainName - The domain name
 * @param {String} tld - the tld name
 * @returns {Boolean} domain name validity
 */
export const isValidDomainName = (domainName, tld = 'com') => {
  return tld === 'name'
    ? isDotNameDomainNameWithoutTld(domainName)
    : isDomainNameWithoutTld(domainName);
};

/**
 * @param {Object} domain - The domain name
 * @returns {String} Formatted availability status string
 */
export const formatDomainAvailability = domain => {
  switch (domain.available) {
    case 'YES':
      return 'AVAILABLE';
    case 'NO':
      return 'REGISTERED';
    case 'PENDING':
      return 'PENDING';
    case 'PRE_SUNRISE':
      return 'PRE_SUNRISE';
    case 'UNKNOWN_WEB3':
      return 'UNKNOWN_WEB3';
    case 'UNKNOWN_MANUAL':
      return 'UNKNOWN_MANUAL';
    case 'UNKNOWN_PHASE':
      return 'UNKNOWN_PHASE';
    case 'ERROR':
    default:
      return 'ERROR';
  }
};
