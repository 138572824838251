import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import { noop } from 'lodash-es';
import {
  bindDialog,
  bindToggle,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectSpecialRequirementsValidityById,
  setSpecialRequirementsValidity,
  updateById
} from '../../register-domains-slice';
import { ValidOrNotMemoized } from './ValidOrNotIcon';

export const SetDotTelSpecialRequirements = ({ domain, domainName, id }) => {
  const dispatch = useDispatch();
  const whoIsOptions = [
    { label: 'Natural Person', value: 'NATURAL' },
    { label: 'Legal Entity', value: 'LEGAL' }
  ];
  const [whoIsValue, setWhoIsValue] = useState(
    domain?.domainSpecialRequirement?.whoIsType ?? whoIsOptions[0]
  );
  const dialogState = usePopupState({
    popupId: 'setDotTelDialog',
    variant: 'dialog'
  });

  const isValid = useSelector(state =>
    selectSpecialRequirementsValidityById(state, id)
  );

  const handleClose = noop;

  const handleSubmit = event => {
    event.preventDefault();
    dispatch(
      updateById({
        changes: [
          {
            key: 'domainSpecialRequirement',
            value: {
              domainSpecialRequirementType: 'DOTTEL',
              whoisType: whoIsValue.value
            }
          }
        ],
        id
      })
    );
    dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }));
    dialogState.close();
  };

  const handleChange = (event, value) => {
    event.preventDefault();
    setWhoIsValue(value);
  };

  useEffect(() => {
    if (domain?.domainSpecialRequirement?.whoisType && !isValid) {
      dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, isValid]);

  return (
    <>
      <Stack alignItems="center" direction="row" spacing={1}>
        <Button size="small" {...bindTrigger(dialogState)}>
          Set requirements
        </Button>
        <ValidOrNotMemoized isValid={isValid} />
      </Stack>
      <Dialog {...bindDialog(dialogState)} maxWidth="xs">
        <form noValidate={true} onSubmit={handleSubmit}>
          <DialogTitle>Set Special Requirements for {domainName}</DialogTitle>
          <DialogContent>
            <Autocomplete
              disableClearable
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={handleChange}
              options={whoIsOptions}
              renderInput={params => (
                <TextField
                  {...params}
                  label={'WhoIs Type'}
                  variant="outlined"
                />
              )}
              size="small"
              sx={{ pt: 2, width: '100%' }}
              value={whoIsValue ?? null}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} {...bindToggle(dialogState)}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export const SetDotTelSpecialRequirementsMemoized = React.memo(
  SetDotTelSpecialRequirements
);
