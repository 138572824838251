import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { navigateToFirstRegistrationStep } from './registration-navigation';

export const MissingCart = () => {
  const navigate = useNavigate();
  return (
    <Stack
      alignContent="center"
      alignItems="center"
      spacing={5}
      sx={{ mt: '10%' }}
    >
      <Typography variant="h5">Your cart is empty.</Typography>
      <Button
        onClick={() => navigateToFirstRegistrationStep(navigate)}
        variant="contained"
      >
        Search for domains
      </Button>
    </Stack>
  );
};
