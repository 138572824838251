import { Link, Typography } from '@mui/material';
import React from 'react';

import styles from './LoginLayout.module.css';

export const RSafeTermsOfService = () => {
  return (
    <Typography className={styles.termsOfService} variant="body2">
      By accessing the RegistrarSafe system you agree to the{' '}
      <Link
        href="https://registrarsec.com/terms-and-conditions/"
        rel="noopener noreferrer"
        target="_blank"
        underline="hover"
      >
        Terms of Service
      </Link>{' '}
      and{' '}
      <Link
        href="https://registrarsec.com/privacy/"
        rel="noopener noreferrer"
        target="_blank"
        underline="hover"
      >
        Privacy Policy
      </Link>
      .
    </Typography>
  );
};
