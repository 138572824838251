import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

import { productTypeOptions } from './product-type-options';
import { handleChange } from './tld-cost-form-functions';

export const ProductTypeInput = ({
  debouncedValidation,
  formData,
  formDataDispatch
}) => {
  return (
    <Autocomplete
      disableClearable
      getOptionLabel={option => option.label}
      onChange={(event, value) =>
        handleChange({
          debouncedValidation,
          event,
          formDataDispatch,
          path: 'productType',
          value
        })
      }
      options={productTypeOptions}
      renderInput={params => (
        <TextField {...params} label="Product Type" variant="outlined" />
      )}
      size="small"
      sx={{ pt: 2, width: '100%' }}
      value={formData.productType.value}
    />
  );
};
