// TODO this is used in many places and belongs in /common
import { generateId } from '../utils/generate-id';
import { CUSTOMERS_TAG, apiSlice } from './api-slice';

export const customersApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getCustomerContacts: builder.query({
      query: ({ customerName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/contacts`
    }),
    getCustomers: builder.query({
      providesTags: [CUSTOMERS_TAG],
      query: ({ reseller }) =>
        `resellers/${reseller}/customers?maxResults=10000&offset=0`,
      transformResponse: ({ entries }) =>
        entries
          .filter(customer => customer.active)
          .map(customer => {
            customer.label =
              customer.organizationMainContactName ?? customer.accountName;
            customer.status = customer.active ? 'Active' : 'Inactive';
            customer.id = generateId();
            return customer;
          })
    }),
    newCustomer: builder.mutation({
      invalidatesTags: [CUSTOMERS_TAG],
      query: ({ payload, reseller }) => ({
        body: payload,
        method: 'PUT',
        url: `resellers/${reseller}/customers/${payload.accountName}`
      })
    }),
    updateCustomer: builder.mutation({
      invalidatesTags: [CUSTOMERS_TAG],
      query: ({ customerName, payload, reseller }) => ({
        body: payload,
        method: 'PUT',
        url: `resellers/${reseller}/customers/${customerName}`
      })
    })
  })
});

export const {
  useGetCustomerContactsQuery,
  useGetCustomersQuery,
  useNewCustomerMutation,
  useUpdateCustomerMutation
} = customersApiSlice;
