import { Box, Typography } from '@mui/material';
// eslint-disable-next-line import/named
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { ApiSignOffContextResponse } from './sign-off-context';

import { BooleanDataGridCell } from '../../common/components/grid-cell/BooleanDataGridCell';
import { selectActiveCustomer } from '../../common/store/customers-slice';
import { gridColumnWidths } from '../../common/utils/grid-column-widths';
import { SignOffContextEditCell } from './SignOffContextEditCell';
import { SignOffContextEditDialog } from './SignOffContextEditDialog';
import { SignOffContextPermissionCountCell } from './SignOffContextPermissionCountCell';
import { SignOffContextPermissionSignersCell } from './SignOffContextPermissionSignersCell';
import { SignOffContextPermissionSignersDialog } from './SignOffContextPermissionSignersDialog';
import { useGetAllSignOffContextsForAccountNameQuery } from './sign-off-context-api-slice';
import { setSelectedSignOffContext } from './sign-off-context-slice';

export const SignOffContext = () => {
  const dispatch = useDispatch();

  const selectedCustomer = useSelector(selectActiveCustomer);

  const [
    isSignOffContextPermissionSignersDialogOpen,
    setIsSignOffContextPermissionSignersDialogOpen
  ] = useState(false);
  const [isSignOffContextEditDialogOpen, setIsSignOffContextEditDialogOpen] =
    useState(false);

  const {
    data: signOffContextData,
    isFetching: isSignOffContextDataFetching,
    isUninitialized: isSignOffContextDataUninitialized
  } = useGetAllSignOffContextsForAccountNameQuery(
    {
      accountName: selectedCustomer!.accountName
    },
    {
      skip: selectedCustomer === undefined
    }
  );

  const columns = useMemo(
    (): GridColDef<ApiSignOffContextResponse>[] => [
      {
        field: '$signOffContextNameFormatted',
        filterable: false,
        flex: 1.25,
        headerName: 'Sign-Off Settings Name',
        sortable: false
      },
      {
        align: 'center',
        field: 'active',
        filterable: false,
        flex: 0.5,
        headerAlign: 'center',
        headerName: 'Active',
        renderCell: params => (
          <BooleanDataGridCell fieldDisplayName="Active" value={params.value} />
        ),
        sortable: false
      },
      {
        align: 'center',
        field: 'forceForAllUsers',
        filterable: false,
        flex: 1,
        headerAlign: 'center',
        headerName: 'Force For All Users',
        renderCell: params => (
          <BooleanDataGridCell
            fieldDisplayName="Force For All Users"
            value={params.value}
          />
        ),
        sortable: false
      },
      {
        align: 'center',
        field: 'hasSpecifiedApprovers',
        filterable: false,
        flex: 1,
        headerAlign: 'center',
        headerName: 'Has Specified Approvers',
        renderCell: params => (
          <BooleanDataGridCell
            fieldDisplayName="Has Specified Approvers"
            value={params.value}
          />
        ),
        sortable: false
      },
      {
        align: 'center',
        field: 'edit',
        filterable: false,
        flex: 0.5,
        headerAlign: 'center',
        headerName: 'Edit Settings',
        renderCell: params => (
          <SignOffContextEditCell
            params={params}
            setIsOpen={handleOpenSignOffContextEditDialog}
          />
        ),
        width: gridColumnWidths.icon.width
      },
      {
        align: 'center',
        field: 'user',
        filterable: false,
        flex: 0.5,
        headerAlign: 'center',
        headerName: 'Approvers',
        renderCell: params => (
          <SignOffContextPermissionSignersCell
            params={params}
            setIsOpen={handleOpenSignOffContextPermissionSignersDialog}
          />
        ),
        width: gridColumnWidths.icon.width
      },
      {
        align: 'center',
        field: 'signerCount',
        flex: 0.5,
        headerAlign: 'center',
        headerName: 'Approver Count',
        renderCell: params => (
          <SignOffContextPermissionCountCell row={params.row} />
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signOffContextData]
  );

  const handleOpenSignOffContextEditDialog = (contextId: number) => {
    dispatch(
      setSelectedSignOffContext(
        signOffContextData!.find(
          (context: ApiSignOffContextResponse) => context.id === contextId
        )
      )
    );

    setIsSignOffContextEditDialogOpen(true);
  };

  const handleOpenSignOffContextPermissionSignersDialog = (
    contextId: number
  ) => {
    dispatch(
      setSelectedSignOffContext(
        signOffContextData!.find(
          (context: ApiSignOffContextResponse) => context.id === contextId
        )
      )
    );

    setIsSignOffContextPermissionSignersDialogOpen(true);
  };

  return (
    <>
      <Box sx={{ height: '100%', width: '100%' }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            height: '48px',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="h4">
            {selectedCustomer?.organizationMainContactName === 'None'
              ? 'No Customer Selected'
              : selectedCustomer?.organizationMainContactName}{' '}
            Sign-Off Settings
          </Typography>
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: 'calc(100% - 48px)',
            justifyContent: 'center'
          }}
        >
          <DataGridPro
            columns={columns}
            disableRowSelectionOnClick
            disableVirtualization={import.meta.env.MODE === 'test'}
            loading={
              isSignOffContextDataFetching || isSignOffContextDataUninitialized
            }
            rows={signOffContextData ?? []}
            sx={{ backgroundColor: 'white', height: '100%' }}
          />
        </Box>
      </Box>
      <SignOffContextPermissionSignersDialog
        closeDialog={() =>
          setIsSignOffContextPermissionSignersDialogOpen(false)
        }
        isOpen={isSignOffContextPermissionSignersDialogOpen}
      />
      <SignOffContextEditDialog
        closeDialog={() => {
          setIsSignOffContextEditDialogOpen(false);
        }}
        isOpen={isSignOffContextEditDialogOpen}
      />
    </>
  );
};
