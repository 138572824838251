import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetContactsQuery } from '../../common/store/contacts-api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import { selectReseller } from '../../common/store/reseller';
import { addTransfer } from './transfer-slice';

export const AddTransferButton = () => {
  const dispatch = useDispatch();
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const { data: contactSets, isLoading: isContactSetsLoading } =
    useGetContactsQuery({ customerName, reseller }, { skip: !customerName });

  const defaultContactSet = contactSets?.find(contact => contact.defaultSet);

  const handleAddTransfer = () =>
    dispatch(addTransfer({ defaultContactSetId: defaultContactSet?.id }));

  return (
    <Button
      disabled={!defaultContactSet || isContactSetsLoading}
      onClick={handleAddTransfer}
      size="small"
      startIcon={<FontAwesomeIcon icon={faPlus} />}
    >
      Add transfer
    </Button>
  );
};
