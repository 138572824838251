import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { SignOffContextFormSchema } from './schema';
import { useUpdateSignOffContextMutation } from './sign-off-context-api-slice';
import { selectSelectedSignOffContext } from './sign-off-context-slice';

type SignOffContextEditConfirmButtonProps = {
  handleClose: () => void;
  validateForm: () => Promise<SignOffContextFormSchema>;
};

export const SignOffContextEditConfirmButton = ({
  handleClose,
  validateForm
}: SignOffContextEditConfirmButtonProps) => {
  const selectedSignOffContext = useSelector(selectSelectedSignOffContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [updateSignOffContext] = useUpdateSignOffContextMutation();

  const submitContextUpdates = (formData: SignOffContextFormSchema) => {
    updateSignOffContext({
      signOffContextId: selectedSignOffContext?.id,
      signOffContextUpdateRequest: {
        ...formData,
        numApprovalsRequired: formData.numApprovalsRequired.value
      }
    })
      .unwrap()
      .then(() => {
        handleClose();
        setIsSubmitting(false);

        enqueueSnackbar('Sign-off settings updated successfully.', {
          variant: 'success'
        });
      })
      .catch(() => {
        setIsSubmitting(false);

        enqueueSnackbar(
          'An unknown error occurred while updating sign-off settings. Please try again or contact support.',
          {
            variant: 'error'
          }
        );
      });
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    validateForm()
      .then(formData => submitContextUpdates(formData))
      .catch(() => setIsSubmitting(false));
  };

  return (
    <LoadingButton
      disabled={isSubmitting}
      loading={isSubmitting}
      onClick={handleSubmit}
    >
      Submit
    </LoadingButton>
  );
};
