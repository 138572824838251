import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Stack
} from '@mui/material';
import {
  bindDialog,
  bindToggle,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectSpecialRequirementsValidityById,
  setSpecialRequirementsValidity
} from '../../register-domains-slice';
import { ValidOrNotMemoized } from './ValidOrNotIcon';

export const SetDotDeSpecialRequirements = ({ domain, domainName, id }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const dialogState = usePopupState({
    popupId: 'setDotDeDialog',
    variant: 'dialog'
  });

  const isValid = useSelector(state =>
    selectSpecialRequirementsValidityById(state, id)
  );

  const handleSubmit = event => {
    event.preventDefault();
    dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }));
    dialogState.close();
  };

  useEffect(() => {
    if (domain.specialRequirements.isValid && !isValid) {
      dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, domainName, isValid]);

  return (
    <>
      <Stack alignItems="center" direction="row" spacing={1}>
        <Button size="small" {...bindTrigger(dialogState)}>
          Acknowledgement
        </Button>
        <ValidOrNotMemoized isValid={isValid} />
      </Stack>
      <Dialog {...bindDialog(dialogState)} maxWidth="sm">
        <form noValidate={true} onSubmit={handleSubmit}>
          <DialogTitle>Set Special Requirements for {domainName}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {domainName} must have name servers configured within 30 days. If
              the domains above are not configured with public DNS within that
              timeframe, the registry will cancel the registration.
            </DialogContentText>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  data-testid="checkbox"
                  onChange={() => setChecked(!checked)}
                />
              }
              label="By checking this box you agree to the statement above"
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button {...bindToggle(dialogState)}>Cancel</Button>
            <Button
              disabled={!checked}
              onClick={handleSubmit}
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export const SetDotDeSpecialRequirementsMemoized = React.memo(
  SetDotDeSpecialRequirements
);
