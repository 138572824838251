import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { noop } from 'lodash-es';
import React from 'react';

export const DotFiForm = ({ specialRequirements }) => {
  const contactTypes = Object.freeze({
    ASSOCIATION: 'Association',
    COMPANY: 'Organization',
    GOVERNMENT: 'Government',
    INSTITUTION: 'Institution',
    MUNICIPALITY: 'Municipality',
    PERSON: 'Person',
    POLITICAL_PARTY: 'Political party',
    PUBLIC_COMMUNITY: 'Public community'
  });
  return (
    <>
      <TextField
        disabled
        label="Contact Type"
        value={contactTypes[specialRequirements.DOTFI.type]}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={specialRequirements.DOTFI.finnish}
            disabled
            onChange={noop}
          />
        }
        label="Contact is Finnish"
      />
      <TextField
        disabled
        label="Register Number"
        value={specialRequirements.DOTFI.registerNumber}
      />
    </>
  );
};
