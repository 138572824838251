import { sharedConfig } from './shared-config';

export const envProduction = Object.freeze({
  ...sharedConfig,
  API_HOST: 'https://rest.registrar-prod.tracer.ai',
  API_PATH_PREFIX: '/rest/api/v1/',
  DEFAULT_TABLE_PAGE_NUMBER: 1,
  DEFAULT_TABLE_ROWS_PER_PAGE: 100,
  DEFAULT_TABLE_ROWS_PER_PAGE_OPTIONS: [25, 50, 100, 200, 500],
  ENABLE_REDUX_DEV_TOOLS: false,
  ENV: 'production',
  LEGACY_HOST: 'https://legacy-ui.registrar-prod.tracer.ai'
});
