import { logos } from '../components/logo/logos';
import { sharedConfig } from './shared-config';

export const envProductionRegistrarSec = Object.freeze({
  ...sharedConfig,
  API_HOST: 'https://rest.registrarsec.com',
  APP_TITLE: 'RegistrarSEC',
  ENABLE_REDUX_DEV_TOOLS: false,
  ENV: 'production',
  HAS_FAVICON: false,
  LEGACY_HOST: 'https://legacy-ui.registrarsec.com',
  LOGO_KEY: logos.REGISTRAR_SEC.key
});
