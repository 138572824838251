import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
import { useGetWhoIsQuery } from './whois-api-slice';
import {
    TextField,
    Paper,
    Typography,
    InputAdornment,
    IconButton,
    CircularProgress,
    Box, FormControl
} from '@mui/material';
import { Stack } from '@mui/system';
import { generateId } from '../../common/utils/generate-id';

export const WhoIsSearch = () => {
  const [input, setInput] = useState('');
  const [domain, setDomain] = useState('');
  const { data: messageLines = [], isFetching } = useGetWhoIsQuery(
    { domain },
    { skip: domain === '' }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setDomain(input);
  };

  return (
    <>
      <Paper sx={{ padding: 2 }}>
        <Stack spacing={2}>
          <Typography variant="h4">WHOIS Search</Typography>
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth>
              <form onSubmit={handleSubmit}>
                <TextField
                  sx={{ width: '50%' }}
                  label="Domain"
                  value={input}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          type="submit"
                          aria-label="Search button"
                        >
                          {isFetching ? (
                            <CircularProgress size="1.5rem" />
                          ) : (
                            <SearchIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </form>
            </FormControl>
          </Stack>

          <Box padding={2}>
            {messageLines.map((line: string) => {
              const key = generateId();
              return line !== '' ? (
                <Typography key={key}>{line}</Typography>
              ) : (
                <br key={key} />
              );
            })}
          </Box>
        </Stack>
      </Paper>
    </>
  );
};
