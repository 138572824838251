import { Link, Typography } from '@mui/material';
import React from 'react';

import styles from './LoginLayout.module.css';

export const TermsOfService = () => {
  return (
    <Typography className={styles.termsOfService} variant="body2">
      By accessing the Tracer system you agree to the{' '}
      <Link
        href="https://www.tracer.ai/legal/terms-of-service"
        rel="noopener noreferrer"
        target="_blank"
        underline="hover"
      >
        Terms of Service
      </Link>{' '}
      and{' '}
      <Link
        href="https://www.tracer.ai/legal/privacy-policy"
        rel="noopener noreferrer"
        target="_blank"
        underline="hover"
      >
        Privacy Policy
      </Link>
      .
    </Typography>
  );
};
