import { Alert, Divider, Grid, Link, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  useCancelEmailVerificationMutation,
  useGetContactsQuery,
  useResendEmailVerificationMutation
} from '../../store/contacts-api-slice';
import { selectCustomerName } from '../../store/customers-slice';
import { selectReseller } from '../../store/reseller';
import { ContactSetLoading } from './ContactSetLoading';
import { selectDisplayedContactSetId } from './contact-sets-slice';
import { ContactSpecialRequirements } from './contact-special-requirements/ContactSpecialRequirements';

export const ContactSetsReadOnlyForm = ({ contactSetId, contactSetType }) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const displayedContactSetId = useSelector(selectDisplayedContactSetId);
  const { enqueueSnackbar } = useSnackbar();

  const contactSetIdToFind = displayedContactSetId || contactSetId;

  const { contactByType } = useGetContactsQuery(
    {
      customerName,
      reseller
    },
    {
      selectFromResult: ({ data }) => {
        if (contactSetId === -1 && !displayedContactSetId) {
          return {
            contactByType: {
              $fax: '',
              $faxPrefix: '',
              $phone: '',
              $phonePrefix: '',
              address1: '',
              address2: '',
              city: '',
              company: '',
              country: '',
              created: '',
              email: '',
              extension: '',
              fax: '',
              firstName: '',
              lastName: '',
              phone: '',
              specialRequirementsByTld: {},
              state: '',
              zipCode: ''
            }
          };
        } else {
          return {
            contactByType: (data?.find(
              contactSet => contactSet.id === contactSetIdToFind
            ) || data?.find(contactSet => contactSet.defaultSet === true))?.[
              contactSetType
            ]
          };
        }
      },
      skip: !customerName
    }
  );

  const contactHasSpecialRequirements = contactByType
    ? Object.keys(contactByType?.specialRequirementsByTld).length !== 0
    : false;

  const [
    resendVerificationEmail,
    { isError: isResendFailure, isSuccess: isResendSuccess }
  ] = useResendEmailVerificationMutation();
  const [
    cancelEmailVerification,
    { isError: isCancelFailure, isSuccess: isCancelSuccess }
  ] = useCancelEmailVerificationMutation();

  const handleResendVerificationClick = (event, emailAddress) => {
    event.preventDefault();
    resendVerificationEmail({ customerName, emailAddress, reseller });
  };

  const handleCancelVerificationClick = (event, emailAddress) => {
    event.preventDefault();
    cancelEmailVerification({ customerName, emailAddress, reseller });
  };

  useEffect(() => {
    if (isResendSuccess) {
      enqueueSnackbar('The email verification has been resent.', {
        variant: 'success'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResendSuccess]);

  useEffect(() => {
    if (isCancelSuccess) {
      enqueueSnackbar('The email verification has been succesfully canceled.', {
        variant: 'success'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancelSuccess]);

  useEffect(() => {
    if (isResendFailure) {
      enqueueSnackbar('Unable to resend the email verification at this time.', {
        variant: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResendFailure]);

  useEffect(() => {
    if (isCancelFailure) {
      enqueueSnackbar('Unable to cancel the email verification at this time.', {
        variant: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancelFailure]);

  const getValueForControlledInput = contactValue => contactValue ?? '';

  return !contactByType ? (
    <ContactSetLoading />
  ) : (
    <Stack spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label="First Name"
            value={contactByType.firstName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label="Country"
            value={contactByType.country}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label="Last Name"
            value={contactByType.lastName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label="State"
            value={getValueForControlledInput(contactByType.state)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label="Company name"
            value={contactByType.company}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label="City"
            value={contactByType.city}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label="Email"
            value={contactByType.email}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label="Zip/Postal Code"
            value={contactByType.zipCode}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label="Address"
            value={contactByType.address1}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            disabled
            label="Phone Prefix"
            value={contactByType.$phonePrefix}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled
            fullWidth
            label="Phone"
            value={contactByType.$phone}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label="Address 2"
            value={getValueForControlledInput(contactByType.address2)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            disabled
            label="Fax Prefix"
            value={getValueForControlledInput(contactByType.$faxPrefix)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled
            fullWidth
            label="Fax"
            value={getValueForControlledInput(contactByType.$fax)}
          />
        </Grid>
        <Grid item xs={6}>
          {contactByType.unverifiedEmail && (
            <Alert severity="error">
              {`The email address ${contactByType.unverifiedEmail?.email} is awaiting verification. You can `}
              <Link
                component="button"
                onClick={event =>
                  handleResendVerificationClick(
                    event,
                    contactByType.unverifiedEmail?.email
                  )
                }
                sx={{ pb: '2px' }}
                variant="body2"
              >
                resend
              </Link>
              {' the link or '}
              <Link
                component="button"
                onClick={event =>
                  handleCancelVerificationClick(
                    event,
                    contactByType.unverifiedEmail?.email
                  )
                }
                sx={{ pb: '2px' }}
                variant="body2"
              >
                cancel
              </Link>
              {' the verification.'}
            </Alert>
          )}
        </Grid>
        <Grid item xs={2}>
          <TextField
            disabled
            label="Extension"
            value={getValueForControlledInput(contactByType.extension)}
          />
        </Grid>
      </Grid>
      {contactHasSpecialRequirements && (
        <>
          <Divider />
          <ContactSpecialRequirements
            specialRequirements={contactByType.specialRequirementsByTld}
          />
        </>
      )}
    </Stack>
  );
};
