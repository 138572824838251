import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem } from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  selectIsCustomer,
  selectIsSuperAdmin,
  selectIsSuperAdminOrFinance
} from '../../../features/auth/auth-slice';
import { selectLegacyHost } from '../../config/config';
import { useAppInfoQuery } from '../../store/app-info-api-slice';
import { selectCustomerName } from '../../store/customers-slice';
import { useGetTransferTokenMutation } from '../../store/transfer-api-slice';
import { TransferLoading } from '../TransferLoading';
import headerStyles from './Header.module.css';
import styles from './HeaderPopover.module.css';

// NOTE the code to transfer a user to the angular app also exists
// in SettingsMenu.jsx. Any fixes here may need to be applied there also.
// cstuart May 20, 2023
export const HeaderPopover = () => {
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const isCustomer = useSelector(selectIsCustomer);
  const isSuperAdminOrFinance = useSelector(selectIsSuperAdminOrFinance);

  const navigate = useNavigate();
  const customerName = useSelector(selectCustomerName);
  const legacyHost = useSelector(selectLegacyHost);
  const [getTransferToken, { data, isError, isLoading }] =
    useGetTransferTokenMutation();
  const [redirectTo, setRedirectTo] = useState();

  const { data: appInfoData } = useAppInfoQuery();

  const navigateToLegacy = path => {
    setRedirectTo(path);
    getTransferToken();
  };

  useEffect(() => {
    if (data?.transferToken && redirectTo) {
      const url = new URL(legacyHost);
      url.pathname = 'transfer.html';
      const searchParams = url.searchParams;
      searchParams.append('token', data.transferToken);
      searchParams.append('accountName', customerName);
      searchParams.append('dest', redirectTo);
      url.search = searchParams.toString();

      // eslint-disable-next-line testing-library/no-debugging-utils
      window.console.debug('redirecting to ', url.toString());
      window.location.assign(url.toString());
    }
  }, [customerName, data, legacyHost, redirectTo]);

  // Redirect to login if transferring fails
  useEffect(() => {
    if (isError) {
      navigate('/login', {
        state: {
          isTransferError: true,
          redirectTo
        }
      });
    }
  }, [isError, navigate, redirectTo]);

  const isSuperAdminOrFinanceOrCustomer = isCustomer || isSuperAdminOrFinance;
  const isSuperAdminOrCustomer = isCustomer || isSuperAdmin;

  const isZoneManagementEnabled =
    appInfoData?.zoneManagementEnabled === undefined
      ? false
      : appInfoData.zoneManagementEnabled;

  return isSuperAdminOrFinanceOrCustomer ? (
    <>
      <PopupState popupId="demo-popup-menu" variant="popover">
        {popupState => (
          <>
            <button
              className={`${styles.moreMenuTrigger} ${headerStyles.primaryLink}`}
              {...bindTrigger(popupState)}
            >
              More
              <FontAwesomeIcon
                className={styles.moreMenuArrow}
                icon={faCaretDown}
              />
            </button>
            <Menu {...bindMenu(popupState)}>
              {isSuperAdminOrCustomer && (
                <MenuItem
                  component="div"
                  onClick={() => {
                    popupState.close();
                    navigate('/messages/customer/');
                  }}
                >
                  Messages
                </MenuItem>
              )}
              {isSuperAdminOrFinanceOrCustomer && (
                <MenuItem
                  component="div"
                  onClick={() => {
                    popupState.close();
                    navigate('/invoices/customer/');
                  }}
                >
                  Invoices
                </MenuItem>
              )}
              {isSuperAdminOrCustomer && (
                <MenuItem
                  component="div"
                  onClick={() => {
                    popupState.close();
                    navigate('transactions');
                  }}
                >
                  Transactions
                </MenuItem>
              )}
              {isSuperAdminOrCustomer && (
                <MenuItem
                  component="div"
                  onClick={() => {
                    popupState.close();
                    navigate('tld-resources');
                  }}
                >
                  TLD Resources
                </MenuItem>
              )}
              {isSuperAdminOrFinance && (
                <MenuItem
                  component="div"
                  onClick={() => {
                    popupState.close();
                    navigate('/tld-costs');
                  }}
                >
                  TLD Costs
                </MenuItem>
              )}
              {isSuperAdminOrFinance && (
                <MenuItem
                  component="div"
                  onClick={() => {
                    popupState.close();
                    navigateToLegacy('pricing');
                  }}
                >
                  TLD Pricing
                </MenuItem>
              )}
              {isSuperAdminOrCustomer && (
                <MenuItem
                  component="div"
                  onClick={() => {
                    popupState.close();
                    navigate('whois');
                  }}
                >
                  WHOIS Search
                </MenuItem>
              )}
              {isSuperAdminOrCustomer && isZoneManagementEnabled && (
                <MenuItem
                  component="div"
                  onClick={() => {
                    popupState.close();
                    navigate('/zone-management/zones');
                  }}
                >
                  Zone Management
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </PopupState>

      {isLoading && <TransferLoading />}
    </>
  ) : null;
};
