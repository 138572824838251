import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Button } from '@mui/material';
import {
  // eslint-disable-next-line import/named
  GridToolbarContainer
} from '@mui/x-data-grid-pro';
import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentDateTimeForFileName } from '../../../../common/utils/date-time';
import { downloadFile } from '../../../../common/utils/grid-export-utils';
import { selectTotalPriceOfSelectedDomains } from '../../register-domains-slice';

export const RegisterDomainsCheckoutToolbar = ({ apiRef }) => {
  const totalPrice = useSelector(selectTotalPriceOfSelectedDomains);
  const handleExportClick = () => {
    const csvData = apiRef.current
      .getDataAsCsv()
      .concat(`\n,,,,Total:,$${totalPrice}`);

    downloadFile(
      `${getCurrentDateTimeForFileName()}_cart_export`,
      'data:text/csv;charset=UTF-8,' + encodeURIComponent(csvData)
    );
  };
  return (
    <GridToolbarContainer>
      <Button
        onClick={handleExportClick}
        size="medium"
        startIcon={<SaveAltIcon />}
      >
        Export
      </Button>
    </GridToolbarContainer>
  );
};
