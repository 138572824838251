import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  TextField
} from '@mui/material';
import { noop } from 'lodash-es';
import {
  bindDialog,
  bindToggle,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { selectCustomerName } from '../../common/store/customers-slice';
import { useGetCustomerDomainsQuery } from '../../common/store/domains-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { getTldNameForDomain } from '../../common/utils/get-tld-name-for-domain';
import { ValidOrNotMemoized } from './ValidOrNotIcon';
import { fieldSchema, formSchema } from './set-name-servers-schema';
import {
  selectSpecialRequirementsValidityById,
  setSpecialRequirementsValidity
} from './transfer-slice';

export const SetNameServers = ({ domainName, id }) => {
  const isBeDomain = getTldNameForDomain(domainName) === 'be';
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const dispatch = useDispatch();
  const [validationErrors, setValidationErrors] = useState(false);
  const isValid = useSelector(state =>
    selectSpecialRequirementsValidityById(state, id)
  );

  const { data: domainsResponse, isFetching } = useGetCustomerDomainsQuery(
    {
      customerName,
      find: domainName,
      reseller
    },
    { skip: !isBeDomain }
  );

  const dialogState = usePopupState({
    popupId: 'setNameServersDialog',
    variant: 'dialog'
  });

  const handleClose = () => {
    // TODO should this clear the form? Probably.
  };

  const handleSubmit = () => {
    formSchema(
      handleNameServersValidation(true),
      handleUniquenessValidation(true)
    )
      .validate(formData)
      .then(() => {
        setValidationErrors(false);
      })
      .then(() =>
        dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }))
      )
      .then(dialogState.close)
      .catch(e => {
        window.console.error(e);
        setValidationErrors(true);
        dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }));
      });
  };

  const getField = (handleNameServersValidation, value) =>
    fieldSchema(handleNameServersValidation, value).getDefault();

  const handleNameServersValidation =
    forceTouch =>
    ({ path }) =>
      formDataDispatch({
        errorMessage: 'Name server is not valid',
        forceTouch,
        path,
        type: 'SET_ERROR'
      });

  const handleUniquenessValidation = forceTouch => () =>
    formDataDispatch({
      errorMessage: 'Name servers must be unique',
      forceTouch,
      type: 'SET_UNIQUE_ERROR'
    });

  const [formData, formDataDispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'INIT':
          state = action.data.map(value =>
            getField(handleNameServersValidation(), value)
          );
          break;
        case 'ADD_FIELD':
          state = state.concat(getField(handleNameServersValidation()));
          break;
        case 'REMOVE_FIELD':
          state = state.filter(field => field.key !== action.key);
          break;
        case 'UPDATE_FIELD':
          state = state.map(field => {
            if (field.key === action.key) {
              field.value = action.value;
              field.touched = true;
              field.errorMessage = '';
              field.hasError = false;
            }
            return field;
          });

          break;
        case 'SET_ERROR':
          // Pull the array index from the path
          const erroredIndex = Number(action.path.match(/(\d+)/)[0]);
          state = state.map((field, index) => {
            if (index === erroredIndex) {
              field.errorMessage = action.errorMessage;
              field.hasError = field.touched || action.forceTouch;
            }
            return field;
          });

          break;
        case 'SET_UNIQUE_ERROR':
          state = state.map(field => {
            if (
              state.filter(otherField => field.value === otherField.value)
                .length > 1
            ) {
              field.errorMessage = 'Name servers must be unique.';
              field.hasError = field.touched;
            } else {
              field.errorMessage = '';
              field.hasError = false;
            }
            return field;
          });

          break;
      }

      if (state.length < 2) {
        state = state.concat(getField(handleNameServersValidation()));
      }

      return state;
    },
    [
      getField(handleNameServersValidation()),
      getField(handleNameServersValidation())
    ]
  );

  const handleAddMore = () =>
    formDataDispatch({
      type: 'ADD_FIELD'
    });

  const handleRemove = key => formDataDispatch({ key, type: 'REMOVE_FIELD' });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedValidation = useDebouncedCallback(
    formSchema =>
      formSchema(handleNameServersValidation(), handleUniquenessValidation())
        .validate(formData)
        .catch(noop),
    1500
  );

  const handleChange = (value, key) => {
    formDataDispatch({
      key,
      type: 'UPDATE_FIELD',
      value
    });
    debouncedValidation(formSchema);
  };

  const isBeDomainAndIsAlreadyInAccount =
    isBeDomain && domainsResponse?.totalCount === 1;

  useEffect(() => {
    if (isBeDomainAndIsAlreadyInAccount) {
      dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBeDomainAndIsAlreadyInAccount]);

  return isFetching || isBeDomainAndIsAlreadyInAccount ? null : (
    <>
      <Button size="small" {...bindTrigger(dialogState)}>
        Set name servers
      </Button>
      <ValidOrNotMemoized isValid={isValid} />
      <Dialog {...bindDialog(dialogState)}>
        <DialogTitle>Set Name Servers</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>
              Add at least two name servers for {domainName}.
            </DialogContentText>
            {validationErrors && (
              <Alert severity="error">Fix form errors before submitting.</Alert>
            )}
            {formData.map((nameServer, index) => (
              <Stack direction="row" key={nameServer.key}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Remove"
                          color="inherit"
                          onClick={() => handleRemove(nameServer.key)}
                          size="small"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={nameServer.hasError}
                  fullWidth
                  helperText={nameServer.errorMessage}
                  label={`Name server ${index + 1}`}
                  onChange={event =>
                    handleChange(event.target.value, nameServer.key)
                  }
                  type="text"
                  value={nameServer.value}
                  variant="standard"
                />
              </Stack>
            ))}
          </Stack>
          <Button
            color="secondary"
            onClick={handleAddMore}
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            sx={{ mb: 2, mt: 3 }}
            variant="contained"
          >
            Add more
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} {...bindToggle(dialogState)}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const SetNameServersMemoized = React.memo(SetNameServers);
