import { Typography } from '@mui/material';
import React from 'react';

export const AvailabilityMessage = ({ message }) => {
  return (
    <div>
      <Typography variant="body2">Availability</Typography>
      <Typography variant="caption">{message}</Typography>
    </div>
  );
};
