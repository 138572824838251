import {
  LABEL_ASSOCIATION_TAG,
  LABEL_TAG,
  apiSlice
} from '../../common/store/api-slice';

export const labelsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addLabelAssociations: builder.mutation({
      invalidatesTags: [LABEL_ASSOCIATION_TAG, LABEL_TAG],
      query: ({ customerName, domainNames, labelId, reseller }) => ({
        body: labelId
          ? domainNames.map(domainName => ({
              domainName,
              label: {
                id: labelId
              }
            }))
          : domainNames
              .map(domainNameObj =>
                domainNameObj?.labels?.map(({ id }) => ({
                  domainName: domainNameObj.domainName,
                  label: {
                    id
                  }
                }))
              )
              .flat()
              .filter(change => change?.label?.id),
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/labels/associations`
      })
    }),
    deleteLabelAssociations: builder.mutation({
      invalidatesTags: [LABEL_ASSOCIATION_TAG, LABEL_TAG],
      query: ({ associationIds, customerName, reseller }) => ({
        method: 'DELETE',
        url: `resellers/${reseller}/customers/${customerName}/labels/associations/${associationIds.join(
          ','
        )}`
      })
    }),
    getLabelAssociations: builder.query({
      providesTags: [LABEL_ASSOCIATION_TAG],
      query: ({ customerName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/labels/associations`
    }),
    getLabels: builder.query({
      providesTags: [LABEL_TAG],
      query: ({ customerName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/labels`,
      transformResponse: response => ({
        flat: response
          .map(labelGroup =>
            labelGroup.labels.map(label => ({
              billing: labelGroup.billing,
              color: labelGroup.color,
              groupAndName: `${labelGroup.name}: ${label.name}`,
              id: label.id,
              label: label.name,
              labelGroupId: labelGroup.id,
              labelGroupName: labelGroup.name
            }))
          )
          .flat(),
        grouped: response
      })
    }),
    updateLabels: builder.mutation({
      invalidatesTags: [LABEL_TAG],
      query: ({ customerName, labels, reseller }) => ({
        body: labels,
        method: 'PUT',
        url: `resellers/${reseller}/customers/${customerName}/labels`
      })
    })
  })
});

export const {
  useAddLabelAssociationsMutation,
  useDeleteLabelAssociationsMutation,
  useGetLabelAssociationsQuery,
  useGetLabelsQuery,
  useUpdateLabelsMutation
} = labelsApiSlice;
