import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, useTheme } from '@mui/material';
import React from 'react';

const ValidOrNot = ({ isValid, message = <></> }) => {
  const theme = useTheme();
  if (typeof isValid !== 'boolean') {
    return null;
  }

  return isValid ? (
    <Tooltip title={message}>
      <FontAwesomeIcon
        aria-label="Valid Icon"
        color={theme.palette.success.main}
        icon={faCheck}
      />
    </Tooltip>
  ) : (
    <Tooltip title={message}>
      <FontAwesomeIcon
        aria-label="Invalid Icon"
        color={theme.palette.error.main}
        icon={faTimes}
      />
    </Tooltip>
  );
};

export const ValidOrNotMemoized = React.memo(ValidOrNot);
