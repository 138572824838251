import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import DOMPurify from 'dompurify';
import {
  bindDialog,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTldNameForDomain } from '../../../../common/utils/get-tld-name-for-domain';
import {
  selectSpecialRequirementsValidityById,
  setSpecialRequirementsValidity
} from '../../register-domains-slice';
import { ValidOrNotMemoized } from './ValidOrNotIcon';

export const SetCheckoutMessage = ({
  checkoutMessages,
  domain,
  domainName,
  id
}) => {
  const dispatch = useDispatch();
  const dialogState = usePopupState({
    popupId: 'setCheckoutMessageDialog',
    variant: 'dialog'
  });

  const isValid = useSelector(state =>
    selectSpecialRequirementsValidityById(state, id)
  );

  const handleSubmit = event => {
    event.preventDefault();
    dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }));
    dialogState.close();
  };

  const checkoutMessage = checkoutMessages?.find(
    message => message.tld.name === getTldNameForDomain(domainName)
  )?.checkoutMessage;

  var purifiedCheckoutMessage = DOMPurify.sanitize(checkoutMessage, {
    ALLOWED_ATTR: ['style', 'href', 'target'],
    ALLOWED_TAGS: ['br', 'div', 'strong', 'p', 'a', 'ul', 'li', 'b', 'i', 'em']
  });

  useEffect(() => {
    if (domain.specialRequirements.isValid && !isValid) {
      dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, domainName, isValid]);

  return (
    <>
      <Stack alignItems="center" direction="row" spacing={1}>
        <Button size="small" {...bindTrigger(dialogState)}>
          Acknowledgement
        </Button>
        <ValidOrNotMemoized isValid={isValid} />
      </Stack>
      <Dialog {...bindDialog(dialogState)} maxWidth="sm">
        <form noValidate={true} onSubmit={handleSubmit}>
          <DialogTitle>Set Special Requirements for {domainName}</DialogTitle>
          <DialogContent sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: purifiedCheckoutMessage
              }}
              variant="body1"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit} type="submit" variant="contained">
              Done
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export const SetCheckoutMessageMemoized = React.memo(SetCheckoutMessage);
