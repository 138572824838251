import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import React from 'react';

export const RegisterDomainsSearchTipsModal = ({ handleClose, isOpen }) => {
  const style = {
    bgcolor: 'secondary.light',
    border: '1px solid',
    borderColor: 'secondary.contrast',
    borderRadius: '3px',
    boxShadow: 24,
    left: '50%',
    p: 4,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800
  };

  return (
    <Modal aria-label="modal-search-tips" onClose={handleClose} open={isOpen}>
      <Box sx={style}>
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          // width: 40px is to give button equal x/y dimensions
          sx={{ position: 'absolute', right: 1, top: 1, width: '40px' }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
        <Typography gutterBottom variant="h5">
          Searching without a TLD
        </Typography>
        <Typography sx={{ paddingBottom: 3 }}>
          Entering in a domain without a TLD will search for that domain using
          all TLDs in your selected Search Group. The search will return up to
          50 results, depending on the group chosen. When searching without a
          TLD, only a single domain is allowed.
        </Typography>
        <Typography gutterBottom variant="h5">
          Targeted searches with a TLD
        </Typography>
        <Typography>
          A specific domain can also be searched for by adding the TLD. It is
          possible to search for multiple fully-qualified domains by separating
          them with either a comma or a space, e.g. example.com example.org
          example.feedback. Searches with a TLD are not affected by the Search
          Group that is selected. Searches using domains with a TLD may not be
          mixed with domains that do not have a TLD.
        </Typography>
      </Box>
    </Modal>
  );
};
