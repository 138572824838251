import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { selectCustomerName } from '../../common/store/customers-slice';
import {
  useCreateOrderMutation,
  usePriceCheckMutation
} from '../../common/store/orders-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { selectClientId } from '../auth/auth-slice';
import {
  navigateToErrorStep,
  navigateToNextTransferStep
} from './transfer-navigation';
import {
  selectTransfers,
  selectUserAgrees
} from './transfer-slice';

export const InitiateTransferButton = ({ isNextDisabled }) => {
  const navigate = useNavigate();
  const stepNameParam = useParams().stepName;
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const clientId = useSelector(selectClientId);
  const transfers = useSelector(selectTransfers);

  const [
    createOrder,
    {
      isError: isCreateOrderError,
      isLoading: isCreateOrderLoading,
      isSuccess: isCreateOrderSuccess
    }
  ] = useCreateOrderMutation();

  const { domainServiceAgreementAccepted, domainServiceAgreementAcceptedDate } =
    useSelector(selectUserAgrees);

  const orderOptions = {
    currency: 'USD',
    domainServiceAgreementAccepted,
    domainServiceAgreementAcceptedDate
  };

  // TODO move to utility function
  const lineItems = transfers.map((transfer, lineItemNum) => ({
    authCode: transfer.authCode,
    clientLineItemId: transfer.id,
    customerContactSetId: transfer.customerContactSetId,
    domain: transfer.domainName,
    lineItemNum,
    lineItemType: 'DOMAIN_TRANSFER',
    price: 1,
    useLocalPresence: transfer.useLocalPresence,
    years: transfer.years
  }));

  const [
    priceCheck,
    { data: priceCheckData, isLoading: isLoadingPrice, isSuccess }
  ] = usePriceCheckMutation();

    useEffect(() => {
        if (customerName) {
            priceCheck({
                clientId,
                customerName,
                lineItems,
                orderOptions: {
                    ...orderOptions,
                    domainServiceAgreementAccepted: true,
                    domainServiceAgreementAcceptedDate: dayjs().toISOString()
                },
                reseller
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerName]);

  const handleNextClick = () => {
    createOrder({ clientId, customerName, lineItems, orderOptions, reseller });
  };

  useEffect(() => {
    if (isCreateOrderSuccess) {
      navigateToNextTransferStep(navigate, stepNameParam);
    }
    if (isCreateOrderError) {
      navigateToErrorStep(navigate);
    }
  }, [isCreateOrderSuccess, isCreateOrderError, navigate, stepNameParam]);

  return (
    <LoadingButton
      disabled={isNextDisabled || !isSuccess}
      endIcon={<FontAwesomeIcon icon={faChevronRight} width={12} />}
      loading={isCreateOrderLoading || isLoadingPrice}
      onClick={handleNextClick}
      variant="contained"
    >
      Finish{' '}
      {priceCheckData?.totalPrice && `(${priceCheckData?.$totalPriceFormatted})`}
    </LoadingButton>
  );
};
