import { TextField } from '@mui/material';
import React from 'react';

import { formatForDisplay } from '../../../../utils/number-price-text';

export const DotNoForm = ({ specialRequirements }) => {
  const identityTypes = Object.freeze({
    LOCAL_IDENTITY: 'Local Identity',
    NATIONAL_IDENTITY: 'National Identity',
    ORGANIZATION: 'Organization',
    PERSON: 'Person'
  });

  return (
    <>
      <TextField
        disabled
        label="Contact Type"
        value={formatForDisplay(specialRequirements.DOTNO.noContactType)}
      />
      <TextField
        disabled
        label="Identity Number"
        value={specialRequirements.DOTNO.identityNumber}
      />
      <TextField
        disabled
        label="Identity Type"
        value={identityTypes[specialRequirements.DOTNO.identityType]}
      />
    </>
  );
};
