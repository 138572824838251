/**
 * @param {string} authCode
 * @returns {Object} object containing message and isError keys
 */
export const getAuthCodeValidity = authCode => {
  const validAuthRegEx = new RegExp(/^\S{2,255}$/);
  if (validAuthRegEx.test(authCode) || !authCode) {
    return {
      isError: false
    };
  } else {
    return {
      isError: true,
      message: `${authCode} is an invalid auth code`
    };
  }
};
