import { createSlice } from '@reduxjs/toolkit';

const initialState = { selectedZoneIds: [], selectedZones: [] };

const selectedZonesSlice = createSlice({
  initialState,
  name: 'selectedZones',
  reducers: {
    removeSelectedZone: (state, { payload }) => {
      state.selectedZoneIds = state.selectedZoneIds.filter(
        zoneId => zoneId !== payload
      );

      state.selectedZones = state.selectedZones.filter(
        zone => zone.id !== payload
      );
    },
    setSelectedZones: (state, { payload }) => {
      state.selectedZones = payload;
      state.selectedZoneIds = payload.map(domain => domain?.id);
    }
  }
});

export const selectedZonesReducer = selectedZonesSlice.reducer;

export const { removeSelectedZone, setSelectedZones } =
  selectedZonesSlice.actions;

export const selectSelectedZones = state => state.selectedZones?.selectedZones;

export const selectSelectedZoneIds = state =>
  state.selectedZones?.selectedZoneIds;

export const selectSelectedZonesCount = state =>
  state.selectedZones?.selectedZones.length;
