import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import { noop } from 'lodash-es';
import {
  bindDialog,
  bindToggle,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import {
  selectSpecialRequirementsValidityById,
  setSpecialRequirementsValidity,
  updateById
} from '../../register-domains-slice';
import { ValidOrNotMemoized } from './ValidOrNotIcon';
import { formSchema } from './set-intended-use-special-requirements-schema';

export const SetIntendedUseRequirements = ({ domain, domainName, id }) => {
  const dispatch = useDispatch();
  const [validationErrors, setValidationErrors] = useState(false);
  const dialogState = usePopupState({
    popupId: 'setIntendedUseDialog',
    variant: 'dialog'
  });

  const isValid = useSelector(state =>
    selectSpecialRequirementsValidityById(state, id)
  );

  const handleClose = noop;

  useEffect(() => {
    if (domain.specialRequirements.isValid && !isValid) {
      dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, domainName, isValid]);

  const handleSubmit = event => {
    event.preventDefault();
    setValidationErrors(false);
    formSchema({
      handleRequiredValidation: handleRequiredValidation(true)
    })
      .validate(formData)
      .then(() =>
        dispatch(
          updateById({
            changes: [
              {
                key: 'domainSpecialRequirement',
                value: {
                  domainSpecialRequirementType: 'INTENDED_USE',
                  intendedUse: formData.intendedUse.value
                }
              }
            ],
            id
          })
        )
      )
      .then(() =>
        dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }))
      )
      .then(dialogState.close)
      .catch(e => {
        window.console.error(e);
        setValidationErrors(true);
        setSpecialRequirementsValidity({ domainName, isValid: false });
      });
  };

  const handleRequiredValidation =
    forceTouch =>
    ({ path }) => {
      formDataDispatch({
        errorMessage: 'This field is required',
        forceTouch,
        path,
        type: 'SET_ERROR'
      });
    };

  const [formData, formDataDispatch] = useReducer((state, action) => {
    if (action.path) {
      action.path = action.path.split('.')[0];
    }
    switch (action.type) {
      case 'INIT':
        state.isInit = true;
        state.intendedUse.value = domain?.domainSpecialRequirement?.intendedUse;
        break;
      case 'UPDATE':
        state[action.path].value = action.value;
        state[action.path].touched = true;
        state[action.path].errorMessage = '';
        state[action.path].hasError = false;
        break;
      case 'SET_ERROR':
        state[action.path].hasError =
          state[action.path].touched || action.forceTouch;
        state[action.path].errorMessage =
          state[action.path].hasError && action.errorMessage;
        break;
      default:
        break;
    }

    return { ...state };
  }, formSchema({}).getDefault());

  const debouncedValidation = useDebouncedCallback(
    formSchema =>
      formSchema({
        handleRequiredValidation: handleRequiredValidation(false)
      })
        .validate(formData)
        .catch(window.console.error),
    1500
  );

  const handleChange = (event, path) => {
    formDataDispatch({
      path,
      type: 'UPDATE',
      value: event.target.value
    });
    debouncedValidation(formSchema);
  };

  useEffect(() => {
    if (domain?.domainSpecialRequirement && formData && !formData?.isInit) {
      formDataDispatch({ patch: '', type: 'INIT', value: '' });
      dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain.domainSpecialRequirement, formData]);

  return (
    <>
      <Stack alignItems="center" direction="row" spacing={1}>
        <Button size="small" {...bindTrigger(dialogState)}>
          Set intended use
        </Button>
        <ValidOrNotMemoized isValid={isValid} />
      </Stack>
      <Dialog {...bindDialog(dialogState)} maxWidth="xs">
        <form noValidate={true} onSubmit={handleSubmit}>
          <DialogTitle>Set Special Requirements for {domainName}</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              {validationErrors && (
                <Alert severity="error">
                  Fix form errors before submitting.
                </Alert>
              )}
              <TextField
                error={formData.intendedUse?.hasError}
                fullWidth
                helperText={formData.intendedUse?.errorMessage}
                inputProps={{ maxLength: 255 }}
                label="Intended Use"
                margin="none"
                onChange={event => handleChange(event, 'intendedUse')}
                value={formData.intendedUse?.value}
                variant="standard"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} {...bindToggle(dialogState)}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export const SetIntendedUseRequirementsMemoized = React.memo(
  SetIntendedUseRequirements
);
