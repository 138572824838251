import {
  RECORD_TYPES_TAG,
  ZONE_MANAGEMENT_TAG,
  apiSlice
} from '../../common/store/api-slice';

export const zoneManagementApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getActiveZonesForCustomer: builder.query({
      providesTags: [ZONE_MANAGEMENT_TAG],
      query: ({ customerName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/zone-management`,
      transformResponse: ({ entity }) =>
        entity.map(zone => {
          zone.recordSets = zone.recordSets.map(recordSet => {
            recordSet.$isUsingBareDomainName =
              recordSet.dnsName === zone.dnsName;

            recordSet.$recordDataDetails = recordSet.recordData.join(', ');

            return recordSet;
          });

          return zone;
        })
    }),
    getValidRecordTypes: builder.query({
      providesTags: [RECORD_TYPES_TAG],
      query: ({ customerName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/zone-management/record-types`,
      transformResponse: ({ entity }) => entity
    })
  })
});

export const {
  useGetActiveZonesForCustomerQuery,
  useGetValidRecordTypesQuery
} = zoneManagementApiSlice;
