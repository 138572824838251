import { Box, Link, Popover } from '@mui/material';
import {
  bindPopover,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React from 'react';

import { Label } from './Label';

export const LabelCell = ({ value }) => {
  const popoverState = usePopupState({
    popupId: 'labelPopover',
    variant: 'popover'
  });
  return (
    <div>
      <Link {...bindTrigger(popoverState)}>
        {value.map(label => (
          <Label key={label.id} label={label} />
        ))}
      </Link>
      <Popover
        {...bindPopover(popoverState)}
        sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: '300px' }}
      >
        {value.map(label => (
          <Box key={label.id} m={1}>
            <Label key={label.id} label={label} />
          </Box>
        ))}
      </Popover>
    </div>
  );
};
