import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { SignOffDialog } from '../../common/components/sign-offs/SignOffDialog';
import {
  entityTypes,
  useJobResponse
} from '../../common/hooks/use-job-response';
import { ZONE_MANAGEMENT_TAG } from '../../common/store/api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import { useCreateOrderMutation } from '../../common/store/orders-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { generateId } from '../../common/utils/generate-id';
import { selectClientId } from '../auth/auth-slice';
import { selectSelectedZones } from './selected-zone-slice';

export const ZoneDeleteConfirmButton = ({ popupState }) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const clientId = useSelector(selectClientId);
  const selectedZones = useSelector(selectSelectedZones);

  const lineItems = useRef([]);
  const clientLineItemIds = useRef([]);
  const shouldStartPolling = useRef(false);

  const [needsForceUpdate, setNeedsForceUpdate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const orderOptions = {
    currency: 'USD',
    paymentType: 'NONE'
  };

  const [
    createOrder,
    {
      data: orderCreationResponse,
      isError: isCreateOrderError,
      isSuccess: isCreateOrderSuccess
    }
  ] = useCreateOrderMutation();

  useJobResponse({
    entityType: entityTypes.zone,
    failureMessage: `Failed to delete ${lineItems.current.length} zones. Please try again later or contact support.`,
    lineItems: lineItems.current,
    onComplete: () => {
      setIsSubmitting(false);

      popupState.close();
    },
    shouldStartPolling: shouldStartPolling.current,
    skipCondition: !isCreateOrderSuccess,
    successMessage: `Successfully deleted ${lineItems.current.length} zone${
      lineItems.current.length > 1 ? 's' : ''
    }`,
    tagsToInvalidate: [ZONE_MANAGEMENT_TAG]
  });

  useEffect(() => {
    if (isCreateOrderError) {
      enqueueSnackbar(
        `An unexpected error occurred while trying to submit the delete request. Please contact support`,
        { variant: 'error' }
      );

      popupState.close();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOrderError]);

  useEffect(() => {
    if (isCreateOrderSuccess && orderCreationResponse) {
      const { forceUpdateRequired, signOffRequired } = orderCreationResponse;

      if (signOffRequired && forceUpdateRequired) {
        // prompt for re-sending order request
        setNeedsForceUpdate(true);
      } else if (signOffRequired) {
        // sign off required toast
        handleSignOff();
      } else {
        shouldStartPolling.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOrderSuccess, orderCreationResponse]);

  const handleSignOff = () => {
    enqueueSnackbar('Your delete request is now pending sign-off', {
      variant: 'info'
    });

    setIsSubmitting(false);

    popupState.close();
  };

  const handleZoneDelete = ({ forceUpdate = false }) => {
    setIsSubmitting(true);

    lineItems.current = selectedZones.map((zone, index) => {
      const clientLineItemId = generateId();

      clientLineItemIds.current =
        clientLineItemIds.current.concat(clientLineItemId);

      return {
        clientLineItemId: clientLineItemId,
        customerZoneId: zone.id,
        lineItemNum: index,
        lineItemType: 'ZONE_DELETE',
        serviceProviderName: zone.serviceProviderName,
        zoneName: zone.zoneName
      };
    });

    createOrder({
      clientId,
      customerName,
      lineItems: lineItems.current,
      orderOptions,
      reseller,
      signOffForceUpdate: forceUpdate
    });
  };

  const handleForceUpdate = () => handleZoneDelete({ forceUpdate: true });

  const handleCloseDialog = () => popupState.close();

  return (
    <>
      <LoadingButton
        disabled={isSubmitting}
        loading={isSubmitting}
        onClick={handleZoneDelete}
        variant="contained"
      >
        Delete
      </LoadingButton>
      <SignOffDialog
        handleClose={handleCloseDialog}
        handleSubmit={handleForceUpdate}
        isOpen={needsForceUpdate}
      />
    </>
  );
};
