import { List, ListItem, Typography } from '@mui/material';
import React from 'react';

export const ContactSetValidityMessage = ({ errorMessage, isVisible }) => {
  return (
    isVisible && (
      <div>
        <Typography variant="body2">Contact Set Invalid</Typography>
        <List dense sx={{ listStyleType: 'disc', pl: 2 }}>
          {errorMessage.map(reason => (
            <ListItem key={reason} sx={{ display: 'list-item', pl: 0 }}>
              {reason}
            </ListItem>
          ))}
        </List>
      </div>
    )
  );
};
