import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Button } from '@mui/material';
import {
  // eslint-disable-next-line import/named
  GridToolbarContainer
} from '@mui/x-data-grid-pro';
import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentDateTimeForFileName } from '../../common/utils/date-time';
import { downloadFile } from '../../common/utils/grid-export-utils';
import { AddTransferButton } from './AddTransferButton';
import { selectTransferTotal, selectTransfers } from './transfer-slice';

export const TransferDetailsGridToolbar = ({ apiRef }) => {
  const totalPrice = useSelector(selectTransferTotal);
  const transfers = useSelector(selectTransfers);
  const handleExportClick = () => {
    let csvData = apiRef.current.getDataAsCsv();
    // Adds local presence line items to export
    transfers?.forEach(transfer => {
      if (transfer.useLocalPresence) {
        csvData = csvData.concat(
          `\n${transfer.domainName}: Local Presence,,,${transfer.$localPresenceCostFormatted},,`
        );
      }
    });

    csvData = csvData.concat(`\n,,Total:,$${totalPrice},,`);

    downloadFile(
      `${getCurrentDateTimeForFileName()}_transfer_export`,
      'data:text/csv;charset=UTF-8,' + encodeURIComponent(csvData)
    );
  };

  return (
    <GridToolbarContainer>
      <AddTransferButton />
      <Button
        onClick={handleExportClick}
        size="small"
        startIcon={<SaveAltIcon />}
      >
        Export
      </Button>
    </GridToolbarContainer>
  );
};
