import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { registerDomainsApiSlice } from './register-domains-api-slice';
import { domainAvailability } from './register-domains-utils';

export const initialState = {
  cart: [],
  cartInfo: null,
  cartIsValid: false,
  clientLineItems: [],
  domainServiceAcceptedDate: null,
  hasAcceptedDomainAgreement: false,
  haveCartItemsWithSpecialRequirements: false,
  idnLangsMap: {},
  searchResultsTableData: [],
  searchString: null,
  selectedSearchGroups: null,
  steps: ['Search', 'Overview', 'Domain Details', 'Checkout', 'Status'],
  supportedTlds: []
};

const registerDomainsSlice = createSlice({
  extraReducers: builder => {
    builder.addMatcher(
      registerDomainsApiSlice.endpoints.addDomainToCart.matchFulfilled,
      (state, { payload }) => {
        state.cart = [...state.cart, payload];
        state.haveCartItemsWithSpecialRequirements = state.cart.some(
          cartItem => cartItem.specialRequirements
        );
      }
    ),
      builder.addMatcher(
        registerDomainsApiSlice.endpoints.updateDomainInCart.matchFulfilled,
        (state, { payload }) => {
          state.cart = state.cart.map(domain => {
            if (domain.id === payload.id) {
              return payload;
            } else {
              return domain;
            }
          });
          state.haveCartItemsWithSpecialRequirements = state.cart.some(
            cartItem => cartItem.specialRequirements
          );
        }
      );
    builder.addMatcher(
      registerDomainsApiSlice.endpoints.removeDomainFromCart.matchFulfilled,
      (state, { meta }) => {
        state.cart = state.cart.filter(
          domain => domain.id !== meta.arg.originalArgs.domainId
        );
        state.haveCartItemsWithSpecialRequirements = state.cart.some(
          cartItem => cartItem.specialRequirements
        );
      }
    ),
      builder.addMatcher(
        registerDomainsApiSlice.endpoints.createNewCart.matchFulfilled,
        (state, { payload }) => {
          state.cartInfo = payload;
        }
      ),
      builder.addMatcher(
        registerDomainsApiSlice.endpoints.removeCart.matchFulfilled,
        state => {
          state.cartInfo = null;
        }
      );
  },
  initialState,
  name: 'registerDomains',
  reducers: {
    bulkUpdateCart: (state, { payload }) => {
      state.cart = payload;
    },
    deselectAllDomains: state => {
      state.cart = [];
      state.haveCartItemsWithSpecialRequirements = false;
    },
    deselectDomain: (state, action) => {
      state.cart = state.cart.filter(
        domain => domain.domain !== action.payload.domainName
      );
      state.haveCartItemsWithSpecialRequirements = state.cart.some(
        domain => domain.specialRequirements
      );
    },
    resetRegisterProcess: () => initialState,
    resumeCart: (state, action) => {
      state.cartInfo = action.payload;
      state.cart = action.payload.cartItems;
      state.haveCartItemsWithSpecialRequirements = state.cart.some(
        domain => domain.specialRequirements
      );
      state.cartIsValid = !state.cart.some(cartItem => !cartItem.isValid);
    },
    selectAllDomains: state => {
      state.cart = state.searchResultsTableData.filter(domain =>
        domainAvailability.includes(domain.available)
      );
      state.haveCartItemsWithSpecialRequirements = state.cart.some(
        domain => domain.specialRequirements
      );
    },
    selectDomain: (state, action) => {
      state.cart = [...state.cart, action.payload];
      state.haveCartItemsWithSpecialRequirements = state.cart.some(
        domain => domain.specialRequirements
      );
    },
    setCartItemValidity: (state, { payload }) => {
      state.cart.find(
        cartItem => cartItem.domainName === payload.domainName
      ).isValid = payload.isValid;
      state.cartIsValid = !state.cart.some(cartItem => !cartItem.isValid);
    },
    setCartValidity: (state, { payload }) => {
      state.cartIsValid = payload.isValid;
    },
    setClientLineItems: (state, action) => {
      state.clientLineItems = action.payload;
    },
    setHasAcceptedDomainAgreement: (state, { payload }) => {
      state.hasAcceptedDomainAgreement = payload.value;
      state.domainServiceAcceptedDate = payload.value
        ? dayjs().toISOString()
        : null;
    },
    setIdnLangsMap: (state, action) => {
      state.idnLangsMap = action.payload;
    },
    setSearchResultsTableData: (state, action) => {
      state.searchResultsTableData = action.payload;
    },
    setSearchString: (state, action) => {
      state.searchString = action.payload;
    },
    setSelectedSearchGroups: (state, action) => {
      state.selectedSearchGroups = action.payload;
    },
    setSpecialRequirementsValidity: (
      state,
      { payload: { domainName, isValid } }
    ) => {
      state.cart = state.cart.map(cartItem => {
        if (cartItem.domainName === domainName) {
          cartItem.specialRequirements.isValid = isValid;
        }
        return cartItem;
      });
      state.cartIsValid = !state.cart.some(cartItem => !cartItem.isValid);
    },
    updateById: (state, { payload }) => {
      state.cart = state.cart.map(cartItem => {
        if (cartItem.id === payload.id) {
          payload.changes.forEach(change => {
            cartItem[change.key] = change.value;
          });
        }
        return cartItem;
      });

      state.haveCartItemsWithSpecialRequirements = state.cart.some(
        cartItem => cartItem.specialRequirements
      );
      state.cartIsValid = !state.cart.some(cartItem => !cartItem.isValid);
    },
    updateDomainYearsSelected: (state, { payload }) => {
      state.cart = state.cart.map(domain => {
        if (domain.id === payload.id) {
          domain = payload;
          domain.totalPrice = null;
        }
        return domain;
      });
    },
    updateSelectedDomain: (state, { payload }) => {
      state.cart = state.cart.map(domain => {
        if (domain.id === payload.id) {
          domain = payload;
        }
        return domain;
      });
    }
  }
});

export const {
  bulkUpdateCart,
  deselectAllDomains,
  deselectDomain,
  resetRegisterProcess,
  resumeCart,
  selectAllDomains,
  selectDomain,
  setCartItemValidity,
  setCartValidity,
  setClientLineItems,
  setHasAcceptedDomainAgreement,
  setIdnLangsMap,
  setSearchResultsTableData,
  setSearchString,
  setSelectedSearchGroups,
  setSpecialRequirementsValidity,
  updateById,
  updateDomainYearsSelected,
  updateSelectedDomain
} = registerDomainsSlice.actions;

export const registerDomainsReducer = registerDomainsSlice.reducer;

export const selectSteps = state => state.registerDomains.steps;
export const selectCart = state => state.registerDomains.cart;
export const selectIsCartEmpty = state =>
  state.registerDomains.cart.length === 0;
export const selectSearchResultsTableData = state =>
  state.registerDomains.searchResultsTableData;
export const selectCartInfo = state => state.registerDomains.cartInfo;
export const selectTotalResultsCount = state =>
  state.registerDomains.searchResultsTableData?.length;
export const selectTotalResultsAvailableCount = state =>
  state.registerDomains.searchResultsTableData?.filter(domain =>
    domainAvailability.includes(domain.available)
  )?.length;
export const selectHasPerformedSearch = state =>
  state.registerDomains.searchResultsTableData?.length > 0;
export const selectSelectedSearchGroups = state =>
  state.registerDomains.selectedSearchGroups;
export const selectSearchString = state => state.registerDomains.searchString;
export const selectIdnLangsMap = state => state.registerDomains.idnLangsMap;
export const selectAreAllDomainsSelected = ({ registerDomains }) =>
  registerDomains.cart?.length &&
  registerDomains.cart.length ===
    registerDomains.searchResultsTableData.filter(domain =>
      domainAvailability.includes(domain.available)
    )?.length;
export const selectTotalPriceOfSelectedDomains = state => {
  const totalPrice = state.registerDomains.cart?.reduce(
    (previousValue, currentValue) => previousValue + currentValue.totalPrice,
    0
  );
  return (Math.round(totalPrice * 100) / 100).toFixed(2);
};
export const selectIsAddingDomainToCart = state =>
  Object.values(state.api.mutations).some(query => {
    return (
      query.endpointName === 'addDomainToCart' && query.status === 'pending'
    );
  });
export const selectIsUpdatingDomainInCart = state =>
  Object.values(state.api.mutations).some(query => {
    return (
      query.endpointName === 'updateDomainInCart' && query.status === 'pending'
    );
  });
export const selectIsAnyDomainManual = state =>
  state.registerDomains.cart.some(domain => domain.$tldObject.manual);
export const selectIsRemovingDomainFromCart = state =>
  Object.values(state.api.mutations).some(query => {
    return (
      query.endpointName === 'removeDomainFromCart' &&
      query.status === 'pending'
    );
  });

export const selectHaveCartItemsWithSpecialRequirements = state =>
  state.registerDomains.haveCartItemsWithSpecialRequirements;

export const selectSpecialRequirementsValidityById = (state, id) =>
  state.registerDomains.cart.find(lineItem => lineItem.id === id)
    ?.specialRequirements?.isValid;

export const selectCartIsValid = state => state.registerDomains.cartIsValid;

export const selectCheckoutTableData = state => {
  const mappedTableData = [];
  state.registerDomains.cart.forEach(cartItem => {
    mappedTableData.push(cartItem);
    if (cartItem.localPresence) {
      mappedTableData.push({
        $service: 'Local Presence',
        domainName: cartItem.domainName,
        registrationPrice: cartItem.$localPresencePrice
      });
    }
  });
  return mappedTableData;
};
export const selectHasAcceptedDomainAgreement = state =>
  state.registerDomains.hasAcceptedDomainAgreement;
export const selectDomainServiceAcceptedDate = state =>
  state.registerDomains.domainServiceAcceptedDate;
export const selectClientLineItems = state =>
  state.registerDomains.clientLineItems.lineItems;
export const selectClientLineItemIds = state =>
  state.registerDomains.clientLineItems.ids;
