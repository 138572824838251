import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import {
  bindDialog,
  bindToggle,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ZoneDeleteConfirmButton } from './ZoneDeleteConfirmButton';
import {
  selectSelectedZones,
  selectSelectedZonesCount
} from './selected-zone-slice';

export const ZoneDeleteDialog = () => {
  const popupState = usePopupState({
    popupId: 'zoneDeleteDialog',
    variant: 'dialog'
  });

  const navigate = useNavigate();

  const selectedZones = useSelector(selectSelectedZones);
  const selectedZonesCount = useSelector(selectSelectedZonesCount);

  return (
    <>
      <Button
        disabled={selectedZonesCount < 1}
        onClick={() => navigate(`delete`)}
        size="small"
        startIcon={<FontAwesomeIcon icon={faTrashCan} />}
        {...bindTrigger(popupState)}
      >
        Delete
      </Button>
      <Dialog {...bindDialog(popupState)} maxWidth="xs">
        <DialogTitle>
          Delete Zone{selectedZones.length > 1 ? 's' : ''}
        </DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
          Are you sure you want to delete the selected zone
          {selectedZones.length > 1 ? 's' : ''}?
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button variant="outlined" {...bindToggle(popupState)}>
            Cancel
          </Button>
          <ZoneDeleteConfirmButton popupState={popupState} />
        </DialogActions>
      </Dialog>
    </>
  );
};
