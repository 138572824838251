import { apiSlice } from '../../common/store/api-slice';
import { CONTACT_SET_TYPES } from '../components/contact-sets/contact-set-types';

const emptyPhoneFaxArray = ['', ''];

const contactsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    cancelEmailVerification: builder.mutation({
      query: ({ customerName, emailAddress, reseller }) => ({
        method: 'DELETE',
        url: `resellers/${reseller}/customers/${customerName}/contacts/cancel-email-verification/${emailAddress}`
      })
    }),
    getContacts: builder.query({
      query: ({ customerName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/contacts`,
      transformResponse: response => {
        const mappedContactSets = response.map(contactSet => {
          Object.values(CONTACT_SET_TYPES).forEach(contactType => {
            const phoneArray =
              contactSet[contactType].phone?.split('.') ?? emptyPhoneFaxArray;
            const faxArray =
              contactSet[contactType].fax?.split('.') ?? emptyPhoneFaxArray;
            contactSet[contactType].$phonePrefix = phoneArray[0];
            contactSet[contactType].$phone = phoneArray[1];
            contactSet[contactType].$faxPrefix = faxArray[0];
            contactSet[contactType].$fax = faxArray[1];
          });
          contactSet.label = contactSet.name;
          return {
            ...contactSet
          };
        });
        const sortedContacts = mappedContactSets.sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });
        return sortedContacts;
      }
    }),
    getSingleContactSet: builder.query({
      query: ({ contactSetId, customerName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/contacts/${contactSetId}`,
      transformResponse: response => {
        const mappedContactSet = response.map(contactSet => {
          Object.values(CONTACT_SET_TYPES).forEach(contactType => {
            const phoneArray =
              contactSet[contactType].phone?.split('.') ?? emptyPhoneFaxArray;
            const faxArray =
              contactSet[contactType].fax?.split('.') ?? emptyPhoneFaxArray;
            contactSet[contactType].$phonePrefix = phoneArray[0];
            contactSet[contactType].$phone = phoneArray[1];
            contactSet[contactType].$faxPrefix = faxArray[0];
            contactSet[contactType].$fax = faxArray[1];
          });
          contactSet.label = contactSet.name;
          return contactSet;
        });
        return mappedContactSet;
      }
    }),
    getTldNamesForContact: builder.query({
      query: ({ contactId, customerName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/contacts/${contactId}/tld-names`
    }),
    resendEmailVerification: builder.mutation({
      query: ({ customerName, emailAddress, reseller }) => {
        return {
          body: { emailAddress },
          method: 'POST',
          url: `resellers/${reseller}/customers/${customerName}/contacts/resend-email-verification`
        };
      }
    }),
    updateSingleContact: builder.mutation({
      query: ({ contactSet, customerName, reseller }) => ({
        body: { contactSets: [contactSet] },
        method: 'PUT',
        url: `resellers/${reseller}/customers/${customerName}/contacts/${contactSet.id}`
      })
    }),
    verifyContactSetForDomain: builder.query({
      query: ({ contactSetId, customerName, domainName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/contacts/${domainName}/${contactSetId}`
    }),
    verifyContactSetsForDomains: builder.query({
      query: ({ customerName, domainsAndContactSetIds, reseller }) => ({
        body: domainsAndContactSetIds,
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/contacts/validate`
      }),
      transformResponse: ({ entity }) => ({
        domains: entity,
        invalidDomainNames: entity
          .filter(domain => domain.valid === false)
          .map(invalidDomain => invalidDomain.domainName)
      })
    })
  })
});

export const {
  useCancelEmailVerificationMutation,
  useGetContactsQuery,
  useGetSingleContactSetQuery,
  useGetTldNamesForContactQuery,
  useResendEmailVerificationMutation,
  useUpdateSingleContactMutation,
  useVerifyContactSetForDomainQuery,
  useVerifyContactSetsForDomainsQuery
} = contactsApiSlice;
