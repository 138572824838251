import { addMethod, array, string } from 'yup';

import { isValidDomainName } from '../../register-domains-utils';

export const SEARCH_ERROR_MESSAGES = Object.freeze({
  invalidSearchString:
    'One or more of the typed domains has invalid characters or name',
  sameSearchType: 'Cannot combine targeted search with category search'
});
export const allEntriesAreTargeted = list =>
  list.every(item => item.includes('.'));
export const allEntriesAreCategory = list =>
  list.every(item => !item.includes('.'));
export const hasMixedEntries = list => {
  const hasTargeted = list.some(item => item.includes('.'));
  const hasCategory = list.some(item => !item.includes('.'));
  return hasTargeted && hasCategory;
};

addMethod(array, 'sameSearchType', function (message = a => a) {
  return this.test('sameSearchType', message, function (list) {
    return allEntriesAreCategory(list) || allEntriesAreTargeted(list);
  });
});
addMethod(array, 'invalidSearchString', function (message = a => a) {
  return this.test('invalidSearchString', message, function (list) {
    // the sameSearchType test already handled the different search types case
    if (allEntriesAreCategory(list)) {
      return list.every(domain => isValidDomainName(domain));
    } else {
      return list.every(domain => {
        const [domainName, tld] = domain.split('.', 2);
        return isValidDomainName(domainName, tld);
      });
    }
  });
});
export const payloadSchema = () =>
  array()
    .of(string())
    .sameSearchType(SEARCH_ERROR_MESSAGES.sameSearchType)
    .invalidSearchString(SEARCH_ERROR_MESSAGES.invalidSearchString);
