import { apiSlice } from '../../common/store/api-slice';
import { transformUserResponse } from './transform-user-response';

const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    completeTwoFactorAuth: builder.mutation({
      query: ({ reseller, userEmail, verificationCode, verificationId }) => ({
        body: {
          verificationCode,
          verificationId
        },
        method: 'POST',
        url: `/authenticate/${reseller}/${userEmail}/verify`
      }),
      transformResponse: transformUserResponse
    }),
    login: builder.mutation({
      query: ({ password, reseller, userEmail }) => ({
        body: {},
        headers: {
          'x-fly9-password': password
        },
        method: 'POST',
        url: `/authenticate/${reseller}/${userEmail}`
      }),
      transformResponse: transformUserResponse
    }),
    renewAccessToken: builder.mutation({
      query: () => ({
        method: 'POST',
        url: '/token/renew'
      })
    })
  })
});

export const {
  useCompleteTwoFactorAuthMutation,
  useLoginMutation,
  useRenewAccessTokenMutation
} = authApiSlice;
