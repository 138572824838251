import { faGear } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import {
  bindMenu,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TransferLoading } from '../../common/components/TransferLoading';
import { selectLegacyHost } from '../../common/config/config';
import { selectCustomerName } from '../../common/store/customers-slice';
import { useGetTransferTokenMutation } from '../../common/store/transfer-api-slice';
import { selectRoles } from '../auth/auth-slice';
import { getSettingsMenuOptions } from './get-settings-menu-options';

// NOTE the code to transfer a user to the angular app also exists
// in HeaderPopover.jsx. Any fixes here may need to be applied there also.
// cstuart May 20, 2023
export const SettingsMenu = () => {
  const roles = useSelector(selectRoles);

  const popupState = usePopupState({
    popupId: 'headerSettingsMenu',
    variant: 'popover'
  });

  const navigate = useNavigate();
  const customerName = useSelector(selectCustomerName);
  const legacyHost = useSelector(selectLegacyHost);
  const [getTransferToken, { data, isError, isLoading }] =
    useGetTransferTokenMutation();
  const [redirectTo, setRedirectTo] = useState();

  useEffect(() => {
    if (data?.transferToken && redirectTo) {
      const url = new URL(legacyHost);
      url.pathname = 'transfer.html';
      const searchParams = url.searchParams;
      searchParams.append('token', data.transferToken);
      searchParams.append('accountName', customerName);
      searchParams.append('dest', redirectTo);
      url.search = searchParams.toString();

      // eslint-disable-next-line testing-library/no-debugging-utils
      window.console.debug('redirecting to ', url.toString());
      window.location.assign(url.toString());
    }
  }, [customerName, data, legacyHost, redirectTo]);

  // Redirect to login if transferring fails
  useEffect(() => {
    if (isError) {
      navigate('/login', {
        state: {
          isTransferError: true,
          redirectTo
        }
      });
    }
  }, [isError, navigate, redirectTo]);

  const handleSettingsMenuLegacyItemClick = legacyPath => {
    setRedirectTo(legacyPath);
    getTransferToken();
    popupState.close();
  };

  const handleSettingsMenuItemClick = path => {
    navigate(path);
    popupState.close();
  };

  return (
    <>
      <IconButton
        aria-label="Settings Menu"
        color="inherit"
        size="small"
        {...bindTrigger(popupState)}
      >
        <FontAwesomeIcon icon={faGear} />
      </IconButton>
      <Menu {...bindMenu(popupState)}>
        {getSettingsMenuOptions(roles).map(option =>
          option.title === 'DIVIDER' ? (
            <Divider key={option.key} />
          ) : (
            <MenuItem
              key={option.key}
              onClick={() =>
                option.path
                  ? handleSettingsMenuItemClick(option.path)
                  : handleSettingsMenuLegacyItemClick(option.legacyPath)
              }
            >
              {option.title}
            </MenuItem>
          )
        )}
      </Menu>
      {isLoading && <TransferLoading />}
    </>
  );
};
