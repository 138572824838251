import { TextField } from '@mui/material';
import React from 'react';

import { formatForDisplay } from '../../../../utils/number-price-text';

export const DotNycForm = ({ specialRequirements }) => (
  <TextField
    disabled
    label="Legal Form"
    value={formatForDisplay(specialRequirements.DOTNYC.contactType)}
  />
);
