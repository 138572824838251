import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

export const LabelsTableInput = ({
  applyValue,
  defaultValue = [],
  isLoading,
  item,
  labelsData,
  value
}) => {
  const handleValueChange = (event, value) => applyValue({ item, value });

  return (
    <Autocomplete
      groupBy={option => option.labelGroupName}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      limitTags={1}
      loading={isLoading}
      multiple
      onChange={handleValueChange}
      options={labelsData?.flat ?? []}
      renderInput={params => <TextField {...params} variant="outlined" />}
      size="small"
      sx={{ width: '100%' }}
      value={value ?? defaultValue}
    />
  );
};
