import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { isEqual, uniq } from 'lodash-es';

const initialState = { selectedDomainIds: [], selectedDomains: [] };

const selectedDomainsSlice = createSlice({
  initialState,
  name: 'selectedDomains',
  reducers: {
    removeSelectedDomain: (state, { payload }) => {
      // expected payload: id of the domain to unselect
      state.selectedDomainIds = state.selectedDomainIds.filter(
        domainId => domainId !== payload
      );
      state.selectedDomains = state.selectedDomains.filter(
        domain => domain.$id !== payload
      );
    },
    setSelectedDomains: (state, { payload }) => {
      // expected payload: array of selected domain objects
      state.selectedDomains = payload;
      state.selectedDomainIds = payload.map(domain => domain?.$id);
    }
  }
});

export const selectedDomainsReducer = selectedDomainsSlice.reducer;

export const { removeSelectedDomain, setSelectedDomains } =
  selectedDomainsSlice.actions;

export const selectSelectedDomainIds = state =>
  state.selectedDomains?.selectedDomainIds;
export const selectSelectedDomains = state =>
  state.selectedDomains?.selectedDomains;

export const selectIsNoneSelected = state =>
  state.selectedDomains?.selectedDomains.length === 0;
export const selectIsOneSelected = state =>
  state.selectedDomains?.selectedDomains.length === 1;
export const selectIsAnySelected = state =>
  state.selectedDomains?.selectedDomains.length > 0;
export const selectIsMultipleSelected = state =>
  state.selectedDomains?.selectedDomains.length > 1;

export const selectAreSomeDomainsManual = state =>
  state.selectedDomains?.selectedDomains.some(
    domain => domain.manual || domain.$tld.manual
  );

export const selectAreAllDomainsManual = state =>
  state.selectedDomains?.selectedDomains.every(
    domain => domain.manual || domain.$tld.manual
  );

export const selectAllLocalPresenceOffered = state =>
  state.selectedDomains?.selectedDomains.every(
    domain => domain.$tld.localPresenceOffered
  );

const selectAnyLocalPresenceEnabled = state =>
  state.selectedDomains?.selectedDomains.some(
    domain => domain.usingLocalPresence
  );
const selectAnyLocalPresenceDisabled = state =>
  state.selectedDomains?.selectedDomains.some(
    domain => !domain.usingLocalPresence
  );

export const selectCanEnableLocalPresence = state =>
  selectAllLocalPresenceOffered(state) &&
  selectIsAnySelected(state) &&
  selectAnyLocalPresenceDisabled(state);

export const selectCanDisableLocalPresence = state =>
  selectAllLocalPresenceOffered(state) &&
  selectIsAnySelected(state) &&
  selectAnyLocalPresenceEnabled(state);

export const selectAnyAutoRenew = state =>
  state.selectedDomains?.selectedDomains.some(domain => domain.autoBilling);
export const selectAnyDoNotAutoRenew = state =>
  state.selectedDomains?.selectedDomains.some(domain => !domain.autoBilling);
export const selectAllAutoRenew = state =>
  state.selectedDomains?.selectedDomains.every(domain => domain.autoBilling);
export const selectAnyMaxRenew = state =>
  state.selectedDomains?.selectedDomains.some(domain => domain.maxAutorenew);
export const selectAllMaxRenew = state =>
  state.selectedDomains?.selectedDomains.every(domain => domain.maxAutorenew);

export const selectAnyRegistryLocked = state =>
  state.selectedDomains?.selectedDomains.some(domain => domain.registryLocked);

export const selectHaveSameExpirationDate = state => {
  const firstExpirationDate = dayjs(
    state.selectedDomains?.selectedDomains?.[0]?.expirationDate
  );
  return state.selectedDomains?.selectedDomains.every(domain =>
    firstExpirationDate.isSame(dayjs(domain.expirationDate), 'day')
  );
};

export const selectHaveSameLocalPresenceExpirationDate = state => {
  const apiValue =
    state.selectedDomains?.selectedDomains?.[0]?.localPresenceExpirationDate;

  const firstLocalPresenceExpirationDate = apiValue
    ? dayjs(
        state.selectedDomains?.selectedDomains?.[0]?.localPresenceExpirationDate
      )
    : null;

  return state.selectedDomains?.selectedDomains?.every(
    domain =>
      typeof domain.localPresenceExpirationDate === 'string' &&
      firstLocalPresenceExpirationDate.isSame(
        dayjs(domain.localPresenceExpirationDate),
        'day'
      )
  );
};

export const selectTldsWithUnsupportedRenewSupportType = state =>
  uniq(
    state.selectedDomains?.selectedDomains
      ?.filter(domain => domain.$tld.renewSupportType === 'UNSUPPORTED')
      // FIXME get the right property for the name
      .map(domain => domain.$tld.name)
  );

export const selectAllDomainsSupportHostManagement = state =>
  state.selectedDomains?.selectedDomains.every(
    domain => domain.$tld.hostManagementType !== 'UNSUPPORTED'
  );

export const selectAnyTldsHaveUnsupportedRenewSupportType = state =>
  selectTldsWithUnsupportedRenewSupportType(state).length > 0;

export const selectAllTldsHaveSupportedRenewSupportType = state =>
  state.selectedDomains?.selectedDomains.length > 0 &&
  selectTldsWithUnsupportedRenewSupportType(state).length === 0;

export const selectAllDomainsHaveRenewalAvailability = state =>
  state.selectedDomains?.selectedDomains.length > 0 &&
  state.selectedDomains?.selectedDomains.every(
    domain => domain.renewalAvailability.available === true
  );

// TODO refactor into getPricesForTldByPhaseAndType
const renewalData = state =>
  state.selectedDomains?.selectedDomains.filter(domain => {
    const activePhase = domain.$tld.phase;
    const renewalPrices = domain.$tld?.prices?.[activePhase]?.renewal;
    return renewalPrices;
  });

export const selectAnyTldsMissingRenewalData = state =>
  renewalData(state) !== undefined;

const getLockAvailabilityByName = (domain, name) =>
  domain.$registrarLocks.types.find(lockType => lockType.name === name)
    .available;

export const selectAnyHaveRegistrarUpdateLockSupport = state =>
  state.selectedDomains?.selectedDomains.some(domain =>
    getLockAvailabilityByName(domain, 'update')
  );

export const selectAnyHaveRegistrarTransferLockSupport = state =>
  state.selectedDomains?.selectedDomains.some(domain =>
    getLockAvailabilityByName(domain, 'transfer')
  );

export const selectAnyHaveRegistrarDeleteLockSupport = state =>
  state.selectedDomains?.selectedDomains.some(domain =>
    getLockAvailabilityByName(domain, 'delete')
  );

export const selectAnyHaveMaxRenewalReached = state =>
  state.selectedDomains?.selectedDomains.some(
    domain => domain.renewalAvailability?.reason === 'MAXIMUM_RENEWAL_REACHED'
  );

const allDomainsHaveSameNameServers = state => {
  const nameServers = state.selectedDomains?.selectedDomains.map(
    ({ $nameServers }) => $nameServers
  );
  return nameServers.every(nameServer => isEqual(nameServer, nameServers[0]));
};

export const selectMultipleDomainsHaveDifferentNameServers = state =>
  selectIsMultipleSelected(state) && !allDomainsHaveSameNameServers(state);

export const selectHaveNoNameServersConfigured = state =>
  state.selectedDomains?.selectedDomains?.every(
    domain => !domain.nameServerInfos?.length
  );

export const selectNameServersToDisplay = state => {
  const firstDomainNameServers =
    state.selectedDomains?.selectedDomains[0]?.$nameServers ?? [];
  if (allDomainsHaveSameNameServers(state)) {
    return firstDomainNameServers;
  } else {
    return [];
  }
};
