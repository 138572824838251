import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { EmptyGridOverlay } from '../../common/components/empty-grid-overlay/EmptyGridOverlay';
import { selectReseller } from '../../common/store/reseller';
import { gridColumnWidths } from '../../common/utils/grid-column-widths';
import { useGetTldCostHistoryQuery } from './tld-cost-api-slice';

export const TldCostHistoryDialog = () => {
  const reseller = useSelector(selectReseller);
  const navigate = useNavigate();
  const { tldCostId } = useParams();
  const {
    data: tldCostChanges = [],
    isLoading,
    isUninitialized
  } = useGetTldCostHistoryQuery(
    {
      reseller,
      tldCostId
    },
    { skip: !tldCostId }
  );

  const tableColumns = [
    {
      field: '$fieldFormatted',
      filterable: false,
      flex: 1,
      headerName: 'Field'
    },
    {
      field: '$values',
      filterable: false,
      flex: 2,
      headerName: 'Values',
      renderCell: ({ row }) => (
        <Stack direction="row" spacing={1}>
          <Typography sx={{ fontStyle: 'italic', pt: 0.25 }} variant="body2">
            {row.$oldValueFormatted}
          </Typography>
          <ArrowRightAltIcon />
          <Typography sx={{ fontStyle: 'italic', pt: 0.25 }} variant="body2">
            {row.$newValueFormatted}
          </Typography>
        </Stack>
      )
    },
    {
      ...gridColumnWidths.formattedDate,
      field: '$changeDateFormatted',
      flex: 2,
      headerName: 'Date Changed'
    },
    {
      field: 'userFullName',
      filterable: false,
      flex: 1,
      headerName: 'User'
    }
  ];

  const handleClose = () => navigate('/tld-costs');

  return (
    <Dialog fullWidth maxWidth="md" open={true}>
      <DialogTitle>Tld Cost</DialogTitle>
      <DialogContent>
        <DataGridPro
          autoHeight={false}
          columns={tableColumns}
          disableRowSelectionOnClick={true}
          disableVirtualization={import.meta.env.MODE === 'test'}
          hideFooter
          loading={isUninitialized || isLoading}
          rows={tldCostChanges}
          slots={{
            noResultsOverlay: () => (
              <EmptyGridOverlay emptyGridText="This TLD Cost has no edit history." />
            ),
            noRowsOverlay: () => (
              <EmptyGridOverlay emptyGridText="This TLD Cost has no edit history." />
            )
          }}
          sx={{
            height: '300px'
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
