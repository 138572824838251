import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(localizedFormat);
dayjs.extend(utc);

export const getLocalizedDateTime = (dateTime: string) =>
  dayjs(dateTime).format('LLL');

export const getLocalizedDate = (date: string) => dayjs(date).format('LL');

export const getUTCDate = (date: string) => dayjs(date).utc().format('ll');

export const getCurrentDateTimeForFileName = () =>
  dayjs().format('YYYY-MM-DD_HH-mm-ss');

export enum dateFormatOptionsForExport {
  year = 'numeric',
  month = 'long',
  day = 'numeric'
}

export enum dateFormatOptionsForDisplay {
  year = 'numeric',
  month = 'long',
  day = 'numeric',
  hour = 'numeric',
  minute = 'numeric',
  second = 'numeric'
}
