import { TextField } from '@mui/material';
import React from 'react';

export const DotDkForm = ({ specialRequirements }) => {
  const contactTypes = Object.freeze({
    ASSOCIATION: 'Association',
    COMPANY: 'Company',
    INDIVIDUAL: 'Individual',
    PUBLIC_ORG: 'Public Organization'
  });
  return (
    <>
      <TextField
        disabled
        label="Contact Type"
        value={contactTypes[specialRequirements.DOTDK.type]}
      />
      <TextField
        disabled
        label="VAT Number"
        value={specialRequirements.DOTDK.vat}
      />
    </>
  );
};
