import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { selectReseller } from '../../common/store/reseller';
import { useGetTldPhaseByTldNameQuery } from '../../common/store/tld-phase-api-slice';
import { useGetSupportedYearsByTldNameQuery } from '../../common/store/tlds-api-slice';
import { CurrencyInput } from './CurrencyInput';
import { EffectiveDateInput } from './EffectiveDateInput';
import { ProductTypeInput } from './ProductTypeInput';
import { RegistryCostInput } from './RegistryCostInput';
import { TldCostsLoading } from './TldCostsLoading';
import { TldNameInput } from './TldNameInput';
import { TldPhaseInput } from './TldPhaseInput';
import {
  useGetTldCostCurrencyQuery,
  useGetTldNamesQuery,
  useUpdateTldCostsMutation
} from './tld-cost-api-slice';
import { formSchema } from './tld-cost-form.schema';
import {
  handleChange,
  handleDateChange,
  handleDateValidation,
  handleRegistryCostChange,
  handleRequiredValidation
} from './tld-cost-form-functions';
import { tldCostFormReducer } from './tld-cost-form-reducer';

export const TldCostAddForm = () => {
  const reseller = useSelector(selectReseller);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [validationErrors, setValidationErrors] = useState(false);
  const handleClose = () => navigate('/tld-costs');

  const [
    updateTldCosts,
    {
      isError: isUpdateTldCostsError,
      isLoading: isUpdateTldCostsLoading,
      isSuccess: isUpdateTldCostsSuccess
    }
  ] = useUpdateTldCostsMutation();

  const { data: currencies = [], isSuccess: isGetCurrenciesSuccess } =
    useGetTldCostCurrencyQuery({
      reseller
    });

  const { data: tldNames = [], isSuccess: isGetTldNamesSuccess } =
    useGetTldNamesQuery({
      reseller
    });

  const handleSubmit = event => {
    event.preventDefault();
    setValidationErrors(false);
    formSchema({
      handleDateValidation: handleDateValidation(true, formDataDispatch),
      handleRequiredValidation: handleRequiredValidation(true, formDataDispatch)
    })
      .validate(formData)
      .then(() => {
        const tldCostDataFromForm = {
          currency: formData.currency.value?.code,
          phase: formData.phase.value.id,
          productType: formData.productType.value.value,
          registryCost: parseFloat(formData.registryCost.value),
          startDate: formData.startDate.value?.valueOf(),
          tld: formData.tld.value.id
        };
        const payload = [tldCostDataFromForm];
        updateTldCosts({ payload, reseller });
      })
      .catch(() => setValidationErrors(true));
  };
  const emptyFormState = () =>
    formSchema({ handleDateValidation, handleRequiredValidation }).getDefault();

  const [formData, formDataDispatch] = useReducer(
    tldCostFormReducer,
    emptyFormState()
  );

  const { data: supportedYearsData = [] } = useGetSupportedYearsByTldNameQuery(
    {
      tldName: formData?.tld?.value?.name
    },
    {
      skip: !formData?.tld?.value?.name
    }
  );

  const { data: tldPhases = [] } = useGetTldPhaseByTldNameQuery(
    {
      tldName: formData?.tld?.value?.name
    },
    {
      skip: !formData?.tld?.value?.name
    }
  );

  const debouncedValidation = useDebouncedCallback(
    formSchema =>
      formSchema({
        handleDateValidation: handleDateValidation(false, formDataDispatch),
        handleRequiredValidation: handleRequiredValidation(
          false,
          formDataDispatch
        )
      })
        .validate(formData)
        .catch(window.console.error),
    1500
  );

  useEffect(() => {
    if (isUpdateTldCostsSuccess) {
      enqueueSnackbar('Successfully updated cost', {
        variant: 'success'
      });
      navigate('/tld-costs');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateTldCostsSuccess]);

  useEffect(() => {
    if (isUpdateTldCostsError) {
      enqueueSnackbar('Could not update cost', {
        variant: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateTldCostsError]);

  const canInitializeNoneSelectedForm =
    isGetCurrenciesSuccess && isGetTldNamesSuccess && !formData?.isInit;

  const tldSupportsProductType =
    supportedYearsData?.supportedProductTypes?.includes(
      formData.productType?.value?.label
    );

  //Initialize empty form - none selected
  useEffect(() => {
    if (canInitializeNoneSelectedForm) {
      // @ts-ignore
      formDataDispatch({
        path: '',
        type: 'INIT',
        value: { ...emptyFormState() }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canInitializeNoneSelectedForm]);

  return (
    <Dialog fullWidth maxWidth="md" open={true}>
      <form noValidate={true} onSubmit={handleSubmit}>
        <DialogTitle sx={{ pb: 0 }}>Add Tld Cost</DialogTitle>
        {!isGetTldNamesSuccess && <TldCostsLoading />}
        {isGetTldNamesSuccess && (
          <DialogContent>
            <Stack spacing={2} sx={{ minHeight: '383px' }}>
              {validationErrors && (
                <Alert severity="error">
                  Fix form errors before submitting.
                </Alert>
              )}
              <Stack>
                <TldNameInput
                  debouncedValidation={debouncedValidation}
                  formData={formData}
                  formDataDispatch={formDataDispatch}
                  tldNames={tldNames}
                />
                <TldPhaseInput
                  debouncedValidation={debouncedValidation}
                  formData={formData}
                  formDataDispatch={formDataDispatch}
                  tldPhases={tldPhases}
                />
                <ProductTypeInput
                  debouncedValidation={debouncedValidation}
                  formData={formData}
                  formDataDispatch={formDataDispatch}
                />
                <Divider sx={{ mt: 1 }} />
              </Stack>

              {!tldSupportsProductType && (
                <Alert severity="warning">
                  The available years for your selection does not match, or a
                  TLD that you are editing does not have any available years
                  configured.
                </Alert>
              )}
              {tldSupportsProductType && (
                <Stack sx={{ pt: 2 }}>
                  <RegistryCostInput
                    debouncedValidation={debouncedValidation}
                    formData={formData}
                    formDataDispatch={formDataDispatch}
                    handleChange={handleRegistryCostChange}
                  />
                  <CurrencyInput
                    currencies={currencies}
                    debouncedValidation={debouncedValidation}
                    formData={formData}
                    formDataDispatch={formDataDispatch}
                    handleChange={handleChange}
                  />
                </Stack>
              )}
              <Divider sx={{ my: 1 }} />
              <EffectiveDateInput
                debouncedValidation={debouncedValidation}
                formData={formData}
                formDataDispatch={formDataDispatch}
                handleChange={handleDateChange}
              />
            </Stack>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={
              isUpdateTldCostsLoading || !formData?.formState?.isTouched
            }
            onClick={handleSubmit}
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

// eslint-disable-next-line import/no-default-export
export default TldCostAddForm;
