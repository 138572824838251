import { noop } from 'lodash-es';
import { boolean, object, string } from 'yup';

const defaultFieldSchema = {
  errorMessage: string().default(''),
  hasError: boolean().default(false),
  touched: boolean().default(false)
};

/**
 * formSchema
 * @param {Function} handleRequiredValidation
 * @returns
 */
export const formSchema = ({ handleRequiredValidation = noop }) =>
  object({
    intendedUse: object({
      ...defaultFieldSchema,
      value: string().required(handleRequiredValidation).default('')
    }),
    isInit: boolean().default(false)
  });
