import React from 'react';

import { MissingCart } from './MissingCart';
import { RegisterDomainsCheckout } from './register-domains-steps/checkout/RegisterDomainsCheckout';
import { RegisterDomainsDomainDetails } from './register-domains-steps/domain-details/RegisterDomainsDomainDetails';
import { RegisterDomainsOverview } from './register-domains-steps/overview/RegisterDomainsOverview';
import { RegisterDomainsSearch } from './register-domains-steps/search/RegisterDomainsSearch';
import { RegisterDomainsStatus } from './register-domains-steps/status/RegisterDomainsStatus';

const registerDomainsSteps = Object.freeze([
  {
    component: <RegisterDomainsSearch />,
    hasOwnFooter: false,
    hideBackButton: true,
    path: '/register/search',
    showExportButton: true,
    stepName: 'search'
  },
  {
    component: <RegisterDomainsOverview />,
    hasOwnFooter: false,
    path: '/register/overview',
    requiresCartItems: true,
    stepName: 'overview'
  },
  {
    component: <RegisterDomainsDomainDetails />,
    hasOwnFooter: true,
    path: '/register/domain-details',
    requiresCartItems: true,
    stepName: 'domain-details'
  },
  {
    component: <RegisterDomainsCheckout />,
    hasOwnFooter: true,
    path: '/register/checkout',
    requiresCartItems: true,
    stepName: 'checkout'
  },
  {
    component: <RegisterDomainsStatus />,
    hasOwnFooter: true,
    path: '/register/status',
    requiresCartItems: true,
    stepName: 'status'
  }
]);
const getCurrentStepByName = stepNameParam =>
  registerDomainsSteps.find(({ stepName }) => stepName === stepNameParam);
const getCurrentStepIndexByName = stepNameParam =>
  registerDomainsSteps.findIndex(({ stepName }) => stepName === stepNameParam);

export const getRegistrationComponent = (stepNameParam, isCartEmpty) =>
  getCurrentStepByName(stepNameParam).requiresCartItems && isCartEmpty ? (
    <MissingCart />
  ) : (
    registerDomainsSteps.find(({ stepName }) => stepName === stepNameParam)
      .component
  );

export const getActiveStepIndex = stepNameParam =>
  getCurrentStepIndexByName(stepNameParam);

export const navigateToNextRegistrationStep = (navigate, stepNameParam) =>
  navigate(
    registerDomainsSteps[getCurrentStepIndexByName(stepNameParam) + 1].path
  );

export const navigateToPreviousRegistrationStep = (navigate, stepNameParam) =>
  navigate(
    registerDomainsSteps[getCurrentStepIndexByName(stepNameParam) - 1].path
  );

export const navigateToFirstRegistrationStep = navigate => {
  navigate(registerDomainsSteps[0].path);
};

export const getFirstRegistrationStepPath = () => registerDomainsSteps[0].path;
export const getFirstStepName = () => registerDomainsSteps[0].stepName;

export const isCurrentStepByName = (name, stepNameParam) =>
  name === stepNameParam;

export const currentStepHasOwnFooter = stepNameParam =>
  getCurrentStepByName(stepNameParam).hasOwnFooter;

export const shouldHideBackButton = stepNameParam =>
  getCurrentStepByName(stepNameParam).hideBackButton;

export const shouldShowExportButton = stepNameParam =>
  getCurrentStepByName(stepNameParam).showExportButton;
