import { Stack } from '@mui/material';
import React from 'react';

import { AuthCodeValidityMessage } from './AuthCodeValidityMessage';
import { AvailabilityMessage } from './AvailabilityMessage';
import { ContactSetValidityMessage } from './ContactSetValidityMessage';
import { SpecialRequirementsValidityMessage } from './SpecialRequirementsValidityMessage';

export const ValidityMessage = ({ validity }) => {
  // We want to show the <AvailabilityMessage /> component if
  // we have an availability error or if we don't have any
  // other issues. We want to avoid showing the <AvailabilityMessage />
  // if availability is valid and any of the others are invalid.
  const shouldShowAvailabilityMessage =
    (validity.authCodeValidity.isError !== true &&
      validity.contactSetValidity.isError !== true &&
      validity.specialRequirementsValidity.isError !== true) ||
    validity.availability.isError === true;

  return (
    <Stack direction="column" spacing={2}>
      {shouldShowAvailabilityMessage && (
        <AvailabilityMessage message={validity.availability.message} />
      )}
      <AuthCodeValidityMessage authCodeValidity={validity.authCodeValidity} />
      <ContactSetValidityMessage
        contactSetValidity={validity.contactSetValidity}
      />
      <SpecialRequirementsValidityMessage
        specialRequirementsValidity={validity.specialRequirementsValidity}
      />
    </Stack>
  );
};
