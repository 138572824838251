import { Alert, Button, Paper, Stack } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  POLLING_INTERVAL,
  usePollJobResponseQuery
} from '../../common/store/job-response-api-slice';
import { selectClientId } from '../auth/auth-slice';
import { MessageStatusCellMemoized } from './MessageStatusCell';
import {
  getFirstTransferStepPath,
  navigateToFirstTransferStep
} from './transfer-navigation';
import { reset, selectTransfers } from './transfer-slice';

export const CompletedTransferStep = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientId = useSelector(selectClientId);
  const transfers = useSelector(selectTransfers);

  const handleViewTransfersClick = () => {
    navigate('/transfers');
    dispatch(reset());
  };
  const handleNewTransferClick = () => {
    dispatch(reset());
    navigateToFirstTransferStep(navigate);
  };

  const { data: jobResultData, refetch } = usePollJobResponseQuery({
    clientId,
    clientLineItemIds: transfers.map(transfer => transfer.id)
  });

  const columns = [
    {
      field: 'domainName',
      flex: 3,
      headerName: 'Domain Name'
    },
    {
      field: 'status',
      flex: 1,
      headerName: 'Status',
      renderCell: ({ row }) => (
        <MessageStatusCellMemoized
          id={row.id}
          messages={jobResultData?.messages}
        />
      )
    }
  ];

  // FIXME this will refetch forever
  useEffect(() => {
    if (jobResultData?.anyPending) {
      setTimeout(refetch, POLLING_INTERVAL.active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobResultData]);

  return (
    <Paper sx={{ height: '100%', p: 2 }} variant="outlined">
      <Stack spacing={4} sx={{ height: '100%' }}>
        {jobResultData?.allSuccess && (
          <Alert severity="success">
            Your domain transfer initiation was successful. We will notify you
            of the next steps via email.
          </Alert>
        )}
        {jobResultData?.anyFailures && (
          <Alert severity="error">
            One or more of your domain transfer initiations were not successful.
            We will notify you of the next steps via email.
          </Alert>
        )}
        {jobResultData?.allComplete && (
          <Stack direction="row" spacing={2}>
            <Button onClick={handleViewTransfersClick} variant="contained">
              View transfers
            </Button>
            <Button
              href={getFirstTransferStepPath()}
              onClick={handleNewTransferClick}
              variant="contained"
            >
              New transfer
            </Button>
          </Stack>
        )}
        <DataGridPro
          columns={columns}
          disableMultipleRowSelection={true}
          disableRowSelectionOnClick={true}
          disableVirtualization={import.meta.env.MODE === 'test'}
          rows={transfers}
        />
      </Stack>
    </Paper>
  );
};
