import { Checkbox, FormControlLabel } from '@mui/material';
import { noop } from 'lodash-es';
import React from 'react';

export const DotPlForm = ({ specialRequirements }) => {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={specialRequirements.DOTPL.individual}
            disabled
            onChange={noop}
          />
        }
        label="Contact is an individual"
        sx={{ ml: 0 }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={specialRequirements.DOTPL.consentForPublishing}
            disabled
            onChange={noop}
          />
        }
        label="Contact provides consent for publishing"
      />
    </>
  );
};
