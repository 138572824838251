import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';

export const EffectiveDateInput = ({
  debouncedValidation,
  formData,
  formDataDispatch,
  handleChange
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label="Effective Date"
        onChange={newValue =>
          handleChange({
            debouncedValidation,
            formDataDispatch,
            path: 'startDate',
            value: newValue
          })
        }
        slotProps={{
          textField: {
            error: false,
            helperText: formData.startDate?.errorMessage,
            margin: 'none',
            variant: 'standard'
          }
        }}
        slots={{
          textField: TextField
        }}
        value={formData.startDate?.value}
      />
    </LocalizationProvider>
  );
};
