import { Paper, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetContactsQuery } from '../../common/store/contacts-api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import { selectReseller } from '../../common/store/reseller';
import { Footer } from './Footer';
import { selectUserInput, setUserInput } from './transfer-slice';

export const AddTransfersStep = () => {
  const dispatch = useDispatch();
  const customerName = useSelector(selectCustomerName);
  const reseller = useSelector(selectReseller);
  const { data: contactSets, isLoading: isLoadingContactSets } =
    useGetContactsQuery(
      {
        customerName,
        reseller
      },
      { skip: !customerName }
    );

  const userInput = useSelector(selectUserInput);
  const defaultContactSet = contactSets?.find(contact => contact.defaultSet);

  const handleTransfersAndAuthCodesChange = event =>
    dispatch(
      setUserInput({
        customerContactSetId: defaultContactSet?.id,
        userInput: event.target.value
      })
    );

  const formatExample = String.raw`example1.info 24052458240
example2.info
  `;

  return (
    <>
      <Paper sx={{ minHeight: '100%', p: 2 }} variant="outlined">
        <Stack spacing={3} sx={{ mb: 8 }}>
          <Typography variant="h5">Add Transfers and Auth Codes</Typography>
          <Typography variant="body1">
            Copy and paste from Excel, email, or other tabular format.
          </Typography>
          <TextField
            maxRows={20}
            minRows={6}
            multiline
            onChange={handleTransfersAndAuthCodesChange}
            placeholder={formatExample}
            sx={{ width: 800 }}
            value={userInput}
          />
        </Stack>
      </Paper>
      <Footer isNextDisabled={!userInput.length || isLoadingContactSets} />
    </>
  );
};
