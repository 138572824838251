import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { selectHasLoggedOut, setAuth } from '../../features/auth/auth-slice';
import { broadcastChannelKeys } from '../../features/auth/broadcast-channel-keys';
import { setStoredUser } from '../../features/auth/user-storage';
import { TransferLoading } from './TransferLoading';

export const HandleLinkFromEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userHasLoggedOut = useSelector(selectHasLoggedOut);
  const [hasNoActiveSession, setHasNoActiveSession] = useState(false);
  const ONE_HALF_SECOND = 500;
  const redirectTo = `${location.pathname}${location.search}`;
  const authBroadcastChannel = new BroadcastChannel(
    broadcastChannelKeys.BROADCAST_CHANNEL_AUTH_REQUEST_NAME
  );

  authBroadcastChannel.postMessage(
    broadcastChannelKeys.BROADCAST_CHANNEL_AUTH_REQUEST
  );

  authBroadcastChannel.onmessage = event => {
    if (
      event.data.message === broadcastChannelKeys.BROADCAST_CHANNEL_AUTH_KEY
    ) {
      setStoredUser(event.data.user);
      dispatch(setAuth(event.data.user));
      return navigate(redirectTo, {
        replace: true
      });
    }
  };

  // Closes broadcast channel when user logs out
  useEffect(() => {
    if (userHasLoggedOut) {
      authBroadcastChannel.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHasLoggedOut]);

  // For redirecting if no active session is found
  setTimeout(() => {
    setHasNoActiveSession(true);
  }, ONE_HALF_SECOND);

  return hasNoActiveSession ? (
    <Navigate state={{ redirectTo }} to="/clear" />
  ) : (
    <TransferLoading />
  );
};
