import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';
import React from 'react';

export const SignOffDialog = ({
  handleClose,
  handleSubmit,
  isOpen,
  loading = false
}) => {
  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <DialogContent>
        <DialogContentText>
          A sign off already exists for this action. Are you sure you want to
          send another sign off request?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          variant="contained"
        >
          Send again
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
