import { CircularProgress } from '@mui/material';
import React, { Suspense, lazy, useEffect } from 'react';
// eslint-disable-next-line import/namespace
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useRenewAccessTokenMutation } from '../../features/auth/auth-api-slice';
import {
  selectCanWrite,
  selectHasLoggedOut,
  selectIsSystemAdmin,
  setAccessToken,
  setIdle
} from '../../features/auth/auth-slice';
import { broadcastChannelKeys } from '../../features/auth/broadcast-channel-keys';
import { setStoredUser } from '../../features/auth/user-storage';
import { SignOffContext } from '../../features/sign-off-context/SignOffContext';
import { TldCostAddForm } from '../../features/tld-cost/TldCostAddForm';
import { TldCostEditMultipleForm } from '../../features/tld-cost/TldCostEditMultipleForm';
import { TldCostEditOneForm } from '../../features/tld-cost/TldCostEditOneForm';
import { TldCostHistoryDialog } from '../../features/tld-cost/TldCostHistoryDialog';
import { WhoIsSearch } from '../../features/whois/WhoIsSearch';
import { ZoneDetailsPage } from '../../features/zone-management/ZoneDetailsPage';
import { ZoneManagement } from '../../features/zone-management/ZoneManagement';
import { Zones } from '../../features/zone-management/Zones';
import { setStoredCustomer } from '../storage/customer-storage';
import { UnauthorizedMemoized } from './Unauthorized';

const Dashboard = lazy(() => import('../../features/dashboard/Dashboard'));
const Invoices = lazy(() => import('../../features/invoices/Invoices'));
const Messages = lazy(() => import('../../features/messages/Messages'));
const Orders = lazy(() => import('../../features/orders/Orders'));
const Domains = lazy(() => import('../../features/domains/Domains'));
const Preregistrations = lazy(
  () => import('../../features/preregistrations/Preregistrations')
);
const Web3 = lazy(() => import('../../features/web3/Web3'));
const PendingTransfers = lazy(
  () => import('../../features/transfers/PendingTransfers')
);
const ArchivedTransfers = lazy(
  () => import('../../features/transfers/ArchivedTransfers')
);
const RegisterDomainsStepper = lazy(
  () => import('../../features/register-domains/RegisterDomainsStepper')
);
const TransferStepper = lazy(
  () => import('../../features/transfer/TransferStepper')
);
const ProfileSecurityCurrentUser = lazy(
  () => import('../../features/profile-security/ProfileSecurityCurrentUser')
);
const ProfileAccountInformationCurrentUser = lazy(
  () =>
    import(
      '../../features/profile-account-information/ProfileAccountInformationCurrentUser'
    )
);
const ProfileChangePassword = lazy(
  () => import('../../features/profile-change-password/ProfileChangePassword')
);
const Transactions = lazy(
  () => import('../../features/transactions/Transactions')
);

const DownloadExport = lazy(
  () => import('../../features/download-export/DownloadExport')
);
const TldResources = lazy(
  () => import('../../features/tld-resources/TldResources')
);
const Customers = lazy(() => import('../../features/customers/Customers'));
const Customer = lazy(() => import('../../features/customers/Customer'));
const NewCustomer = lazy(() => import('../../features/customers/NewCustomer'));
const NewCustomerAccessManager = lazy(
  () => import('../../features/customers/NewCustomerAccessManager')
);
const TldCosts = lazy(() => import('../../features/tld-cost/TldCosts'));
const Pricing = lazy(() => import('../../features/pricing/Pricing'));
const TldHealth = lazy(() => import('../../features/tld-health/TldHealth'));
const UsersAccessManager = lazy(
  () => import('../../features/users/UsersAccessManager')
);
const NewUserAccessManager = lazy(
  () => import('../../features/users/NewUserAccessManager')
);
const TldCostsAccessManager = lazy(
  () => import('../../features/tld-cost/TldCostsAccessManager')
);
const TldHealthAccessManager = lazy(
  () => import('../../features/tld-health/TldHealthAccessManager')
);
const PricingAccessManager = lazy(
  () => import('../../features/pricing/PricingAccessManager')
);
const Users = lazy(() => import('../../features/users/Users'));
const User = lazy(() => import('../../features/users/User'));
const NewUser = lazy(() => import('../../features/users/NewUser'));
const RestrictedUsers = lazy(
  () => import('../../features/restricted-users/RestrictedUsers')
);
const CustomerAdminAccessManager = lazy(
  () => import('./authenticated-routes/CustomerAdminAccessManager')
);
const SignOffFromEmail = lazy(
  () => import('../../features/sign-off-from-email/SignOffFromEmail')
);
const SystemSearchGroups = lazy(() => import('./search-groups/SearchGroups'));

export const AuthenticatedRoutes = () => {
  const store = useStore();
  const isSystemAdmin = useSelector(selectIsSystemAdmin);
  const userHasLoggedOut = useSelector(selectHasLoggedOut);
  const canWrite = useSelector(selectCanWrite);
  const dispatch = useDispatch();
  const onIdle = () => dispatch(setIdle({ isIdle: true }));
  const onActive = () => dispatch(setIdle({ isIdle: false }));
  const ONE_MINUTE = 60 * 1000;
  const FOUR_MINUTES = 4 * ONE_MINUTE;
  const TWO_MINUTES = 2 * ONE_MINUTE;

  useIdleTimer({ onActive, onIdle, timeout: FOUR_MINUTES });

  const [renewAccessToken] = useRenewAccessTokenMutation();

  useEffect(() => {
    if (import.meta.env.MODE === 'test') {
      // eslint-disable-next-line testing-library/no-debugging-utils
      return window.console.debug('Token renewal disabled in test');
    }

    let intervalId;

    const renew = async () => {
      intervalId = setInterval(async () => {
        try {
          const response = await renewAccessToken().unwrap();
          dispatch(setAccessToken(response.access_token));
        } catch (error) {
          window.console.warn(error);
          dispatch(setAccessToken(null));
        }
      }, TWO_MINUTES);
    };
    renew();

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let previousStoredAuth;

    const unsubscribe = store.subscribe(() => {
      const nextStoredAuth = store.getState().auth;

      if (previousStoredAuth !== nextStoredAuth) {
        setStoredUser(nextStoredAuth);
        previousStoredAuth = nextStoredAuth;
      }
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Allows email link session to check for authorized sessions
  const authBroadcastChannel = new BroadcastChannel(
    broadcastChannelKeys.BROADCAST_CHANNEL_AUTH_REQUEST_NAME
  );
  authBroadcastChannel.onmessage = event => {
    if (event.data === broadcastChannelKeys.BROADCAST_CHANNEL_AUTH_REQUEST) {
      authBroadcastChannel.postMessage({
        message: broadcastChannelKeys.BROADCAST_CHANNEL_AUTH_KEY,
        user: store.getState().auth
      });
    }
  };
  // Closes broadcast channel when user logs out
  useEffect(() => {
    if (userHasLoggedOut) {
      authBroadcastChannel.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHasLoggedOut]);

  useEffect(() => {
    let previousStoredCustomer;

    const unsubscribe = store.subscribe(() => {
      const nextStoredCustomer = store.getState().customers?.active;

      if (previousStoredCustomer !== nextStoredCustomer) {
        setStoredCustomer(nextStoredCustomer);
        previousStoredCustomer = nextStoredCustomer;
      }
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Suspense fallback={<CircularProgress sx={{ ml: '50%', mt: '35%' }} />}>
        <Routes>
          {isSystemAdmin ? (
            <>
              {/* Users */}
              <Route path="/users">
                <Route element={<UsersAccessManager />} path="*">
                  <Route element={<Users />} path=":userType" />
                  <Route
                    element={<User />}
                    path=":userType/edit/:userEmail/:settingsType"
                  />
                  <Route element={<NewUser />} path="new" />
                </Route>
              </Route>
              {/* Profile -> Account Information */}
              <Route
                element={<ProfileAccountInformationCurrentUser />}
                path="/profile/account-information"
              />
              {/* Profile -> Security */}
              <Route
                element={<ProfileSecurityCurrentUser />}
                path="/profile/security"
              />
              {/* Change Password */}
              <Route
                element={<ProfileChangePassword />}
                path="/profile/change-password"
              />
            </>
          ) : (
            <>
              {/* Dashboard */}
              <Route element={<Navigate to="/dashboard" />} path="/" />
              <Route element={<Dashboard />} path="/dashboard" />
              {/* Domains */}
              <Route element={<Domains />} path="/domains">
                <Route
                  element={<Domains />}
                  path="page/:page/page-size/:pageSize"
                />
                <Route element={<Domains />} path="page/:page" />
              </Route>
              {/* Preregistrations */}
              <Route
                element={<Preregistrations />}
                path="/domains/preregistrations"
              >
                <Route
                  element={<Preregistrations />}
                  path="page/:page/page-size/:pageSize"
                />
                <Route element={<Preregistrations />} path="page/:page" />
              </Route>
              {/* Web 3 */}
              <Route element={<Web3 />} path="/domains/web3">
                <Route
                  element={<Web3 />}
                  path="page/:page/page-size/:pageSize"
                />
                <Route element={<Web3 />} path="page/:page" />
              </Route>
              {/* Transfers */}
              <Route element={<PendingTransfers />} path="/transfers" />
              <Route
                element={<ArchivedTransfers />}
                path="/transfers/archive"
              />
              {/* Invoices */}
              <Route element={<Invoices />} path="/invoices">
                <Route
                  element={<Invoices />}
                  path=":tableType/page/:page/page-size/:pageSize"
                />
                <Route element={<Invoices />} path=":tableType/page/:page" />
                <Route element={<Invoices />} path=":tableType" />
              </Route>
              {/* Messages */}
              <Route element={<Messages />} path="/messages">
                <Route
                  element={<Messages />}
                  path=":tableType/page/:page/page-size/:pageSize"
                />
                <Route element={<Messages />} path=":tableType/page/:page" />
                <Route element={<Messages />} path=":tableType" />
              </Route>
              {/* TLD Resources */}
              <Route element={<TldResources />} path="/tld-resources">
                <Route
                  element={<TldResources />}
                  path="page/:page/page-size/:pageSize"
                />
              </Route>
              {/* Tld Health */}
              <Route element={<TldHealthAccessManager />} path="/tld-health">
                <Route element={<TldHealth />} index />
              </Route>
              {/* Profile -> Account Information */}
              <Route
                element={<ProfileAccountInformationCurrentUser />}
                path="/profile/account-information"
              />
              {/* Profile -> Security */}
              <Route
                element={<ProfileSecurityCurrentUser />}
                path="/profile/security"
              />
              {/* Profile -> Change Password */}
              <Route
                element={<ProfileChangePassword />}
                path="/profile/change-password"
              />
              {/* Register */}
              <Route
                element={<RegisterDomainsStepper />}
                path="/register/:stepName"
              />
              {/* Transfer */}
              <Route
                element={
                  canWrite ? <TransferStepper /> : <UnauthorizedMemoized />
                }
                path="/transfer/:stepName"
              />
              {/* Orders */}
              <Route element={<Orders />} path="/orders" />
              {/* Customers */}
              <Route path="/customers">
                <Route element={<Customers />} index />
                <Route element={<NewCustomerAccessManager path="*" />}>
                  <Route element={<NewCustomer />} path="new" />
                </Route>
                <Route
                  element={<Customer />}
                  path=":customerName/:settingsType"
                />
              </Route>
              {/* System Level Search Groups */}
              <Route
                element={<SystemSearchGroups isSystemSearchGroups={true} />}
                path="/system-search-groups"
              />
              {/* Transactions */}
              <Route element={<Transactions />} path="/transactions">
                <Route
                  element={<Transactions />}
                  path="page/:page/page-size/:pageSize"
                />
              </Route>
              {/* Users */}
              <Route path="/users">
                <Route element={<UsersAccessManager />} path="*">
                  <Route element={<Users />} path=":userType" />
                  <Route
                    element={<User />}
                    path=":userType/edit/:userEmail/:settingsType"
                  />
                </Route>
                <Route
                  element={<CustomerAdminAccessManager />}
                  path="restricted-users"
                >
                  <Route element={<RestrictedUsers />} index />
                </Route>
                <Route element={<NewUserAccessManager />} path="*">
                  <Route element={<NewUser />} path="new" />
                </Route>
              </Route>
              {/* Tld Costs */}
              <Route element={<TldCostsAccessManager />} path="/tld-costs">
                <Route element={<TldCosts />} path="">
                  <Route
                    element={<TldCostHistoryDialog />}
                    path="history/:tldCostId"
                  />
                  <Route element={<TldCostAddForm />} path="add" />
                  <Route
                    element={<TldCostEditOneForm />}
                    path="edit/:tldCostIds"
                  />
                  <Route
                    element={<TldCostEditMultipleForm />}
                    path="edit-multiple/:tldCostIds"
                  />
                </Route>
              </Route>
              {/* Zone Management*/}
              <Route element={<ZoneManagement />} path="/zone-management">
                <Route element={<Zones />} path="zones" />
                <Route
                  element={<ZoneDetailsPage />}
                  path="zones/details/:zoneId"
                />
              </Route>
              {/* Pricing */}
              <Route element={<PricingAccessManager />} path="/pricing">
                <Route element={<Pricing />} path="" />
                <Route element={<Pricing />} path=":pricingViewType" />
              </Route>
              {/* WhoIs Search */}
              <Route element={<WhoIsSearch />} path="/whois" />
              {/* Sign Off Context Permissions */}
              <Route element={<SignOffContext />} path="/sign-off-settings" />

              {/* Any incoming links from email should be nested under this path */}
              <Route path="/e">
                <Route
                  element={<DownloadExport />}
                  path="download-export/:downloadKey"
                />
                <Route
                  element={<SignOffFromEmail />}
                  path="sign-offs/:action/:id"
                />
              </Route>
            </>
          )}
          <Route element={null} path="/empty-route-for-tests" />
        </Routes>
      </Suspense>
    </>
  );
};
