import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { useAppInfoQuery } from '../../common/store/app-info-api-slice';
import { ZoneManagementTabs } from './ZoneManagementTabs';

export const ZoneManagement = () => {
  const {
    data: appInfoData = {
      zoneManagementEnabled: false
    }
  } = useAppInfoQuery();

  const { zoneManagementEnabled } = appInfoData;

  return (
    zoneManagementEnabled && (
      <Box sx={{ height: '100%', weight: '100%' }}>
        <ZoneManagementTabs index={0} />

        <Outlet />
      </Box>
    )
  );
};
