import {
  faCheck,
  faClock,
  faHourglassHalf,
  faXmark
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCustomerName } from '../../../../common/store/customers-slice';
import { selectReseller } from '../../../../common/store/reseller';
import { formatPrice } from '../../../../common/utils/number-price-text';
import {
  selectCanWrite,
  selectIsSuperAdminOrFinance
} from '../../../auth/auth-slice';
import {
  useAddDomainToCartMutation,
  useCreateNewCartMutation,
  useRemoveDomainFromCartMutation
} from '../../register-domains-api-slice';
import {
  selectAreAllDomainsSelected,
  selectCart,
  selectCartInfo,
  selectIdnLangsMap,
  selectIsAddingDomainToCart,
  selectIsRemovingDomainFromCart,
  selectSearchResultsTableData,
  selectSelectedSearchGroups
} from '../../register-domains-slice';
import {
  domainAvailability,
  getTldPhaseName
} from '../../register-domains-utils';
import { DomainCell } from '../DomainCell';
import { delayedRegistrationValues } from '../domain-details/special-requirements';
import { domainCheckAvailabilities } from './domain-check-availabilities';

export const RegisterDomainsSearchResultsTable = ({
  streamingTableData,
  tldsData
}) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const isSuperAdminOrFinance = useSelector(selectIsSuperAdminOrFinance);
  const canWrite = useSelector(selectCanWrite);
  const idnLangsMap = useSelector(selectIdnLangsMap);
  const cart = useSelector(selectCart);
  const selectedSearchGroups = useSelector(selectSelectedSearchGroups);
  const theme = useTheme();

  const isAddingDomainToCart = useSelector(selectIsAddingDomainToCart);
  const isRemovingDomainFromCart = useSelector(selectIsRemovingDomainFromCart);
  const cartInfo = useSelector(selectCartInfo);
  const areAllDomainsSelected = useSelector(selectAreAllDomainsSelected);
  const tableData = useSelector(selectSearchResultsTableData);
  const tableRows = tableData?.length ? tableData : streamingTableData;
  const [isAddAllBtnDisabled, setIsAddAllBtnDisabled] = useState(true);

  const [createNewCart] = useCreateNewCartMutation();

  const [addDomainToCart] = useAddDomainToCartMutation();
  const [removeDomainFromCart] = useRemoveDomainFromCartMutation();

  const areAnyDomainAvailabilitiesChecking = () => {
    return tableRows.some(
      domain => domain.available === domainCheckAvailabilities.CHECKING
    );
  };

  const isAnyDomainAvailable = () => {
    return tableRows.some(domain =>
      domainAvailability.includes(domain.available)
    );
  };

  useEffect(() => {
    if (areAnyDomainAvailabilitiesChecking()) {
      setIsAddAllBtnDisabled(true);
    } else if (isAnyDomainAvailable()) {
      setIsAddAllBtnDisabled(false);
    } else {
      setIsAddAllBtnDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRows]);

  const getSelectedDomain = domainToSelect =>
    cart?.find(
      selectedDomain => selectedDomain.domainName === domainToSelect.domain
    );

  const handleSelectDomain = domain => {
    const idnLanguage = idnLangsMap[domain.domain];
    // If there are no selected domains, create a new cart and add the selected domain to it
    if (!cartInfo) {
      createNewCart({ customerName, reseller }).then(res => {
        addDomainToCart({
          customerCartId: res.data?.id,
          customerCartItemType: domain.$tldObject?.web3 ? 'WEB3' : 'DNS',
          customerName,
          domain,
          domainName: domain.domain,
          idnLanguage,
          reseller
        });
      });
    } else {
      addDomainToCart({
        customerCartId: cartInfo?.id,
        customerCartItemType: domain.$tldObject?.web3 ? 'WEB3' : 'DNS',
        customerName,
        domain,
        domainName: domain.domain,
        idnLanguage,
        reseller
      });
    }
  };

  const handleRemoveDomain = domainToRemove => {
    removeDomainFromCart({
      customerName,
      domainId: domainToRemove.id,
      reseller
    });
  };

  const handleSelectAllDomains = () => {
    if (!cartInfo) {
      createNewCart({ customerName, reseller }).then(() => {
        tableRows.forEach(row => {
          if (domainAvailability.includes(row.available)) {
            handleSelectDomain(row)
          }
        });
      });
    } else {
      tableRows.forEach(row => {
        //Add to cart if available and not already selected
        if (
          domainAvailability.includes(row.available) &&
          !cart.some(domain => domain.domainName === row.domain)
        ) {
          handleSelectDomain(row)
        }
      });
    }
  };

  const handleDeselectAllDomains = () => {
    cart.forEach(domain => handleRemoveDomain(domain));
  };

  // eslint-disable-next-line complexity
  const checkOrXMark = domain => {
    switch (domain.available) {
      case domainCheckAvailabilities.YES:
        return (
          <Tooltip title="Available">
            <FontAwesomeIcon
              color={theme.palette.success.main}
              icon={faCheck}
            />
          </Tooltip>
        );
      case domainCheckAvailabilities['-']:
      case domainCheckAvailabilities.NO:
        return (
          <Tooltip title="Not Available">
            <FontAwesomeIcon color={theme.palette.error.main} icon={faXmark} />
          </Tooltip>
        );
      case domainCheckAvailabilities.PENDING:
        return (
          <Tooltip title="Availability Pending">
            <FontAwesomeIcon
              color={theme.palette.warning.dark}
              icon={faHourglassHalf}
            />
          </Tooltip>
        );
      case domainCheckAvailabilities.CHECKING:
        return (
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            sx={{ color: 'info.main' }}
          >
            <FontAwesomeIcon icon={faClock} />
            <Typography sx={{ pl: 0.5 }} variant="body2">
              Checking
            </Typography>
          </Stack>
        );
      case domainCheckAvailabilities.PRE_SUNRISE:
        return (
          <Typography
            sx={{ fontStyle: 'italic', textAlign: 'center' }}
            variant="body2"
          >
            May Be Available
            <br />
            (Sunrise)
          </Typography>
        );
      case domainCheckAvailabilities.UNKNOWN_MANUAL:
      case domainCheckAvailabilities.UNKNOWN_WEB3:
        return (
          <Typography
            sx={{ fontStyle: 'italic', textAlign: 'center' }}
            variant="body2"
          >
            May Be Available
          </Typography>
        );
      case domainCheckAvailabilities.ERROR:
      case domainCheckAvailabilities.UNKNOWN_PHASE:
        return (
          <Typography
            sx={{
              color: theme => theme.palette.error.main,
              fontStyle: 'italic',
              textAlign: 'center'
            }}
            variant="body2"
          >
            An error occurred.
          </Typography>
        );
      default:
        return '';
    }
  };

  const addOrRemoveButton = domain => {
    const hideAddButton =
      domain.available === domainCheckAvailabilities.CHECKING ||
      (domain.available === domainCheckAvailabilities.PRE_SUNRISE &&
        areAnyDomainAvailabilitiesChecking());
    if (hideAddButton) {
      return;
    }
    if (domain.unsupported) {
      return <i>Unsupported TLD</i>;
    }
    if (
      domain.available === domainCheckAvailabilities.NO ||
      domain.available === domainCheckAvailabilities.UNKNOWN_PHASE ||
      domain.available === domainCheckAvailabilities.ERROR
    ) {
      return <span>-</span>;
    }
    const selectedDomain = getSelectedDomain(domain);
    if (selectedDomain) {
      return (
        <LoadingButton
          color="secondary"
          loading={isRemovingDomainFromCart}
          onClick={() => handleRemoveDomain(selectedDomain)}
          variant="outlined"
        >
          Remove
        </LoadingButton>
      );
    } else {
      return (
        <LoadingButton
          loading={isAddingDomainToCart}
          onClick={() => handleSelectDomain(domain)}
          variant="outlined"
        >
          Add
        </LoadingButton>
      );
    }
  };

  const columnDefaultProps = {
    disableColumnMenu: true,
    filterable: false
  };

  const columns = useMemo(() => {
    const tableColumns = [
      {
        ...columnDefaultProps,
        field: 'domain',
        flex: 1,
        headerName: 'Domain Name',
        renderCell: ({ row }) => <DomainCell params={row} />,
        sortable: true
      },
      {
        ...columnDefaultProps,
        field: 'years',
        headerName: 'Years',
        renderCell: ({ row }) => {
          if (row.available === domainCheckAvailabilities['-']) {
            return '-';
          } else {
            const tldPhaseName = row.domain
              ? getTldPhaseName(row.domain, tldsData)
              : getTldPhaseName(row.domainName, tldsData);
            return delayedRegistrationValues.includes(tldPhaseName)
              ? ''
              : row.years;
          }
        },
        sortable: true
      },
      {
        ...columnDefaultProps,
        align: 'center',
        field: '$localPresencePrice',
        flex: 1,
        headerAlign: 'center',
        headerName: 'Local Presence Price',
        renderCell: ({ row }) =>
          row.$localPresencePrice
            ? `$${formatPrice(row.$localPresencePrice)}`
            : ''
      },
      {
        ...columnDefaultProps,
        field: 'price',
        flex: 1,
        headerName: 'Price',
        renderCell: ({ row }) => {
          if (row.available === domainCheckAvailabilities['-']) {
            return '-';
          } else {
            const tldPhaseName = row.domain
              ? getTldPhaseName(row.domain, tldsData)
              : getTldPhaseName(row.domainName, tldsData);
            return delayedRegistrationValues.includes(tldPhaseName) ||
              !row.price
              ? ''
              : `$${formatPrice(row.price)}`;
          }
        },
        sortable: true
      },
      {
        ...columnDefaultProps,
        align: 'center',
        field: 'availability',
        flex: 1,
        headerAlign: 'center',
        headerName: 'Availability',
        renderCell: ({ row }) => checkOrXMark(row),
        sortable: false
      }
    ];
    if (isSuperAdminOrFinance) {
      tableColumns.splice(2, 0, {
        ...columnDefaultProps,
        field: 'registryCost',
        flex: 1,
        headerName: 'Registry Cost',
        renderCell: ({ row }) => row.$registryCost,
        sortable: true
      });
    }
    if (canWrite) {
      tableColumns.push({
        ...columnDefaultProps,
        align: 'center',
        field: 'add-remove',
        headerAlign: 'center',
        renderCell: ({ row }) => addOrRemoveButton(row),
        renderHeader: () => (
          <>
            {areAllDomainsSelected ? (
              <LoadingButton
                color="secondary"
                loading={isRemovingDomainFromCart}
                onClick={handleDeselectAllDomains}
                variant="outlined"
              >
                Remove all
              </LoadingButton>
            ) : (
              <LoadingButton
                disabled={isAddAllBtnDisabled}
                loading={isAddingDomainToCart}
                onClick={handleSelectAllDomains}
                variant="outlined"
              >
                Add all
              </LoadingButton>
            )}
          </>
        ),
        sortable: false,
        width: 160
      });
    }
    return tableColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableRows,
    isAddingDomainToCart,
    isRemovingDomainFromCart,
    cart,
    isAddAllBtnDisabled
  ]);

  const getNoRowsOverlayText = () => {
    const overlayText = !selectedSearchGroups?.length
      ? 'Select a search group to perform a search without a TLD'
      : 'Perform a search to see results';
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: '10%'
        }}
      >
        {overlayText}
      </Box>
    );
  };

  return (
    <DataGridPro
      columns={columns}
      components={{
        NoRowsOverlay: getNoRowsOverlayText
      }}
      disableRowSelectionOnClick={true}
      disableVirtualization={import.meta.env.MODE === 'test'}
      getRowId={row => row.domainName ?? row.domain}
      hideFooter
      rowCount={tableRows?.length}
      rows={tableRows}
      sx={{
        backgroundColor: 'white'
      }}
    />
  );
};
