import { apiSlice } from '../../common/store/api-slice';
import { formatPrice } from '../utils/number-price-text';

const tldsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getCheckoutMessages: builder.query({
      query: () => `/tlds/checkout-messages`,
      transformResponse: response => response.entity
    }),
    getSupportedYearsByTldName: builder.query({
      query: ({ tldName }) => `/tlds/${tldName}/supported-years`,
      transformResponse: response => ({
        ...response,
        supportedProductTypes: Object.keys(response.supportedYears)
      })
    }),
    getTldHealth: builder.query({
      query: () => `/tlds/tld-health-data`,
      transformResponse: response =>
        response.map((tld, index) => {
          tld.$id = index;
          return tld;
        })
    }),
    getTlds: builder.query({
      query: () => `tlds`
    }),
    getTldsPricingByCustomer: builder.query({
      query: ({ customerName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/tlds/consolidated-pricing?maxResults=5000`,
      transformResponse: response =>
        response.map(tld => {
          tld.$localPresenceCost = Object.values(
            tld?.prices?.[tld.phase]?.localpresence ?? {}
          )?.[0];

          if (tld.$localPresenceCost) {
            tld.$localPresenceCostFormatted = `$${formatPrice(
              tld.$localPresenceCost
            )}`;

            tld.$isLocalPresenceSupported =
              tld.localPresenceOffered &&
              typeof tld.$localPresenceCost === 'number';
          }

          // for renewals
          tld.$prices = {};
          Object.keys(tld.prices).forEach(phase => {
            tld.$prices[phase] = {};
            Object.keys(tld.prices[phase]).forEach(productType => {
              tld.$prices[phase][productType] = Object.keys(
                tld.prices[phase][productType]
              ).map(years => ({
                price: tld.prices[phase][productType][years],
                years
              }));
            });
          });
          return tld;
        })
    })
  })
});

export const {
  useGetCheckoutMessagesQuery,
  useGetSupportedYearsByTldNameQuery,
  useGetTldHealthQuery,
  useGetTldsPricingByCustomerQuery,
  useGetTldsQuery
} = tldsApiSlice;
