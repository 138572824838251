import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { updateById } from './transfer-slice';

export const AuthCodeCell = ({ authCode, authCodeValidity, id }) => {
  const [fieldValue, setFieldValue] = useState(authCode ?? '');
  const isAuthCodeInvalid = authCodeValidity?.isError ?? false;
  const dispatch = useDispatch();

  // There's a network request associated with this change,
  // therefore we want to debounce for performance
  const debouncedAuthCodeChange = useDebouncedCallback(value => {
    dispatch(
      updateById({
        changes: [{ key: 'authCode', value: value }],
        id
      })
    );
  }, 1000);

  const handleAuthCodeChange = event => {
    setFieldValue(event.target.value);
    debouncedAuthCodeChange(event.target.value);
  };

  return (
    <TextField
      error={isAuthCodeInvalid}
      fullWidth
      onChange={handleAuthCodeChange}
      size="small"
      value={fieldValue}
    />
  );
};

export const AuthCodeCellMemoized = React.memo(AuthCodeCell);
