import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import {
  selectAccessToken,
  selectIsAdmin,
  setAuth
} from '../../features/auth/auth-slice';
import { useGetCustomersQuery } from '../store/customers-api-slice';
import {
  selectCustomerName,
  setActiveCustomer
} from '../store/customers-slice';
import { selectReseller } from '../store/reseller';
import { useGetJWTQuery } from '../store/transfer-api-slice';
import { TransferLoading } from './TransferLoading';

export const HandleTransferToken = () => {
  const [searchParams] = useSearchParams();
  const transferTokenParam = searchParams.get('transferToken');
  const dispatch = useDispatch();
  const location = useLocation();

  const reseller = useSelector(selectReseller);
  const redirectTo = location.pathname;

  const customerNameParam = searchParams.get('customerName');
  const customerName = useSelector(selectCustomerName);
  const accessToken = useSelector(selectAccessToken);
  const isAdmin = useSelector(selectIsAdmin);
  const navigate = useNavigate();

  // Get a JWT access token
  const {
    data: getJWTResponse,
    isError: isGetJWTError,
    isSuccess: isGetJWTSuccess
  } = useGetJWTQuery(
    {
      transferToken: transferTokenParam
    },
    {
      skip: !transferTokenParam
    }
  );

  const haveAccessTokenFromTransferResponse =
    // `accessToken` is not the property returned by the API, but it is
    // what transformUserResponse returns.
    typeof getJWTResponse?.accessToken === 'string';
  const haveCustomerNameFromTransferResponse =
    typeof getJWTResponse?.customer?.accountName === 'string';

  // Set the JWT access token
  useEffect(() => {
    if (haveAccessTokenFromTransferResponse) {
      dispatch(setAuth(getJWTResponse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [haveAccessTokenFromTransferResponse]);

  // Set the customer for non-admin users
  useEffect(() => {
    if (haveCustomerNameFromTransferResponse) {
      dispatch(
        setActiveCustomer({
          ...getJWTResponse.customer,
          label: getJWTResponse.customer.accountName
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [haveCustomerNameFromTransferResponse]);

  // Get customers if admin and we have a customerNameParam
  const { data: customersResponse, isError: isGetCustomersError } =
    useGetCustomersQuery(
      { reseller },
      {
        skip:
          !transferTokenParam ||
          !isGetJWTSuccess ||
          !isAdmin ||
          !customerNameParam
      }
    );

  // Set the active customer from customers response
  useEffect(() => {
    if (customersResponse) {
      const activeCustomer = customersResponse.find(
        customer => customer.accountName === customerNameParam
      );

      dispatch(setActiveCustomer(activeCustomer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersResponse, customerNameParam]);

  // Navigate to redirectTo
  useEffect(() => {
    if (
      // Non-admin users and admin users with a customerNameParam query param
      (customerName && accessToken) ||
      // Admin users without a customerNameParam (they will see the customer selection dialog)
      (isAdmin && accessToken && !customerNameParam)
    ) {
      // Prefix with system-admin if they're a system admin.
      navigate(redirectTo, {
        replace: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerName, accessToken]);

  // Redirect to /login on error or if we somehow don't have a transferTokenParam
  useEffect(() => {
    if (isGetJWTError || isGetCustomersError || !transferTokenParam) {
      navigate('/login', {
        replace: true,
        state: { isTransferError: true, redirectTo }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferTokenParam, isGetJWTError, isGetCustomersError, redirectTo]);

  return <TransferLoading />;
};
