import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { generateId } from '../../common/utils/generate-id';
import { formatPrice } from '../../common/utils/number-price-text';
import { getSpecialRequirements } from './special-requirements';

export const initialState = {
  displaySpecialRequirementsNotice: false,
  domainsToTransferCount: 0,
  haveTransfersWithSpecialRequirements: false,
  selectedTransferIds: [],
  selectedTransfers: [],
  steps: ['Add Domains', 'Domain Details', 'Initiate Transfer'],
  transferValidity: false,
  transfers: [],
  transfersTotal: 0,
  userAgrees: {
    domainServiceAgreementAccepted: false,
    domainServiceAgreementAcceptedDate: null
  },
  userInput: ''
};

const updateTransferCount = transfers =>
  transfers.filter(transfer => transfer?.availability?.available === true)
    .length;

const domainsAndAuthCodesToString = domainsAndAuthCodes =>
  domainsAndAuthCodes
    .map(({ authCode, domainName }) => `${domainName ?? ''} ${authCode ?? ''}`)
    .join('\n');

const transferSlice = createSlice({
  initialState,
  name: 'transfer',
  reducers: {
    addTransfer: (state, { payload }) => {
      state.transfers = state.transfers.concat({
        authCode: '',
        customerContactSetId: payload.defaultContactSetId,
        domainName: '',
        id: generateId(),
        labels: []
      });
    },
    displaySpecialRequirementsNotice: (state, { payload }) => {
      state.displaySpecialRequirementsNotice = payload;
    },
    removeById: (state, { payload }) => {
      state.transfers = state.transfers.filter(
        transfer => transfer.id !== payload
      );
      state.domainsToTransferCount = updateTransferCount(state.transfers);
      state.userInput = domainsAndAuthCodesToString(state.transfers);
    },
    removeSelectedTransfer: (state, { payload }) => {
      // expected payload: id of the domain to unselect
      state.selectedTransferIds = state.selectedTransferIds.filter(
        domainId => domainId !== payload
      );
      state.selectedTransfers = state.selectedTransfers.filter(
        domain => domain.id !== payload
      );
    },
    reset: state => {
      state = initialState;
      return state;
    },
    setSelectedTransfers: (state, { payload }) => {
      // expected payload: array of selected domain objects
      state.selectedTransfers = payload;
      state.selectedTransferIds = payload.map(transfer => transfer?.id);
    },
    setSpecialRequirementsValidity: (
      state,
      { payload: { domainName, isValid } }
    ) => {
      state.transfers = state.transfers.map(transfer => {
        if (transfer.domainName === domainName) {
          transfer.specialRequirements.isValid = isValid;
        }
        return transfer;
      });
    },
    setUserAgrees: (state, { payload }) => {
      state.userAgrees = {
        domainServiceAgreementAccepted: payload,
        domainServiceAgreementAcceptedDate: payload
          ? dayjs().toISOString()
          : null
      };
    },

    setUserInput: (state, { payload: { customerContactSetId, userInput } }) => {
      state.userInput = userInput;
      state.transfers = userInput
        .split(/\n/g)
        .filter(line => (line || '').trim().length)
        .map(line => {
          const lineParts = line.trim().split(/\s/g);
          return {
            authCode: (lineParts?.[1] || '').trim(),
            customerContactSetId: customerContactSetId,
            domainName: (lineParts[0] || '').trim(),
            id: generateId(),
            specialRequirements: getSpecialRequirements(lineParts[0]),
            years: '1'
          };
        });

      state.haveTransfersWithSpecialRequirements = state.transfers.some(
        transfer => transfer.specialRequirements
      );
    },
    updateById: (state, { payload }) => {
      const updates = Array.isArray(payload) ? payload : [payload];

      state.transfers = state.transfers.map(transfer => {
        const matchingChange = updates.find(
          update => update.id === transfer.id
        );

        if (matchingChange) {
          matchingChange.changes.forEach(change => {
            transfer[change.key] = change.value;
            if (change.key === 'domainName') {
              transfer.specialRequirements = getSpecialRequirements(
                change.value
              );
            }
          });
        }

        return transfer;
      });

      state.userInput = domainsAndAuthCodesToString(state.transfers);
      state.haveTransfersWithSpecialRequirements = state.transfers.some(
        transfer => transfer.specialRequirements
      );
    },
    updateTransfersWithPrices: (state, { payload }) => {
      const updates = Array.isArray(payload) ? payload : [payload];
      state.transfers = state.transfers.map(transfer => {
        const matchingChange = updates.find(
          update => update.domainName === transfer.domainName
        );
        const sortedYears = matchingChange?.prices
          ?.map(price => price.years)
          .sort();

        transfer.prices = matchingChange.prices;
        if (transfer.years === null) {
          transfer.years = sortedYears[0];
        }
        return transfer;
      });
    }
  }
});

export const {
  addTransfer,
  displaySpecialRequirementsNotice,
  removeById,
  removeSelectedTransfer,
  reset,
  setSelectedTransfers,
  setSpecialRequirementsValidity,
  setUserAgrees,
  setUserInput,
  updateById,
  updateTransfersWithPrices
} = transferSlice.actions;

export const transferReducer = transferSlice.reducer;

export const selectSteps = state => state.transfer.steps;
export const selectUserInput = state => state.transfer.userInput;
export const selectTransfers = state => state.transfer.transfers;
export const selectUserAgrees = state => state.transfer.userAgrees;

export const selectHaveTransfersWithSpecialRequirements = state =>
  state.transfer.haveTransfersWithSpecialRequirements;

export const selectDisplaySpecialRequirementsNotice = state =>
  state.transfer.displaySpecialRequirementsNotice;

const SHORTEST_POSSIBLE_LENGTH_FOR_DOMAIN_NAME = 4;
export const selectHaveAnyEmptyDomainNames = state =>
  state.transfer.transfers.some(
    ({ domainName }) =>
      domainName.length < SHORTEST_POSSIBLE_LENGTH_FOR_DOMAIN_NAME
  );

export const selectNonEmptyDomainNames = state =>
  state.transfer.transfers.filter(
    ({ domainName }) =>
      domainName.length >= SHORTEST_POSSIBLE_LENGTH_FOR_DOMAIN_NAME
  );

export const selectHaveValidSpecialRequirements = state =>
  state.transfer.transfers.every(
    transfer =>
      transfer.specialRequirements === undefined ||
      transfer.specialRequirements.isValid
  );

export const selectSpecialRequirementsValidityById = (state, id) =>
  state.transfer.transfers.find(transfer => transfer.id === id)
    ?.specialRequirements?.isValid;

export const selectSelectedTransferIds = state =>
  state.transfer?.selectedTransferIds;
export const selectSelectedTransfers = state =>
  state.transfer?.selectedTransfers;

export const selectAllDomainsAreValidForTransfer = state =>
  state.transfer?.transfers?.every(transfer => transfer.validForTransfer);

export const selectTransferTotal = state => {
  const transferPriceArray = state.transfer?.transfers
    ?.map(
      transfer =>
        transfer?.prices?.find(
          price => price.years === parseInt(transfer.years)
        )?.amount
    )
    .filter(Number);
  const transferLocalPresenceArray = state.transfer?.transfers
    ?.map(transfer => transfer?.localPresenceCost)
    .filter(Number);

  const pricesSum = transferPriceArray.length
    ? transferPriceArray.reduce((priceA, priceB) => priceA + priceB)
    : 0;
  const localPresenceSum = transferLocalPresenceArray.length
    ? transferLocalPresenceArray.reduce((costA, costB) => costA + costB)
    : 0;

  return formatPrice(pricesSum + localPresenceSum);
};

export const selectLocalPresenceTotal = state => {
  const transferLocalPresenceArray = state.transfer?.transfers
    ?.map(transfer => transfer?.localPresenceCost)
    .filter(Number);

  return transferLocalPresenceArray.length
    ? transferLocalPresenceArray.reduce((costA, costB) => costA + costB)
    : 0;
};
