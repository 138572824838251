import { List, ListItem, Typography } from '@mui/material';
import React from 'react';

export const ContactSetValidityMessage = ({ contactSetValidity }) => {
  if (!contactSetValidity.message || !contactSetValidity.isError) {
    return null;
  }

  const getErrorMessages = () => {
    if (contactSetValidity.message?.status === 404) {
      return (
        <ListItem
          key={contactSetValidity?.message.message}
          sx={{ display: 'list-item', pl: 0 }}
        >
          {contactSetValidity?.message.message}
        </ListItem>
      );
    } else if (contactSetValidity.message?.length) {
      return contactSetValidity.message?.map(reason => (
        <ListItem key={reason} sx={{ display: 'list-item', pl: 0 }}>
          {reason}
        </ListItem>
      ));
    } else {
      return (
        <ListItem sx={{ display: 'list-item', pl: 0 }}>
          There was an issue checking the validity of the contact set for this
          domain
        </ListItem>
      );
    }
  };

  return (
    !contactSetValidity.isValid && (
      <div>
        <Typography variant="body2">Contact Set Invalid</Typography>
        <List dense sx={{ listStyleType: 'disc', pl: 2 }}>
          {getErrorMessages()}
        </List>
      </div>
    )
  );
};
