import { apiSlice } from '../../common/store/api-slice';

export const tldPhaseApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTldPhaseByTldName: builder.query({
      query: ({ tldName }) => ({
        method: 'GET',
        url: `tld-phase/${tldName}`
      })
    })
  })
});

export const { useGetTldPhaseByTldNameQuery } = tldPhaseApiSlice;
