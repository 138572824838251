import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { selectAccessToken } from './auth-slice';

export const RequireAuth = () => {
  const location = useLocation();
  const redirectTo = `${location.pathname}${location.search}`;
  const accessToken = useSelector(selectAccessToken);

  return accessToken ? (
    <Outlet />
  ) : (
    <Navigate state={{ redirectTo }} to="/clear" />
  );
};
