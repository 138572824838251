import dayjs from 'dayjs';

export const getDatesApiFilters = (newFilterModel, dateField) => {
  const DEFAULT_FROM_DATE = dayjs('1980-07-31 00:00').format('YYYY-MM-DD');
  const DEFAULT_TO_DATE = dayjs().format('YYYY-MM-DD');
  let fromDate = DEFAULT_FROM_DATE;
  let toDate = DEFAULT_TO_DATE;

  const matchingIsFilter = newFilterModel.items.find(
    item => item.field === dateField && item.operator === 'is'
  );
  const matchingAfterFilter = newFilterModel.items.find(
    item => item.field === dateField && item.operator === 'after'
  );
  const matchingBeforeFilter = newFilterModel.items.find(
    item => item.field === dateField && item.operator === 'before'
  );

  if (matchingIsFilter?.value) {
    fromDate = matchingIsFilter.value;
    toDate = matchingIsFilter.value;
  }

  if (matchingAfterFilter?.value) {
    fromDate = matchingAfterFilter.value;
  }

  if (matchingBeforeFilter?.value) {
    toDate = matchingBeforeFilter.value;
  }

  return {
    fromDate,
    toDate
  };
};
