/* eslint-disable complexity */
/* eslint-disable max-lines */
import { faInfoCircle, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ContactSetsDialog } from '../../../../common/components/contact-sets/ContactSetsDialog';
import { ContactSetsInput } from '../../../../common/components/contact-sets-input/ContactSetInput';
import { NameServersInput } from '../../../../common/components/custom-inputs/NameServersInput';
import { LabelsTableInput } from '../../../../common/components/labels-table-input/LabelsTableInput';
import {
  getAssociatedLabelsForDomain,
  getAssociationsForDomain
} from '../../../../common/store/api-slice-utils';
import {
  useGetContactsQuery,
  useVerifyContactSetsForDomainsQuery
} from '../../../../common/store/contacts-api-slice';
import { selectCustomerName } from '../../../../common/store/customers-slice';
import {
  useAddLabelAssociationsMutation,
  useDeleteLabelAssociationsMutation,
  useGetLabelAssociationsQuery,
  useGetLabelsQuery
} from '../../../../common/store/labels-api-slice';
import {
  useGetNameServerGroupsQuery,
  useVerifyNameServerGroupsForTldsQuery
} from '../../../../common/store/name-servers-api-slice';
import { selectReseller } from '../../../../common/store/reseller';
import { useGetCheckoutMessagesQuery } from '../../../../common/store/tlds-api-slice';
import { formatPrice } from '../../../../common/utils/number-price-text';
// @ts-ignore
import classes from '../../RegisterDomains.module.css';
import { RegisterDomainsFooter } from '../../RegisterDomainsFooter';
import {
  useRemoveDomainFromCartMutation,
  useUpdateCartItemsMutation,
  useUpdateDomainInCartMutation
} from '../../register-domains-api-slice';
import {
  selectCart,
  selectCartIsValid,
  selectHaveCartItemsWithSpecialRequirements,
  setCartItemValidity
} from '../../register-domains-slice';
import { navigateToNextRegistrationStep } from '../../registration-navigation';
import { DomainCell } from '../DomainCell';
import { BulkLabelSelectPopover } from './BulkLabelSelectPopover';
import { BulkUpdateFormButton } from './BulkUpdateFormButton';
import { ContactSetsActions } from './ContactSetsActions';
import { ContactSetsDialogTrigger } from './ContactSetsDialogTrigger';
import { DnssecDialog } from './DnssecDialog';
import { SetCheckoutMessageMemoized } from './SetCheckoutMessage';
import { SetDotAeroSpecialRequirementsMemoized } from './SetDotAeroSpecialRequirements';
import { SetDotCoopSpecialRequirementsMemoized } from './SetDotCoopSpecialRequirements';
import { SetDotDeSpecialRequirementsMemoized } from './SetDotDeSpecialRequirements';
import { SetDotNewSpecialRequirementsMemoized } from './SetDotNewSpecialRequirements';
import { SetDotNoSpecialRequirementsMemoized } from './SetDotNoSpecialRequirements';
import { SetDotTelSpecialRequirementsMemoized } from './SetDotTelSpecialRequirements';
import { SetIntendedUseRequirementsMemoized } from './SetIntendedUseRequirements';
import { SetSmdSpecialRequirementMemoized } from './SetSmdSpecialRequirement';
import { SetSslAcknowledgementsMemoized } from './SetSslAcknowledgements';
import { ValidCell } from './ValidCell';
import { getCartItemValidityObject } from './cart-item-validity';
import { specialRequirements } from './special-requirements';

export const RegisterDomainsDomainDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stepName = useParams().stepName;
  const apiRef = useGridApiRef();
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const cart = useSelector(selectCart);
  const haveCartItemsWithSpecialRequirements = useSelector(
    selectHaveCartItemsWithSpecialRequirements
  );
  const [updateDomainInCart] = useUpdateDomainInCartMutation();
  const [
    updateCartItems,
    {
      isError: isUpdateCartError,
      isLoading: isUpdatingCart,
      isSuccess: isUpdateCartSuccess
    }
  ] = useUpdateCartItemsMutation();
  const [removeDomainFromCart] = useRemoveDomainFromCartMutation();
  const [addLabelAssociations] = useAddLabelAssociationsMutation();
  const [deleteLabelAssociations] = useDeleteLabelAssociationsMutation();
  const [checkedDomains, setCheckedDomains] = useState([]);
  const cartIsValid = useSelector(selectCartIsValid);
  const { enqueueSnackbar } = useSnackbar();

  const { data: checkoutMessages } = useGetCheckoutMessagesQuery();

  const handleApplyNameServerGroup = ({ item, value }) =>
    updateDomainInCart({
      customerName,
      domainId: item.id,
      reseller,
      updatedDomainBody: {
        ...item,
        nameServerGroupId: value.id
      }
    });

  const handleApplyContactSet = ({ item, value }) => {
    const domainToUpdate = cart.find(domain => domain.domainName === item);
    updateDomainInCart({
      customerName,
      domainId: domainToUpdate.id,
      reseller,
      updatedDomainBody: {
        ...domainToUpdate,
        customerContactSetId: value
      }
    });
  };

  const {
    data: contactSets,
    isLoading: isLoadingContactSets,
    isSuccess: isContactsSuccess
  } = useGetContactsQuery(
    {
      customerName,
      reseller
    },
    { skip: !customerName }
  );

  const {
    data: nameServerGroups,
    isLoading: isLoadingNameServers,
    isSuccess: isNameServersSuccess
  } = useGetNameServerGroupsQuery(
    {
      customerName,
      reseller
    },
    { skip: !customerName }
  );

  const {
    data: labelsData,
    isLoading: isLoadingLabels,
    isSuccess: isLabelsSuccess
  } = useGetLabelsQuery(
    {
      customerName,
      reseller
    },
    { skip: !customerName }
  );

  const {
    data: associationsData,
    isLoading: isLoadingAssociations,
    isSuccess: isAssociationsSuccess
  } = useGetLabelAssociationsQuery(
    {
      customerName,
      reseller
    },
    { skip: !customerName }
  );

  // updates checkedDomains when a refetch is triggered from one of the child components
  useEffect(() => {
    if (checkedDomains.length && apiRef?.current) {
      setCheckedDomains(
        Array.from(apiRef.current.getSelectedRows()).map(row => row[1])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, apiRef]);

  const defaultContactSet = contactSets?.find(contact => contact.defaultSet);
  const defaultNameServerGroup = nameServerGroups?.find(group => group.default);

  const domainsAndContactSetIds = cart.map(cartItem => ({
    contactSetId: cartItem.customerContactSetId ?? defaultContactSet?.id,
    domainName: cartItem.domainName
  }));

  const {
    data: bulkContactSetVerification,
    isFetching: isVerifyContactSetsForDomainsFetching
  } = useVerifyContactSetsForDomainsQuery(
    {
      customerName,
      domainsAndContactSetIds,
      reseller
    },
    { skip: !customerName || !domainsAndContactSetIds }
  );

  const tldsAndNameServerGroupIds = cart
    .map(cartItem => ({
      groupId: cartItem.nameServerGroupId ?? undefined,
      tldName: cartItem.$tldObject.name
    }))
    .filter(tldNameServerObject => tldNameServerObject.groupId);

  const {
    data: bulkNameServerVerification,
    isFetching: isVerifyNameServerGroupsForTldsFetching
  } = useVerifyNameServerGroupsForTldsQuery(
    {
      customerName,
      reseller,
      tldsAndNameServerGroupIds
    },
    { skip: !customerName || !tldsAndNameServerGroupIds.length }
  );

  useEffect(() => {
    cart.forEach(cartItem =>
      dispatch(
        setCartItemValidity(
          getCartItemValidityObject({
            cartItem,
            contactSetIsValid: bulkContactSetVerification?.domains?.some(
              contactSetValidity =>
                contactSetValidity.domainName === cartItem.domainName
            ),
            nameServerGroupIsValid:
              !bulkNameServerVerification?.invalidTlds.some(
                nameServerValidity => nameServerValidity.tld === cartItem.tld
              )
          })
        )
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, bulkContactSetVerification, bulkNameServerVerification]);

  const columnDefaultProps = {
    disableColumnMenu: true,
    filterable: false
  };

  const shouldShowLocalPresencePrice = row =>
    row.$tldObject?.localPresenceOffered && row.$localPresencePrice;

  const getNameServer = domain => {
    if (domain.nameServerGroupId) {
      return getNameServerGroupById(domain.nameServerGroupId);
    } else if (domain.$tldObject?.nameServersRequired) {
      return defaultNameServerGroup;
    } else {
      return null;
    }
  };

  const columns = useMemo(
    () => [
      {
        ...columnDefaultProps,
        field: 'domainName',
        flex: 1,
        headerName: 'Domain',
        minWidth: 200,
        renderCell: ({ row }) => <DomainCell params={row} />,
        sortable: true
      },
      {
        ...columnDefaultProps,
        field: 'contactSet',
        flex: 1,
        headerName: 'Contact Set',
        minWidth: 200,
        renderCell: ({ row }) =>
          row.$tldObject.web3 ? (
            'n/a'
          ) : (
            <Stack alignItems="center" direction="row" sx={{ width: '100%' }}>
              <ContactSetsInput
                applyValue={handleApplyContactSet}
                contactSetsData={contactSets}
                isInForm={false}
                isLoading={!contactSets.length}
                item={row}
                value={getContactSetById(row.customerContactSetId)}
              />
              <ContactSetsDialog
                contactSetId={row.customerContactSetId}
                domains={[row].map(domain => domain.domainName)}
                handleApplyContactSet={handleApplyContactSet}
                renderActions={props => <ContactSetsActions {...props} />}
                renderTrigger={props => <ContactSetsDialogTrigger {...props} />}
              />
            </Stack>
          ),
        sortable: false
      },
      {
        ...columnDefaultProps,
        field: '$localPresenceOffered',
        headerName: 'Buy Local Presence',
        renderCell: ({ row }) => {
          return row.$tldObject.web3
            ? 'n/a'
            : shouldShowLocalPresencePrice(row) && (
                <Stack alignItems="center" direction="row">
                  <Checkbox
                    checked={row.localPresence}
                    color="primary"
                    onClick={() => handleLocalPresenceClick(row)}
                  />
                  <Typography variant="caption">
                    ${formatPrice(row.$localPresencePrice)}
                  </Typography>
                </Stack>
              );
        },
        sortable: true,
        width: 160
      },
      {
        ...columnDefaultProps,
        field: 'nameServerGroup',
        flex: 1,
        headerName: 'Name Server Group',
        minWidth: 200,
        renderCell: ({ row }) =>
          row.$tldObject.web3 ? (
            'n/a'
          ) : (
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-start"
              spacing={1}
              sx={{ width: '100%' }}
            >
              <NameServersInput
                applyValue={handleApplyNameServerGroup}
                defaultValue={getNameServer(row)}
                item={row}
                nameServerGroupsData={nameServerGroups}
              />
              <Tooltip title={getNameServerTooltipContent(row)}>
                <FontAwesomeIcon
                  className={classes.nameServerTooltip}
                  icon={faInfoCircle}
                  size="lg"
                />
              </Tooltip>
            </Stack>
          ),
        sortable: true
      },
      {
        ...columnDefaultProps,
        field: '$dnssecSupported',
        headerAlign: 'center',
        headerName: 'DNSSEC',
        renderCell: ({ row }) =>
          row.$tldObject.web3 ? 'n/a' : <DnssecDialog row={row} />,
        sortable: false,
        width: 120
      },
      {
        ...columnDefaultProps,
        field: 'labels',
        flex: 1,
        headerName: 'Labels',
        minWidth: 200,
        renderCell: ({ row }) => (
          <LabelsTableInput
            applyValue={handleLabelSelection}
            isLoading={false}
            item={row}
            labelsData={labelsData}
            value={getAssociatedLabelsForDomain(
              row.domainName,
              labelsData?.flat,
              associationsData
            )}
          />
        ),
        sortable: false
      },
      {
        ...columnDefaultProps,
        align: 'center',
        field: 'valid',
        headerAlign: 'center',
        headerName: 'Valid',
        renderCell: ({ row }) => (
          <ValidCell
            customerContactSetId={row.customerContactSetId}
            domain={row}
            nameServerGroupId={getNameServer(row)?.id}
          />
        ),
        sortable: false,
        width: 80
      },
      {
        ...columnDefaultProps,
        align: 'center',
        field: 'delete',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <IconButton onClick={() => handleRemoveDomain(row)}>
            <FontAwesomeIcon icon={faTrashCan} size="sm" />
          </IconButton>
        ),
        renderHeader: () => (
          <Button onClick={handleRemoveAllDomains} variant="outlined">
            Remove all
          </Button>
        ),
        sortable: false,
        width: 160
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cart, labelsData, associationsData, contactSets, nameServerGroups]
  );

  // Add special requirements column
  if (haveCartItemsWithSpecialRequirements) {
    columns.splice(5, 0, {
      disableColumnMenu: true,
      field: 'specialRequirements',
      filterable: false,
      headerAlign: 'left',
      headerName: 'Special Requirements',
      renderCell: params => {
        switch (params.row?.specialRequirements?.component) {
          case 'SetDotAeroSpecialRequirements':
            return (
              <SetDotAeroSpecialRequirementsMemoized
                domain={params.row}
                domainName={params.row.domainName}
                id={params.row.id}
              />
            );
          case 'SetIntendedUseRequirements':
            return (
              <SetIntendedUseRequirementsMemoized
                domain={params.row}
                domainName={params.row.domainName}
                id={params.row.id}
              />
            );
          case 'SetDotNoSpecialRequirements':
            return (
              <SetDotNoSpecialRequirementsMemoized
                domain={params.row}
                domainName={params.row.domainName}
                id={params.row.id}
              />
            );
          case 'SetDotDeSpecialRequirements':
            return (
              <SetDotDeSpecialRequirementsMemoized
                domain={params.row}
                domainName={params.row.domainName}
                id={params.row.id}
              />
            );
          case 'SetSslAcknowledgements':
            return (
              <SetSslAcknowledgementsMemoized
                domain={params.row}
                domainName={params.row.domainName}
                id={params.row.id}
              />
            );
          case 'SetCheckoutMessage':
            return (
              <SetCheckoutMessageMemoized
                checkoutMessages={checkoutMessages}
                domain={params.row}
                domainName={params.row.domainName}
                id={params.row.id}
              />
            );
          case 'SetDotNewSpecialRequirements':
            return (
              <SetDotNewSpecialRequirementsMemoized
                checkoutMessages={checkoutMessages}
                domain={params.row}
                domainName={params.row.domainName}
                id={params.row.id}
              />
            );
          case 'SetDotTelSpecialRequirements':
            return (
              <SetDotTelSpecialRequirementsMemoized
                domain={params.row}
                domainName={params.row.domainName}
                id={params.row.id}
              />
            );
          case 'SetSignMarkedData':
            return (
              <SetSmdSpecialRequirementMemoized
                domain={params.row}
                domainName={params.row.domainName}
                id={params.row.id}
              />
            );
          case 'SetDotCoopSpecialRequirements':
            return (
              <SetDotCoopSpecialRequirementsMemoized
                checkoutMessages={checkoutMessages}
                domain={params.row}
                domainName={params.row.domainName}
                id={params.row.id}
              />
            );
          default:
            return null;
        }
      },
      sortable: false,
      width: 200
    });
  }

  const handleSelectionModelChange = (newSelectionModel, { api }) =>
    setCheckedDomains(Array.from(api.getSelectedRows()).map(row => row[1]));

  const handleLocalPresenceClick = domain =>
    updateDomainInCart({
      customerName,
      domainId: domain.id,
      reseller,
      updatedDomainBody: {
        ...domain,
        localPresence: !domain.localPresence
      }
    });

  const handleRemoveDomain = domainToRemove => {
    removeDomainFromCart({
      customerName,
      domainId: domainToRemove.id,
      reseller
    });
  };

  const handleRemoveAllDomains = () => {
    cart.forEach(domain => handleRemoveDomain(domain));
  };

  const handleLabelSelection = ({ item, value }) => {
    const currentAssociations = getAssociationsForDomain(
      item.domainName,
      associationsData
    );

    const associationToAdd = value.find(label => !label.association);
    const associationToRemove = currentAssociations.find(
      association => !value.some(label => label.id === association.label.id)
    );

    if (value.length === 0) {
      deleteLabelAssociations({
        customerName,
        reseller
      });
    } else if (associationToRemove) {
      deleteLabelAssociations({
        associationIds: [associationToRemove.id],
        customerName,
        reseller
      });
    } else if (associationToAdd) {
      addLabelAssociations({
        customerName,
        domainNames: [item.domainName],
        labelId: associationToAdd.id,
        reseller
      });
    }
  };

  const handleNextStepClick = () => {
    const mappedDomains = cart.map(domain => ({
      algorithmType: domain.algorithmType,
      buySsl: domain.buySsl,
      customerCartItemType: domain.customerCartItemType,
      customerContactSetId: domain.customerContactSetId ?? defaultContactSet.id,
      digest: domain.digest,
      digestType: domain.digestType,
      domainName: domain.domainName,
      domainSpecialRequirement: domain.domainSpecialRequirement,
      id: domain.id,
      idnLanguage: domain.idnLanguage,
      keyTag: domain.keyTag,
      localPresence: domain.localPresence,
      nameServerGroupId: getNameServer(domain)?.id,
      registrationPrice: domain.registrationPrice,
      registrationPriceMethod: domain.registrationPriceMethod,
      selectedLabels: {},
      smdFileId: domain.smdFileId,
      tldPhaseId: domain.tldPhaseId,
      totalPrice: domain.totalPrice,
      type: 'registration',
      years: domain.years
    }));
    updateCartItems({
      cart: mappedDomains,
      cartItems: cart,
      customerName,
      reseller
    });
  };

  useEffect(() => {
    // move to the next page when the domain register order was successfully created
    if (!isUpdatingCart && isUpdateCartSuccess) {
      navigateToNextRegistrationStep(navigate, stepName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdatingCart, isUpdateCartSuccess]);

  useEffect(() => {
    if (isUpdateCartError) {
      enqueueSnackbar('There was an error updating the cart');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCartError]);

  const isLoadingTableData =
    isLoadingContactSets ||
    isLoadingAssociations ||
    isLoadingNameServers ||
    isLoadingLabels;
  const canLoadTable =
    isAssociationsSuccess &&
    isContactsSuccess &&
    isLabelsSuccess &&
    isNameServersSuccess;

  const getNameServerGroupHosts = nameServerGroupId =>
    nameServerGroups
      ?.find(nameServer => nameServer.id === nameServerGroupId)
      ?.hosts.join('\r\n');

  const getNameServerGroupById = nameServerGroupId =>
    nameServerGroups?.find(nameServer => nameServer.id === nameServerGroupId);
  const getContactSetById = (contactSetId = defaultContactSet) =>
    contactSets?.find(contactSet => contactSet.id === contactSetId);

  const getNameServerTooltipContent = domain =>
    domain.nameServerGroupId
      ? getNameServerGroupHosts(domain.nameServerGroupId)
      : 'No name server group applied to this domain';

  const specialRequirementsTlds = specialRequirements
    .map(specialRequirement => specialRequirement.tld)
    .join(', ');

  const showSpecialRequirementsAlert =
    !cartIsValid &&
    canLoadTable &&
    !isVerifyContactSetsForDomainsFetching &&
    !isVerifyNameServerGroupsForTldsFetching;

  return (
    <Paper sx={{ height: '90%' }} variant="outlined">
      <Box sx={{ height: 'calc(100% - 60px)', padding: 2, pb: 5 }}>
        <Stack direction="column" justifyContent="space-between" mb={2}>
          <Grid container>
            <Grid
              item
              style={{ minWidth: '195px' }}
              xs={showSpecialRequirementsAlert ? 2 : 12}
            >
              <Typography variant="h5">Domain Details</Typography>
              <Typography variant="body2">{`${cart.length} Domains`}</Typography>
            </Grid>

            {showSpecialRequirementsAlert && (
              <Grid item xs={10}>
                <Alert severity="warning">
                  Complete the special requirements for any domains with the
                  following TLDs: {specialRequirementsTlds}, or any TLDs in the
                  Sunrise or Pre-sunrise phase.
                </Alert>
              </Grid>
            )}
          </Grid>
        </Stack>
        <Box>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-start"
          >
            <BulkUpdateFormButton
              buttonTitle="CONTACTS"
              checkedDomains={checkedDomains}
              isDisabled={!contactSets?.length}
              objectUpdateKey="customerContactSetId"
              options={contactSets}
            />
            <BulkUpdateFormButton
              buttonTitle="NAME SERVERS"
              checkedDomains={checkedDomains}
              isDisabled={!nameServerGroups?.length}
              objectUpdateKey="nameServerGroupId"
              options={nameServerGroups}
            />
            <BulkLabelSelectPopover
              buttonTitle="LABELS"
              checkedDomains={checkedDomains}
            />
          </Stack>
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: 'calc(100% - 116px)',
            marginTop: 2
          }}
        >
          {canLoadTable && (
            <DataGridPro
              apiRef={apiRef}
              checkboxSelection
              // @ts-ignore
              columns={columns}
              disableRowSelectionOnClick={true}
              // @ts-ignore
              disableVirtualization={import.meta.env.MODE === 'test'}
              getRowId={row => row.domainName ?? row.domain}
              hideFooter
              loading={isLoadingTableData}
              onRowSelectionModelChange={handleSelectionModelChange}
              rowCount={cart?.length}
              rows={cart ?? []}
              sx={{
                backgroundColor: 'white',
                height: '100%'
              }}
            />
          )}
        </Box>
      </Box>
      <RegisterDomainsFooter handleNextStepClick={handleNextStepClick} />
    </Paper>
  );
};
