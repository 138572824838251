import type {
  GridRenderCellParams,
  GridTreeNodeWithRender
} from '@mui/x-data-grid-pro';

import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import React from 'react';

interface SignOffContextPermissionSignersCellProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>;
  setIsOpen: (contextId: number) => void;
}

export const SignOffContextPermissionSignersCell = ({
  params,
  setIsOpen
}: SignOffContextPermissionSignersCellProps) => {
  return (
    <IconButton
      data-testid="sign-off-context-permission-edit"
      onClick={() => setIsOpen(params.row.id)}
      size="small"
    >
      <FontAwesomeIcon icon={faUser} />
    </IconButton>
  );
};
