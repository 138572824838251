import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { config } from '../../common/config/config';
import { ZoneRecordSetsToolbar } from './ZoneRecordSetsToolbar';
import { setSelectedRecords } from './selected-record-slice';
import { selectSelectedZones } from './selected-zone-slice';

export const ZoneRecordSetsGrid = () => {
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const selectedZone = useSelector(selectSelectedZones)[0];

  const data = selectedZone.recordSets;

  // update selected records when data is updated
  useEffect(() => {
    if (data?.length && apiRef?.current) {
      dispatch(
        setSelectedRecords(
          Array.from(apiRef.current.getSelectedRows()).map(row => row[1])
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const columns = useMemo(
    () => [
      {
        field: 'dnsName',
        flex: 2,
        headerName: 'DNS Name'
      },
      {
        field: 'recordType',
        flex: 1,
        headerName: 'Record Type'
      },
      {
        field: 'ttl',
        flex: 1,
        headerName: 'TTL (seconds)',
        type: 'number'
      },
      {
        field: '$recordDataDetails',
        flex: 3,
        headerName: 'Details',
        sortable: false
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handleSelectionModelChange = (newSelectionModel, { api }) => {
    dispatch(
      setSelectedRecords(Array.from(api.getSelectedRows()).map(row => row[1]))
    );
  };

  return (
    <DataGridPro
      apiRef={apiRef}
      checkboxSelection
      columns={columns}
      disableSelectionOnClick
      disableVirtualization={import.meta.env.MODE === 'test'}
      getRowId={row => row.id}
      onRowSelectionModelChange={handleSelectionModelChange}
      pagination
      rowCount={data.length}
      rows={data}
      rowsPerPageOptions={config.DEFAULT_TABLE_ROWS_PER_PAGE_OPTIONS}
      slots={{ toolbar: ZoneRecordSetsToolbar }}
      sx={{ backgroundColor: 'white' }}
    />
  );
};
