import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useVerifyContactSetsForDomainsQuery } from '../../../../common/store/contacts-api-slice';
import { selectCustomerName } from '../../../../common/store/customers-slice';
import { useVerifyNameServerGroupsForTldsQuery } from '../../../../common/store/name-servers-api-slice';
import { selectReseller } from '../../../../common/store/reseller';
import { selectCart } from '../../register-domains-slice';
import { ValidOrNotMemoized } from './ValidOrNotIcon';
import { ValidityMessage } from './ValidityMessage';

// eslint-disable-next-line complexity
export const ValidCell = ({ domain }) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const [validityMessage, setValidityMessage] = useState(<></>);
  const cart = useSelector(selectCart);

  const domainsAndContactSetIds = cart.map(cartItem => ({
    contactSetId: cartItem.customerContactSetId,
    domainName: cartItem.domainName
  }));

  const { contactValidationError, isValidatingContactSet } =
    useVerifyContactSetsForDomainsQuery(
      {
        customerName,
        domainsAndContactSetIds,
        reseller
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          contactValidationError: data?.domains.find(
            validatedDomain =>
              validatedDomain.domainName === domain.domainName &&
              !validatedDomain.valid
          ),
          isValidatingContactSet: isFetching
        }),
        skip: !customerName || !domainsAndContactSetIds.length
      }
    );

  const tldsAndNameServerGroupIds = cart
    .map(cartItem => ({
      groupId: cartItem.nameServerGroupId,
      tldName: cartItem.$tldObject?.name
    }))
    .filter(tldNameServerObject => tldNameServerObject.groupId);

  const { isValidatingNameServers, nameServerValidationError } =
    useVerifyNameServerGroupsForTldsQuery(
      {
        customerName,
        reseller,
        tldsAndNameServerGroupIds
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          isValidatingNameServers: isFetching,
          nameServerValidationError: data?.tlds.find(
            tld => tld.tld === domain.$tldObject?.name && !tld.valid
          )
        }),
        skip: !customerName || !tldsAndNameServerGroupIds?.length
      }
    );

  const dnssecIsValid =
    !domain.$tldObject.dnssecRequired ||
    (domain.$tldObject.dnssecRequired && !!domain.digest);
  const specialRequirementsAreValid =
    !domain.specialRequirements || domain.specialRequirements?.isValid;

  const contactSetIsValid = domain.localPresence || !contactValidationError;

  const isValid =
    !nameServerValidationError &&
    contactSetIsValid &&
    specialRequirementsAreValid &&
    dnssecIsValid;

  useEffect(() => {
    if (!isValid) {
      setValidityMessage(
        <ValidityMessage
          contactValidationError={contactValidationError}
          domain={domain}
          nameServerValidationError={nameServerValidationError}
        />
      );
    } else if (isValid) {
      setValidityMessage(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactValidationError, domain, isValid, nameServerValidationError]);

  return (
    <>
      {(isValidatingNameServers || isValidatingContactSet) && (
        <CircularProgress size={15} />
      )}
      <ValidOrNotMemoized
        isValid={
          isValidatingContactSet || isValidatingNameServers
            ? undefined
            : isValid
        }
        message={validityMessage}
      />
    </>
  );
};

export const ValidCellMemoized = React.memo(ValidCell);
