import {
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { getAssociatedLabelsForDomain } from '../../../../common/store/api-slice-utils';
import { useGetContactsQuery } from '../../../../common/store/contacts-api-slice';
import { selectCustomerName } from '../../../../common/store/customers-slice';
import {
  useGetLabelAssociationsQuery,
  useGetLabelsQuery
} from '../../../../common/store/labels-api-slice';
import { useGetNameServerGroupsQuery } from '../../../../common/store/name-servers-api-slice';
import { useCreateOrderMutation } from '../../../../common/store/orders-api-slice';
import { selectReseller } from '../../../../common/store/reseller';
import { generateId } from '../../../../common/utils/generate-id';
import { formatPrice } from '../../../../common/utils/number-price-text';
import { selectClientId } from '../../../auth/auth-slice';
import { RegisterDomainsFooter } from '../../RegisterDomainsFooter';
import {
  selectCart,
  selectCheckoutTableData,
  selectDomainServiceAcceptedDate,
  setClientLineItems
} from '../../register-domains-slice';
import { navigateToNextRegistrationStep } from '../../registration-navigation';
import { DomainCell } from '../DomainCell';
import { LabelsCell } from '../LabelsCell';
import { delayedRegistrationValues } from '../domain-details/special-requirements';
import { RegisterDomainsCheckoutToolbar } from './RegisterDomainsCheckoutToolbar';

export const RegisterDomainsCheckout = () => {
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stepName = useParams().stepName;
  const clientId = useSelector(selectClientId);
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const cart = useSelector(selectCart);
  const checkoutTableData = useSelector(selectCheckoutTableData);
  const domainServiceAcceptedDate = useSelector(
    selectDomainServiceAcceptedDate
  );
  const { enqueueSnackbar } = useSnackbar();

  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [
    createOrder,
    {
      isError: isOrderCreationError,
      isLoading: isOrderCreationLoading,
      isSuccess: isOrderCreationSuccess
    }
  ] = useCreateOrderMutation();
  const { data: contactSets } = useGetContactsQuery(
    {
      customerName,
      reseller
    },
    { skip: !customerName }
  );

  const { data: nameServerGroups } = useGetNameServerGroupsQuery(
    {
      customerName,
      reseller
    },
    { skip: !customerName }
  );

  const { data: associationsData } = useGetLabelAssociationsQuery(
    {
      customerName,
      reseller
    },
    { skip: !customerName }
  );
  const { data: labelsData } = useGetLabelsQuery(
    {
      customerName,
      reseller
    },
    { skip: !customerName }
  );
  const getNameServerGroupById = nameServerGroupId =>
    nameServerGroups?.find(nameServer => nameServer.id === nameServerGroupId);
  const getContactSetById = contactSetId =>
    contactSets?.find(contactSet => contactSet.id === contactSetId);

  const columnDefaultProps = {
    disableColumnMenu: true,
    filterable: false
  };

  const columns = [
    {
      ...columnDefaultProps,
      field: 'domainName',
      flex: 1,
      headerName: 'Domain Name',
      renderCell: ({ row }) => <DomainCell params={row} />,
      sortable: true
    },
    {
      ...columnDefaultProps,
      field: 'contactSet',
      flex: 1,
      headerName: 'Contact Set',
      sortable: true,
      valueGetter: ({ row }) =>
        contactSets && getContactSetById(row.customerContactSetId)?.name
    },
    {
      ...columnDefaultProps,
      field: 'nameServerGroup',
      flex: 1,
      headerName: 'Name Server Group',
      sortable: true,
      valueGetter: ({ row }) =>
        nameServerGroups && getNameServerGroupById(row.nameServerGroupId)?.name
    },
    {
      ...columnDefaultProps,
      field: '$service',
      flex: 1,
      headerName: 'Product Type',
      sortable: true
    },
    {
      ...columnDefaultProps,
      field: 'labels',
      flex: 1,
      headerName: 'Labels',
      renderCell: ({ row }) => (
        <LabelsCell
          params={{
            associationsData: associationsData,
            labelsData: labelsData,
            row: row
          }}
        />
      ),
      sortable: true,
      valueFormatter: params =>
        getAssociatedLabelsForDomain(
          params.id,
          labelsData?.flat,
          associationsData
        )
          .map(label => label.groupAndName)
          .join(', ')
    },
    {
      ...columnDefaultProps,
      field: 'years',
      headerName: 'Years',
      sortable: true
    },
    {
      ...columnDefaultProps,
      field: 'registrationPrice',
      headerName: 'Price',
      renderCell: ({ row }) => `$${formatPrice(row.registrationPrice)}`,
      sortable: true,
      valueFormatter: ({ value }) => `${formatPrice(value)}`
    }
  ];

  const getLineItemType = domain => {
    const isWeb3Domain = domain.$tldObject.web3;
    if (isWeb3Domain) {
      return 'WEB3_REGISTRATION';
    } else if (delayedRegistrationValues.includes(domain.tldPhaseName)) {
      return 'DOMAIN_PREREGISTRATION';
    } else {
      return 'DOMAIN_REGISTRATION';
    }
  };

  const handleNextStepClick = () => {
    const lineItems = cart.map((domain, lineItemNum) => ({
      clientLineItemId: generateId(),
      customerCartItemId: domain.id,
      customerContactSetId: domain.customerContactSetId,
      domain: domain.domainName,
      domainDnssec: domain.digest
        ? [
            {
              algorithmType: domain.algorithmType,
              digest: domain.digest,
              digestType: domain.digestType,
              keyTag: domain.keyTag
            }
          ]
        : [],
      domainSpecialRequirement: domain.domainSpecialRequirement,
      idnLanguage: domain.idnLanguage,
      lineItemNum,
      lineItemType: getLineItemType(domain),
      nameServers:
        nameServerGroups.find(group => group.id === domain.nameServerGroupId)
          ?.hosts ?? [],
      price: domain.totalPrice,
      priceMethod: domain.registrationPriceMethod,
      smdFileId: domain.smdFileId,
      tldPhaseId: domain.tldPhaseId,
      token_password: null,
      useLocalPresence: domain.localPresence,
      years: domain.years
    }));
    const clientLineItemIds = lineItems.map(
      lineItem => lineItem.clientLineItemId
    );
    const orderOptions = {
      currency: 'USD',
      domainServiceAgreementAccepted: true,
      domainServiceAgreementAcceptedDate: domainServiceAcceptedDate,
      paymentType: 'FUTURE'
    };
    dispatch(setClientLineItems({ ids: clientLineItemIds, lineItems }));
    setIsBackdropOpen(true);
    createOrder({
      clientId,
      customerName,
      lineItems,
      orderOptions,
      reseller
    });
  };

  useEffect(() => {
    // move to the next page when the domain register order was successfully created
    if (!isOrderCreationLoading && isOrderCreationSuccess) {
      setIsBackdropOpen(false);
      navigateToNextRegistrationStep(navigate, stepName);
    } else if (isOrderCreationError) {
      setIsBackdropOpen(false);
      enqueueSnackbar('There was an error submitting your order.', {
        variant: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOrderCreationLoading, isOrderCreationSuccess, isOrderCreationError]);

  const RenderBackdrop = () => {
    return (
      <Backdrop
        open={isBackdropOpen}
        sx={{
          bottom: -50,
          color: '#fff',
          left: -50,
          position: 'absolute',
          right: -50,
          top: -30,
          zIndex: theme => theme.zIndex.drawer + 1
        }}
      >
        <CircularProgress />
      </Backdrop>
    );
  };

  return !cart.length ? null : (
    <>
      <Paper sx={{ height: '90%' }} variant="outlined">
        <Box sx={{ height: 'calc(100% - 60px)', padding: 2, pb: 5 }}>
          <Stack direction="column" justifyContent="space-between" mb={2}>
            <Typography variant="h5">Order Summary</Typography>
            <Typography variant="body2">{`${checkoutTableData.length} Items`}</Typography>
          </Stack>
          <Box
            sx={{ display: 'flex', height: 'calc(100% - 65px)', marginTop: 2 }}
          >
            <DataGridPro
              apiRef={apiRef}
              columns={columns}
              disableRowSelectionOnClick={true}
              disableVirtualization={import.meta.env.MODE === 'test'}
              getRowId={row =>
                row.$service === 'Local Presence'
                  ? `${row.domainName}${row.$service}`
                  : row.domainName
              }
              hideFooter
              rowCount={checkoutTableData?.length}
              rows={checkoutTableData}
              slotProps={{
                toolbar: {
                  apiRef
                }
              }}
              slots={{
                toolbar: RegisterDomainsCheckoutToolbar
              }}
              sx={{
                backgroundColor: 'white'
              }}
            />
          </Box>
        </Box>
        <RegisterDomainsFooter handleNextStepClick={handleNextStepClick} />
      </Paper>
      <RenderBackdrop />
    </>
  );
};
