import { TextField } from '@mui/material';
import React from 'react';

export const DotItForm = ({ specialRequirements }) => {
  const entityTypes = Object.freeze({
    ITALIAN_COMPANY: 'Italian Company',
    ITALIAN_NONPROFIT_ORG: 'Italian Non-Profit Organization',
    ITALIAN_PUBLIC_ORG: 'Italian Public Organization',
    ITALIAN_WORKER: 'Italian Freelance Worker',
    NATURAL_PERSON: 'Natural Person',
    NON_ITALIAN_ORG: 'Non-Italian Organization',
    NON_ITALIAN_WORKER: 'Non-Italian Freelance Worker',
    OTHER: 'Other'
  });

  return (
    <>
      <TextField
        disabled
        label="Contact Category Type"
        value={entityTypes[specialRequirements.DOTIT.entityType]}
      />
      <TextField
        disabled
        label="VAT Number / Tax Code"
        value={specialRequirements.DOTIT.regCode}
      />
    </>
  );
};
