import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import React, { useState } from 'react';
import { IdnAutocomplete } from './IdnAutocomplete';
import { useDispatch } from 'react-redux';
import { setIdnLangsMap } from '../../register-domains-slice';

interface IdnIdnCheckDialogProps {
  isOpen: boolean;
  domains: string[];
  handleClose: () => void;
}

export const IdnCheckDialog: React.FC<IdnIdnCheckDialogProps> = ({
  isOpen,
  domains,
  handleClose
}) => {
  const [idnMap, setIdnMap] = useState<Record<string, string>>({});
  const dispatch = useDispatch();
  const handleSetMap = (domain: string, idnLangTag: string) => {
    setIdnMap(prev => ({ ...prev, [domain]: idnLangTag }));
  };
  const handleSave = () => {
    dispatch(setIdnLangsMap(idnMap));
    setIdnMap({});
  };
  const handleCancel = () => {
    setIdnMap({});
    handleClose();
  };
  return (
    <>
      <Dialog open={isOpen}>
        <DialogTitle>
          The following IDNs require a language tag selection prior to
          performing the search:
        </DialogTitle>
        <DialogContent>
          {domains.map(domain => (
            <IdnAutocomplete
              key={domain}
              domain={domain}
              handleSetMap={handleSetMap}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            variant="contained"
            disabled={Object.keys(idnMap).length !== domains.length}
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
