import { Autocomplete, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectCustomerName } from '../../../../common/store/customers-slice';
import { selectReseller } from '../../../../common/store/reseller';
import { useGetCustomerCategoriesQuery } from '../../register-domains-api-slice';
import {
  selectSelectedSearchGroups,
  setSelectedSearchGroups
} from '../../register-domains-slice';

export const RegisterDomainSearchGroupsDropdown = () => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const selectedSearchGroups = useSelector(selectSelectedSearchGroups);

  const dispatch = useDispatch();
  const handleFilterChange = (event, value) => {
    dispatch(setSelectedSearchGroups(value));
  };

  const { data } = useGetCustomerCategoriesQuery(
    {
      customerName,
      reseller
    },
    { skip: !customerName }
  );

  useEffect(() => {
    if (data?.initialCategories && !selectedSearchGroups) {
      dispatch(setSelectedSearchGroups(data.initialCategories));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedSearchGroups]);

  return (
    data && (
      <Autocomplete
        disableCloseOnSelect
        groupBy={option => option.mappedGrouping}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        limitTags={1}
        multiple
        onChange={handleFilterChange}
        options={data?.mappedCategories ?? []}
        renderInput={params => (
          <TextField
            {...params}
            label="Search Group"
            sx={{
              backgroundColor: 'white',
              overflow: 'visible',
              width: 320,
              zIndex: 1
            }}
            variant="outlined"
          />
        )}
        renderOption={(props, option) => (
          <Typography {...props} variant="body2">
            {option.value}
          </Typography>
        )}
        size="small"
        sx={{
          backgroundColor: 'white',
          height: '28px',
          overflow: 'visible',
          width: 320
        }}
        value={selectedSearchGroups ?? []}
      />
    )
  );
};
