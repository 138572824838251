import { createSlice } from '@reduxjs/toolkit';

const initialSelectedRecordsState = {
  selectedRecords: [],
  selectedRecordsIds: []
};
const selectedRecordsSlice = createSlice({
  initialState: initialSelectedRecordsState,
  name: 'selectedRecords',
  reducers: {
    setSelectedRecords: (state, { payload }) => {
      state.selectedRecords = payload;
      state.selectedRecordsIds = payload.map(record => record?.id);
    }
  }
});

export const selectedRecordsReducer = selectedRecordsSlice.reducer;

export const { setSelectedRecords } = selectedRecordsSlice.actions;

export const selectSelectedRecords = state =>
  state.selectedRecords?.selectedRecords;

export const selectSelectedRecordsCount = state =>
  state.selectedRecords?.selectedRecords.length;
