import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: {}, shouldReset: null };

const pendingUserSlice = createSlice({
  initialState,
  name: 'pendingUser',
  reducers: {
    resetPending: state => {
      state.data = {};
      state.shouldReset = Date.now();
    },
    updatePending: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    }
  }
});

export const { resetPending, updatePending } = pendingUserSlice.actions;

export const pendingUserReducer = pendingUserSlice.reducer;
export const selectPending = state => state.pendingUser.data;
export const selectShouldReset = state => state.pendingUser.shouldReset;
