import { TextField } from '@mui/material';
import React from 'react';

export const RegistryCostInput = ({
  debouncedValidation,
  formData,
  formDataDispatch,
  handleChange
}) => (
  <TextField
    error={formData.registryCost?.hasError}
    fullWidth
    helperText={formData.registryCost?.errorMessage}
    inputProps={{ maxLength: 255 }}
    label="Cost per year"
    margin="none"
    onChange={event =>
      handleChange(event, formDataDispatch, debouncedValidation)
    }
    value={formData.registryCost?.value}
  />
);
