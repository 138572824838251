const csvHeaderMappingArray = [
  { key: 'domain', label: 'Domain Name' },
  { key: 'tld', label: 'TLD' },
  { key: '', label: 'Premium' },
  { key: 'years', label: 'Years' },
  { key: 'price', label: 'Price' },
  {
    isSuperAdminOrFinanceOnly: true,
    key: 'registryCost',
    label: 'Registry Cost'
  },
  {
    isSuperAdminOrFinanceOnly: true,
    key: 'registryCostCurrency',
    label: 'Registry Cost Currency'
  },
  { key: '$availableFormatted', label: 'Availability' },
  { key: 'country', label: 'Country' },
  { key: 'region', label: 'Region' },
  { key: 'type', label: 'Type' },
  { key: 'localPresenceNexusRequired', label: 'Local Presence/Nexus Required' },
  { key: 'restricted', label: 'Restricted' },
  {
    key: 'restrictionRequirementNexus',
    label: 'Restriction Requirement/Nexus'
  },
  {
    key: 'localPresenceNexusProvidedByAppdetex',
    label: 'Local Presence/Nexus Provided by Appdetex'
  },
  { key: 'dnsConfigured', label: 'DNS Configured' },
  {
    key: 'transferRestrictionRequirements',
    label: 'Transfer Restriction Requirements'
  }
];

/**
 * Function to retrieve csv header array
 * @param {Boolean} isSuperAdminOrFinance
 * @returns {Array} array of csv headers
 */
export const getCsvHeaderArray = isSuperAdminOrFinance =>
  isSuperAdminOrFinance
    ? csvHeaderMappingArray
    : csvHeaderMappingArray.filter(header => !header.isSuperAdminOrFinanceOnly);
