import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { getLabelsForMultipleDomainsWithAssociations } from '../../store/api-slice-utils';
import { selectCustomerName } from '../../store/customers-slice';
import {
  useAddLabelAssociationsMutation,
  useDeleteLabelAssociationsMutation,
  useGetLabelAssociationsQuery,
  useGetLabelsQuery
} from '../../store/labels-api-slice';
import { selectReseller } from '../../store/reseller';
import { LabelFormGroup } from './LabelFormGroup';

export const LabelsSelection = ({ selectedDomains = [] }) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);

  const [addLabelAssociations, { isLoading: isAddLabelLoading }] =
    useAddLabelAssociationsMutation();
  const [deleteLabelAssociations, { isLoading: isDeleteLabelLoading }] =
    useDeleteLabelAssociationsMutation();

  const {
    data: associationsData,
    fulfilledTimeStamp,
    isSuccess: isGetLabelAssociationsSuccess
  } = useGetLabelAssociationsQuery(
    {
      customerName,
      reseller
    },
    { skip: !customerName }
  );

  const { labelsWithCheckedState } = useGetLabelsQuery(
    {
      customerName,
      fulfilledTimeStamp,
      reseller
    },
    {
      selectFromResult: ({ data }) => ({
        labelsWithCheckedState: getLabelsForMultipleDomainsWithAssociations(
          selectedDomains.map(domain => domain.domainName),
          data?.flat,
          associationsData
        )
      }),
      skip: [customerName, isGetLabelAssociationsSuccess].some(
        isTruthy => !isTruthy
      )
    }
  );

  const handleLabelAssociationCheckboxChange = ({
    associations,
    isChecked,
    labelId
  }) => {
    if (isChecked) {
      addLabelAssociations({
        customerName,
        domainNames: selectedDomains
          .map(domain => domain.domainName)
          .filter(
            domainName =>
              !associations.some(
                association => association.domainName === domainName
              )
          ),
        labelId,
        reseller
      });
    } else {
      deleteLabelAssociations({
        associationIds: associations.map(association => association.id),
        customerName,
        reseller
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: '#fff',
          bottom: 0,
          display: isAddLabelLoading || isDeleteLabelLoading ? 'flex' : 'none',
          justifyContent: 'center',
          left: 0,
          opacity: '0.8',
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: theme => theme.zIndex.drawer + 1
        }}
      >
        <CircularProgress />
      </Box>
      <LabelFormGroup
        handleLabelAssociationCheckboxChange={
          handleLabelAssociationCheckboxChange
        }
        labelsWithCheckedState={labelsWithCheckedState}
      />
    </>
  );
};
