import { createSlice } from '@reduxjs/toolkit';

import { getStoredCustomer } from '../storage/customer-storage';

const storedCustomer = getStoredCustomer();

const initialState = {
  active: storedCustomer,
  isDialogOpen: false,
  pending: null
};

const customersSlice = createSlice({
  initialState,
  name: 'customers',
  reducers: {
    setActiveCustomer: (state, action) => {
      if (!action?.payload?.label) {
        window.console.error(
          "Missing label property on active customer. Req'd for <Autocomplete />."
        );
      }
      state.active = state.pending = action.payload;
      return state;
    },
    setIsDialogOpen: (state, action) => {
      state.isDialogOpen = action.payload;
      return state;
    },
    setPendingActiveCustomer: (state, action) => {
      // Set to active if payload is null
      state.pending = action.payload ?? state.active;
      return state;
    }
  }
});

export const { setActiveCustomer, setIsDialogOpen, setPendingActiveCustomer } =
  customersSlice.actions;

export const customersReducer = customersSlice.reducer;
export const selectCustomerName = state => state.customers?.active?.accountName;
export const selectActiveCustomer = state => state.customers?.active;
export const selectPendingCustomer = state => state.customers?.pending;
export const selectIsDialogOpen = state => state.customers?.isDialogOpen;
export const selectOrganizationMainContactName = state =>
  state.customers?.active?.organizationMainContactName;
