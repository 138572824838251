import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Stack,
  TextField
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { selectCustomerName } from '../../../../common/store/customers-slice';
import { selectReseller } from '../../../../common/store/reseller';
import { dnssecOptions } from '../../../../common/utils/dnssec-options';
import { useUpdateDomainInCartMutation } from '../../register-domains-api-slice';
import { formSchema } from './schema';

export const DnssecDialog = ({ row }) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const [isDnssecFormOpen, setIsDnssecFormOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [updateDomainInCart, { isSuccess: isUpdatingMutationSuccess }] =
    useUpdateDomainInCartMutation();
  const handleOpenDnssecDialog = () => setIsDnssecFormOpen(true);
  const handleCloseDnssec = () => setIsDnssecFormOpen(false);

  useEffect(() => {
    if (isUpdatingMutationSuccess) {
      enqueueSnackbar('The DNSSEC config was successfully updated.', {
        variant: 'success'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdatingMutationSuccess]);

  useEffect(() => {
    if (row) {
      // @ts-ignore
      formDataDispatch({ type: 'INIT', value: row });
    }
  }, [row]);

  const handleKeyTagValidation = ({ path }) =>
    // @ts-ignore
    formDataDispatch({
      errorMessage: 'Key tag must be number',
      path,
      type: 'SET_ERROR'
    });

  const handleHexStringValidation = ({ path }) =>
    // @ts-ignore
    formDataDispatch({
      errorMessage: 'Digest must be a hexadecimal value',
      forceTouch: false,
      path,
      type: 'SET_ERROR'
    });

  const handleRequiredValidation = ({ path }) =>
    // @ts-ignore
    formDataDispatch({
      errorMessage: 'This field is required',
      path,
      type: 'SET_ERROR'
    });

  const emptyFormState = () =>
    formSchema(handleRequiredValidation).getDefault();

  const [formData, formDataDispatch] = useReducer((state, action) => {
    if (action.path) {
      action.path = action.path.split('.')[0];
    }
    switch (action.type) {
      case 'INIT':
        state = emptyFormState();
        state.keyTag.value = action.value.keyTag ?? '0';
        state.algorithmType.value = action.value.algorithmType ?? '';
        state.digestType.value = action.value.digestType;
        state.digest.value = action.value.digest;
        break;
      case 'UPDATE':
        state[action.path].value = action.value;
        state[action.path].touched = true;
        state[action.path].errorMessage = '';
        state[action.path].hasError = false;
        break;
      case 'SET_ERROR':
        state[action.path].errorMessage = action.errorMessage;
        state[action.path].hasError = true;
        break;

      default:
        break;
    }
    return { ...state };
  }, emptyFormState());

  const handleSubmit = event => {
    event.preventDefault();
    setValidationErrors(false);
    // @ts-ignore
    formSchema({
      handleHexStringValidation,
      handleKeyTagValidation,
      // @ts-ignore
      handleRequiredValidation
    })
      .validate(formData)
      .then(() => {
        const dataFromForm = Object.keys(formData).reduce(
          (reducingData, formDataKey) => {
            reducingData[formDataKey] = formData[formDataKey].value;
            return reducingData;
          },
          {}
        );
        const updatedDomainBody = { ...row, ...dataFromForm };
        updateDomainInCart({
          customerName,
          domainId: row.id,
          reseller,
          updatedDomainBody
        });
        handleCloseDnssec();
      })
      .catch(() => setValidationErrors(true));
  };
  const handleCancel = () => {
    // @ts-ignore
    formDataDispatch({ type: 'INIT', value: row });
    setValidationErrors(false);
    handleCloseDnssec();
  };
  const debouncedValidation = useDebouncedCallback(
    formSchema =>
      formSchema(handleRequiredValidation)
        .validate(formData)
        .catch(() => {}),
    1500
  );

  const handleChange = (event, path) => {
    setValidationErrors(false);
    // @ts-ignore
    formDataDispatch({
      path,
      type: 'UPDATE',
      value: event.target.value
    });
    debouncedValidation(formSchema);
  };
  return (
    <>
      <Button
        disabled={!row.$dnssecSupported}
        onClick={handleOpenDnssecDialog}
        variant="outlined"
      >
        Configure
      </Button>
      <Dialog onClose={handleCloseDnssec} open={isDnssecFormOpen}>
        <DialogTitle>Configure DNSSEC</DialogTitle>
        <DialogContent sx={{ width: '280px' }}>
          <DialogContentText>Select DNSSEC values</DialogContentText>
          <form noValidate={true} onSubmit={handleSubmit}>
            <Stack marginTop={3} spacing={3}>
              {validationErrors && (
                <Alert severity="error" sx={{ maxWidth: '280px' }}>
                  Fix form errors before submitting.
                </Alert>
              )}
              <TextField
                error={formData?.keyTag?.hasError}
                helperText={formData?.keyTag?.errorMessage}
                label="Key Tag"
                onChange={event => handleChange(event, 'keyTag')}
                value={formData?.keyTag?.value ?? ''}
              />
              <TextField
                error={formData?.algorithmType?.hasError}
                helperText={formData?.algorithmType?.errorMessage}
                label="Algorithm Type"
                onChange={event => handleChange(event, 'algorithmType')}
                select
                value={formData?.algorithmType?.value ?? ''}
              >
                {dnssecOptions.algorithmType.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                error={formData?.digestType?.hasError}
                helperText={formData?.digestType?.errorMessage}
                label="Digest Type"
                onChange={event => handleChange(event, 'digestType')}
                select
                value={formData?.digestType?.value ?? ''}
              >
                {dnssecOptions.digestType.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                error={formData?.digest?.hasError}
                helperText={formData?.digest?.errorMessage}
                label="Digest"
                onChange={event => handleChange(event, 'digest')}
                value={formData?.digest?.value ?? ''}
              />
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
