import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../common/store/store';
import type { SignOffContextState } from './sign-off-context';

export const initialState: SignOffContextState = {
  selectedSignOffContext: null
};

const signOffContextSlice = createSlice({
  initialState,
  name: 'signOffContext',
  reducers: {
    setSelectedSignOffContext: (state, { payload }) => {
      state.selectedSignOffContext = payload;
    }
  }
});

export const signOffContextReducer = signOffContextSlice.reducer;

export const { setSelectedSignOffContext } = signOffContextSlice.actions;

export const selectSelectedSignOffContext = (state: RootState) =>
  state.signOffContext.selectedSignOffContext;
