import React from 'react';
import { useSelector } from 'react-redux';

import { selectLogoKey } from '../../config/config';
import { getLogo, logoVariants } from './logos';

export const Logo = ({ variant = logoVariants.HORIZONTAL, ...rest }) => {
  const logoVariant = useSelector(selectLogoKey);
  const logo = getLogo(logoVariant)[variant];

  return <img src={logo} {...rest} />;
};
