import { Stack, Typography } from '@mui/material';
import { noop } from 'lodash-es';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectClientId } from '../../features/auth/auth-slice';
import { CopyToClipboard } from '../components/copy-to-clipboard/CopyToClipboard';
import { SnackbarCloseAction } from '../components/snackbar-close-action/SnackbarCloseAction';
import { apiSlice } from '../store/api-slice';
import {
  POLLING_INTERVAL,
  POLLING_MAX_ITEMS_DISPLAYED,
  POLLING_MAX_REQUESTS,
  usePollJobResponseQuery
} from '../store/job-response-api-slice';

/**
 * depending on the entity type, the assigned value should
 * reference the prop name for the value you want displayed
 * in the snackbar notifications regarding the status of
 * the line items.
 */
export enum entityTypes {
  domain = 'domain',
  zone = 'zoneName',
  record = 'typeWithDnsName'
}

type UseJobResponseProps = {
  shouldStartPolling: boolean;
  lineItems: LineItem[];
  tagsToInvalidate: Parameters<typeof apiSlice.util.invalidateTags>[0];
  successMessage: string;
  failureMessage: string;
  skipCondition: boolean;
  entityType: string;
  onComplete: Function;
};

export const useJobResponse = ({
  shouldStartPolling,
  lineItems,
  tagsToInvalidate = [],
  successMessage,
  failureMessage,
  skipCondition,
  entityType = entityTypes.domain,
  onComplete = noop
}: UseJobResponseProps) => {
  const pollingCount = useRef(0);
  const pollingInterval = useRef(POLLING_INTERVAL.off);
  const dispatch = useDispatch();
  const clientId = useSelector(selectClientId);

  enum copyToClipboardStyles {
    color = '#fff !important',
    borderColor = '#fff !important'
  }

  useEffect(() => {
    if (shouldStartPolling) {
      pollingInterval.current = POLLING_INTERVAL.active;
    }
  }, [shouldStartPolling]);

  const getSnackbarContent = (
    heading: string,
    entityNames: Array<string | null>
  ) => (
    <Stack direction="column" sx={{ mb: 2 }}>
      <Typography variant="body2">{heading}</Typography>
      {entityNames.length &&
        entityNames
          .filter(entityName => typeof entityName === 'string')
          .slice(0, POLLING_MAX_ITEMS_DISPLAYED)
          .map(entityName => {
            return (
              <Typography key={entityName} variant="caption">
                {entityName}
              </Typography>
            );
          })}
      {entityNames?.length > POLLING_MAX_ITEMS_DISPLAYED ? (
        <Typography variant="caption">
          + {entityNames.length - POLLING_MAX_ITEMS_DISPLAYED} more
        </Typography>
      ) : null}
    </Stack>
  );

  const { data: jobResultData } = usePollJobResponseQuery(
    {
      clientId,
      clientLineItemIds: lineItems.map(
        ({ clientLineItemId }) => clientLineItemId
      ),
      lineItems,
      entityLabelPropName: entityTypes.domain
    },
    {
      skip: skipCondition,
      pollingInterval: pollingInterval.current
    }
  );

  useEffect(() => {
    if (!jobResultData) {
      return;
    }

    pollingCount.current++;

    const reachedMaxPollingRequests =
      pollingCount.current === POLLING_MAX_REQUESTS;

    const donePolling = reachedMaxPollingRequests || jobResultData.allComplete;

    if (donePolling && jobResultData.anySuccess) {
      enqueueSnackbar(
        getSnackbarContent(
          successMessage,
          jobResultData.successfulLineItemNames
        ),
        {
          variant: 'success'
        }
      );
    }

    if (donePolling && jobResultData.anyFailures) {
      enqueueSnackbar(
        getSnackbarContent(failureMessage, jobResultData.failedLineItemNames),
        {
          variant: 'error',
          persist: true,
          action: snackbarId => (
            <Stack direction="row" spacing={1}>
              <CopyToClipboard
                textToCopy={jobResultData.failedLineItemNames}
                variant="outlined"
                sx={copyToClipboardStyles}
              />
              <SnackbarCloseAction snackbarId={snackbarId} />
            </Stack>
          )
        }
      );
    }

    if (donePolling && jobResultData.anyPending) {
      enqueueSnackbar(
        getSnackbarContent(
          'Some items in your order are still processing.',
          jobResultData.pendingLineItemNames
        ),
        {
          variant: 'warning',
          persist: true,
          action: snackbarId => (
            <Stack direction="row" spacing={1}>
              <CopyToClipboard
                textToCopy={jobResultData.pendingLineItemNames}
                variant="outlined"
                sx={copyToClipboardStyles}
              />
              <SnackbarCloseAction snackbarId={snackbarId} />
            </Stack>
          )
        }
      );
    }

    if (donePolling) {
      pollingInterval.current = POLLING_INTERVAL.off;
      dispatch(apiSlice.util.invalidateTags(tagsToInvalidate));
      onComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobResultData]);

  return { isLoading: pollingInterval.current === POLLING_INTERVAL.active };
};
