import { Box, Typography } from '@mui/material';
import React from 'react';

export const CategoryTargetedSearchError = ({
  categorySearchTerms,
  errorMessage,
  targetedSearchTerms
}) => {
  return (
    <Box>
      <Typography variant="body1">{errorMessage}</Typography>
      <Box sx={{ fontWeight: 700, mt: 2 }}>
        Targeted search terms:
        {targetedSearchTerms?.map(term => (
          <Typography key={term} variant="body2">
            {term}
          </Typography>
        ))}
      </Box>
      <Box sx={{ fontWeight: 700, mt: 2 }}>
        Category search terms:
        {categorySearchTerms?.map(term => (
          <Typography key={term} variant="body2">
            {term}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};
