import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { IdnLanguageEntity } from '../../register-domains-types';
import { useGetIdnLanguagesQuery } from '../../register-domains-api-slice';
import { getTldNameForDomain } from '../../../../common/utils/get-tld-name-for-domain';

interface Props {
  domain: string;
  handleSetMap: (domain: string, idnLangTag: string) => void;
}

export const IdnAutocomplete: React.FC<Props> = ({ domain, handleSetMap }) => {
  const tld = getTldNameForDomain(domain);
  const idnLangs = useGetIdnLanguagesQuery({ tld });

  const options = idnLangs.data?.entity.map(
    (lang: IdnLanguageEntity) => lang.description
  );
  const handleChange = (event: React.SyntheticEvent) => {
    const target = event.target as Node;
    const language = target.textContent;
    const idnLangTag = idnLangs.data?.entity.find(
      (lang: IdnLanguageEntity) => lang.description === language
    ).registryScriptLangCode;
    handleSetMap(domain, idnLangTag);
  };
  return (
    <Autocomplete
      disableClearable
      id="idn-languages-autocomplete"
      onChange={event => handleChange(event)}
      options={options || []}
      renderInput={params => (
        <TextField
          {...params}
          label={domain}
          size="small"
          sx={{ marginTop: 2 }}
        />
      )}
    />
  );
};
