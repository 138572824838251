import { CircularProgress } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useVerifyContactSetForDomainQuery } from '../../common/store/contacts-api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import { useGetBulkDomainTransferAvailabilityQuery } from '../../common/store/domains-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { ValidOrNotMemoized } from './ValidOrNotIcon';
import { ValidityMessage } from './ValidityMessage';
import { getAuthCodeValidity } from './get-auth-code-validity-object';
import { getMessageForTransfer } from './get-message-for-transfer';
import {
  selectHaveAnyEmptyDomainNames,
  selectTransfers,
  updateById
} from './transfer-slice';

export const ValidCell = ({ customerContactSetId, domain, domainName }) => {
  const dispatch = useDispatch();
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const transfers = useSelector(selectTransfers);
  const anyEmptyDomainNames = useSelector(selectHaveAnyEmptyDomainNames);

  const { availability, isFetching } =
    useGetBulkDomainTransferAvailabilityQuery(
      {
        customerName,
        domainNames: transfers.map(({ domainName }) => domainName),
        reseller
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          availability: data?.find(
            availabilityForDomain =>
              availabilityForDomain.domainName === domainName
          ),
          isFetching
        }),
        skip: !customerName || anyEmptyDomainNames
      }
    );

  const isUnavailable = !availability?.available;

  const { error: contactValidationError, isFetching: isValidatingContactSet } =
    useVerifyContactSetForDomainQuery(
      {
        contactSetId: customerContactSetId,
        customerName,
        domainName,
        reseller
      },
      { skip: !customerName || !customerContactSetId || !domainName }
    );

  const isValidating = isValidatingContactSet || isFetching;

  const validity = useMemo(
    () => ({
      authCodeValidity: getAuthCodeValidity(domain.authCode),
      availability: {
        isError: isUnavailable,
        message: getMessageForTransfer(availability)
      },
      contactSetValidity: {
        isError: contactValidationError && !domain.useLocalPresence,
        // @ts-ignore
        message: contactValidationError?.data
      },
      specialRequirementsValidity: {
        isError:
          domain.specialRequirements && !domain.specialRequirements?.isValid,
        message: 'The special requirements have not been met for this domain.'
      }
    }),
    [
      availability,
      contactValidationError,
      domain.authCode,
      domain.specialRequirements,
      domain.useLocalPresence,
      isUnavailable
    ]
  );

  const domainIsValid = Object.values(validity).every(
    validationObject => !validationObject.isError
  );

  useEffect(() => {
    const validityArray = Object.entries(validity).map(([key, value]) => {
      return { key, value };
    });
    dispatch(updateById({ changes: [...validityArray], id: domain.id }));
    if (domain.validForTransfer !== domainIsValid && !isValidating) {
      dispatch(
        updateById({
          changes: [
            {
              key: 'validForTransfer',
              value: Object.values(validity).every(
                validationObject => !validationObject.isError
              )
            }
          ],
          id: domain.id
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, domainIsValid, validity, isValidating]);

  return isValidating ? (
    <CircularProgress size={15} />
  ) : (
    <ValidOrNotMemoized
      isValid={Object.values(validity).every(
        validationObject => !validationObject.isError
      )}
      message={<ValidityMessage validity={validity} />}
    />
  );
};

export const ValidCellMemoized = React.memo(ValidCell);
