import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

export const NameServersInput = props => {
  const { applyValue, defaultValue, item, nameServerGroupsData } = props;

  const handleValueChange = (event, value) => applyValue({ item, value });

  return (
    <Autocomplete
      disableClearable
      isOptionEqualToValue={(option, value) => option.id === value.id}
      limitTags={1}
      onChange={handleValueChange}
      options={nameServerGroupsData ?? []}
      renderInput={params => (
        <TextField
          {...params}
          placeholder="Select Name Server Group"
          variant="outlined"
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      size="small"
      sx={{ width: '100%' }}
      value={defaultValue}
    />
  );
};
