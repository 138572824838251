import { Button, Dialog } from '@mui/material';
import {
  bindDialog,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React from 'react';

import { BulkApplyContactSetsList } from './BulkApplyContactSetsList';

export const BulkApplyContactSetsDialog = ({
  disableTrigger = false,
  handleContactSetSelection
}) => {
  const popupState = usePopupState({
    popupId: 'bulkApplyContactSetsToTransfersWithDialog',
    variant: 'dialog'
  });

  return (
    <>
      <Button {...bindTrigger(popupState)} disabled={disableTrigger}>
        Contact Sets
      </Button>
      <Dialog {...bindDialog(popupState)}>
        <BulkApplyContactSetsList
          handleContactSetSelection={handleContactSetSelection}
          popupState={popupState}
        />
      </Dialog>
    </>
  );
};
