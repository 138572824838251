import { camelCase, startCase } from 'lodash-es';

/**
 * Function to format a price string for display
 * @param {String} priceToBeFormatted
 * @returns {String} the formatted price string
 */
export const formatPrice = priceToBeFormatted =>
  Number(priceToBeFormatted).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'decimal'
  });

/**
 * Function to format a number string for display
 * @param {String} numberToBeFormatted
 * @returns {String} the formatted number string
 */
export const formatNumber = numberToBeFormatted => {
  const parsedNumber = Number(numberToBeFormatted);
  if (isNaN(parsedNumber)) {
    return '';
  }
  return parsedNumber.toLocaleString('en-US');
};

/**
 * Function to format a string value for display
 * @param {String} valueToDisplay
 * @returns {String} the formatted value string
 */
export const formatForDisplay = valueToDisplay =>
  startCase(camelCase(valueToDisplay)).replace(/ /g, '');
