export const getLabelsApiFilters = ({ newFilterModel }) => {
  let response;
  const matchingFilter = newFilterModel.items.find(
    item => item.field === '$labels'
  );

  if (matchingFilter?.value) {
    response = matchingFilter.value;
  } else {
    response = [];
  }

  response = response.map(label => label.id);

  return response;
};
