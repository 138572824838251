export enum CONTACT_SET_TYPES {
  registrant = 'registrant',
  billing = 'billing',
  administrative = 'administrative',
  technical = 'technical'
}

export enum CONTACT_SET_TYPE_LABELS {
  registrant = 'REGISTRANT CONTACT',
  billing = 'BILLING CONTACT',
  administrative = 'ADMINISTRATIVE CONTACT',
  technical = 'TECHNICAL CONTACT'
}
