import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import {
  bindDialog,
  bindToggle,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React from 'react';
import { useSelector } from 'react-redux';

import { ZoneRecordSetDeleteConfirmButton } from './ZoneRecordSetDeleteConfirmButton';
import { RECORD_TYPES } from './record-types';
import {
  selectSelectedRecords,
  selectSelectedRecordsCount
} from './selected-record-slice';
import { selectSelectedZones } from './selected-zone-slice';

export const ZoneRecordSetDeleteDialog = () => {
  const popupState = usePopupState({
    popupId: 'bulkApplyContactSetsToTransfersWithDialog',
    variant: 'dialog'
  });

  const selectedRecords = useSelector(selectSelectedRecords);
  const selectedZone = useSelector(selectSelectedZones)[0];
  const selectedRecordsCount = useSelector(selectSelectedRecordsCount);

  // only the original NS and SOA cannot be deleted
  const invalidSoaOrNsRecordIsSelected = () => {
    const selectedNsAndSoaRecords = selectedRecords.filter(record => {
      const recordDnsNameIsSameAsZone = record.dnsName === selectedZone.dnsName;
      const recordTypeIsSoaOrNs = RECORD_TYPES.REQUIRED_RECORD_TYPES.includes(
        record.recordType
      );

      return recordDnsNameIsSameAsZone && recordTypeIsSoaOrNs;
    });

    return selectedNsAndSoaRecords.length > 0;
  };

  return (
    <>
      <Button
        {...bindTrigger(popupState)}
        disabled={selectedRecordsCount < 1 || invalidSoaOrNsRecordIsSelected()}
        size="small"
        startIcon={<FontAwesomeIcon icon={faTrashCan} />}
      >
        Delete
      </Button>
      <Dialog {...bindDialog(popupState)} maxWidth="xs">
        <DialogTitle>
          Delete Record{selectedRecords.length > 1 ? 's' : ''}
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete the {selectedRecords.length} selected
          record{selectedRecords.length > 1 ? 's' : ''}?
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button {...bindToggle(popupState)} variant="outlined">
            Cancel
          </Button>
          <ZoneRecordSetDeleteConfirmButton popupState={popupState} />
        </DialogActions>
      </Dialog>
    </>
  );
};
