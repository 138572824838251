import { LINE_ITEM_STATUSES } from '../../common/store/job-response-api-slice';

export const LOCK_TYPES = Object.freeze({
  DELETE: 'delete',
  TRANSFER: 'transfer',
  UPDATE: 'update'
});

export const getEnabledValue = (registrarLocks, lockType) => {
  return registrarLocks.find(type => type.name === lockType).enabled;
};

export const PROHIBITED_TYPES = Object.freeze({
  DELETE: 'clientDeleteProhibited',
  TRANSFER: 'clientTransferProhibited',
  UPDATE: 'clientUpdateProhibited'
});

export const getProhibitedValue = (domainStatuses, prohibitedType) => {
  return domainStatuses.includes(prohibitedType);
};

/**
 * @param {string} lock - The lock type (updateLock, transferLock, deleteLock).
 */
export const getClientProhibitedValue = lock => {
  return PROHIBITED_TYPES[lock.replace('Lock', '').toUpperCase()];
};

// counts the number of succeeded and failed jobs from a poll request
export const defineJobStatus = messageObject => {
  return messageObject.reduce(
    (acc, curr) => {
      if (curr.status === LINE_ITEM_STATUSES.success) {
        acc.successCount++;
      } else if (curr.status === LINE_ITEM_STATUSES.failure) {
        acc.failureCount++;
      } else if (curr.status === LINE_ITEM_STATUSES.pending) {
        acc.pendingCount++;
      }
      acc.total = acc.successCount + acc.failureCount + acc.pendingCount;
      return acc;
    },
    { failureCount: 0, pendingCount: 0, successCount: 0, total: 0 }
  );
};

// maps the domain name to the corresponding status of the operation
export const defineJobDomainStatus = (messageObject, lineItems) => {
  return lineItems.map(lineItem => {
    const status = messageObject.find(
      jobResult => lineItem.clientLineItemId === jobResult.clientLineItemId
    );
    return Object.assign({}, lineItem, status);
  });
};
