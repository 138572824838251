import { CircularProgress, Paper } from '@mui/material';
import React from 'react';

export const Loading = () => {
  return (
    <Paper
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: '95%',
        justifyContent: 'center',
        p: 2
      }}
      variant="outlined"
    >
      <CircularProgress />
    </Paper>
  );
};
