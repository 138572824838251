import { Stack } from '@mui/material';
import React from 'react';

import { BulkApplyContactSets } from './BulkApplyContactSets';
import { BulkApplyLabels } from './BulkApplyLabels';

export const BulkApplyActions = () => (
  <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
    <BulkApplyLabels />
    <BulkApplyContactSets />
  </Stack>
);
