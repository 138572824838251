import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { getDatesApiFilters } from '../../common/utils/get-dates-api-filters';

const initialState = {
  apiFilters: {
    completedStatus: true,
    fromDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
    toDate: dayjs().format('YYYY-MM-DD')
  },
  filterModel: {}
};

const getStatusApiFilter = ({ newFilterModel }) => {
  const matchingFilter = newFilterModel.items.find(
    item => item.field === '$statusMapped'
  );

  if (matchingFilter?.value === 'Completed') {
    return true;
  } else if (matchingFilter?.value === 'Not Complete') {
    return false;
  } else {
    return undefined;
  }
};

const getLabelsApiFilters = ({ newFilterModel }) => {
  let response;
  const matchingFilter = newFilterModel.items.find(
    item => item.field === '$labels'
  );

  if (matchingFilter?.value) {
    response = matchingFilter.value;
  } else {
    response = [];
  }

  response = response.map(label => label.id);

  return response;
};

const getProductTypesApiFilters = ({ newFilterModel }) => {
  const matchingFilter = newFilterModel.items.find(
    item => item.field === '$productTypeMapped'
  );
  return matchingFilter?.value?.map(productType => productType.id);
};

const getSearchStringApiFilters = ({ newFilterModel }) =>
  (newFilterModel?.quickFilterValues ?? []).join(' ');

const invoiceFiltersSlice = createSlice({
  initialState,
  name: 'invoiceFilters',
  reducers: {
    updateFilters: (state, { payload }) => {
      const { labelsData, newFilterModel, productTypesData } = payload;
      const { fromDate, toDate } = getDatesApiFilters(
        newFilterModel,
        '$completedDateFormatted'
      );

      state.apiFilters = {
        completedStatus: getStatusApiFilter({ newFilterModel }),
        fromDate,
        labelIds: getLabelsApiFilters({ labelsData, newFilterModel }),
        productTypes: getProductTypesApiFilters({
          newFilterModel,
          productTypesData
        }),
        searchTerm: getSearchStringApiFilters({ newFilterModel }),
        toDate
      };
    }
  }
});

export const invoiceFiltersReducer = invoiceFiltersSlice.reducer;

export const { updateFilters } = invoiceFiltersSlice.actions;
export const selectApiFilters = state => state.invoiceFilters.apiFilters;
