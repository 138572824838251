import { faTrash } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';

import { removeById } from './transfer-slice';

export const RemoveCell = ({ id }) => {
  const dispatch = useDispatch();
  const handleRemoveClick = () => dispatch(removeById(id));

  return (
    <IconButton onClick={handleRemoveClick}>
      <FontAwesomeIcon icon={faTrash} />
    </IconButton>
  );
};

export const RemoveCellMemoized = React.memo(RemoveCell);
