export const domainCheckAvailabilities = Object.freeze({
  '-': '-',
  CHECKING: 'CHECKING',
  ERROR: 'ERROR',
  NO: 'NO',
  PENDING: 'PENDING',
  PRE_SUNRISE: 'PRE_SUNRISE',
  UNKNOWN_MANUAL: 'UNKNOWN_MANUAL',
  UNKNOWN_PHASE: 'UNKNOWN_PHASE',
  UNKNOWN_WEB3: 'UNKNOWN_WEB3',
  YES: 'YES'
});
