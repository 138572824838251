/**
 * @param {Object} availability
 * @returns {String} message
 */
export const getMessageForTransfer = availability => {
  if (availability?.available === true) {
    return 'This domain name is available for transfer.';
  } else if (availability?.reason === 'TRANSFER_EXISTS') {
    return 'This domain name is already being transferred.';
  } else if (availability?.reason === 'DOMAIN_EXISTS') {
    return 'This domain name exists at the registrary already.';
  } else if (availability?.reason === 'UNSUPPORTED_TLD') {
    return 'The entered tld is not supported at this time.';
  } else {
    return 'This domain name is not available for transfer.';
  }
};
