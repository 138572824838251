import { array, boolean, number, object, string } from 'yup';

import type { ApiUserResponse } from '../../common/types/user';

export type SignOffContextFormSchema = {
  active: boolean;
  forceForAllUsers: boolean;
  hasSpecifiedApprovers: boolean;
  numApprovalsRequired: {
    errorMessage: null | string;
    hasError: boolean;
    value: number;
  };
};

export const signOffContextFormSchema = (
  handleRequiredValidation: ({ path }: { path: string }) => void,
  handleMinimumApproverValidation: ({ path }: { path: string }) => void
) =>
  object({
    active: boolean().required().default(false),
    forceForAllUsers: boolean().required().default(false),
    hasSpecifiedApprovers: boolean().required().default(false),
    numApprovalsRequired: object({
      errorMessage: string().nullable().default(''),
      hasError: boolean().required().default(false),
      value: number()
        .required(handleRequiredValidation)
        .default(1)
        .min(1, handleMinimumApproverValidation)
    })
  });

export type SignOffContextSignerFormSchema = {
  approvedSigners: ApiUserResponse[];
};

export const signOffContextSignerFormSchema = () =>
  object({
    approvedSigners: array().of(object({})).required().default([])
  });
