export const dnssecOptions = {
  algorithmType: [
    '1',
    '2',
    '3',
    '5',
    '6',
    '7',
    '8',
    '10',
    '12',
    '13',
    '14',
    '15',
    '16'
  ],
  digestType: ['1', '2', '4']
};
