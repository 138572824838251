import { startCase, toLower } from 'lodash-es';

import { TLD_COSTS_TAG, apiSlice } from '../../common/store/api-slice';
import {
  dateFormatOptionsForDisplay,
  getLocalizedDate,
  getLocalizedDateTime
} from '../../common/utils/date-time';
import { formatPrice } from '../../common/utils/number-price-text';

const formatValueForDisplay = ({ field, valueToFormat }) => {
  if (!valueToFormat.length) {
    return 'No Value';
  } else if (field === 'START_DATE') {
    const value = new Date(parseInt(valueToFormat));
    return value.toLocaleDateString('en-US', dateFormatOptionsForDisplay);
  } else {
    return valueToFormat;
  }
};

export const tldCostApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTldCostCurrency: builder.query({
      query: ({ reseller }) => ({
        method: 'GET',
        url: `resellers/${reseller}/tld-cost/currency`
      }),
      transformResponse: response =>
        response
          .map(currency => ({
            ...currency,
            label: `${currency.name} (${currency.code})`,
            value: currency.code
          }))
          .sort((a, b) => (a.name > b.name ? 1 : -1))
    }),
    getTldCostHistory: builder.query({
      query: ({ reseller, tldCostId }) => ({
        method: 'GET',
        url: `resellers/${reseller}/tld-cost/view-history/${tldCostId}`
      }),
      transformResponse: response =>
        response.map(historyItem => ({
          ...historyItem,
          $changeDateFormatted: getLocalizedDateTime(historyItem.changeDate),
          $fieldFormatted: startCase(toLower(historyItem.fieldChanged)),
          $newValueFormatted: formatValueForDisplay({
            field: historyItem.fieldChanged,
            valueToFormat: historyItem.newValue
          }),
          $oldValueFormatted: formatValueForDisplay({
            field: historyItem.fieldChanged,
            valueToFormat: historyItem.oldValue
          })
        }))
    }),
    getTldCosts: builder.query({
      providesTags: [TLD_COSTS_TAG],
      query: ({ reseller }) => ({
        method: 'POST',
        url: `/resellers/${reseller}/tld-cost/costs`
      }),
      transformResponse: response => {
        return response.map(tldCost => ({
          ...tldCost,
          $icannFeeFormatted: `$${formatPrice(tldCost.icannFee)}`,
          $registryCostFormatted: formatPrice(tldCost.registryCost),
          $startDateFormatted: getLocalizedDate(tldCost.startDate),
          $systemPriceFormatted: tldCost.systemPrice
            ? `$${formatPrice(tldCost.systemPrice)}`
            : '',
          $tldCostFormatted: `$${formatPrice(tldCost.tldCostUsd)}`,
          $tldName: tldCost.tld.name,
          $tldType: tldCost.tld.type,
          $totalCostFormatted: `$${formatPrice(tldCost.totalCost)}`
        }));
      }
    }),
    getTldNames: builder.query({
      query: ({ reseller }) => ({
        method: 'GET',
        url: `resellers/${reseller}/tld-cost/tld-names`
      }),
      transformResponse: response =>
        response
          .map(tld => ({
            ...tld,
            label: tld.name,
            value: tld.name
          }))
          .sort((a, b) => (a.name > b.name ? 1 : -1))
    }),
    getTldRegistryFilters: builder.query({
      query: ({ reseller }) => ({
        method: 'GET',
        url: `resellers/${reseller}/tld-cost/tld-registry-filters`
      }),
      transformResponse: response => response.sort()
    }),
    updateTldCosts: builder.mutation({
      invalidatesTags: [TLD_COSTS_TAG],
      query: ({ payload, reseller }) => ({
        body: payload,
        method: 'POST',
        url: `resellers/${reseller}/tld-cost/update-costs`
      })
    })
  })
});

export const {
  useGetTldCostCurrencyQuery,
  useGetTldCostHistoryQuery,
  useGetTldCostsQuery,
  useGetTldNamesQuery,
  useGetTldRegistryFiltersQuery,
  useUpdateTldCostsMutation
} = tldCostApiSlice;
