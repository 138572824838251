import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { noop } from 'lodash-es';
import React from 'react';

export const DotJobsForm = ({ specialRequirements }) => {
  return (
    <>
      <TextField
        disabled
        label="Website"
        value={specialRequirements.DOTJOBS.website}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={specialRequirements.DOTJOBS.adminContact}
            disabled
            onChange={noop}
          />
        }
        label="Administrative Contact"
      />
    </>
  );
};
