import { Button, Dialog, Paper } from '@mui/material';
import React, { useState } from 'react';

import { LabelsSelection } from '../../../../common/components/labels-selection/LabelsSelection';

export const BulkLabelSelectPopover = ({ buttonTitle, checkedDomains }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  return (
    <>
      <Button
        disabled={!checkedDomains.length}
        onClick={handleOpen}
        sx={{ mr: 2 }}
      >
        {buttonTitle}
      </Button>
      <Dialog
        PaperProps={{
          style: {
            overflowY: 'unset'
          }
        }}
        onClose={handleClose}
        open={open}
      >
        <Paper sx={{ overflow: 'auto', p: 2 }} variant="outlined">
          <LabelsSelection selectedDomains={checkedDomains} />
        </Paper>
      </Dialog>
    </>
  );
};
