import { uniq } from 'lodash-es';
import { array, boolean, object, string } from 'yup';

import { NAME_SERVER_REGEX } from '../../common/utils/name-server-regex';

/**
 *
 * @param {Function} handleNameServerValidation
 
 * @param {String} value
 * @returns
 */
export const fieldSchema = (handleNameServerValidation, value = '') =>
  object({
    errorMessage: string().default(''),
    hasError: boolean().default(false),
    key: string().required().default(Math.random().toString().slice(2)),
    touched: boolean().default(false),
    value: string()
      .matches(NAME_SERVER_REGEX, handleNameServerValidation, {
        excludeEmptyString: true
      })
      .default(value)
  });

/**
 *
 * @param {Function} handleNameServerValidation
 * @param {Function} handleUniqueValidation
 * @returns
 */
export const formSchema = (
  handleNameServerValidation,
  handleUniqueValidation
) =>
  array()
    .min(2)
    .of(fieldSchema(handleNameServerValidation))
    .test('unique', handleUniqueValidation, fields => {
      const rawFields = fields
        .map(field => field.value)
        .filter(value => value.length > 0);

      const areUnique = rawFields.length === uniq(rawFields).length;
      const isValid = rawFields.length < 2 || areUnique;

      return isValid;
    });
