import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack
} from '@mui/material';
import { isNil } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { selectReseller } from '../../common/store/reseller';
import { CurrencyInput } from './CurrencyInput';
import { EffectiveDateInput } from './EffectiveDateInput';
import { RegistryCostInput } from './RegistryCostInput';
import {
  useGetTldCostCurrencyQuery,
  useUpdateTldCostsMutation
} from './tld-cost-api-slice';
import {
  handleChangeMultiple,
  handleDateChangeMultiple,
  handleDateValidation,
  handleRegistryCostChangeMultiple
} from './tld-cost-form-functions';
import { formSchemaMultiple } from './tld-cost-form-multiple.schema';
import { tldCostFormReducer } from './tld-cost-form-reducer';

export const TldCostEditMultipleForm = () => {
  const reseller = useSelector(selectReseller);
  const navigate = useNavigate();
  const { tldCostIds } = useParams();
  const tldCostIdsArray = tldCostIds?.split(',');
  const { enqueueSnackbar } = useSnackbar();

  const [validationErrors, setValidationErrors] = useState(false);

  const handleClose = () => navigate('/tld-costs');

  const [
    updateTldCosts,
    {
      isError: isUpdateTldCostsError,
      isLoading: isUpdateTldCostsLoading,
      isSuccess: isUpdateTldCostsSuccess
    }
  ] = useUpdateTldCostsMutation();

  const { data: currencies = [], isSuccess: isGetCurrenciesSuccess } =
    useGetTldCostCurrencyQuery({
      reseller
    });

  const handleSubmit = event => {
    event.preventDefault();
    setValidationErrors(false);
    formSchemaMultiple({
      handleDateValidation: handleDateValidation(true, formDataDispatch)
    })
      .validate(formData)
      .then(() => {
        const tldCostDataFromForm = {
          currency: formData.currency.touched
            ? formData.currency.value?.code
            : null,
          registryCost: formData.registryCost.touched
            ? parseFloat(formData.registryCost.value)
            : null,
          startDate: formData.startDate.touched
            ? formData.startDate.value?.valueOf()
            : null
        };

        Object.keys(tldCostDataFromForm).forEach(key => {
          if (isNil(tldCostDataFromForm[key])) {
            delete tldCostDataFromForm[key];
          }
        });
        const payload = tldCostIdsArray.map(id => ({
          ...tldCostDataFromForm,
          id
        }));
        updateTldCosts({ payload, reseller });
      })
      .catch(() => setValidationErrors(true));
  };
  const emptyFormState = () =>
    formSchemaMultiple({ handleDateValidation }).getDefault();

  const [formData, formDataDispatch] = useReducer(
    tldCostFormReducer,
    formSchemaMultiple({ handleDateValidation }).getDefault()
  );

  const debouncedValidation = useDebouncedCallback(
    formSchema =>
      formSchema({
        handleDateValidation: handleDateValidation(false, formDataDispatch)
      })
        .validate(formData)
        .catch(window.console.error),
    1500
  );

  useEffect(() => {
    if (isUpdateTldCostsSuccess) {
      enqueueSnackbar('Successfully updated costs', {
        variant: 'success'
      });
      navigate('/tld-costs');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateTldCostsSuccess]);

  useEffect(() => {
    if (isUpdateTldCostsError) {
      enqueueSnackbar('Could not update costs', {
        variant: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateTldCostsError]);

  const canInitializeMultipleSelectedForm =
    isGetCurrenciesSuccess && !formData?.isInit;

  //Initialize empty form many selected
  useEffect(() => {
    if (canInitializeMultipleSelectedForm) {
      // @ts-ignore
      formDataDispatch({
        path: '',
        type: 'INIT',
        value: emptyFormState()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canInitializeMultipleSelectedForm]);

  return (
    <Dialog fullWidth maxWidth="md" open={true}>
      <form noValidate={true} onSubmit={handleSubmit}>
        <DialogTitle sx={{ pb: 0 }}>Edit Tld Costs</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            {validationErrors && (
              <Alert severity="error">Fix form errors before submitting.</Alert>
            )}
            <Stack sx={{ pt: 2 }}>
              <RegistryCostInput
                debouncedValidation={debouncedValidation}
                formData={formData}
                formDataDispatch={formDataDispatch}
                handleChange={handleRegistryCostChangeMultiple}
              />
              <CurrencyInput
                currencies={currencies}
                debouncedValidation={debouncedValidation}
                formData={formData}
                formDataDispatch={formDataDispatch}
                handleChange={handleChangeMultiple}
              />
            </Stack>
            <Divider sx={{ my: 1 }} />
            <EffectiveDateInput
              debouncedValidation={debouncedValidation}
              formData={formData}
              formDataDispatch={formDataDispatch}
              handleChange={handleDateChangeMultiple}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={
              isUpdateTldCostsLoading || !formData?.formState?.isTouched
            }
            onClick={handleSubmit}
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

// eslint-disable-next-line import/no-default-export
export default TldCostEditMultipleForm;
