import { Typography } from '@mui/material';
import React from 'react';

export const DnssecValidityMessage = ({ isVisible }) => {
  return (
    isVisible && (
      <div>
        <Typography variant="body2">DNNSEC invalid</Typography>
        <Typography variant="caption">
          DNSSEC must be configured for this domain.
        </Typography>
      </div>
    )
  );
};
