import { TextField } from '@mui/material';
import React from 'react';

export const DotNlForm = ({ specialRequirements }) => {
  const legalForms = Object.freeze({
    ANDERS: 'Other',
    BRO: 'Non-Dutch legal form/enterprise/subsidiary',
    BV: 'Limited company',
    BVI_O: 'Limited company in formation',
    COOP: 'Cooperative',
    CV: 'Limited partnership',
    EENMANSZAAK: 'Sole trader',
    EESV: 'European Economic Interest Group',
    KERK: 'Religious society',
    MAATSCHAP: 'Partnership',
    NV: 'Public company',
    OWM: 'Mutual benefit company',
    PERSOON: 'Natural person',
    REDR: 'Shipping company',
    STICHTING: 'Foundation',
    VERENIGING: 'Association',
    VOF: 'Trading partnership'
  });

  return (
    <TextField
      disabled
      label="Legal Form"
      value={legalForms[specialRequirements.DOTNL.legalForm]}
    />
  );
};
