import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { selectCustomerName } from '../../common/store/customers-slice';
import { useGetBulkDomainTransferAvailabilityQuery } from '../../common/store/domains-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { navigateToNextTransferStep } from './transfer-navigation';
import {
  displaySpecialRequirementsNotice,
  selectAllDomainsAreValidForTransfer,
  selectHaveAnyEmptyDomainNames,
  selectHaveValidSpecialRequirements,
  selectTransferTotal,
  selectTransfers
} from './transfer-slice';

export const TransferDetailsStepNextButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stepNameParam = useParams().stepName;
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const transfers = useSelector(selectTransfers);
  const haveValidSpecialRequirements = useSelector(
    selectHaveValidSpecialRequirements
  );
  const anyEmptyDomainNames = useSelector(selectHaveAnyEmptyDomainNames);
  const allDomainsAreValidForTransfer = useSelector(
    selectAllDomainsAreValidForTransfer
  );
  const transferTotal = useSelector(selectTransferTotal);

  const { allAvailable, transferCount } =
    useGetBulkDomainTransferAvailabilityQuery(
      {
        customerName,
        domainNames: transfers.map(({ domainName }) => domainName),
        reseller
      },
      {
        selectFromResult: ({ data }) => ({
          allAvailable: data?.every(({ available }) => available === true),
          transferCount: data?.filter(
            availabilityForDomain => availabilityForDomain.available === true
          )?.length
        }),
        skip: !customerName || anyEmptyDomainNames
      }
    );

  const handleNextClick = () => {
    if (!haveValidSpecialRequirements) {
      dispatch(displaySpecialRequirementsNotice(true));
    } else {
      dispatch(displaySpecialRequirementsNotice(false));
      navigateToNextTransferStep(navigate, stepNameParam);
    }
  };

  return (
    <LoadingButton
      disabled={
        transferCount === 0 || !allAvailable || !allDomainsAreValidForTransfer
      }
      endIcon={<FontAwesomeIcon icon={faChevronRight} width={12} />}
      loading={false}
      onClick={handleNextClick}
      variant="contained"
    >
      {`Next ($${transferTotal})`}
    </LoadingButton>
  );
};
