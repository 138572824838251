import { createSlice } from '@reduxjs/toolkit';

const RESELLER = 'tracer';

export const resellerReducer = createSlice({
  initialState: RESELLER,
  name: RESELLER
}).reducer;

export const selectReseller = state => state.reseller;
