import { Alert, Link, Snackbar } from '@mui/material';
import React, { useState } from 'react';

import { useAppInfoQuery } from '../../common/store/app-info-api-slice';
import { haveSeenCookieNotice, setSeenCookie } from './cookie-storage';

export const CookieToast = () => {
  const { data: appInfo, isSuccess } = useAppInfoQuery();
  const [open, setOpen] = useState(true);
  const resellerDisplayName = appInfo?.registrarName;
  const handleClose = () => {
    setSeenCookie();
    setOpen(false);
  };

  return haveSeenCookieNotice() ? null : (
    <Snackbar
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      data-testid={isSuccess ? 'get-appinfo-success' : null}
      onClose={handleClose}
      open={open}
    >
      <Alert onClose={handleClose} severity="info" sx={{ width: '300px' }}>
        This site uses cookies to provide a great experience for our users. By
        using {resellerDisplayName} Registrar you agree to our{' '}
        <Link href="/cookie-policy" onClick={handleClose}>
          Cookie Policy
        </Link>
        .
      </Alert>
    </Snackbar>
  );
};
