// Domain Search Patterns
export const DOMAIN_REGISTRY_PATTERNS = Object.freeze({
  domainNameWithoutTld:
    /^(?=.{1,254}$)((?=[a-zA-Z0-9-]{1,63})[a-zA-Z0-9](-*[a-zA-Z0-9]+)*)$/,
  domainSearchSplitPattern: /[ ,]+/,
  dotNameDomainNameWithoutTld:
    /^(?=.{1,254}$)((?=[a-zA-Z0-9-]{1,63})[a-zA-Z0-9]((-*|\.)[a-zA-Z0-9]+)*)$/,
  idnPattern: /^xn--[-a-z0-9]+(\.[a-z]+)?$/
});
/**
 * @param {string} domainName
 * @returns {boolean} is a domain name without tld
 */
export const isDomainNameWithoutTld = domainName =>
  DOMAIN_REGISTRY_PATTERNS.domainNameWithoutTld.test(domainName);

/**
 * @param {string} domainName
 * @returns {boolean} is a dot name domain name without tld
 */
export const isDotNameDomainNameWithoutTld = domainName =>
  DOMAIN_REGISTRY_PATTERNS.dotNameDomainNameWithoutTld.test(domainName);

/**
 * @param {string} searchTerm
 * @returns {boolean} is a valid search term
 */
export const isIdnSearchTerm = searchTerm =>
  DOMAIN_REGISTRY_PATTERNS.idnPattern.test(searchTerm);

// Import Domain
// This won't match just a single word, but can match even international FQDNs, e.g. xn--dtbjjdcfhg5cckn1k9a.xn--p1ai
const FULLY_QUALIFIED_DOMAIN_NAME =
  /^(?=.{1,254}$)((?=[a-z0-9-]{1,63}\.)[a-z0-9]+(-*[a-z0-9]+)*\.)+((?=.{2,63}$)(?:[a-z0-9]+(?:-*[a-z0-9]+)*))$/;

/**
 * @param {string} domainName
 * @returns {boolean}
 */
export const isFullyQualifiedDomainName = domainName =>
  FULLY_QUALIFIED_DOMAIN_NAME.test(domainName);
