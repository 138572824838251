import { LicenseInfo } from '@mui/x-data-grid-pro';

import { initializers } from './initializers';

// https://mui.com/x/introduction/licensing/#how-to-install-the-key
export const muiXInitializer = () =>
  LicenseInfo.setLicenseKey(
    '1c50cb74cccb98ab9b0041f26762f9e2Tz05Mzc3MSxFPTE3NTE4MTQzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
  );

initializers.push(muiXInitializer);
