import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, AlertTitle, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectIsAnyDomainManual } from '../../register-domains-slice';
import { OrderSummary } from './OrderSummary';

export const RegisterDomainsStatus = () => {
  const isAnyDomainManual = useSelector(selectIsAnyDomainManual);
  const navigate = useNavigate();

  const handleNavigate = event => {
    navigate(event.target.name);
  };

  const NavigationButtons = () => {
    return (
      <Stack direction="row" spacing={2} sx={{ pb: 2 }}>
        <Button
          endIcon={<FontAwesomeIcon icon={faChevronRight} />}
          name="/dashboard"
          onClick={handleNavigate}
          variant="contained"
        >
          Dashboard
        </Button>
        <Button
          endIcon={<FontAwesomeIcon icon={faChevronRight} />}
          name="/domains"
          onClick={handleNavigate}
          variant="contained"
        >
          Domains
        </Button>
        <Button
          endIcon={<FontAwesomeIcon icon={faChevronRight} />}
          name="/messages/customer"
          onClick={handleNavigate}
          variant="contained"
        >
          Messages
        </Button>
      </Stack>
    );
  };

  return (
    <Stack
      spacing={1}
      sx={{
        alignItems: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <Typography sx={{ textAlign: 'center' }} variant="h3">
        Thank you for your order
      </Typography>
      <Typography sx={{ textAlign: 'center' }} variant="h6">
        You will receive an order confirmation email shortly
      </Typography>
      {isAnyDomainManual && (
        <Alert severity="warning" sx={{ width: 600 }} variant="filled">
          <AlertTitle>
            One or more domains require manual registration
          </AlertTitle>
          Your Account Manager will complete these registrations as soon as
          possible
        </Alert>
      )}
      <NavigationButtons />
      <OrderSummary />
    </Stack>
  );
};
