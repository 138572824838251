import {
  faAdd,
  faEdit,
  faPlus,
  faTrash
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  TextField
} from '@mui/material';
import { noop } from 'lodash-es';
import { bindDialog } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useSnackbar } from 'notistack';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { selectCustomerName } from '../../common/store/customers-slice';
import { selectReseller } from '../../common/store/reseller';
import { ZoneRecordSetAddConfirmButton } from './ZoneRecordSetAddConfirmButton';
import { defaultRecordDataSchema, zoneRecordFormSchema } from './schema';
import {
  selectSelectedRecords,
  selectSelectedRecordsCount
} from './selected-record-slice';
import { selectSelectedZones } from './selected-zone-slice';
import { useGetValidRecordTypesQuery } from './zone-management-api-slice';
import { zoneRecordSetFormDefaults } from './zone-record-set-form-defaults';

export const ZoneRecordSetAddDialog = ({ isEdit }) => {
  const popupState = usePopupState({
    popupId: 'zoneRecordSetAddDialog',
    variant: 'dialog'
  });

  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);

  const selectedRecord = useSelector(selectSelectedRecords)[0];
  const selectedRecordCount = useSelector(selectSelectedRecordsCount);
  const selectedZone = useSelector(selectSelectedZones)[0];

  const { enqueueSnackbar } = useSnackbar();

  const [validationErrors, setValidationErrors] = useState(false);
  const [isUserRemovingTooManyRecords, setUserRemovingTooManyRecords] =
    useState(false);
  const [isUserAddingTooManyRecords, setUserAddingTooManyRecords] =
    useState(false);

  const { data: recordTypes = [] } = useGetValidRecordTypesQuery({
    customerName,
    reseller
  });

  useEffect(() => {
    if (isUserAddingTooManyRecords) {
      const recordType = formData.recordType.value;
      const maxRecordCount = getMaxRecordsForRecordType(recordType);

      enqueueSnackbar(
        `There can be no more than ${maxRecordCount} ${recordType} record${
          maxRecordCount > 1 ? 's' : ' '
        } per set.`,
        {
          variant: 'error'
        }
      );

      setUserAddingTooManyRecords(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAddingTooManyRecords]);

  useEffect(() => {
    if (isUserRemovingTooManyRecords) {
      const recordType = formData.recordType.value;
      const minRecordCount = getMinRecordsForRecordType(recordType);

      enqueueSnackbar(
        `There must be at least ${minRecordCount} ${recordType} record${
          minRecordCount > 1 ? 's' : ' '
        } per set.`,
        {
          variant: 'error'
        }
      );

      setUserRemovingTooManyRecords(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserRemovingTooManyRecords]);

  const handleRequiredValidation = ({ path }) =>
    formDataDispatch({
      errorMessage: 'This field is required',
      path,
      type: 'SET_ERROR'
    });

  const handleTtlValidation = ({ path }) =>
    formDataDispatch({
      errorMessage: 'TTL must be a number',
      path,
      type: 'SET_ERROR'
    });

  const handleRecordDataValidation = ({ path }) =>
    formDataDispatch({
      errorMessage: getErrorMessageForSelectedRecordType(
        formData.recordType.value
      ),
      path,
      type: 'SET_ERROR'
    });

  const handleSubDomainNameValidation = ({ path }) =>
    formDataDispatch({
      errorMessage: 'Must enter a valid sub-domain ex: my-sub-domain.example',
      path,
      type: 'SET_ERROR'
    });

  const handleDomainNameValidation = ({ path }) =>
    formDataDispatch({
      errorMessage:
        'Must ender a valid domain in the following format: sample.example.com.',
      path,
      type: 'SET_ERROR'
    });

  const handleAddRecordDataEntry = () =>
    formDataDispatch({
      index: formData.recordData.value.length,
      newValue: defaultRecordDataSchema(
        handleRequiredValidation,
        getRegexForSelectedRecordType(formData.recordType.value)
      ).getDefault(),
      path: 'recordData',
      type: 'ADD_RECORD_DATA'
    });

  const handleRemoveRecordDataEntry = key =>
    formDataDispatch({
      key: key,
      type: 'REMOVE_RECORD_DATA'
    });

  const emptyFormState = () =>
    zoneRecordFormSchema({
      handleDomainNameValidation,
      handleRecordDataValidation,
      handleRequiredValidation,
      handleSubDomainNameValidation,
      handleTtlValidation,
      isRecordSetUsingBareDomainName: !selectedRecord?.$isUsingBareDomainName
    }).getDefault();

  const getErrorMessageForSelectedRecordType = recordType => {
    const res = recordTypes.find(curr => curr.name === recordType);

    return res === undefined ? null : res.recordDataErrorMessage;
  };

  const getRegexForSelectedRecordType = recordType => {
    const res = recordTypes.find(curr => curr.name === recordType);

    return res === undefined ? null : new RegExp(res.recordDataRegex);
  };

  const getRecordTypeDataForSelectedRecordType = recordTypeName =>
    recordTypes.find(currentType => currentType.name === recordTypeName);

  const simplifyPath = path => {
    return path.split('.')[0];
  };

  const extractIndex = path => {
    if (path.includes('[')) {
      return parseInt(path.substring(path.indexOf('[') + 1, path.indexOf(']')));
    } else {
      return undefined;
    }
  };

  const getMinRecordsForRecordType = recordType =>
    recordTypes.filter(currRecord => currRecord.name === recordType)[0]
      .recordDataMin ?? 0;

  const getMaxRecordsForRecordType = recordType =>
    recordTypes.filter(currRecord => currRecord.name === recordType)[0]
      .recordDataMax ?? 99;

  const canRemoveRecordFromSetForType = (currentSetLength, recordType) => {
    const minRecordCount = getMinRecordsForRecordType(recordType);

    if (currentSetLength > minRecordCount) {
      return true;
    } else {
      setUserRemovingTooManyRecords(true);

      return false;
    }
  };

  const canAddRecordToSetForType = (currentSetLength, recordType) => {
    const maxRecordCount = getMaxRecordsForRecordType(recordType);

    if (maxRecordCount === null || currentSetLength < maxRecordCount) {
      return true;
    } else {
      setUserAddingTooManyRecords(true);

      return false;
    }
  };

  const formatDnsNameForEdit = dnsName => {
    const lengthOfSubDomain = dnsName.length - selectedZone.dnsName.length;

    if (lengthOfSubDomain === 0) {
      return dnsName;
    } else {
      return dnsName.substring(0, lengthOfSubDomain - 1);
    }
  };

  // eslint-disable-next-line complexity
  const [formData, formDataDispatch] = useReducer((state, action) => {
    if (action.path) {
      action.index =
        action.index === undefined ? extractIndex(action.path) : action.index;
      action.path = simplifyPath(action.path);
    }

    switch (action.type) {
      case 'RESET':
        state = emptyFormState();

        break;
      case 'INIT':
        state = emptyFormState();

        if (isEdit && action.value) {
          state.dnsName.value = formatDnsNameForEdit(action.value.dnsName);
          state.recordType.value = action.value.recordType;
          state.ttl.value = action.value.ttl;
          state.recordData.value = action.value.recordData.map(
            recordDataValue => {
              const formModel = defaultRecordDataSchema(
                handleRequiredValidation,
                handleRecordDataValidation,
                getRegexForSelectedRecordType(state.recordType.value)
              ).getDefault();

              formModel.value = recordDataValue;

              return formModel;
            }
          );
        }

        break;
      case 'UPDATE_FIELD':
        if (action.path === 'recordData') {
          state[action.path].touched = true;
          state[action.path].hasError = false;
          state[action.path].errorMessage = '';

          state[action.path].value[action.index].value = action.value;
          state[action.path].value[action.index].touched = true;
          state[action.path].value[action.index].errorMessage = '';
          state[action.path].value[action.index].hasError = false;
        } else {
          state[action.path].value = action.value;
          state[action.path].touched = true;
          state[action.path].errorMessage = '';
          state[action.path].hasError = false;

          // eslint-disable-next-line max-depth
          if (action.path === 'recordType') {
            state.recordData.value = [
              defaultRecordDataSchema(
                handleRequiredValidation,
                handleRecordDataValidation,
                getRegexForSelectedRecordType(state.recordType.value)
              ).getDefault()
            ];

            // change TTL to the default when record type is changed
            state.ttl.value =
              getRecordTypeDataForSelectedRecordType(state.recordType.value)
                ?.defaultTtl ?? zoneRecordSetFormDefaults.DEFAULT_TTL;

            // reset the 'required' message on the dns name field
            // and let the debounced validator add it back if it's
            // needed
            state.dnsName.errorMessage = '';
          }
        }

        break;
      case 'SET_ERROR':
        if (action.path === 'recordData' && action.index !== undefined) {
          state.recordData.value[action.index].errorMessage =
            action.errorMessage;
          state.recordData.value[action.index].hasError =
            state.recordData.value[action.index].touched;
        } else {
          state[action.path].errorMessage = action.errorMessage;
          state[action.path].hasError = state[action.path].touched;
        }

        break;
      case 'ADD_RECORD_DATA':
        const recordExistsAtIndex =
          state[action.path].value[action.index] !== undefined;

        if (
          !recordExistsAtIndex &&
          canAddRecordToSetForType(
            state.recordData.value.length,
            state.recordType.value
          )
        ) {
          state[action.path].value[action.index] = action.newValue;
        }

        break;
      case 'REMOVE_RECORD_DATA':
        const recordExistsInZone = state.recordData.value.some(
          record => record.key === action.key
        );

        if (state.recordType.value === '' || !recordExistsInZone) {
          break;
        }

        if (
          canRemoveRecordFromSetForType(
            state.recordData.value.length,
            state.recordType.value
          )
        ) {
          state.recordData.value = state.recordData.value.filter(
            record => record.key !== action.key
          );
        }

        break;
      default:
        break;
    }

    return { ...state };
  }, emptyFormState());

  const debouncedValidation = useDebouncedCallback(zoneRecordFormSchema => {
    zoneRecordFormSchema({
      handleDomainNameValidation,
      handleRecordDataValidation,
      handleRequiredValidation,
      handleSubDomainNameValidation,
      handleTtlValidation,
      isRecordSetUsingBareDomainName: !selectedRecord?.$isUsingBareDomainName,
      recordType: getRecordTypeDataForSelectedRecordType(
        formData.recordType.value
      )
    })
      .validate(formData)
      .catch(noop);
  }, 1500);

  const handleChange = async (event, path, index) => {
    setValidationErrors(false);

    await formDataDispatch({
      index: index,
      path,
      type: 'UPDATE_FIELD',
      value: path === 'recordType' ? event.target.innerHTML : event.target.value
    });

    debouncedValidation(zoneRecordFormSchema);
  };

  const handleCancel = event => {
    event.stopPropagation();

    formDataDispatch({ type: 'INIT', value: selectedRecord });

    popupState.close();
  };

  const allRequiredFieldsPopulated = () => {
    const dnsNamePopulated = formData.dnsName.value !== '';
    const recordTypeSelected = formData.recordType.value !== '';
    const ttlPopulated = formData.ttl.value !== '';
    const atLeastOneRecordDataPopulated =
      formData.recordData.value[0].value !== '';

    const selectedRecordTypeData = getRecordTypeDataForSelectedRecordType(
      formData.recordType.value
    );

    const dnsNameIsValid =
      dnsNamePopulated ||
      selectedRecordTypeData?.bareDomainNameAllowedForDnsName === true;

    return (
      dnsNameIsValid &&
      recordTypeSelected &&
      ttlPopulated &&
      atLeastOneRecordDataPopulated
    );
  };

  const validateForm = () => {
    return zoneRecordFormSchema({
      handleRecordDataValidation,
      handleRequiredValidation,
      handleSubDomainNameValidation,
      handleTtlValidation,
      isRecordSetUsingBareDomainName: !selectedRecord?.$isUsingBareDomainName,
      recordType: getRecordTypeDataForSelectedRecordType(
        formData.recordType.value
      )
    }).validate(formData);
  };

  const handleSubmit = event => {
    event.preventDefault();
  };

  const handleOpen = () => {
    formDataDispatch({
      type: 'INIT',
      value: selectedRecord
    });

    popupState.open();
  };

  return (
    <>
      <Button
        disabled={(isEdit && selectedRecordCount !== 1) || false}
        onClick={handleOpen}
        size="small"
        startIcon={<FontAwesomeIcon icon={isEdit ? faEdit : faAdd} />}
      >
        {isEdit ? 'Edit' : 'Add'}
      </Button>
      <Dialog
        {...bindDialog(popupState)}
        fullWidth
        onClose={event => handleCancel(event)}
      >
        <DialogTitle>{isEdit ? 'Edit ' : 'Add '} Record Set</DialogTitle>
        <form noValidate={true} onSubmit={handleSubmit}>
          <DialogContent sx={{ p: 1 }}>
            <Stack spacing={3} sx={{ width: '100%' }}>
              {validationErrors && (
                <Alert severity="error">
                  Fix form errors before submitting
                </Alert>
              )}
              <TextField
                InputProps={{
                  endAdornment: !selectedRecord?.$isUsingBareDomainName && (
                    <InputAdornment position="end">
                      {'.' + selectedZone.dnsName}
                    </InputAdornment>
                  )
                }}
                disabled={isEdit}
                error={formData.dnsName.hasError}
                fullWidth
                helperText={formData.dnsName.errorMessage}
                label="DNS Name"
                onChange={event => handleChange(event, 'dnsName')}
                value={formData.dnsName.value}
              />
              <Autocomplete
                disableClearable
                disabled={isEdit}
                fullWidth
                getOptionLabel={option => option}
                limitTags={1}
                onChange={event => handleChange(event, 'recordType')}
                options={recordTypes.map(record => record.name)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Record Type"
                    variant="outlined"
                  />
                )}
                selectOnFocus
                size="small"
                value={
                  formData.recordType.value === ''
                    ? null
                    : formData.recordType.value
                }
              />
              <TextField
                error={formData.ttl.hasError}
                fullWidth
                helperText={formData.ttl.errorMessage}
                label="TTL (Seconds)"
                onChange={event => handleChange(event, 'ttl')}
                value={formData.ttl.value}
              />
              {formData.recordData.value.map((dataEntry, index) => (
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Remove"
                          color="inherit"
                          onClick={() =>
                            handleRemoveRecordDataEntry(dataEntry.key)
                          }
                          size="small"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  disabled={formData.recordType.value === ''}
                  error={dataEntry.hasError}
                  fullWidth
                  helperText={dataEntry.errorMessage}
                  key={index}
                  label="Record Data"
                  margin="dense"
                  onChange={event => handleChange(event, 'recordData', index)}
                  size="small"
                  value={dataEntry.value}
                  variant="outlined"
                />
              ))}
              <Button
                color="secondary"
                disabled={formData.recordType.value === ''}
                onClick={handleAddRecordDataEntry}
                startIcon={<FontAwesomeIcon icon={faPlus} />}
                sx={{ alignSelf: 'start' }}
                variant="contained"
              >
                Add more
              </Button>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} variant="outlined">
              Cancel
            </Button>
            <ZoneRecordSetAddConfirmButton
              disabled={validationErrors || !allRequiredFieldsPopulated()}
              formData={formData}
              formDataDispatch={formDataDispatch}
              isEdit={isEdit}
              popupState={popupState}
              setValidationErrors={setValidationErrors}
              validateForm={validateForm}
            />
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
