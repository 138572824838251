import { Alert, Paper, Stack, Typography } from '@mui/material';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { isEqual } from 'lodash-es';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAssociatedLabelsForDomain } from '../../common/store/api-slice-utils';
import { useGetContactsQuery } from '../../common/store/contacts-api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import { useGetBulkDomainTransferAvailabilityQuery } from '../../common/store/domains-api-slice';
import {
  useGetLabelAssociationsQuery,
  useGetLabelsQuery
} from '../../common/store/labels-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { gridColumnWidths } from '../../common/utils/grid-column-widths';
import { formatPrice } from '../../common/utils/number-price-text';
import { AuthCodeCellMemoized } from './AuthCodeCell';
import { BulkApplyActions } from './BulkApplyActions';
import { ContactSetsCellMemoized } from './ContactSetsCell';
import { DomainNameCellMemoized } from './DomainNameCell';
import { Footer } from './Footer';
import { LabelsCellMemoized } from './LabelsCell';
import { LocalPresenceCellMemoized } from './LocalPresenceCell';
import { RemoveCellMemoized } from './RemoveCell';
import { SetDotNoSpecialRequirementsMemoized } from './SetDotNoSpecialRequirements';
import { SetNameServersMemoized } from './SetNameServers';
import { TransferDetailsGridToolbar } from './TransferDetailsGridToolbar';
import { TransferDetailsStepNextButton } from './TransferDetailsStepNextButton';
import { ValidCellMemoized } from './ValidCell';
import { YearsCellMemoized } from './YearsCell';
import { specialRequirements } from './special-requirements';
import {
  selectDisplaySpecialRequirementsNotice,
  selectHaveTransfersWithSpecialRequirements,
  selectNonEmptyDomainNames,
  selectSelectedTransferIds,
  selectTransfers,
  setSelectedTransfers,
  updateTransfersWithPrices
} from './transfer-slice';

export const TransferDetailsStep = () => {
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const selectedTransferIds = useSelector(selectSelectedTransferIds);
  useEffect(() => {
    apiRef.current.setRowSelectionModel(selectedTransferIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef, selectedTransferIds.length]);

  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const transfers = useSelector(selectTransfers);
  const haveTransfersWithSpecialRequirements = useSelector(
    selectHaveTransfersWithSpecialRequirements
  );
  const nonEmptyDomainNames = useSelector(selectNonEmptyDomainNames);
  const displaySpecialRequirementsNotice = useSelector(
    selectDisplaySpecialRequirementsNotice
  );

  const { data: contactSets } = useGetContactsQuery(
    {
      customerName,
      reseller
    },
    {
      skip: !customerName
    }
  );

  const { data: associationsData } = useGetLabelAssociationsQuery(
    {
      customerName,
      reseller
    },
    { skip: !customerName }
  );
  const { data: labelsData } = useGetLabelsQuery(
    {
      customerName,
      reseller
    },
    { skip: !customerName }
  );

  const getPriceForRow = row => {
    const priceToShow = row?.prices?.find(
      price => price.years === parseInt(row.years)
    )?.amount;
    return priceToShow === undefined ? '' : `$${formatPrice(priceToShow)}`;
  };

  const getContactSetById = contactSetId =>
    contactSets?.find(contactSet => contactSet.id === contactSetId);

  const {
    availableTransfers,
    isError: isTransferAvailabilityError,
    isFetching: isTransferAvailabilityFetching,
    isLoading: isTransferAvailabilityLoading,
    transferCount = 0
  } = useGetBulkDomainTransferAvailabilityQuery(
    {
      customerName,
      domainNames: nonEmptyDomainNames.map(({ domainName }) => domainName),
      reseller
    },
    {
      selectFromResult: ({ data, isError, isFetching, isLoading }) => ({
        availableTransfers: data?.filter(
          availabilityForDomain => availabilityForDomain.available === true
        ),
        isError,
        isFetching,
        isLoading,
        transferCount:
          data?.filter(
            availabilityForDomain => availabilityForDomain.available === true
          )?.length ?? 0
      }),
      skip: !customerName || nonEmptyDomainNames.length === 0
    }
  );

  const isCheckingTransferAvailability =
    isTransferAvailabilityFetching || isTransferAvailabilityLoading;
  const mappedTransfersFromRequest = availableTransfers?.map(
    item => item.domainName
  );
  const mappedTransfersFromState = transfers?.map(item => item.domainName);

  const transfersHaveSynced = isEqual(
    mappedTransfersFromRequest,
    mappedTransfersFromState
  );

  useEffect(() => {
    if (transfersHaveSynced) {
      dispatch(updateTransfersWithPrices(availableTransfers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transfersHaveSynced]);

  const handleSelectionModelChange = (newSelectionModel, { api }) => {
    dispatch(
      setSelectedTransfers(Array.from(api.getSelectedRows()).map(row => row[1]))
    );
  };

  const columns = useMemo(() => {
    const columns = [
      {
        ...gridColumnWidths.domainName,
        field: 'domainName',
        headerName: 'Domain Name',
        renderCell: params => (
          <DomainNameCellMemoized
            domainName={params.row.domainName}
            domainNameAvailability={params.row.availability}
            id={params.row.id}
            isCheckingTransferAvailability={isCheckingTransferAvailability}
          />
        )
      },
      {
        field: 'authCode',
        flex: 2,
        headerName: 'Auth Code',
        renderCell: params => (
          <AuthCodeCellMemoized
            authCode={params.row.authCode}
            authCodeValidity={params.row.authCodeValidity}
            id={params.row.id}
          />
        )
      },
      {
        field: 'years',
        headerName: 'Years',
        renderCell: params => (
          <YearsCellMemoized
            domainName={params.row.domainName}
            id={params.row.id}
            value={params.row.years}
          />
        ),
        width: 110
      },
      {
        field: 'prices',
        headerName: 'Price',
        renderCell: ({ row }) => getPriceForRow(row),
        sortable: true,
        valueGetter: ({ row }) => getPriceForRow(row),
        width: 110
      },
      {
        field: 'contactSet',
        headerName: 'Contact Set',
        renderCell: params => (
          <ContactSetsCellMemoized
            domainName={params.row.domainName}
            id={params.row.id}
            value={params.row.customerContactSetId}
          />
        ),
        valueGetter: ({ row }) =>
          contactSets && getContactSetById(row.customerContactSetId)?.name,
        width: 210
      },
      {
        disableExport: true,
        field: 'buyLocalPresence',
        headerName: 'Buy Local Presence',
        renderCell: params => (
          <LocalPresenceCellMemoized
            domainName={params.row.domainName}
            id={params.row.id}
            useLocalPresence={params.row.useLocalPresence}
          />
        )
      },
      {
        ...gridColumnWidths.labels,
        field: 'labels',
        headerName: 'Labels',
        renderCell: params => (
          <LabelsCellMemoized domainName={params.row.domainName} />
        ),
        valueGetter: ({ row }) =>
          getAssociatedLabelsForDomain(
            row.domainName,
            labelsData?.flat,
            associationsData
          )
            .map(label => label.groupAndName)
            .join(', ')
      },
      {
        ...gridColumnWidths.icon,
        align: 'center',
        disableExport: true,
        field: 'valid',
        headerAlign: 'center',
        headerName: 'Valid',
        renderCell: params => (
          <ValidCellMemoized
            customerContactSetId={params.row.customerContactSetId}
            domain={params.row}
            domainName={params.row.domainName}
          />
        )
      },
      {
        ...gridColumnWidths.icon,
        disableExport: true,
        field: 'remove',
        headerName: '',
        renderCell: params => <RemoveCellMemoized id={params.row.id} />
      }
    ];

    if (haveTransfersWithSpecialRequirements) {
      columns.splice(columns.length - 3, 0, {
        disableExport: true,
        field: 'specialRequirements',
        headerName: 'Special Requirements',
        renderCell: params => {
          switch (params.row?.specialRequirements?.component) {
            case 'SetNameServers':
              return (
                <SetNameServersMemoized
                  domainName={params.row.domainName}
                  id={params.row.id}
                />
              );
            case 'SetDotNoSpecialRequirements':
              return (
                <SetDotNoSpecialRequirementsMemoized
                  domainName={params.row.domainName}
                  id={params.row.id}
                />
              );
            default:
              return null;
          }
        },
        width: 200
      });
    }

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    associationsData,
    contactSets,
    haveTransfersWithSpecialRequirements,
    isCheckingTransferAvailability,
    labelsData?.flat
  ]);

  const specialRequirementsTlds = specialRequirements
    .map(specialRequirement => specialRequirement.tld)
    .join(', ');

  return (
    <>
      <Paper
        sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 2 }}
        variant="outlined"
      >
        <Stack spacing={2} sx={{ flexGrow: 0, mb: 2 }}>
          <Typography variant="h5">Domain Details</Typography>
          {transferCount > 0 && (
            <Typography sx={{ mb: 4 }} variant="body2">
              {transferCount} domain
              {transferCount > 1 ? 's' : null} to transfer
            </Typography>
          )}
          {transferCount === 0 && !isTransferAvailabilityFetching && (
            <Alert severity="warning">You have no domains to transfer.</Alert>
          )}
          {displaySpecialRequirementsNotice && (
            <Alert severity="warning">
              Complete the special requirements for any domains with the
              following TLDs: {specialRequirementsTlds}.
            </Alert>
          )}
          {isTransferAvailabilityError && (
            <Alert severity="error">
              An error occurred while checking domain transfer availability.
              Check your domain names and try again.
            </Alert>
          )}
        </Stack>
        <BulkApplyActions />
        <DataGridPro
          // disableMultipleRowSelection={true}
          apiRef={apiRef}
          checkboxSelection
          // pagination
          // rowCount={domainsAndAuthCodes.length}
          columns={columns}
          disableRowSelectionOnClick={true}
          disableVirtualization={import.meta.env.MODE === 'test'}
          onRowSelectionModelChange={handleSelectionModelChange}
          rows={transfers}
          slotProps={{
            toolbar: {
              apiRef
            }
          }}
          // pageSizeOptions={config.DEFAULT_TABLE_ROWS_PER_PAGE_OPTIONS}
          slots={{ toolbar: TransferDetailsGridToolbar }}
        />
      </Paper>
      <Footer nextButton={<TransferDetailsStepNextButton />} />
    </>
  );
};
