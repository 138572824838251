import { Typography } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { Logo } from '../../common/components/logo/Logo';
import { config } from '../../common/config/config';
import styles from './LoginLayout.module.css';
import { RSafeTermsOfService } from './RSafeTermsOfService';
import { RSecTermsOfService } from './RSecTermsOfService';
import { TermsOfService } from './TermsOfService';

export const LoginLayout = () => {
  const getTermsOfServiceForHost = () => {
    if (config.API_HOST === 'https://rest.registrarsafe.com') {
      return <RSafeTermsOfService />;
    } else if (config.API_HOST === 'https://rest.registrarsec.com') {
      return <RSecTermsOfService />;
    } else {
      return <TermsOfService />;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.content}>
          <Logo alt="Registrar Logo" className={styles.logo} />
          <Typography className={styles.subhead} variant="h6">
            Registrar
          </Typography>
          <Outlet />
          {getTermsOfServiceForHost()}
        </div>
      </div>
      <div className={styles.right}></div>
    </div>
  );
};
