import { Box } from '@mui/material';
import {
  // eslint-disable-next-line import/named
  GridToolbarContainer,
  // eslint-disable-next-line import/named
  GridToolbarFilterButton,
  // eslint-disable-next-line import/named
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectCanWrite } from '../auth/auth-slice';
import { ZoneDeleteDialog } from './ZoneDeleteDialog';
import { ZoneFormDialog } from './ZoneFormDialog';

export const ZoneGridToolbar = () => {
  const canWrite = useSelector(selectCanWrite);

  return (
    <GridToolbarContainer
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <GridToolbarFilterButton />
      {canWrite && <ZoneFormDialog isEdit={false} />}
      {canWrite && <ZoneDeleteDialog />}
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};
