import { createSlice } from '@reduxjs/toolkit';

const initialState = { selectedDomainIds: [], selectedDomains: [] };

const selectedWeb3Slice = createSlice({
  initialState,
  name: 'selectedWeb3Domains',
  reducers: {
    removeSelectedDomain: (state, { payload }) => {
      // expected payload: id of the domain to unselect
      state.selectedDomainIds = state.selectedDomainIds.filter(
        domainId => domainId !== payload
      );
      state.selectedDomains = state.selectedDomains.filter(
        domain => domain.$id !== payload
      );
    },
    setSelectedDomains: (state, { payload }) => {
      // expected payload: array of selected domain objects
      state.selectedDomains = payload;
      state.selectedDomainIds = payload.map(domain => domain?.$id);
    }
  }
});

export const selectedWeb3Reducer = selectedWeb3Slice.reducer;

export const { removeSelectedDomain, setSelectedDomains } =
  selectedWeb3Slice.actions;

export const selectSelectedDomainIds = state =>
  state.selectedWeb3Domains?.selectedDomainIds;
export const selectSelectedDomains = state =>
  state.selectedWeb3Domains?.selectedDomains;

export const selectIsNoneSelected = state =>
  state.selectedWeb3Domains?.selectedDomains.length === 0;
export const selectIsOneSelected = state =>
  state.selectedWeb3Domains?.selectedDomains.length === 1;
export const selectIsAnySelected = state =>
  state.selectedWeb3Domains?.selectedDomains.length > 0;
export const selectIsMultipleSelected = state =>
  state.selectedWeb3Domains?.selectedDomains.length > 1;

export const selectIsTransferredToCustomerWallet = state =>
  state.selectedWeb3Domains?.selectedDomains.every(
    domain => domain.web3Status === 'TRANSFERRED_TO_CUSTOMER_WALLET'
  );
