import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectCustomerName } from '../../../../common/store/customers-slice';
import { selectReseller } from '../../../../common/store/reseller';
import { useUpdateDomainInCartMutation } from '../../register-domains-api-slice';
import { getSelectableYears } from '../../register-domains-utils';

export const YearsCell = ({ params }) => {
  const [updateDomainInCart] = useUpdateDomainInCartMutation();
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const tldPhaseName = params.tldPhaseName;
  const selectableYears = getSelectableYears({ params, tldPhaseName });

  const handleYearsSelect = (domainToUpdate, event) => {
    const updatedDomainBody = {
      ...domainToUpdate,
      years: event.target.value
    };
    updateDomainInCart({
      customerName,
      domainId: domainToUpdate.id,
      reseller,
      updatedDomainBody
    });
  };

  return (
    <Select
      onChange={event => handleYearsSelect(params, event)}
      size="small"
      value={params.years}
    >
      {selectableYears.map(year => (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      ))}
    </Select>
  );
};
