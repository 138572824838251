import { Stack } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';

import { ContactSetsAutocomplete } from '../../common/components/contact-sets/ContactSetsAutocomplete';
import { ContactSetsDialog } from '../../common/components/contact-sets/ContactSetsDialog';
import { ContactSetsActions } from './ContactSetsActions';
import { ContactSetsDialogTrigger } from './ContactSetsDialogTrigger';
import { updateById } from './transfer-slice';

export const ContactSetsCell = ({ domainName, id, value = null }) => {
  const dispatch = useDispatch();

  const handleApplyContactSet = value =>
    dispatch(
      updateById({
        changes: [{ key: 'customerContactSetId', value }],
        id
      })
    );

  return (
    <Stack alignItems="center" direction="row" width="100%">
      <ContactSetsAutocomplete
        contactSetId={value}
        handleApplyContactSet={handleApplyContactSet}
      />
      <ContactSetsDialog
        contactSetId={value}
        domains={[domainName]}
        handleApplyContactSet={handleApplyContactSet}
        renderActions={props => <ContactSetsActions {...props} />}
        renderTrigger={props => <ContactSetsDialogTrigger {...props} />}
      />
    </Stack>
  );
};

export const ContactSetsCellMemoized = React.memo(ContactSetsCell);
