import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectCustomerName } from '../../../../common/store/customers-slice';
import { selectReseller } from '../../../../common/store/reseller';
import { useUpdateDomainInCartMutation } from '../../register-domains-api-slice';
import { getSelectableYears } from '../../register-domains-utils';

export const PhaseCell = ({ params }) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const [updateDomainInCart] = useUpdateDomainInCartMutation();

  const handlePhaseSelect = (domainToUpdate, event) => {
    const tldPhaseId = domainToUpdate.$tldObject.allPhases.find(
      phase => phase.name === event.target.value
    ).id;
    // prevents the case where the new phase doesn't support the years selected in previous phase
    const selectableYears = getSelectableYears({
      params: domainToUpdate,
      tldPhaseName: event.target.value
    });
    const years = selectableYears.includes(domainToUpdate.years)
      ? domainToUpdate.years
      : selectableYears[0];
    const updatedDomainBody = {
      ...domainToUpdate,
      tldPhaseId,
      tldPhaseName: event.target.value,
      years
    };
    updateDomainInCart({
      customerName,
      domainId: domainToUpdate.id,
      reseller,
      updatedDomainBody
    });
  };

  const selectableKeyList = Object.keys(params.$tldObject.$prices).filter(
    key => key !== 'Pre-Sunrise'
  );
  return selectableKeyList.length === 1 ? (
    <>{`${params.tldPhaseName}`}</>
  ) : (
    <Select
      onChange={event => handlePhaseSelect(params, event)}
      size="small"
      style={{ minWidth: 110 }}
      value={params.tldPhaseName}
    >
      {selectableKeyList.map(key => (
        <MenuItem key={key} value={key}>
          {key}
        </MenuItem>
      ))}
    </Select>
  );
};
