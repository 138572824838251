import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';

import { LoginLayout } from '../../features/auth/LoginLayout';
import { RequireAuth } from '../../features/auth/RequireAuth';
import {
  selectAccessToken,
  selectHasLoggedOut
} from '../../features/auth/auth-slice';
import { AuthenticatedRoutes } from './AuthenticatedRoutes';
import { HandleLinkFromEmail } from './HandleLinkFromEmail';
import { HandleTransferToken } from './HandleTransferToken';
import { Layout } from './Layout';

const Login = lazy(() => import('../../features/auth/Login'));
const PasswordReset = lazy(
  () => import('../../features/forgot-password/PasswordReset')
);
const Clear = lazy(() => import('../../features/auth/Clear'));
const CookiePolicy = lazy(
  () => import('../../features/cookie-policy/CookiePolicy')
);
const ForceError = lazy(() => import('./force-error/ForceError'));

export const BaseRoutes = () => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const transferTokenParam = searchParams.get('transferToken');
  const isLinkFromEmail = pathname.startsWith('/e/');
  const accessToken = useSelector(selectAccessToken);
  const hasAccessToken = typeof accessToken === 'string';
  const hasLoggedOut = useSelector(selectHasLoggedOut);
  const shouldHandleLinkFromEmail =
    isLinkFromEmail && !hasAccessToken && !hasLoggedOut;

  if (transferTokenParam) {
    return <HandleTransferToken />;
  } else if (shouldHandleLinkFromEmail) {
    return <HandleLinkFromEmail />;
  } else {
    return (
      <Routes>
        <Route element={<CookiePolicy />} path="/cookie-policy" />
        <Route element={<LoginLayout />} path="/login">
          <Route element={<Login />} index />
        </Route>
        <Route
          element={<LoginLayout />}
          path="/reset-password/:reseller/:userEmail/:code"
        >
          <Route element={<PasswordReset />} index />
        </Route>
        <Route element={<Clear />} path="/clear" />
        <Route element={<RequireAuth />}>
          <Route element={<Layout />} path="*">
            <Route element={<AuthenticatedRoutes />} path="*"></Route>
          </Route>
        </Route>
        <Route element={<ForceError />} path="/force-error" />
      </Routes>
    );
  }
};
