import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectCustomerName } from '../../../../common/store/customers-slice';
import { selectReseller } from '../../../../common/store/reseller';
import { formatPrice } from '../../../../common/utils/number-price-text';
import { useRemoveDomainFromCartMutation } from '../../register-domains-api-slice';
import { selectCart } from '../../register-domains-slice';
import { DomainCell } from '../DomainCell';
import { PhaseCell } from './PhaseCell';
import { YearsCell } from './YearsCell';

export const RegisterDomainsOverview = () => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const cart = useSelector(selectCart);
  const [removeDomainFromCart] = useRemoveDomainFromCartMutation();

  const handleRemoveDomain = domainToRemove => {
    removeDomainFromCart({
      customerName,
      domainId: domainToRemove.id,
      reseller
    });
  };

  const handleRemoveAllDomains = () => {
    cart.forEach(domain => handleRemoveDomain(domain));
  };

  const columnDefaultProps = {
    disableColumnMenu: true,
    filterable: false
  };

  const columns = useMemo(
    () => [
      {
        ...columnDefaultProps,
        field: 'domainName',
        flex: 1,
        headerName: 'Domain',
        renderCell: ({ row }) => <DomainCell params={row} />,
        sortable: true
      },
      {
        ...columnDefaultProps,
        field: 'tldPhaseName',
        flex: 1.5,
        headerName: 'Phase',
        renderCell: ({ row }) => <PhaseCell params={row} />,
        sortable: true
      },
      {
        ...columnDefaultProps,
        field: '$registration',
        flex: 1,
        headerName: 'Registration',
        sortable: false
      },
      {
        ...columnDefaultProps,
        field: '$service',
        flex: 1,
        headerName: 'Service',
        sortable: false
      },
      {
        ...columnDefaultProps,
        field: '$statusFormatted',
        flex: 1,
        headerName: 'Status',
        sortable: true
      },
      {
        ...columnDefaultProps,
        align: 'center',
        field: 'years',
        flex: 1,
        headerAlign: 'center',
        headerName: 'Years',
        renderCell: ({ row }) => <YearsCell params={row} />
      },
      {
        ...columnDefaultProps,
        field: 'registrationPrice',
        flex: 1,
        headerName: 'Price',
        renderCell: ({ row }) => `$${formatPrice(row.registrationPrice)}`,
        sortable: true
      },
      {
        ...columnDefaultProps,
        align: 'center',
        field: 'delete',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <IconButton onClick={() => handleRemoveDomain(row)}>
            <FontAwesomeIcon icon={faTrashCan} size="sm" />
          </IconButton>
        ),
        renderHeader: () => (
          <Button onClick={handleRemoveAllDomains} variant="outlined">
            Remove all
          </Button>
        ),
        sortable: false,
        width: 160
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cart]
  );

  return (
    <Paper sx={{ height: '90%' }} variant="outlined">
      <Box sx={{ height: 'calc(100% - 60px)', padding: 2 }}>
        <Stack direction="column" justifyContent="space-between" mb={2}>
          <Typography variant="h5">Order Summary</Typography>
          <Typography variant="body2">{`${cart.length} Domains`}</Typography>
        </Stack>

        <Box
          sx={{ display: 'flex', height: 'calc(100% - 64px)', marginTop: 2 }}
        >
          <DataGridPro
            columns={columns}
            disableRowSelectionOnClick={true}
            disableVirtualization={import.meta.env.MODE === 'test'}
            getRowId={row => row.domainName ?? row.domain}
            hideFooter
            rowCount={cart?.length}
            rows={cart}
            sx={{
              backgroundColor: 'white',
              height: '100%'
            }}
          />
        </Box>
      </Box>
    </Paper>
  );
};
