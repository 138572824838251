import { Button, Menu, MenuItem } from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectCustomerName } from '../../../../common/store/customers-slice';
import { selectReseller } from '../../../../common/store/reseller';
import { useUpdateDomainInCartMutation } from '../../register-domains-api-slice';

export const BulkUpdateFormButton = ({
  buttonTitle,
  checkedDomains,
  isDisabled,
  objectUpdateKey,
  options
}) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const [updateDomainInCart] = useUpdateDomainInCartMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdate = option => {
    if (!checkedDomains) {
      enqueueSnackbar('Select 1 or more items to perform this action.', {
        variant: 'warning'
      });
    }
    checkedDomains.forEach(domain => {
      const updatedDomainBody = { ...domain };
      updatedDomainBody[objectUpdateKey] = option.id;
      updateDomainInCart({
        customerName,
        domainId: domain.id,
        reseller,
        updatedDomainBody
      });
    });
  };

  const getMenuItems = (options, handleClose) =>
    options.map(option => (
      <MenuItem
        key={option.id}
        onClick={() => {
          handleUpdate(option);
          handleClose();
        }}
      >
        {`${option.label}`}
      </MenuItem>
    ));

  return (
    <PopupState popupId="bulk-apply" variant="popover">
      {popupState => (
        <React.Fragment>
          <Button
            sx={{ mr: 2 }}
            {...bindTrigger(popupState)}
            disabled={isDisabled || !checkedDomains.length}
          >
            {`${buttonTitle}`}
          </Button>
          <Menu {...bindMenu(popupState)} onClose={popupState.close}>
            {options?.length && getMenuItems(options, popupState.close)}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};
