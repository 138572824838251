import React from 'react';

import { DotAtForm } from './DotAtForm';
import { DotCnForm } from './DotCnForm';
import { DotDeForm } from './DotDeForm';
import { DotDkForm } from './DotDkForm';
import { DotEsForm } from './DotEsForm';
import { DotFiForm } from './DotFiForm';
import { DotHkForm } from './DotHkForm';
import { DotItForm } from './DotItForm';
import { DotJobsForm } from './DotJobsForm';
import { DotJpForm } from './DotJpForm';
import { DotLtForm } from './DotLtForm';
import { DotLuForm } from './DotLuForm';
import { DotLvForm } from './DotLvForm';
import { DotNlForm } from './DotNlForm';
import { DotNoForm } from './DotNoForm';
import { DotNycForm } from './DotNycForm';
import { DotPlForm } from './DotPlForm';
import { DotSiForm } from './DotSiForm';
import { DotUsNexusForm } from './DotUsNexusForm';

export const getTldForm = (specialRequirements, selectedTld, tldsData) => {
  const tldFormsObject = Object.freeze({
    at: <DotAtForm specialRequirements={specialRequirements[selectedTld]} />,
    cn: <DotCnForm specialRequirements={specialRequirements[selectedTld]} />,
    de: <DotDeForm specialRequirements={specialRequirements[selectedTld]} />,
    dk: <DotDkForm specialRequirements={specialRequirements[selectedTld]} />,
    es: <DotEsForm specialRequirements={specialRequirements[selectedTld]} />,
    fi: <DotFiForm specialRequirements={specialRequirements[selectedTld]} />,
    hk: <DotHkForm specialRequirements={specialRequirements[selectedTld]} />,
    it: <DotItForm specialRequirements={specialRequirements[selectedTld]} />,
    jobs: (
      <DotJobsForm specialRequirements={specialRequirements[selectedTld]} />
    ),
    jp: <DotJpForm specialRequirements={specialRequirements[selectedTld]} />,
    lt: <DotLtForm specialRequirements={specialRequirements[selectedTld]} />,
    lu: <DotLuForm specialRequirements={specialRequirements[selectedTld]} />,
    lv: <DotLvForm specialRequirements={specialRequirements[selectedTld]} />,
    nl: <DotNlForm specialRequirements={specialRequirements[selectedTld]} />,
    no: <DotNoForm specialRequirements={specialRequirements[selectedTld]} />,
    nyc: <DotNycForm specialRequirements={specialRequirements[selectedTld]} />,
    pl: <DotPlForm specialRequirements={specialRequirements[selectedTld]} />,
    si: <DotSiForm specialRequirements={specialRequirements[selectedTld]} />,
    us: (
      <DotUsNexusForm specialRequirements={specialRequirements[selectedTld]} />
    )
  });

  const selectedTldData = tldsData?.find(tld => tld.name === selectedTld);
  if (!selectedTld || !selectedTldData) {
    return null;
  }

  if (Object.keys(tldFormsObject).includes(selectedTldData.name)) {
    return tldFormsObject[selectedTldData.name];
  } else {
    return tldFormsObject[selectedTldData.parentName];
  }
};
