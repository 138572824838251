import { createSlice } from '@reduxjs/toolkit';

import { getDatesApiFilters } from '../../common/utils/get-dates-api-filters';
import { getLabelsApiFilters } from '../../common/utils/get-labels-api-filters';
import { getSearchStringApiFilters } from '../../common/utils/get-search-string-api-filters';

const initialState = {
  apiFilters: {},
  automationData: [
    { id: 'MANUAL', label: 'Manual' },
    { id: 'AUTOMATED', label: 'Automated' }
  ],
  filterModel: {}
};

/**
 *
 * @param {Object} params
 * @param {Object} params.newFilterModel
 * @param {Array} params.nameServersData
 * @returns {Array}
 */
const getNameServersApiFilters = ({ nameServersData, newFilterModel }) => {
  let response;
  const matchingFilter = newFilterModel.items.find(
    item => item.field === 'nameServerInfos'
  );
  const matchingIds = matchingFilter?.value?.map(nameServer => nameServer.id);

  if (matchingFilter?.value && matchingFilter.operator === 'isAnyOf') {
    response = matchingFilter?.value;
  } else if (matchingFilter?.value && matchingFilter.operator === 'not') {
    response = nameServersData.filter(
      nameServerValue => !matchingIds.includes(nameServerValue.id)
    );
  } else {
    response = [];
  }

  return response.map(({ name, value }) => ({
    name,
    value
  }));
};

/**
 *
 * @param {Object} params
 * @param {Object} params.newFilterModel
 * @returns {String|undefined}
 */
const getAutomationApiFilters = ({ newFilterModel }) => {
  const matchingManualFilterId = newFilterModel.items.find(
    item => item.field === 'manual' && item?.value?.id === 'MANUAL'
  )?.value?.id;
  const matchingAutomatedFilterId = newFilterModel.items.find(
    item => item.field === 'manual' && item?.value?.id === 'AUTOMATED'
  )?.value?.id;

  // If we have both set we want to return undefined, otherwise return an individual
  // value if we have one.
  return matchingManualFilterId && matchingAutomatedFilterId
    ? undefined
    : matchingManualFilterId || matchingAutomatedFilterId;
};

const domainFiltersSlice = createSlice({
  initialState,
  name: 'domainFilters',
  reducers: {
    updateFilters: (state, { payload }) => {
      const { nameServersData, newFilterModel } = payload;
      const { fromDate, toDate } = getDatesApiFilters(
        newFilterModel,
        'addedDate'
      );

      state.apiFilters = {
        automationType: getAutomationApiFilters({
          newFilterModel
        }),
        find: getSearchStringApiFilters({ newFilterModel }),
        fromDate,
        labelIds: getLabelsApiFilters({ newFilterModel }),
        nameServers: getNameServersApiFilters({
          nameServersData,
          newFilterModel
        }),
        toDate
      };
    }
  }
});

export const domainFiltersReducer = domainFiltersSlice.reducer;

export const { updateFilters } = domainFiltersSlice.actions;
export const selectApiFilters = state => state.domainFilters.apiFilters;
export const selectAutomationData = state => state.domainFilters.automationData;
