import { getTldNameForDomain } from '../../../../common/utils/get-tld-name-for-domain';

export const specialRequirements = [
  { component: 'SetDotAeroSpecialRequirements', isValid: false, tld: 'aero' },
  { component: 'SetDotDeSpecialRequirements', isValid: false, tld: 'de' },
  { component: 'SetIntendedUseRequirements', isValid: false, tld: 'barcelona' },
  { component: 'SetIntendedUseRequirements', isValid: false, tld: 'eus' },
  { component: 'SetIntendedUseRequirements', isValid: false, tld: 'gal' },
  { component: 'SetDotNoSpecialRequirements', isValid: false, tld: 'no' },
  {
    component: 'SetDotPharmacySpecialRequirements',
    isValid: false,
    tld: 'pharmacy'
  },
  { component: 'SetIntendedUseRequirements', isValid: false, tld: 'scot' },
  { component: 'SetDotTelSpecialRequirements', isValid: false, tld: 'tel' },
  { component: 'SetIntendedUseRequirements', isValid: false, tld: 'quebec' },
  { component: 'SetDotCoopSpecialRequirements', isValid: false, tld: 'coop' },
  { component: 'SetSslAcknowledgements', isValid: false, tld: 'app' },
  { component: 'SetSslAcknowledgements', isValid: false, tld: 'dev' },
  { component: 'SetSslAcknowledgements', isValid: false, tld: 'page' },
  { component: 'SetSslAcknowledgements', isValid: false, tld: 'appdetex-eap' },
  {
    component: 'SetSslAcknowledgements',
    isValid: false,
    tld: 'appdetex-sunrise'
  },
  { component: 'SetDotNewSpecialRequirements', isValid: false, tld: 'new' },
  { component: 'SetCheckoutMessage', isValid: false, tld: 'gay' },
  { component: 'SetCheckoutMessage', isValid: false, tld: 'dog' }
];

export const delayedRegistrationValues = [
  'SUNRISE',
  'Sunrise',
  'PRE_SUNRISE',
  'Pre-Sunrise'
];

export const getSpecialRequirements = domain => {
  const tldPhase = domain.tldPhaseName ?? domain.$tldObject.phase;
  if (delayedRegistrationValues.includes(tldPhase)) {
    return { component: 'SetSignMarkedData', isValid: false };
  }
  const tld = getTldNameForDomain(domain.domainName ?? domain.domain);
  return specialRequirements.find(
    specialRequirement => specialRequirement.tld === tld
  );
};
