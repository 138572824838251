import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { gridColumnWidths } from '../../common/utils/grid-column-widths';

export const ZoneViewRecordSetsCell = ({ params }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: gridColumnWidths.icon.width
      }}
    >
      <IconButton
        onClick={() => navigate(`details/${params.row.id}`)}
        size="small"
      >
        <FontAwesomeIcon icon={faEye} />
      </IconButton>
    </Box>
  );
};
