import { isPlainObject } from 'lodash-es';

const CUSTOMER_INFO_KEY = 'adxStoredCustomer';

/**
 * Set customer in sessionStorage. Should only be called as a side effect
 * to this value changing in redux.
 * @param {Object} customer
 */
export const setStoredCustomer = customer => {
  if (isPlainObject(customer)) {
    sessionStorage.setItem(CUSTOMER_INFO_KEY, JSON.stringify(customer));
  }
};

export const clearStoredCustomer = () => {
  sessionStorage.removeItem(CUSTOMER_INFO_KEY);
};

/**
 * Get stored customer. Should only be called by Redux to get initial state.
 * @returns {Object} stored customer
 */
export const getStoredCustomer = () => {
  if (sessionStorage.getItem(CUSTOMER_INFO_KEY)) {
    try {
      return JSON.parse(sessionStorage.getItem(CUSTOMER_INFO_KEY));
    } catch {
      throw new Error('Unable to parse customer info');
    }
  }
};
