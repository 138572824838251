import { TextField } from '@mui/material';
import React from 'react';

export const DotLtForm = ({ specialRequirements }) => (
  <TextField
    disabled
    label="Organization Code"
    value={specialRequirements.DOTLT.orgCode}
  />
);
