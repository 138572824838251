import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { getFirstTransferStepPath } from '../../../features/transfer/transfer-navigation';
import { reset } from '../../../features/transfer/transfer-slice';
import styles from './Header.module.css';

export const TransferButton = () => {
  const dispatch = useDispatch();
  const getSecondaryNavLinkClassNames = ({ isActive }) =>
    isActive
      ? `Header-linkActive ${styles.secondaryLink}`
      : styles.secondaryLink;
  const transferReset = () => {
    dispatch(reset());
  };
  return (
    <NavLink
      className={getSecondaryNavLinkClassNames}
      onClick={transferReset}
      to={getFirstTransferStepPath()}
    >
      <FontAwesomeIcon className={styles.secondaryLinkIcon} icon={faPlus} />
      Transfer
    </NavLink>
  );
};
