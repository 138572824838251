import { Chip } from '@mui/material';
import React from 'react';
import { yiq } from 'yiq';

export const Label = ({ label }) => (
  <Chip
    key={label.id}
    label={label.groupAndName}
    size="small"
    sx={{
      backgroundColor: `#${label.color}`,
      color: yiq(`#${label.color}` ?? '#fff'),
      mx: 0.25
    }}
  />
);
