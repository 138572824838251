import { TextField } from '@mui/material';
import React from 'react';

import { formatForDisplay } from '../../../../utils/number-price-text';

export const DotJpForm = ({ specialRequirements }) => {
  return (
    <>
      <TextField
        disabled
        label="Role Type"
        value={formatForDisplay(specialRequirements.DOTJP.role)}
      />
      <TextField
        disabled
        label="First Name Japanese"
        value={specialRequirements.DOTJP.fnameMl}
      />
      <TextField
        disabled
        label="Last Name Japanese"
        value={specialRequirements.DOTJP.lnameMl}
      />
      <TextField
        disabled
        label="Organization Japanese"
        value={specialRequirements.DOTJP.organizationMl}
      />
      <TextField
        disabled
        label="Street Japanese"
        value={specialRequirements.DOTJP.streetMl}
      />
      <TextField
        disabled
        label="City Japanese"
        value={specialRequirements.DOTJP.cityMl}
      />
    </>
  );
};
