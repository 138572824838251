import { Autocomplete, Grid, Stack, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectCustomerName } from '../../../store/customers-slice';
import { selectReseller } from '../../../store/reseller';
import { useGetTldsPricingByCustomerQuery } from '../../../store/tlds-api-slice';
import {
  selectSelectedTldForSpecialRequirements,
  setSelectedTldForSpecialRequirments
} from '../contact-sets-slice';
import { getTldForm } from './tld-forms/TldForms';

export const ContactSpecialRequirements = ({ specialRequirements }) => {
  const dispatch = useDispatch();
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const selectedTldForSpecialRequirements = useSelector(
    selectSelectedTldForSpecialRequirements
  );
  const tldOptions = Object.keys(specialRequirements);

  const handleTldChange = (event, value) => {
    dispatch(setSelectedTldForSpecialRequirments(value));
  };

  const { tldsData } = useGetTldsPricingByCustomerQuery(
    {
      customerName,
      reseller
    },
    {
      selectFromResult: ({ data }) => ({
        tldsData: data?.filter(
          tld => Object.keys(tld.specialRequirements).length !== 0
        )
      }),
      skip: !customerName
    }
  );

  const haveSpecialRequirementsForSelectedTld = Object.keys(
    specialRequirements
  ).includes(selectedTldForSpecialRequirements);

  //Resets input when changing contacts and contact doesnt have that special requirement
  useEffect(() => {
    if (
      selectedTldForSpecialRequirements !== null &&
      !haveSpecialRequirementsForSelectedTld
    ) {
      dispatch(setSelectedTldForSpecialRequirments(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    haveSpecialRequirementsForSelectedTld,
    selectedTldForSpecialRequirements
  ]);

  const tldForm = getTldForm(
    specialRequirements,
    selectedTldForSpecialRequirements,
    tldsData
  );

  return (
    <>
      <Grid columnGap={2} container direction="row" rowSpacing={2}>
        <Grid item xs={5}>
          <Autocomplete
            disableClearable
            onChange={handleTldChange}
            options={tldOptions ?? []}
            renderInput={params => (
              <TextField
                {...params}
                label="Special requirement for TLDs:"
                variant="outlined"
              />
            )}
            size="small"
            value={selectedTldForSpecialRequirements}
          />
        </Grid>
        <Grid item xs={4}>
          <Stack direction="column" spacing={2}>
            {haveSpecialRequirementsForSelectedTld && tldForm}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
