import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import { noop } from 'lodash-es';
import {
  bindDialog,
  bindToggle,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectCustomerName } from '../../../../common/store/customers-slice';
import { selectReseller } from '../../../../common/store/reseller';
import {
  useCheckRevokedSmdFilesQuery,
  useGetSmdFilesQuery
} from '../../../../common/store/smd-api-slice';
import {
  selectSpecialRequirementsValidityById,
  setSpecialRequirementsValidity,
  updateById
} from '../../register-domains-slice';
import { ValidOrNotMemoized } from './ValidOrNotIcon';

export const SetSmdSpecialRequirement = ({ domain, domainName, id }) => {
  const dispatch = useDispatch();
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const { data: smdFiles } = useGetSmdFilesQuery(
    {
      customerName,
      reseller
    },
    {
      skip: !customerName
    }
  );

  const { data: revokedSmdFiles } = useCheckRevokedSmdFilesQuery(
    {
      customerName,
      reseller
    },
    {
      skip: !customerName
    }
  );

  const [selectedSmdFile, setSelectedSmdFile] = useState(
    smdFiles?.find(file => file.id === domain?.smdFileId)
  );
  const [selectedFileIsRevoked, setSelectedFileIsRevoked] = useState(false);
  const dialogState = usePopupState({
    popupId: 'setSmdDialog',
    variant: 'dialog'
  });

  const isValid = useSelector(state =>
    selectSpecialRequirementsValidityById(state, id)
  );

  const handleClose = noop;

  const handleSubmit = event => {
    event.preventDefault();
    if (selectedSmdFile) {
      dispatch(
        updateById({
          changes: [
            {
              key: 'smdFileId',
              value: selectedSmdFile.id
            }
          ],
          id
        })
      );
    }

    dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }));
    dialogState.close();
  };

  const handleChange = (event, value) => {
    event.preventDefault();
    setSelectedSmdFile(value);
    if (revokedSmdFiles.some(smdFile => smdFile.id === value.id)) {
      setSelectedFileIsRevoked(true);
    } else {
      setSelectedFileIsRevoked(false);
    }
  };

  useEffect(() => {
    if (domain.smdFileId && !isValid) {
      dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, isValid]);

  return (
    smdFiles && (
      <>
        <Stack alignItems="center" direction="row" spacing={1}>
          <Button size="small" {...bindTrigger(dialogState)}>
            Signed marked data
          </Button>
          <ValidOrNotMemoized isValid={isValid} />
        </Stack>
        <Dialog {...bindDialog(dialogState)} maxWidth="xs">
          <form noValidate={true} onSubmit={handleSubmit}>
            <DialogTitle>Set Special Requirements for {domainName}</DialogTitle>
            <DialogContent>
              {selectedFileIsRevoked && (
                <Alert severity="error">
                  This SMD file has been revoked by TMCH. Please select a
                  different file.
                </Alert>
              )}
              <Autocomplete
                disableClearable
                getOptionLabel={option => option.label}
                onChange={handleChange}
                options={smdFiles}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={'Signed Mark Data'}
                    variant="outlined"
                  />
                )}
                size="small"
                sx={{ pt: 2, width: '100%' }}
                value={selectedSmdFile ?? null}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} {...bindToggle(dialogState)}>
                Cancel
              </Button>
              <Button
                disabled={selectedFileIsRevoked}
                onClick={handleSubmit}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </>
    )
  );
};

export const SetSmdSpecialRequirementMemoized = React.memo(
  SetSmdSpecialRequirement
);
