import dayjs from 'dayjs';
import { noop } from 'lodash-es';
import { boolean, mixed, object, string } from 'yup';

const defaultFieldSchema = {
  errorMessage: string().default(''),
  hasError: boolean().default(false),
  touched: boolean().default(false)
};

/**
 * formSchemaMultiple
 * @param {Function} handleDateValidation
 * @returns
 */
export const formSchemaMultiple = ({ handleDateValidation = noop }) =>
  object({
    currency: object({
      ...defaultFieldSchema,
      value: object().default(null)
    }),
    formState: object({
      isTouched: boolean().default(false)
    }),
    registryCost: object({
      ...defaultFieldSchema,
      value: mixed().default('')
    }),
    startDate: object({
      ...defaultFieldSchema,
      value: mixed()
        .test('is-valid-date', handleDateValidation, value => {
          return dayjs(value).isValid();
        })
        .default(null)
    })
  });
