import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { SignOffDialog } from '../../common/components/sign-offs/SignOffDialog';
import {
  entityTypes,
  useJobResponse
} from '../../common/hooks/use-job-response';
import { ZONE_MANAGEMENT_TAG } from '../../common/store/api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import { useCreateOrderMutation } from '../../common/store/orders-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { generateId } from '../../common/utils/generate-id';
import { selectClientId } from '../auth/auth-slice';
import { selectSelectedZones } from './selected-zone-slice';

export const ZoneAddConfirmButton = ({
  disabled,
  formData,
  formDataDispatch,
  handleCancel,
  isEdit,
  popupState,
  setValidationErrors,
  validateForm
}) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const clientId = useSelector(selectClientId);
  const selectedZone = useSelector(selectSelectedZones)[0];

  const [needsForceUpdate, setNeedsForceUpdate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const clientLineItemIds = useRef([generateId()]);
  const lineItems = useRef([]);

  const shouldStartPolling = useRef(false);

  const { enqueueSnackbar } = useSnackbar();

  const orderOptions = {
    currency: 'USD',
    paymentType: 'NONE'
  };

  const [
    createOrder,
    {
      data: orderCreationResponse,
      isError: isCreateOrderError,
      isSuccess: isCreateOrderSuccess
    }
  ] = useCreateOrderMutation();

  useJobResponse({
    entityType: entityTypes.zone,
    failureMessage: `Failed to ${
      isEdit ? 'apply changes to' : 'create'
    } the zone.`,
    lineItems: lineItems.current,
    onComplete: () => {
      formDataDispatch({
        type: 'INIT',
        value: isEdit ? selectedZone : undefined
      });

      setIsSubmitting(false);

      popupState.close();
    },
    shouldStartPolling: shouldStartPolling.current,
    skipCondition: !isCreateOrderSuccess,
    successMessage: `Successfully ${
      isEdit ? 'applied changes to zone' : 'created new zone'
    }`,
    tagsToInvalidate: [ZONE_MANAGEMENT_TAG]
  });

  useEffect(() => {
    if (isCreateOrderSuccess && orderCreationResponse) {
      const { forceUpdateRequired, signOffRequired } = orderCreationResponse;

      if (signOffRequired && forceUpdateRequired) {
        // prompt for re-sending order request
        setNeedsForceUpdate(true);
      } else if (signOffRequired) {
        // sign off required toast
        handleSignOff();
      } else {
        shouldStartPolling.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOrderSuccess, orderCreationResponse]);

  useEffect(() => {
    if (isCreateOrderError) {
      enqueueSnackbar(
        `An error occurred while trying to ${
          isEdit ? 'update' : 'add'
        } the zone.`,
        { variant: 'error' }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOrderError]);

  const appendRootToDnsNameIfMissing = dnsName =>
    dnsName.endsWith('.') === false ? dnsName + '.' : dnsName;

  const handleSignOff = () => {
    enqueueSnackbar(
      `Your ${isEdit ? 'edit' : 'add'} request is now pending sign-off`,
      {
        variant: 'info'
      }
    );

    formDataDispatch({
      type: 'INIT',
      value: isEdit ? selectedZone : undefined
    });

    setIsSubmitting(false);

    popupState.close();
  };

  const handleZoneAddSubmit = ({ forceUpdate = false }) => {
    setIsSubmitting(true);

    validateForm()
      .then(() => {
        const zoneDataFromForm = Object.keys(formData).reduce(
          (reducingZoneData, formDataKey) => {
            reducingZoneData[formDataKey] = formData[formDataKey].value;

            return reducingZoneData;
          },
          {}
        );

        lineItems.current = [
          {
            clientLineItemId: clientLineItemIds.current[0],
            customerZoneId: isEdit ? selectedZone.id : undefined,
            description: zoneDataFromForm.description,
            dnsName: appendRootToDnsNameIfMissing(zoneDataFromForm.dnsName),
            lineItemNum: 0,
            lineItemType: isEdit ? 'ZONE_UPDATE' : 'ZONE_CREATE',
            serviceProviderName: 'GOOGLE_CLOUD_DNS', // hard-coding this value for now, might never change.
            zoneName: zoneDataFromForm.zoneName
          }
        ];

        createOrder({
          clientId,
          customerName,
          lineItems: lineItems.current,
          orderOptions,
          reseller,
          signOffForceUpdate: forceUpdate
        });
      })
      .catch(() => {
        setIsSubmitting(false);

        setValidationErrors(true);
      });
  };

  const handleForceUpdate = () => handleZoneAddSubmit({ forceUpdate: true });

  return (
    <>
      <LoadingButton
        disabled={disabled || isSubmitting}
        loading={isSubmitting}
        onClick={handleZoneAddSubmit}
        variant="contained"
      >
        Submit
      </LoadingButton>
      <SignOffDialog
        handleClose={handleCancel}
        handleSubmit={handleForceUpdate}
        isOpen={needsForceUpdate}
      />
    </>
  );
};
