import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetCustomersQuery } from '../../common/store/customers-api-slice';
import {
  selectActiveCustomer,
  selectPendingCustomer,
  setIsDialogOpen,
  setPendingActiveCustomer
} from '../../common/store/customers-slice';
import { selectReseller } from '../../common/store/reseller';

export const CustomerDropdown = ({ isInHeader = false }) => {
  const dispatch = useDispatch();
  const activeCustomer = useSelector(selectActiveCustomer);
  const pendingActiveCustomer = useSelector(selectPendingCustomer);
  const reseller = useSelector(selectReseller);
  const { data: customers = [], isLoading: isCustomersDataLoading } =
    useGetCustomersQuery({ reseller });

  useEffect(() => {
    if (customers?.length) {
      dispatch(setPendingActiveCustomer(activeCustomer ?? customers[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  const handleCustomerChange = (event, value) => {
    dispatch(
      setPendingActiveCustomer(
        customers.find(customer => customer.label === value.label)
      )
    );
    if (isInHeader) {
      dispatch(setIsDialogOpen(true));
    }
  };

  return (
    <Autocomplete
      disableClearable={true}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      loading={isCustomersDataLoading}
      onChange={handleCustomerChange}
      options={customers}
      renderInput={params => (
        <TextField
          {...params}
          sx={{ '& .MuiOutlinedInput-root': { backgroundColor: '#fff' } }}
          variant="outlined"
        />
      )}
      role="menu"
      sx={{ width: '240px' }}
      value={pendingActiveCustomer ?? customers[0] ?? null}
    />
  );
};
