import { isPlainObject } from 'lodash-es';

const USER_INFO_KEY = 'adxStoredUser';

export const setStoredUser = user => {
  if (isPlainObject(user)) {
    sessionStorage.setItem(USER_INFO_KEY, JSON.stringify(user));
  }
};

export const getStoredUser = () => {
  if (sessionStorage.getItem(USER_INFO_KEY)) {
    try {
      return JSON.parse(sessionStorage.getItem(USER_INFO_KEY));
    } catch {
      throw new Error('Unable to parse user info');
    }
  }
};

export const clearStoredUser = () => {
  sessionStorage.removeItem(USER_INFO_KEY);
};
