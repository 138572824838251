export const getCartItemValidityObject = ({
  cartItem,
  contactSetIsValid,
  nameServerGroupIsValid
}) => {
  const dnssecIsValid =
    !cartItem.$tldObject.dnssecRequired ||
    (cartItem.$tldObject.dnssecRequired && !!cartItem.digest);

  const specialRequirementsAreValid =
    !cartItem.specialRequirements || cartItem.specialRequirements?.isValid;

  const contactSetIsValidOrLocalPresenceSelected =
    cartItem.localPresence || contactSetIsValid;

  const isValid =
    nameServerGroupIsValid &&
    contactSetIsValidOrLocalPresenceSelected &&
    specialRequirementsAreValid &&
    dnssecIsValid;

  return { domainName: cartItem.domainName, isValid };
};
