import dayjs from 'dayjs';
import { noop } from 'lodash-es';
import { boolean, mixed, object, string } from 'yup';

const VERSION_NUMBER_REGEX = new RegExp('^[0-9]+\\.[0-9]+$');
const ACCEPTANCE_NAME_REGEX = new RegExp('^[\\w]+\\ [\\w]+$');

const defaultFieldSchema = {
  errorMessage: string().default(''),
  hasError: boolean().default(false),
  touched: boolean().default(false)
};

/**
 * formSchema
 * @param {Function} handleRequiredValidation
 * @param {Function} handleDateValidation
 * @param {Function} handleVersionNumberMatch
 * @param {Function} handleAcceptanceNameMatch
 * @returns
 */
export const formSchema = ({
  handleAcceptanceNameMatch = noop,
  handleDateValidation = noop,
  handleRequiredValidation = noop,
  handleVersionNumberMatch = noop
}) =>
  object({
    acceptanceDate: object({
      ...defaultFieldSchema,
      value: mixed()
        .test('is-valid-date', handleDateValidation, value => {
          return dayjs(value).isValid();
        })
        .required(handleRequiredValidation)
        .default(() => dayjs())
    }),
    acceptanceName: object({
      ...defaultFieldSchema,
      value: string()
        .matches(ACCEPTANCE_NAME_REGEX, handleAcceptanceNameMatch)
        .required(handleRequiredValidation)
        .default('')
    }),
    isInit: boolean().default(false),
    versionNumber: object({
      ...defaultFieldSchema,
      value: string()
        .matches(VERSION_NUMBER_REGEX, handleVersionNumberMatch)
        .required(handleRequiredValidation)
        .default('')
    })
  });
