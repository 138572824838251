import { TextField } from '@mui/material';
import React from 'react';

export const DotUsNexusForm = ({ specialRequirements }) => {
  const nexusCategories = Object.freeze({
    FOREIGN_ORGANIZATION:
      'A foreign entity or organization engaging in business in the US',
    FOREIGN_ORGANIZATION_WITH_US_FACILITY:
      'A foreign entity or organization with an office in the US',
    PERMANENT_RESIDENT: 'US Permanent Resident',
    US_CITIZEN: 'US Citizen',
    US_CORPORATION:
      'An entity or organization incorporated in the US or its territories'
  });

  const nexusAppPurposes = Object.freeze({
    BUSINESS_PROFIT: 'For-profit business',
    EDUCATIONAL: 'Educational use',
    GOVERNMENT: 'Governmental use',
    NON_PROFIT: 'Non-profit business or organization',
    PERSONAL: 'Personal use'
  });

  return (
    <>
      <TextField
        disabled
        label="Registration Category"
        value={nexusCategories[specialRequirements.DOTUS_NEXUS.category]}
      />
      <TextField
        disabled
        label="Registration Purpose"
        value={nexusAppPurposes[specialRequirements.DOTUS_NEXUS.appPurpose]}
      />
    </>
  );
};
