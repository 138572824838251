import { Typography } from '@mui/material';
import React from 'react';

export const AuthCodeValidityMessage = ({ authCodeValidity }) => {
  return (
    authCodeValidity.isError && (
      <div>
        <Typography variant="body2">Auth Code Invalid</Typography>
        <Typography variant="caption">{authCodeValidity.message}</Typography>
      </div>
    )
  );
};
