import { createSlice } from '@reduxjs/toolkit';

const initialState = { selectedTransfers: [], selectedTransfersIds: [] };

const selectedTransfersSlice = createSlice({
  initialState,
  name: 'selectedTransfers',
  reducers: {
    removeSelectedTransfer: (state, { payload }) => {
      // expected payload: id of selected transfers to unselect
      state.selectedTransfersIds = state.selectedTransfersIds.filter(
        transferId => transferId !== payload
      );
      state.selectedTransfers = state.selectedTransfers.filter(
        transfer => transfer.$id !== payload
      );
    },
    setSelectedTransfers: (state, { payload }) => {
      // expected payload: array of selected transfers objects
      state.selectedTransfers = payload;
      state.selectedTransfersIds = payload.map(transfer => transfer.$id);
    }
  }
});

export const selectedTransfersReducer = selectedTransfersSlice.reducer;

export const { removeSelectedTransfer, setSelectedTransfers } =
  selectedTransfersSlice.actions;

export const selectSelectedTransfersIds = state =>
  state.selectedTransfers.selectedTransfersIds;
export const selectSelectedTransfers = state =>
  state.selectedTransfers.selectedTransfers;

export const selectHaveManual = state =>
  state.selectedTransfers.selectedTransfers.some(
    transfer => transfer.$tld?.manual
  );

export const selectIsNoneSelected = state =>
  state.selectedTransfers.selectedTransfers.length === 0;
export const selectIsOneSelected = state =>
  state.selectedTransfers.selectedTransfers.length === 1;
export const selectIsAnySelected = state =>
  state.selectedTransfers.selectedTransfers.length > 0;
export const selectIsMultipleSelected = state =>
  state.selectedTransfers.selectedTransfers.length > 1;

export const selectAllManual = state =>
  state.selectedTransfers.selectedTransfers.length > 0 &&
  state.selectedTransfers.selectedTransfers.every(
    transfer => transfer.$tld?.manual
  );

export const selectHaveAuto = state =>
  state.selectedTransfers.selectedTransfers.some(
    transfer => !transfer.$tld?.manual
  );

export const selectHaveManualAndAuto = state =>
  state.selectedTransfers.selectedTransfers.some(
    transfer => !transfer.$tld?.manual
  ) &&
  state.selectedTransfers.selectedTransfers.some(
    transfer => transfer.$tld?.manual
  );

export const selectAllAuto = state =>
  state.selectedTransfers.selectedTransfers.length > 0 &&
  state.selectedTransfers.selectedTransfers.every(
    transfer => !transfer.$tld?.manual
  );

export const selectCanUpdateRegistrantData = state =>
  state.selectedTransfers.selectedTransfers.length > 0 &&
  state.selectedTransfers.selectedTransfers.every(
    transfer => transfer.canUpdateRegistrantData
  );

export const selectIneligibleSelectedTransfers = state =>
  state?.selectedTransfers?.selectedTransfers?.filter(
    transfer => !transfer.canUpdateRegistrantData
  );
