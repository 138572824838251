import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

export const ContactSetsInput = ({
  applyValue,
  contactSetsData,
  isInForm,
  isLoading,
  item,
  value
}) => {
  const handleValueChange = (event, value) =>
    applyValue({ item: item.domainName, value: value.id });

  return (
    <Autocomplete
      disableClearable
      isOptionEqualToValue={(option, value) => option.id === value.id}
      limitTags={1}
      loading={isLoading}
      onChange={handleValueChange}
      options={contactSetsData ?? []}
      renderInput={params => (
        <TextField
          {...params}
          label={isInForm ? 'Contact Sets' : ''}
          variant="outlined"
        />
      )}
      size="small"
      sx={{ width: '100%' }}
      value={value ?? null}
    />
  );
};
