/**
 * Creates and clicks a download link in the document
 */
export const downloadFile = (filename: string, data: string) => {
  const a = document.createElement('a');
  a.download = filename;
  a.href = data;
  a.click();
  window.URL.revokeObjectURL(data);
};
