import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

export const TransferLoading = () => (
  <Backdrop
    open={true}
    sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);
