import {
  Checkbox,
  FormControlLabel,
  Link,
  List,
  ListItem,
  Paper,
  Typography
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAppInfoQuery } from '../../common/store/app-info-api-slice';
import { selectOrganizationMainContactName } from '../../common/store/customers-slice';
import { Error } from './Error';
import { Footer } from './Footer';
import { InitiateTransferButton } from './InitiateTransferButton';
import styles from './InitiateTransferStep.module.css';
import { Loading } from './Loading';
import { selectUserAgrees, setUserAgrees } from './transfer-slice';

export const InitiateTransferStep = () => {
  const dispatch = useDispatch();
  const organizationMainContactName = useSelector(
    selectOrganizationMainContactName
  );
  const userAgrees = useSelector(selectUserAgrees);

  const handleUserAgreesClick = event =>
    dispatch(setUserAgrees(event.target.checked));

  const {
    data: appInfoData,
    isError: isAppInfoError,
    isLoading: isAppInfoLoading,
    isSuccess: isAppInfoSuccess
  } = useAppInfoQuery();

  return (
    <>
      {isAppInfoError && <Error />}
      {isAppInfoLoading && <Loading />}
      {isAppInfoSuccess && (
        <>
          <Paper sx={{ height: '100%', p: 2 }} variant="outlined">
            <Typography gutterBottom={true} sx={{ mb: 3 }} variant="h5">
              Initiate Transfer
            </Typography>
            <Typography variant="h6">
              1. Make sure you <b>unlock</b> the domains you are transferring
            </Typography>
            <Typography variant="h6">
              2. Initiate the transfer process by clicking the button below
            </Typography>
            <Typography variant="h6">
              3. Check your domain administrator email address for further
              instructions <small>(Approx. 5 minutes to get the mail)</small>
            </Typography>
            <List className={styles.list}>
              <ListItem className={styles.listItem}>
                Once you have entered into the agreement, the transfer will take
                place within five (5) calendar days.
              </ListItem>
              <ListItem className={styles.listItem}>
                If your current registrar accepts the transfer, then the
                transfer will finish within 24 hours.
              </ListItem>
              <ListItem className={styles.listItem}>
                If your current registrar rejects the transfer, then the
                transfer will be cancelled and you will be sent a message.
              </ListItem>
              <ListItem className={styles.listItem}>
                Once a transfer takes place, you will not be able to transfer
                this domain to another registrar for sixty (60) days.
              </ListItem>
            </List>
            <FormControlLabel
              control={
                <Checkbox
                  checked={userAgrees.domainServiceAgreementAccepted}
                  onClick={handleUserAgreesClick}
                />
              }
              label="By clicking here you agree to the following:"
            />
            <List className={styles.list}>
              <ListItem className={styles.listItem}>
                {appInfoData?.$domainServiceAgreementParts?.prefix}&nbsp;
                <Link
                  href={appInfoData?.$domainServiceAgreementParts?.url}
                  rel="noopener"
                  target="_blank"
                >
                  {appInfoData?.$domainServiceAgreementParts?.linkText}
                </Link>
                {appInfoData?.$domainServiceAgreementParts?.suffix}
              </ListItem>
              <ListItem className={styles.listItem}>
                I understand that the selected contact information will be used
                upon successful transfer.
              </ListItem>
              <ListItem className={styles.listItem}>
                I confirm that I wish to proceed with the transfer of the
                selected domain names to {organizationMainContactName}, and that
                I am the owner of these domains.
              </ListItem>
            </List>
            <Typography variant="body1">
              If you have any questions about the transfer policy, please
              contact the {organizationMainContactName} account manager or
              support.
            </Typography>
          </Paper>
          <Footer
            nextButton={
              <InitiateTransferButton
                isNextDisabled={
                  userAgrees.domainServiceAgreementAccepted !== true
                }
              />
            }
          />
        </>
      )}
    </>
  );
};
