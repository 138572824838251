import { faAdd, faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField
} from '@mui/material';
import { noop } from 'lodash-es';
import {
  bindDialog,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { ZoneAddConfirmButton } from './ZoneAddConfirmButton';
import { zoneFormSchema } from './schema';
import { selectSelectedZones } from './selected-zone-slice';

export const ZoneFormDialog = ({ isEdit }) => {
  const popupState = usePopupState({
    popupId: 'zoneFormDialog',
    variant: 'dialog'
  });

  const [validationErrors, setValidationErrors] = useState(false);
  const selectedZone = useSelector(selectSelectedZones)[0];

  useEffect(() => {
    formDataDispatch({
      type: 'INIT',
      value: selectedZone
    });
  }, [selectedZone]);

  const handleRequiredValidation = ({ path }) =>
    formDataDispatch({
      errorMessage: 'This field is required',
      path,
      type: 'SET_ERROR'
    });

  const handleDomainNameValidation = ({ path }) =>
    formDataDispatch({
      errorMessage:
        'DNS name must be in the following format: sample.domain-name.com.',
      path,
      type: 'SET_ERROR'
    });

  const emptyFormState = () =>
    zoneFormSchema(
      handleRequiredValidation,
      handleDomainNameValidation
    ).getDefault();

  const [formData, formDataDispatch] = useReducer((state, action) => {
    if (action.path) {
      action.path = action.path.split('.')[0];
    }

    switch (action.type) {
      case 'INIT':
        state = emptyFormState();

        if (isEdit) {
          state.zoneName.value = action.value.zoneName;
          state.dnsName.value = action.value.dnsName;
          state.description.value = action.value.description;
        }

        break;
      case 'UPDATE_FIELD':
        state[action.path].value = action.value;
        state[action.path].touched = true;
        state[action.path].errorMessage = '';
        state[action.path].hasError = false;

        break;
      case 'SET_ERROR':
        state[action.path].errorMessage = action.errorMessage;
        state[action.path].hasError = state[action.path].touched;

        break;
      default:
        break;
    }

    return { ...state };
  }, emptyFormState());

  const debouncedValidation = useDebouncedCallback(
    zoneFormSchema =>
      zoneFormSchema(handleRequiredValidation, handleDomainNameValidation)
        .validate(formData)
        .catch(noop),
    1500
  );

  const handleChange = (event, path) => {
    setValidationErrors(false);

    formDataDispatch({
      path,
      type: 'UPDATE_FIELD',
      value: event.target.value
    });

    debouncedValidation(zoneFormSchema);
  };

  const handleCancel = event => {
    event.stopPropagation();

    setValidationErrors(false);

    formDataDispatch({
      type: 'INIT',
      value: isEdit ? selectedZone : undefined
    });

    popupState.close();
  };

  const handleSubmit = event => event.preventDefault();

  const allFieldsPopulated = () =>
    [formData.zoneName, formData.dnsName, formData.description].every(
      formField => formField.value.length > 0
    );

  const validateForm = () =>
    zoneFormSchema(
      handleRequiredValidation,
      handleDomainNameValidation
    ).validate(formData);

  return (
    <>
      {isEdit ? (
        <Button
          size="medium"
          startIcon={<FontAwesomeIcon icon={faEdit} />}
          sx={{ mr: 3 }}
          variant="outlined"
          {...bindTrigger(popupState)}
        >
          Edit details
        </Button>
      ) : (
        <Button
          size="small"
          startIcon={<FontAwesomeIcon icon={faAdd} />}
          {...bindTrigger(popupState)}
        >
          Add
        </Button>
      )}
      <Dialog
        {...bindDialog(popupState)}
        fullWidth
        maxWidth="xs"
        onClose={event => handleCancel(event)}
      >
        <DialogTitle>{isEdit ? 'Edit ' : 'Add '} Zone</DialogTitle>
        <form noValidate={true} onSubmit={handleSubmit}>
          <DialogContent sx={{ pr: 1, pt: 1 }}>
            <Grid container spacing={2}>
              <Stack spacing={3} sx={{ paddingTop: 2, width: '100%' }}>
                {validationErrors && (
                  <Alert severity="error">
                    Fix form errors before submitting.
                  </Alert>
                )}
                <TextField
                  error={formData.zoneName.hasError}
                  fullWidth
                  helperText={formData.zoneName.errorMessage}
                  label="Zone Name"
                  onChange={event => handleChange(event, 'zoneName')}
                  value={formData.zoneName.value}
                />
                <TextField
                  disabled={isEdit}
                  error={formData.dnsName.hasError}
                  fullWidth
                  helperText={formData.dnsName.errorMessage}
                  label="DNS Name"
                  onChange={event => handleChange(event, 'dnsName')}
                  value={formData.dnsName.value}
                />
                <TextField
                  error={formData.description.hasError}
                  fullWidth
                  helperText={formData.description.errorMessage}
                  label="Description"
                  maxRows={5}
                  minRows={2}
                  multiline
                  onChange={event => handleChange(event, 'description')}
                  value={formData.description.value}
                />
              </Stack>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} variant="outlined">
              Cancel
            </Button>
            <ZoneAddConfirmButton
              disabled={validationErrors || !allFieldsPopulated()}
              formData={formData}
              formDataDispatch={formDataDispatch}
              handleCancel={handleCancel}
              isEdit={isEdit}
              popupState={popupState}
              setValidationErrors={setValidationErrors}
              validateForm={validateForm}
            />
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
