import { faDollarSign } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';

export const DomainCell = ({ params }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1}>
      <div>{params.domain ?? params.domainName}</div>
      {params.$isPremium && (
        <Typography variant="body">
          <Tooltip title="Premium Domain Name">
            <FontAwesomeIcon
              color={theme.palette.success.main}
              icon={faDollarSign}
            />
          </Tooltip>
        </Typography>
      )}
    </Stack>
  );
};
