import { transformUserResponse } from '../../features/auth/transform-user-response';
import { apiSlice } from './api-slice';

export const transferInOutApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getJWT: builder.query({
      query: ({ transferToken }) => `transfer/${transferToken}`,
      transformResponse: transformUserResponse
    }),
    getTransferToken: builder.mutation({
      query: () => ({
        method: 'POST',
        url: `transfer`
      })
    })
  })
});

export const { useGetJWTQuery, useGetTransferTokenMutation } =
  transferInOutApiSlice;
