import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, useTheme } from '@mui/material';
import React from 'react';

const getTransferStatusByClientLineItemId = (messages, clientLineItemId) =>
  messages.find(message => message.clientLineItemId === clientLineItemId)
    ?.status ?? 'PENDING';

const MessageStatusCell = ({ id, messages = [] }) => {
  const theme = useTheme();

  const status = getTransferStatusByClientLineItemId(messages, id);
  switch (status) {
    case 'SUCCESS':
      return (
        <FontAwesomeIcon color={theme.palette.success.main} icon={faCheck} />
      );
    case 'FAILURE':
      return (
        <FontAwesomeIcon color={theme.palette.error.main} icon={faTimes} />
      );
    case 'PENDING':
      return <CircularProgress size={15} />;
    default:
      return null;
  }
};

export const MessageStatusCellMemoized = React.memo(MessageStatusCell);
