import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BulkApplyContactSetsDialog } from '../../common/components/bulk-apply-contact-sets/BulkApplyContactSetsDialog';
import { selectSelectedTransferIds, updateById } from './transfer-slice';

export const BulkApplyContactSets = () => {
  const dispatch = useDispatch();
  const selectedTransferIds = useSelector(selectSelectedTransferIds);

  const handleContactSetSelection = contactSet =>
    dispatch(
      updateById(
        selectedTransferIds.map(id => ({
          changes: [{ key: 'customerContactSetId', value: contactSet.id }],
          id
        }))
      )
    );

  return (
    <BulkApplyContactSetsDialog
      disableTrigger={selectedTransferIds.length < 1}
      handleContactSetSelection={handleContactSetSelection}
    />
  );
};
