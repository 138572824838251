import { TextField } from '@mui/material';
import React from 'react';

import { formatForDisplay } from '../../../../utils/number-price-text';

export const DotLvForm = ({ specialRequirements }) => {
  return (
    <>
      <TextField
        disabled
        label="Person Type"
        value={formatForDisplay(specialRequirements.DOTLV.lvPersonType)}
      />
      <TextField
        disabled
        label="Latvian Registration Number"
        value={specialRequirements.DOTLV.regNum}
      />
      <TextField
        disabled
        label="VAT Number"
        value={specialRequirements.DOTLV.vatNum}
      />
    </>
  );
};
