import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectCustomerName } from '../../common/store/customers-slice';
import { selectReseller } from '../../common/store/reseller';
import { useGetTldsPricingByCustomerQuery } from '../../common/store/tlds-api-slice';
import { getTldNameForDomain } from '../../common/utils/get-tld-name-for-domain';
import { updateById } from './transfer-slice';

export const LocalPresenceCell = ({
  domainName,
  id,
  useLocalPresence = false
}) => {
  const dispatch = useDispatch();

  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const [tld, setTld] = useState({});

  const { data: tldPricingData, isLoading: isTldPricingLoading } =
    useGetTldsPricingByCustomerQuery({
      customerName,
      reseller
    });

  useEffect(() => {
    if (tldPricingData) {
      const domainNameTld = getTldNameForDomain(domainName);
      const tld = tldPricingData.find(tld => tld.name === domainNameTld);

      if (tld) {
        setTld(tld);
      }
    }
  }, [tldPricingData, domainName]);

  const handleLocalPresenceChange = event => {
    const localPresenceChecked = event.target.checked;
    const localPresenceCost = localPresenceChecked ? tld.$localPresenceCost : 0;
    dispatch(
      updateById({
        changes: [
          { key: 'useLocalPresence', value: event.target.checked },
          { key: 'localPresenceCost', value: localPresenceCost },
          {
            key: '$localPresenceCostFormatted',
            value: tld.$localPresenceCostFormatted
          }
        ],
        id
      })
    );
  };

  return (
    <>
      {isTldPricingLoading && <CircularProgress size={15} />}
      {tld.$isLocalPresenceSupported && (
        <FormControlLabel
          control={
            <Checkbox
              checked={useLocalPresence}
              onChange={handleLocalPresenceChange}
            />
          }
          label={tld.$localPresenceCostFormatted}
        />
      )}
    </>
  );
};

export const LocalPresenceCellMemoized = React.memo(LocalPresenceCell);
