import { Box, Paper, Stack, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import {
  LINE_ITEM_STATUSES,
  POLLING_INTERVAL,
  usePollJobResponseQuery
} from '../../../../common/store/job-response-api-slice';
import { selectClientId } from '../../../auth/auth-slice';
import { defineJobDomainStatus } from '../../../domains/registrar-locks';
import {
  selectCart,
  selectClientLineItemIds,
  selectClientLineItems
} from '../../register-domains-slice';
import { PurchaseStatus } from './PurchaseStatus';

export const OrderSummary = () => {
  const cart = useSelector(selectCart);
  const clientLineItemIds = useSelector(selectClientLineItemIds);
  const clientLineItems = useSelector(selectClientLineItems);
  const clientId = useSelector(selectClientId);
  const jobDomainStatus = useRef([]);

  // maps the clientLineItemId to the domain name
  const domainLineItemMapping = useMemo(() => {
    return clientLineItems.map(lineItem => {
      const $tldObject = cart.find(
        domain => lineItem.domain === domain.domainName
      ).$tldObject;
      return Object.assign({}, lineItem, $tldObject);
    });
  }, [clientLineItems, cart]);

  const { data: jobResultData, refetch } = usePollJobResponseQuery(
    {
      clientId,
      clientLineItemIds
    },
    { skip: clientLineItemIds.length === 0 }
  );

  const areAllPendingDomainsManual = jobResultData => {
    const isManual = lineItemId =>
      domainLineItemMapping.find(
        lineItem => lineItem.clientLineItemId === lineItemId
      ).manual;
    return jobResultData?.messages
      .filter(job => job.status === LINE_ITEM_STATUSES.pending)
      .every(element => isManual(element.clientLineItemId));
  };

  // FIXME this will poll forever
  useEffect(() => {
    if (!areAllPendingDomainsManual(jobResultData)) {
      setTimeout(refetch, POLLING_INTERVAL.active);
    }
    if (jobResultData) {
      jobDomainStatus.current = defineJobDomainStatus(
        jobResultData?.messages,
        clientLineItems
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobResultData]);

  const columnDefaultProps = { disableColumnMenu: true, filterable: false };
  const columns = useMemo(
    () => [
      {
        ...columnDefaultProps,
        field: 'domainName',
        flex: 1,
        headerName: 'Domain'
      },
      {
        ...columnDefaultProps,
        field: '$service',
        flex: 1,
        headerName: 'Product Type',
        valueGetter: ({ row }) =>
          `${row.$service}${row.localPresence ? ' + Local Presence' : ''}`
      },
      {
        ...columnDefaultProps,
        field: 'purchaseStatus',
        flex: 1,
        headerName: 'Purchase Status',
        renderCell: ({ row }) => (
          <PurchaseStatus
            domainObject={row}
            jobDomainStatus={jobDomainStatus.current}
          />
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cart, jobResultData]
  );

  return (
    <Paper sx={{ width: '98%' }} variant="outlined">
      <Box sx={{ padding: 2 }}>
        <Stack direction="column" justifyContent="space-between" mb={2}>
          <Typography variant="h5">Order Summary</Typography>
          <Typography variant="body2">{`${cart.length} Domains`}</Typography>
        </Stack>
        <DataGridPro
          columns={columns}
          disableRowSelectionOnClick={true}
          // @ts-ignore
          disableVirtualization={import.meta.env.MODE === 'test'}
          hideFooter
          rows={cart}
          sx={{
            backgroundColor: 'white',
            height: '100%'
          }}
        />
      </Box>
    </Paper>
  );
};
