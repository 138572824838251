import { Typography } from '@mui/material';
import React from 'react';

export const SpecialRequirementsValidityMessage = ({
  specialRequirementsValidity
}) => {
  return (
    specialRequirementsValidity.isError && (
      <div>
        <Typography variant="body2">Special Requirements Invalid</Typography>
        <Typography variant="caption">
          The special requirements have not been met for this domain.
        </Typography>
      </div>
    )
  );
};
