import { apiSlice } from '../../common/store/api-slice';

const parseDomainServiceAgreementText = (text, url) => {
  const [prefix, linkText, suffix] = text.split('###');
  return {
    linkText,
    prefix,
    suffix,
    url
  };
};

const appInfoApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    appInfo: builder.query({
      query: () => `resellers/bySiteDomain/${window.location.hostname}/info`,
      transformResponse: response => ({
        ...response,
        $domainServiceAgreementParts: parseDomainServiceAgreementText(
          response.domainServiceAgreementText,
          response.domainServiceAgreementUrl
        )
      })
    })
  })
});

export const { useAppInfoQuery } = appInfoApiSlice;
