import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Card } from '@mui/material';
import React, { Dispatch } from 'react';

import type { FormAction } from '../../common/types/form';
import type { ApiUserResponse } from '../../common/types/user';

import { SignOffContextPermissionUserAutocomplete } from './SignOffContextPermissionUserAutocomplete';
import { SignOffContextSignerFormSchema } from './schema';

type SignOffContextPermissionSignerSelectProps = {
  formData: SignOffContextSignerFormSchema;
  formDataDispatch: Dispatch<FormAction>;
  isLoading: boolean;
  userOptions: ApiUserResponse[];
};

export const SignOffContextPermissionSignerSelect = ({
  formData,
  formDataDispatch,
  isLoading,
  userOptions
}: SignOffContextPermissionSignerSelectProps) => {
  const emptyUserOption = { fullName: 'None', id: -1 };

  const handleAddMore = () =>
    formDataDispatch({
      errorMessage: null,
      path: null,
      type: 'ADD',
      value: emptyUserOption
    });

  const handleRemove = (signer: ApiUserResponse) =>
    formDataDispatch({
      errorMessage: null,
      path: null,
      type: 'REMOVE',
      value: signer
    });

  return (
    <>
      {formData.approvedSigners?.map(approvedSigner =>
        approvedSigner.id !== -1 ? (
          <Card
            key={approvedSigner.id}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              height: '56px',
              justifyContent: 'space-between',
              my: 2,
              pl: 2,
              position: 'relative'
            }}
            variant="outlined"
          >
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              {approvedSigner.fullName} - {approvedSigner.roles[0].roleName}
            </Box>
            <Button
              onClick={() => handleRemove(approvedSigner)}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 0,
                pr: 3
              }}
            >
              <FontAwesomeIcon data-testid="fa-trash" icon={faTrash} />
            </Button>
          </Card>
        ) : (
          <SignOffContextPermissionUserAutocomplete
            formDataDispatch={formDataDispatch}
            isLoading={isLoading}
            key={-1}
            options={userOptions}
          />
        )
      )}
      <Button
        color="secondary"
        data-testid="AddMoreSignersButton"
        onClick={handleAddMore}
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        variant="contained"
      >
        Add more
      </Button>
    </>
  );
};
