import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { resetRegisterProcess } from '../../../features/register-domains/register-domains-slice';
import { getFirstRegistrationStepPath } from '../../../features/register-domains/registration-navigation';
import styles from './Header.module.css';

export const RegisterButton = () => {
  const dispatch = useDispatch();
  const registerReset = () => {
    dispatch(resetRegisterProcess());
  };
  const getSecondaryNavLinkClassNames = ({ isActive }) =>
    isActive
      ? `Header-linkActive ${styles.secondaryLink}`
      : styles.secondaryLink;

  return (
    <NavLink
      className={getSecondaryNavLinkClassNames}
      onClick={registerReset}
      to={getFirstRegistrationStepPath()}
    >
      <FontAwesomeIcon className={styles.secondaryLinkIcon} icon={faPlus} />
      Register
    </NavLink>
  );
};
