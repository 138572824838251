import { Box } from '@mui/material';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { config } from '../../common/config/config';
import { selectCustomerName } from '../../common/store/customers-slice';
import { selectReseller } from '../../common/store/reseller';
import { gridColumnWidths } from '../../common/utils/grid-column-widths';
import { ZoneGridToolbar } from './ZoneGridToolbar';
import { ZoneViewRecordSetsCell } from './ZoneViewRecordSetsCell';
import { selectSelectedZoneIds, setSelectedZones } from './selected-zone-slice';
import { useGetActiveZonesForCustomerQuery } from './zone-management-api-slice';

export const Zones = () => {
  const dispatch = useDispatch();

  const apiRef = useGridApiRef();

  const customerName = useSelector(selectCustomerName);
  const reseller = useSelector(selectReseller);
  const selectedZoneIds = useSelector(selectSelectedZoneIds);

  const {
    data = [],
    isFetching,
    isUninitialized
  } = useGetActiveZonesForCustomerQuery({
    customerName,
    reseller
  });

  const columns = useMemo(
    () => {
      return [
        {
          field: 'zoneName',
          flex: 3,
          headerName: 'Name'
        },
        {
          field: 'dnsName',
          flex: 3,
          headerName: 'DNS Name'
        },
        {
          field: 'description',
          filterable: false,
          flex: 4,
          headerName: 'Description'
        },
        {
          field: 'recordSets',
          filterable: false,
          flex: 1,
          headerName: 'Record Sets',
          renderCell: params => <ZoneViewRecordSetsCell params={params} />,
          width: gridColumnWidths.icon.width
        }
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  // sets selected rows when page is loaded
  useEffect(() => {
    apiRef.current.setRowSelectionModel(selectedZoneIds);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef, selectedZoneIds.length]);

  // updates selected zones when data is updated
  useEffect(() => {
    if (data?.length && apiRef?.current) {
      dispatch(
        setSelectedZones(
          Array.from(apiRef.current.getSelectedRows()).map(row => row[1])
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // store currently selected zones in redux
  const handleSelectionModelChange = (newSelectionModel, { api }) => {
    dispatch(
      setSelectedZones(Array.from(api.getSelectedRows()).map(row => row[1]))
    );
  };

  return (
    <Box sx={{ height: 'calc(100%  - 48px)' }}>
      <DataGridPro
        apiRef={apiRef}
        checkboxSelection
        columns={columns}
        disableSelectionOnClick
        disableVirtualization={import.meta.env.MODE === 'test'}
        getRowId={row => row.id}
        loading={isUninitialized || isFetching}
        onRowSelectionModelChange={handleSelectionModelChange}
        pagination
        rowCount={data.length}
        rows={data}
        rowsPerPageOptions={config.DEFAULT_TABLE_ROWS_PER_PAGE_OPTIONS}
        slots={{ toolbar: ZoneGridToolbar }}
        sx={{ backgroundColor: 'white', height: '100%' }}
      />
    </Box>
  );
};
