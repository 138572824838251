import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Link,
  Stack
} from '@mui/material';
import DOMPurify from 'dompurify';
import {
  bindDialog,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTldNameForDomain } from '../../../../common/utils/get-tld-name-for-domain';
import {
  selectSpecialRequirementsValidityById,
  setSpecialRequirementsValidity,
  updateById
} from '../../register-domains-slice';
import { ValidOrNotMemoized } from './ValidOrNotIcon';

export const SetDotNewSpecialRequirements = ({
  checkoutMessages,
  domain,
  domainName,
  id
}) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(domain.buySsl);
  const dialogState = usePopupState({
    popupId: 'setDotNewDialog',
    variant: 'dialog'
  });

  const isValid = useSelector(state =>
    selectSpecialRequirementsValidityById(state, id)
  );

  const handleClose = () => {
    dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }));
    dialogState.close();
  };

  const handleSubmit = event => {
    event.preventDefault();

    dispatch(
      updateById({
        changes: [
          {
            key: 'buySsl',
            value: checked
          }
        ],
        id
      })
    );
    dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }));
    dialogState.close();
  };

  useEffect(() => {
    if (domain.buySsl && !isValid) {
      dispatch(setSpecialRequirementsValidity({ domainName, isValid: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, domainName, isValid]);

  const checkoutMessage = checkoutMessages?.find(
    message => message.tld.name === getTldNameForDomain(domainName)
  )?.checkoutMessage;

  var purifiedCheckoutMessage = DOMPurify.sanitize(checkoutMessage, {
    ALLOWED_ATTR: ['style', 'href', 'target'],
    ALLOWED_TAGS: ['br', 'div', 'strong', 'p', 'a', 'ul', 'li', 'b', 'i', 'em']
  });

  return (
    <>
      <Stack alignItems="center" direction="row" spacing={1}>
        <Button size="small" {...bindTrigger(dialogState)}>
          Acknowledgement
        </Button>
        <ValidOrNotMemoized isValid={isValid} />
      </Stack>
      <Dialog {...bindDialog(dialogState)} maxWidth="sm">
        <form noValidate={true} onSubmit={handleSubmit}>
          <DialogTitle>Set Special Requirements for {domainName}</DialogTitle>
          <DialogContent>
            <DialogContentText
              component="div"
              dangerouslySetInnerHTML={{
                __html: purifiedCheckoutMessage
              }}
              sx={{ mb: 2 }}
              variant="body1"
            />
            <Divider />
            <DialogContentText component="div" sx={{ my: 2 }} variant="body1">
              <div>
                <strong>SSL Certificate</strong>
              </div>
              <br />.{getTldNameForDomain(domainName)} is a secure namespace.
              This means that properly configured HTTPS is required. You can buy
              the selected domain names now, but to ensure proper functionality
              in browsers, you must first configure HTTPS serving.
            </DialogContentText>
            <DialogContentText>
              {
                'For more information about configuring HTTPS, including resources to obtain an SSL certificate, please visit '
              }
              <Link
                href="https://letsencrypt.org/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {"Let's Encrypt"}
              </Link>
              . If you would like to purchase an SSL certificate through
              AppDetex, please request it in the table below. An AppDetex
              employee will reach out to you after registration.
            </DialogContentText>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  data-testid="checkbox"
                  onChange={() => setChecked(!checked)}
                />
              }
              label={`Request SSL Certificate for ${domainName}`}
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit} type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export const SetDotNewSpecialRequirementsMemoized = React.memo(
  SetDotNewSpecialRequirements
);
