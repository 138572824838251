import { Typography } from '@mui/material';
import React, { memo } from 'react';

const Unauthorized = () => {
  return (
    <Typography
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      You are not authorized to view this page.
    </Typography>
  );
};

export const UnauthorizedMemoized = memo(Unauthorized);
