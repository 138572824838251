import { drop } from 'lodash-es';

export const getTldNameForDomain = (domainName = ''): string => {
  if (domainName.endsWith('.name')) {
    return 'name';
  } else {
    const domainNameParts = domainName.split('.');
    return drop(domainNameParts, 1).join('.');
  }
};
