import type { FormAction } from '../../common/types/form';
import type { SignOffContextFormSchema } from './schema';
import type { ApiSignOffContextResponse } from './sign-off-context';

export const signOffContextEditFormReducer = (
  state: SignOffContextFormSchema,
  action: FormAction
) => {
  switch (action.type) {
    case 'INIT':
      const actionValue = action.value as ApiSignOffContextResponse | null;

      if (actionValue != null) {
        state.active = actionValue.active;
        state.hasSpecifiedApprovers = actionValue.hasSpecifiedApprovers;

        state.forceForAllUsers = actionValue.forceForAllUsers;

        state.numApprovalsRequired = {
          errorMessage: '',
          hasError: false,
          value: actionValue.numApprovalsRequired
        };
      }

      return { ...state };
    case 'UPDATE':
      if (action.path === 'numApprovalsRequired') {
        return {
          ...state,
          [action.path as string]: {
            errorMessage: '',
            hasError: false,
            value: action.value
          }
        };
      }

      return {
        ...state,
        [action.path as string]: action.value
      };
    case 'SET_ERROR':
      return {
        ...state,
        numApprovalsRequired: {
          ...state.numApprovalsRequired,
          errorMessage: action.errorMessage,
          hasError: true
        }
      };
    default:
      return { ...state };
  }
};
