import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import React, { useState } from 'react';

import { ContactSetsReadOnlyForm } from './ContactSetsReadOnlyForm';
import {
  CONTACT_SET_TYPE_LABELS,
  CONTACT_SET_TYPES
} from './contact-set-types';

export const ContactSetsTabs = ({ contactSetId }) => {
  const [activeTab, setActiveTab] = useState('tab0');
  const handleChange = (event, value) => setActiveTab(value);

  return (
    <TabContext value={activeTab}>
      <TabList onChange={handleChange}>
        {Object.keys(CONTACT_SET_TYPES).map((contactSetType, index) => (
          <Tab
            key={contactSetType}
            label={CONTACT_SET_TYPE_LABELS[contactSetType]}
            value={`tab${index}`}
          />
        ))}
      </TabList>
      {Object.keys(CONTACT_SET_TYPES).map((contactSetType, index) => (
        <TabPanel
          key={contactSetType}
          sx={{ minHeight: 490 }}
          value={`tab${index}`}
        >
          <ContactSetsReadOnlyForm
            contactSetId={contactSetId}
            contactSetType={contactSetType}
          />
        </TabPanel>
      ))}
    </TabContext>
  );
};
