import { createSlice } from '@reduxjs/toolkit';

const initialState = { apiFilters: {}, filterModel: {} };

const getStatusesApiFilters = ({ newFilterModel }) =>
  newFilterModel.items.find(item => item.field === 'status')?.value ?? [];

const getLabelsApiFilters = ({ newFilterModel }) => {
  let response;
  const matchingFilter = newFilterModel.items.find(
    item => item.field === '$labels'
  );

  if (matchingFilter?.value) {
    response = matchingFilter.value;
  } else {
    response = [];
  }

  response = response.map(label => label.id);

  return response;
};

const getSearchStringApiFilters = ({ newFilterModel }) =>
  (newFilterModel?.quickFilterValues ?? []).join(' ');

const preregistrationsFiltersSlice = createSlice({
  initialState,
  name: 'preregistrationsFilters',
  reducers: {
    updateFilters: (state, { payload }) => {
      const { newFilterModel } = payload;

      state.apiFilters = {
        domain: getSearchStringApiFilters({ newFilterModel }),
        labelIds: getLabelsApiFilters({ newFilterModel }),
        statuses: getStatusesApiFilters({ newFilterModel })
      };
    }
  }
});

export const preregistrationsFiltersReducer =
  preregistrationsFiltersSlice.reducer;

export const { updateFilters } = preregistrationsFiltersSlice.actions;

export const selectFilterModel = state =>
  state.preregistrationsFilters.filterModel;
export const selectApiFilters = state =>
  state.preregistrationsFilters.apiFilters;
