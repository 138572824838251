import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from '@mui/material';
import React from 'react';

export const LabelFormGroup = ({
  handleLabelAssociationCheckboxChange,
  labelsWithCheckedState
}) => {
  return (
    <>
      {Object.keys(labelsWithCheckedState).map(labelGroupName => (
        <div key={labelGroupName}>
          <Typography variant="h6">{labelGroupName}</Typography>
          <FormGroup sx={{ mb: 2 }}>
            {labelsWithCheckedState[labelGroupName].map(label => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={label.$isChecked}
                    indeterminate={label.$isIndeterminate}
                    onChange={event =>
                      handleLabelAssociationCheckboxChange({
                        associations: label.$associations,
                        isChecked: event.target.checked,
                        labelId: label.id
                      })
                    }
                  />
                }
                key={label.id}
                label={label.label}
              />
            ))}
          </FormGroup>
        </div>
      ))}
    </>
  );
};
