import { TextField } from '@mui/material';
import React from 'react';

import { formatForDisplay } from '../../../../utils/number-price-text';

export const DotHkForm = ({ specialRequirements }) => {
  const individualDocTypes = Object.freeze({
    IND_BIRTHCERT: 'Birth Certificate',
    IND_HKID: 'Hong Kong Identity Number',
    IND_OTHID: "Other's Country Identity Number",
    IND_OTHIDV: 'Others Individual Document',
    IND_PASSNO: 'Passport No.'
  });

  const orgDocTypes = Object.freeze({
    ORG_BR: 'Business Registration Certificate',
    ORG_CI: 'Certificate of Incorporation',
    ORG_CRS: 'Certificate of Registration of a School',
    ORG_HKORDINANCE: 'Ordinance of Hong Kong',
    ORG_HKSARG: 'Hong Kong Special Administrative Region Government Department',
    ORG_OTHORG: 'Others Organization Document'
  });

  const industryTypes = Object.freeze({
    0: 'None (Other industry not defined in this list)',
    '010100':
      'Plastics, Petro-Chemicals, Chemicals - Plastics & Plastic Products',
    '010200': 'Plastics, Petro-Chemicals, Chemicals - Rubber & Rubber Products',
    '010300':
      'Plastics, Petro-Chemicals, Chemicals - Fibre Materials & Products',
    '010400':
      'Plastics, Petro-Chemicals, Chemicals - Petroleum, Coal & Other Fuels',
    '010500':
      'Plastics, Petro-Chemicals, Chemicals - Chemicals & Chemical Products',
    '020100': 'Metals, Machinery, Equipment - Metal Materials & Treatment',
    '020200': 'Metals, Machinery, Equipment - Metal Products',
    '020300': 'Metals, Machinery, Equipment - Industrial Machinery & Supplies',
    '020400': 'Metals, Machinery, Equipment - Precision & Optical Equipment',
    '020500': 'Metals, Machinery, Equipment - Moulds & Dies',
    '030100':
      'Printing, Paper, Publishing - Printing, Photocopying, Publishing',
    '030200': 'Printing, Paper, Publishing - Paper, Paper Products',
    '040100':
      'Construction, Decoration, Environmental Engineering - Construction Contractors',
    '040200':
      'Construction, Decoration, Environmental Engineering - Construction Materials',
    '040300':
      'Construction, Decoration, Environmental Engineering - Decoration Materials',
    '040400':
      'Construction, Decoration, Environmental Engineering - Construction, Safety Equipment & Supplies',
    '040500':
      'Construction, Decoration, Environmental Engineering - Decoration, Locksmiths, Plumbing & Electrical Works',
    '040600':
      'Construction, Decoration, Environmental Engineering - Fire Protection Equipment & Services',
    '040700':
      'Construction, Decoration, Environmental Engineering - Environmental Engineering, Waste Reduction',
    '050100': 'Textiles, Clothing & Accessories - Textiles, Fabric',
    '050200': 'Textiles, Clothing & Accessories - Clothing',
    '050300': 'Textiles, Clothing & Accessories - Uniforms, Special Clothing',
    '050400':
      'Textiles, Clothing & Accessories - Clothing Manufacturing Accessories',
    '050500':
      'Textiles, Clothing & Accessories - Clothing Processing & Equipment',
    '050600': 'Textiles, Clothing & Accessories - Fur, Leather & Leather Goods',
    '050700':
      'Textiles, Clothing & Accessories - Handbags, Footwear, Optical Goods, Personal Accessories',
    '060100':
      'Electronics, Electrical Appliances - Electronic Equipment & Supplies',
    '060200':
      'Electronics, Electrical Appliances - Electronic Parts & Components',
    '060300':
      'Electronics, Electrical Appliances - Electrical Appliances, Audio-Visual Equipment',
    '070100':
      'Houseware, Watches, Clocks, Jewelry, Toys, Gifts - Kitchenware, Tableware',
    '070200': 'Houseware, Watches, Clocks, Jewelry, Toys, Gifts - Bedding',
    '070300':
      'Houseware, Watches, Clocks, Jewelry, Toys, Gifts - Bathroom, Cleaning Accessories',
    '070400':
      'Houseware, Watches, Clocks, Jewelry, Toys, Gifts - Household Goods',
    '070500':
      'Houseware, Watches, Clocks, Jewelry, Toys, Gifts - Wooden, Bamboo & Rattan Goods',
    '070600':
      'Houseware, Watches, Clocks, Jewelry, Toys, Gifts - Home Furnishings, Arts & Crafts',
    '070700':
      'Houseware, Watches, Clocks, Jewelry, Toys, Gifts - Watches, Clocks',
    '070800':
      'Houseware, Watches, Clocks, Jewelry, Toys, Gifts - Jewelry Accessories',
    '070900':
      'Houseware, Watches, Clocks, Jewelry, Toys, Gifts - Toys, Games, Gifts',
    '080100':
      'Business & Professional Services, Finance - Accounting, Legal Services',
    '080200':
      'Business & Professional Services, Finance - Advertising, Promotion Services',
    '080300':
      'Business & Professional Services, Finance - Consultancy Services',
    '080400':
      'Business & Professional Services, Finance - Translation, Design Services',
    '080500':
      'Business & Professional Services, Finance - Cleaning, Pest Control Services',
    '080600': 'Business & Professional Services, Finance - Security Services',
    '080700':
      'Business & Professional Services, Finance - Trading, Business Services',
    '080800': 'Business & Professional Services, Finance - Employment Services',
    '080900':
      'Business & Professional Services, Finance - Banking, Finance, Investment',
    '081000': 'Business & Professional Services, Finance - Insurance',
    '081100':
      'Business & Professional Services, Finance - Property, Real Estate',
    '090100': 'Transportation, Logistics - Land Transport, Motorcars',
    '090200': 'Transportation, Logistics - Sea Transport, Boats',
    '090300': 'Transportation, Logistics - Air Transport',
    '090400':
      'Transportation, Logistics - Moving, Warehousing, Courier & Logistics Services',
    '090500': 'Transportation, Logistics - Freight Forwarding',
    100100:
      'Office Equipment, Furniture, Stationery, Information Technology - Office, Commercial Equipment & Supplies',
    100200:
      'Office Equipment, Furniture, Stationery, Information Technology - Office & Home Furniture',
    100300:
      'Office Equipment, Furniture, Stationery, Information Technology - Stationery & Educational Supplies',
    100400:
      'Office Equipment, Furniture, Stationery, Information Technology - Telecommunication Equipment & Services',
    100500:
      'Office Equipment, Furniture, Stationery, Information Technology - Computers, Information Technology',
    110100: 'Food, Flowers, Fishing & Agriculture - Food Products & Supplies',
    110200: 'Food, Flowers, Fishing & Agriculture - Beverages, Tobacco',
    110300:
      'Food, Flowers, Fishing & Agriculture - Restaurant Equipment & Supplies',
    110400:
      'Food, Flowers, Fishing & Agriculture - Flowers, Artificial Flowers, Plants',
    110500: 'Food, Flowers, Fishing & Agriculture - Fishing',
    110600: 'Food, Flowers, Fishing & Agriculture - Agriculture',
    120100:
      'Medical Services, Beauty, Social Services - Medicine & Herbal Products',
    120200:
      'Medical Services, Beauty, Social Services - Medical & Therapeutic Services',
    120300:
      'Medical Services, Beauty, Social Services - Medical Equipment & Supplies',
    120400: 'Medical Services, Beauty, Social Services - Beauty, Health',
    120500: 'Medical Services, Beauty, Social Services - Personal Services',
    120600:
      'Medical Services, Beauty, Social Services - Organizations, Associations',
    120700: 'Medical Services, Beauty, Social Services - Information, Media',
    120800: 'Medical Services, Beauty, Social Services - Public Utilities',
    120900:
      'Medical Services, Beauty, Social Services - Religion, Astrology, Funeral Services',
    130100: 'Culture, Education - Music, Arts',
    130200: 'Culture, Education - Learning Instruction & Training',
    130300: 'Culture, Education - Elementary Education',
    130400: 'Culture, Education - Tertiary Education, Other Education Services',
    130500: 'Culture, Education - Sporting Goods',
    130600: 'Culture, Education - Sporting, Recreational Facilities & Venues',
    130700: 'Culture, Education - Hobbies, Recreational Activities',
    130800: 'Culture, Education - Pets, Pets Services & Supplies',
    140101:
      'Dining, Entertainment, Shopping, Travel - Restaurant Guide - Chinese',
    140102:
      'Dining, Entertainment, Shopping, Travel - Restaurant Guide - Asian',
    140103:
      'Dining, Entertainment, Shopping, Travel - Restaurant Guide - Western',
    140200:
      'Dining, Entertainment, Shopping, Travel - Catering Services, Eateries',
    140300: 'Dining, Entertainment, Shopping, Travel - Entertainment Venues',
    140400:
      'Dining, Entertainment, Shopping, Travel - Entertainment Production & Services',
    140500:
      'Dining, Entertainment, Shopping, Travel - Entertainment Equipment & Facilities',
    140600: 'Dining, Entertainment, Shopping, Travel - Shopping Venues',
    140700:
      'Dining, Entertainment, Shopping, Travel - Travel, Hotels & Accommodation'
  });

  const isCategoryOrganization =
    specialRequirements.DOTHK.category === 'ORGANIZATION';

  return (
    <>
      <TextField
        disabled
        label="Contact Category Type"
        value={formatForDisplay(specialRequirements.DOTHK.category)}
      />
      {isCategoryOrganization ? (
        <TextField
          disabled
          label="Document Type"
          value={orgDocTypes[specialRequirements.DOTHK.documentType]}
        />
      ) : (
        <TextField
          disabled
          label="Document Type"
          value={individualDocTypes[specialRequirements.DOTHK.documentType]}
        />
      )}
      <TextField
        disabled
        label="Document Number"
        value={specialRequirements.DOTHK.documentNumber}
      />
      <TextField
        disabled
        label="Country of Origin"
        value={specialRequirements.DOTHK.documentOriginCountry}
      />
      {isCategoryOrganization && (
        <TextField
          disabled
          label="Industry Type"
          value={industryTypes[specialRequirements.DOTHK.industryType]}
        />
      )}
      <TextField
        disabled
        label="Chinese Company Name"
        value={specialRequirements.DOTHK.chineseCompany}
      />
      <TextField
        disabled
        label="Mobile Phone Number"
        value={specialRequirements.DOTHK.mobileNumber}
      />
    </>
  );
};
