import { boolean, number, object, string } from 'yup';

/**
 *
 * @param {Function} handleRequiredValidation
 * @returns
 */
const defaultFieldSchema = handleRequiredValidation =>
  object({
    errorMessage: string().default(''),
    hasError: boolean().default(false),
    touched: boolean().default(false),
    value: string().required(handleRequiredValidation).default('')
  });

/**
 *
 * @param {Function} handleRequiredValidation
 * @param {Function} handleHexStringValidation
 * @param {Function} handleKeyTagValidation
 * @returns
 */
export const formSchema = ({
  handleHexStringValidation,
  handleKeyTagValidation,
  handleRequiredValidation
}) =>
  object({
    algorithmType: defaultFieldSchema(handleRequiredValidation),
    digest: object({
      errorMessage: string().default(''),
      hasError: boolean().default(false),
      touched: boolean().default(false),
      value: string()
        .required(handleRequiredValidation)
        .matches(/^[0-9a-fA-F]+$/, handleHexStringValidation)
        .default('')
    }),
    digestType: defaultFieldSchema(handleRequiredValidation),
    keyTag: object({
      errorMessage: string().default(''),
      hasError: boolean().default(false),
      touched: boolean().default(false),
      value: number()
        .typeError(handleKeyTagValidation)
        .nullable(true)
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .default(undefined)
    })
  });
