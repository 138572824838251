import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';

export const SnackbarCloseAction = ({ snackbarId }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <Button
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
      size="small"
      sx={{
        borderColor: '#fff !important',
        color: '#fff !important'
      }}
      variant="outlined"
    >
      Dismiss
    </Button>
  );
};
