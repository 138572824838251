import { createSlice } from '@reduxjs/toolkit';

import { generateId } from '../../common/utils/generate-id';
import { getStoredUser } from './user-storage';

const storedUser = getStoredUser();

const initialState = {
  $roles: storedUser?.$roles ?? {},
  accessToken: storedUser?.accessToken,
  clientId: generateId(),
  email: storedUser?.email ?? '',
  hasLoggedOut: false,
  isIdle: false,
  roles: storedUser?.roles ?? {}
};

const authSlice = createSlice({
  initialState: { isIdle: false, ...initialState },
  name: 'auth',
  reducers: {
    setAccessToken: (state, { payload }) => {
      if (state.isIdle === false && state.accessToken !== payload) {
        state.accessToken = payload;
      }
    },
    setAuth: (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.email = payload?.email;
      state.roles = payload?.roles;
      state.$roles = payload?.$roles;
      state.hasLoggedOut = false;
    },
    setHasLoggedOut: (state, { payload }) => {
      state.hasLoggedOut = payload;
    },
    setIdle: (state, { payload }) => {
      state.isIdle = payload.isIdle;
    }
  }
});

export const { setAccessToken, setAuth, setHasLoggedOut, setIdle } =
  authSlice.actions;

export const authReducer = authSlice.reducer;
export const selectAccessToken = state => state.auth?.accessToken;
export const selectUserEmail = state => state.auth?.email;
export const selectRoles = state => state.auth?.$roles;
export const selectIsAdmin = state => state.auth?.$roles?.isAdmin;
export const selectIsMemberOfCustomer = state =>
  state.auth?.$roles?.isMemberOfCustomer;
export const selectIsCustomerAdmin = state =>
  state.auth?.$roles?.isCustomerAdmin;
export const selectIsSuperAdmin = state => state.auth?.$roles?.isSuperAdmin;
export const selectIsSystemAdmin = state => state.auth?.$roles?.isSystemAdmin;
export const selectIsSuperAdminOrFinance = state =>
  state.auth?.$roles?.isSuperAdminOrFinance;
export const selectIsCustomer = state => state.auth?.$roles?.isCustomer;

export const selectCanWrite = state => state.auth?.$roles?.canWrite;
export const selectClientId = state => state.auth?.clientId;

export const selectCanAlwaysSeeSignOffs = state =>
  state.auth?.$roles?.canAlwaysSeePendingSignOffs;

export const selectCanEditAccountInformation = state => {
  const roles = state.auth?.$roles;

  return (
    (roles.isSuperAdmin || roles.isCustomerAdmin || roles.isSystemAdmin) &&
    roles.canWrite
  );
};

export const selectCanEditApprovalSignOffs = state =>
  state.auth?.$roles.isSuperAdmin && state.auth?.$roles.canWrite;

export const selectCanReset2FA = state =>
  state.auth?.$roles.isSuperAdmin && state.auth?.$roles.canWrite;

export const selectCanViewUsers = state => {
  const roles = state.auth?.$roles;

  return roles.isCustomer || roles.isSystemAdmin || roles.isSuperAdmin;
};
export const selectCanCreateUsers = state => {
  const roles = state.auth?.$roles;
  return (
    roles.canWrite &&
    (roles.isCustomerAdmin || roles.isSystemAdmin || roles.isSuperAdmin)
  );
};
export const selectCanViewAccountSecurity = state => {
  const roles = state.auth?.$roles;

  return roles.isCustomerAdmin || roles.isSystemAdmin || roles.isSuperAdmin;
};

export const selectCanEditAccountSecurity = state => {
  const roles = state.auth?.$roles;

  return selectCanViewAccountSecurity(state) && roles.canWrite;
};

export const selectCanViewTldCosts = state =>
  state.auth?.$roles.isSuperAdminOrFinance;

export const selectCanViewPricing = state =>
  state.auth?.$roles.isSuperAdminOrFinance;

export const selectHasLoggedOut = state => state.auth?.hasLoggedOut;
