import { apiSlice } from '../../common/store/api-slice';

const nameServerApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getNameServerGroups: builder.query({
      query: ({ customerName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/name-server-groups`,
      transformResponse: response => {
        return response.entity.map(nameServerGroup => ({
          ...nameServerGroup,
          label: nameServerGroup.name
        }));
      }
    }),
    verifyNameServerGroupForTld: builder.query({
      query: ({ customerName, nameServerGroupId, reseller, tldName }) =>
        `resellers/${reseller}/customers/${customerName}/name-server-groups/${tldName}/${nameServerGroupId}`,
      transformResponse: response => {
        if (!response.valid) {
          throw response.reason;
        } else {
          return response;
        }
      }
    }),
    verifyNameServerGroupsForTlds: builder.query({
      query: ({ customerName, reseller, tldsAndNameServerGroupIds }) => ({
        body: tldsAndNameServerGroupIds,
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/name-server-groups/validate`
      }),
      transformResponse: ({ entity }) => ({
        invalidTlds: entity
          .filter(tld => tld.valid === false)
          .map(invalidTld => invalidTld.tld),
        tlds: entity
      })
    })
  })
});

export const {
  useGetNameServerGroupsQuery,
  useVerifyNameServerGroupForTldQuery,
  useVerifyNameServerGroupsForTldsQuery
} = nameServerApiSlice;
