import titleCase from 'string-title-case';

import type { ApiUserResponse } from '../../common/types/user';
import type { ApiSignOffContextResponse } from './sign-off-context';

import {
  CUSTOMER_USERS_TAG,
  SIGN_OFF_CONTEXT_PERMISSION_TAG,
  SIGN_OFF_CONTEXT_TAG,
  apiSlice
} from '../../common/store/api-slice';

const signOffContextApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllSignOffContextsForAccountName: builder.query({
      providesTags: [SIGN_OFF_CONTEXT_TAG],
      query: ({ accountName }) =>
        `sign-offs/sign-off-context?accountName=${accountName}`,
      transformResponse: ({
        entity
      }: {
        entity: ApiSignOffContextResponse[];
      }) =>
        entity
          .map((context: ApiSignOffContextResponse) => {
            return {
              ...context,
              $signOffContextNameFormatted: titleCase(
                context.signOffContextType.signOffContext.replaceAll('_', ' '),
                []
              )
            };
          })
          .sort(
            (a: ApiSignOffContextResponse, b: ApiSignOffContextResponse) => {
              const nameA = a.$signOffContextNameFormatted.toLowerCase();
              const nameB = b.$signOffContextNameFormatted.toLowerCase();

              if (nameA < nameB) {
                return -1;
              } else if (nameA > nameB) {
                return 1;
              } else {
                return 0;
              }
            }
          )
    }),
    getApprovedSignersForCustomerAndContextId: builder.query({
      providesTags: [SIGN_OFF_CONTEXT_PERMISSION_TAG],
      query: ({ accountName, contextId }) => ({
        method: 'GET',
        url: `sign-offs/sign-off-context-permission?accountName=${accountName}&contextId=${contextId}`
      }),
      transformResponse: ({ entity }) => entity
    }),
    getAvailableApprovers: builder.query({
      providesTags: [CUSTOMER_USERS_TAG],
      query: ({ accountName }) => ({
        method: 'GET',
        url: `sign-offs/available-approvers/${accountName}`
      })
    }),
    updateSignOffContext: builder.mutation({
      invalidatesTags: [SIGN_OFF_CONTEXT_TAG, SIGN_OFF_CONTEXT_PERMISSION_TAG],
      query: ({ signOffContextId, signOffContextUpdateRequest }) => ({
        body: {
          ...signOffContextUpdateRequest
        },
        method: 'POST',
        url: `sign-offs/sign-off-context/${signOffContextId}`
      })
    }),
    updateSignOffContextPermission: builder.mutation({
      invalidatesTags: [SIGN_OFF_CONTEXT_PERMISSION_TAG],
      query: ({ context, customer, signers }) => ({
        body: {
          customerAccountName: customer.accountName,
          signOffContextId: context.id,
          userIds: signers.map((signer: ApiUserResponse) => signer.id)
        },
        method: 'POST',
        url: `sign-offs/sign-off-context-permission`
      })
    })
  })
});

export const {
  useGetAllSignOffContextsForAccountNameQuery,
  useGetApprovedSignersForCustomerAndContextIdQuery,
  useGetAvailableApproversQuery,
  useUpdateSignOffContextMutation,
  useUpdateSignOffContextPermissionMutation
} = signOffContextApiSlice;
