import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import type {
  GridRenderCellParams,
  GridTreeNodeWithRender
} from '@mui/x-data-grid-pro';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';

interface SignOffContextEditCellProps {
  setIsOpen: (contextId: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>;
}

export const SignOffContextEditCell = ({
  setIsOpen,
  params
}: SignOffContextEditCellProps) => {
  return (
    <IconButton onClick={() => setIsOpen(params.row.id)} size="small">
      <FontAwesomeIcon icon={faEdit} data-testid="sign-off-context-edit" />
    </IconButton>
  );
};
