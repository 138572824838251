import { List, ListItem, ListItemButton } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { useGetContactsQuery } from '../../store/contacts-api-slice';
import { selectCustomerName } from '../../store/customers-slice';
import { selectReseller } from '../../store/reseller';

export const BulkApplyContactSetsList = ({
  handleContactSetSelection,
  popupState
}) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const { data } = useGetContactsQuery(
    { customerName, reseller },
    { skip: !customerName }
  );

  return (
    <List>
      {data?.map(contactSet => (
        <ListItem disablePadding key={contactSet.id}>
          <ListItemButton
            onClick={() => {
              handleContactSetSelection(contactSet);
              popupState.close();
            }}
          >
            {contactSet.label}
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};
