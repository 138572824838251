import { muiTheme } from '@appdetex/detect-mui';
import { Box, Button, ThemeProvider, Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import React from 'react';

export const ErrorBoundaryFallback = () => {
  return (
    <ThemeProvider
      theme={createTheme({
        ...muiTheme
      })}
    >
      <Box
        sx={{
          overflow: 'auto',
          pt: '20vh',
          textAlign: 'center',
          width: '100vw'
        }}
      >
        <Typography sx={{ fontWeight: 600, pt: 6 }} variant="h1">
          Tracer Registrar
        </Typography>
        <Typography sx={{ fontWeight: 700, pt: 6 }} variant="h5">
          An error occurred
        </Typography>
        <Typography variant="h6">
          If the problem persists contact your account manager.
        </Typography>
        <Button
          href="/dashboard"
          sx={{ fontWeight: 700, mt: 5 }}
          variant="contained"
        >
          Go to dashboard
        </Button>
      </Box>
    </ThemeProvider>
  );
};
