import dayjs from 'dayjs';
import { noop } from 'lodash-es';
import { boolean, mixed, object, string } from 'yup';

const defaultFieldSchema = {
  errorMessage: string().default(''),
  hasError: boolean().default(false),
  touched: boolean().default(false)
};

/**
 * formSchema
 * @param {Function} handleRequiredValidation
 * @returns
 */
export const formSchema = ({
  handleDateValidation = noop,
  handleRequiredValidation = noop
}) =>
  object({
    currency: object({
      ...defaultFieldSchema,
      value: object().required(handleRequiredValidation).default(null)
    }),
    formState: object({
      isTouched: boolean().default(false)
    }),
    phase: object({
      ...defaultFieldSchema,
      value: object().required(handleRequiredValidation).default(null)
    }),
    productType: object({
      ...defaultFieldSchema,
      value: object().required(handleRequiredValidation).default(null)
    }),
    registryCost: object({
      ...defaultFieldSchema,
      value: mixed().required(handleRequiredValidation).default('')
    }),
    startDate: object({
      ...defaultFieldSchema,
      value: mixed()
        .test('is-valid-date', handleDateValidation, value => {
          return dayjs(value).isValid();
        })
        .required(handleRequiredValidation)
        .default(null)
    }),
    tld: object({
      ...defaultFieldSchema,
      value: object().required(handleRequiredValidation).default(null)
    })
  });
