import { TextField } from '@mui/material';
import React from 'react';

import { formatForDisplay } from '../../../../utils/number-price-text';

export const DotSiForm = ({ specialRequirements }) => (
  <TextField
    disabled
    label="Contact Type"
    value={formatForDisplay(specialRequirements.DOTSI.type)}
  />
);
