import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { selectCustomerName } from '../../common/store/customers-slice';
import { selectReseller } from '../../common/store/reseller';
import { selectCanWrite } from '../auth/auth-slice';
import { ZoneFormDialog } from './ZoneFormDialog';
import { ZoneRecordSetsGrid } from './ZoneRecordSetsGrid';
import { selectSelectedZones, setSelectedZones } from './selected-zone-slice';
import { useGetActiveZonesForCustomerQuery } from './zone-management-api-slice';

export const ZoneDetailsPage = () => {
  const selectedZone = useSelector(selectSelectedZones)[0];

  const customerName = useSelector(selectCustomerName);
  const reseller = useSelector(selectReseller);
  const canWrite = useSelector(selectCanWrite);

  const { zoneId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data } = useGetActiveZonesForCustomerQuery({
    customerName,
    reseller
  });

  // updates selected zones when data is updated
  useEffect(() => {
    if (data) {
      const zone = data.filter(zone => zone.id === parseInt(zoneId, 10))[0];

      dispatch(setSelectedZones([zone]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderPage = () => (
    <Box
      sx={{
        backgroundColor: 'var(--secondary-light)',
        height: '100%',
        position: 'relative',
        top: '-48px'
      }}
    >
      <Button
        onClick={() => navigate(`/zone-management/zones`)}
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        sx={{
          alignSelf: 'start',
          fontSize: '0.875rem',
          fontWeight: '700',
          height: '48px',
          lineHeight: '1.25',
          mb: 1,
          ml: 3
        }}
      >
        Back
      </Button>
      <Paper
        sx={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 48px)',
          width: '100%'
        }}
        variant="outlined"
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Stack spacing={1} sx={{ m: 3, width: '50%' }}>
            <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>
              {selectedZone?.zoneName ?? ''}
            </Typography>
            <Typography>{selectedZone?.description ?? ''}</Typography>
            <Typography sx={{ color: 'grey' }}>
              DNS Name: {selectedZone?.dnsName ?? ''}
            </Typography>
          </Stack>
          {canWrite && <ZoneFormDialog isEdit={true} />}
        </Box>
        <ZoneRecordSetsGrid />
      </Paper>
    </Box>
  );

  const renderBackdrop = () => (
    <Backdrop open={selectedZone === undefined}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  return selectedZone !== undefined ? renderPage() : renderBackdrop();
};
