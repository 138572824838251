import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectActiveCustomer,
  selectIsDialogOpen,
  selectPendingCustomer,
  setActiveCustomer,
  setIsDialogOpen,
  setPendingActiveCustomer
} from '../../common/store/customers-slice';
import { CustomerDropdown } from './CustomerDropdown';
import styles from './CustomerSelection.module.css';

export const CustomerSelection = () => {
  const dispatch = useDispatch();
  const activeCustomer = useSelector(selectActiveCustomer);
  const pendingActiveCustomer = useSelector(selectPendingCustomer);
  const isDialogOpen = useSelector(selectIsDialogOpen);

  const handleSubmit = () => {
    dispatch(setIsDialogOpen(false));
    dispatch(setActiveCustomer(pendingActiveCustomer));
  };

  const handleCancel = () => {
    if (!activeCustomer) {
      return;
    }

    dispatch(setIsDialogOpen(false));
    dispatch(setPendingActiveCustomer(null));
  };

  useEffect(() => {
    if (!activeCustomer) {
      dispatch(setIsDialogOpen(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoadingPendingCustomer = pendingActiveCustomer === null;
  const contentText = activeCustomer
    ? 'Are you sure you want to switch customers?'
    : 'Are you sure you want to sign in using this customer?';

  return (
    <Dialog maxWidth="lg" onClose={handleCancel} open={isDialogOpen}>
      <DialogTitle sx={{ minWidth: '600px' }}>
        Selected Customer: {pendingActiveCustomer?.organizationMainContactName}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
        {!activeCustomer && (
          <>
            <div className={styles.dropDownLabel}>
              Or, Choose a Different Customer
            </div>
            <CustomerDropdown isInHeader={false} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1}>
          {activeCustomer && (
            <Button onClick={handleCancel} variant="outlined">
              Cancel
            </Button>
          )}
          <Button
            data-testid="CustomerSelectionSubmit"
            disabled={isLoadingPendingCustomer}
            onClick={handleSubmit}
            sx={{ m: 1 }}
            variant="contained"
          >
            {activeCustomer ? 'Switch to ' : 'Sign in using '}
            {pendingActiveCustomer?.organizationMainContactName}
          </Button>
        </Stack>
      </DialogActions>
      {isLoadingPendingCustomer && (
        <Box className={styles.loadingOverlay}>
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};
