/* eslint-disable complexity */
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Link,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { noop } from 'lodash-es';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { ExportButton } from '../../common/components/ExportButton';
import { useAppInfoQuery } from '../../common/store/app-info-api-slice';
import { formatPrice } from '../../common/utils/number-price-text';
import {
  selectCanWrite,
  selectIsSuperAdminOrFinance
} from '../auth/auth-slice';
import classes from './RegisterDomains.module.css';
import { getCsvHeaderArray } from './csv-header-mapping';
import {
  selectCart,
  selectCartIsValid,
  selectHasAcceptedDomainAgreement,
  selectHasPerformedSearch,
  selectIsAddingDomainToCart,
  selectIsRemovingDomainFromCart,
  selectIsUpdatingDomainInCart,
  selectSearchResultsTableData,
  selectTotalPriceOfSelectedDomains,
  selectTotalResultsAvailableCount,
  selectTotalResultsCount,
  setHasAcceptedDomainAgreement
} from './register-domains-slice';
import {
  isCurrentStepByName,
  navigateToNextRegistrationStep,
  navigateToPreviousRegistrationStep,
  shouldHideBackButton,
  shouldShowExportButton
} from './registration-navigation';

export const RegisterDomainsFooter = ({ handleNextStepClick = noop }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stepNameParam = useParams().stepName;
  const cart = useSelector(selectCart);
  const totalPrice = useSelector(selectTotalPriceOfSelectedDomains);
  const searchResultsTableData = useSelector(selectSearchResultsTableData);
  const totalResultsCount = useSelector(selectTotalResultsCount);
  const totalResultsAvailableCount = useSelector(
    selectTotalResultsAvailableCount
  );
  const hasPerformedSearch = useSelector(selectHasPerformedSearch);
  const isAddingDomainToCart = useSelector(selectIsAddingDomainToCart);
  const isRemovingDomainFromCart = useSelector(selectIsRemovingDomainFromCart);
  const isUpdatingDomainInCart = useSelector(selectIsUpdatingDomainInCart);
  const canWrite = useSelector(selectCanWrite);
  const isSuperAdminOrFinance = useSelector(selectIsSuperAdminOrFinance);
  const cartIsValid = useSelector(selectCartIsValid);
  const hasAcceptedDomainAgreement = useSelector(
    selectHasAcceptedDomainAgreement
  );
  const isDomainDetailsStep = isCurrentStepByName(
    'domain-details',
    stepNameParam
  );
  const isCheckoutStep = isCurrentStepByName('checkout', stepNameParam);

  const handleNextStep = () => {
    navigateToNextRegistrationStep(navigate, stepNameParam);
    if (isDomainDetailsStep || isCheckoutStep) {
      handleNextStepClick();
    }
  };

  const handleBack = () => {
    navigateToPreviousRegistrationStep(navigate, stepNameParam);
  };

  const isCheckoutStepAndHasNotAcceptedDomainAgreement =
    isCheckoutStep && !hasAcceptedDomainAgreement;

  const isDomainDetailsStepAndCannotProceed =
    isDomainDetailsStep && !cartIsValid;

  const isNextButtonDisabled =
    isAddingDomainToCart ||
    isRemovingDomainFromCart ||
    !cart?.length ||
    isDomainDetailsStepAndCannotProceed ||
    isCheckoutStepAndHasNotAcceptedDomainAgreement;

  const { data: appInfoData } = useAppInfoQuery({ skip: !isCheckoutStep });

  const handleDomainAgreementCheckboxClick = () =>
    dispatch(
      setHasAcceptedDomainAgreement({ value: !hasAcceptedDomainAgreement })
    );

  return (
    <Paper
      sx={{
        alignItems: 'center',
        bottom: 0,
        display: 'inline-flex',
        height: '64px',
        justifyContent: 'space-between',
        left: 0,
        position: 'fixed',
        right: 0
      }}
      variant="outlined"
    >
      {hasPerformedSearch && (
        <>
          <Typography
            sx={{
              fontWeight: 700,
              marginLeft: 2
            }}
            variant="body2"
          >{`${totalResultsCount} Total`}</Typography>
          <Typography sx={{ fontWeight: 700, mx: 2 }} variant="body2">{`  
            ${totalResultsAvailableCount} Available
            `}</Typography>
          {shouldShowExportButton(stepNameParam) && (
            <ExportButton
              color="secondary"
              data={searchResultsTableData}
              filenamePostfix="_registration_export"
              headers={getCsvHeaderArray(isSuperAdminOrFinance)}
              size="medium"
              variant="contained"
            />
          )}
        </>
      )}
      {!shouldHideBackButton(stepNameParam) && (
        <Button
          color="secondary"
          disabled={false}
          onClick={handleBack}
          sx={{ marginLeft: 2 }}
          variant="contained"
        >
          Back
        </Button>
      )}
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          marginLeft: 'auto',
          marginRight: 2
        }}
      >
        {!shouldHideBackButton(stepNameParam) && (
          <Box sx={{ position: 'relative' }}>
            <Stack alignItems="center" direction="row">
              {isCheckoutStep && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasAcceptedDomainAgreement}
                      onChange={handleDomainAgreementCheckboxClick}
                    />
                  }
                  label={
                    <Typography
                      maxWidth={300}
                      sx={{ fontSize: '12px' }}
                      variant="body2"
                    >
                      {appInfoData?.$domainServiceAgreementParts?.prefix}
                      <Link
                        href={appInfoData?.$domainServiceAgreementParts?.url}
                        rel="noopener"
                        target="_blank"
                      >
                        {appInfoData?.$domainServiceAgreementParts?.linkText}
                      </Link>
                      {appInfoData?.$domainServiceAgreementParts?.suffix}
                    </Typography>
                  }
                />
              )}
              <Typography
                className={isUpdatingDomainInCart ? classes.loading : ''}
                variant="h6"
              >
                {`Total: $${formatPrice(totalPrice)}`}
              </Typography>
              {isUpdatingDomainInCart && (
                <CircularProgress
                  size={24}
                  sx={{
                    left: '50%',
                    marginLeft: '-12px',
                    marginTop: '-12px',
                    position: 'absolute',
                    top: '50%'
                  }}
                />
              )}
            </Stack>
          </Box>
        )}
        {canWrite && (
          <Button
            disabled={isNextButtonDisabled}
            endIcon={<FontAwesomeIcon icon={faChevronRight} />}
            onClick={handleNextStep}
            sx={{ marginLeft: 2 }}
            variant="contained"
          >
            {isCheckoutStep ? 'Submit order' : `Next (${cart?.length} items)`}
          </Button>
        )}
      </Box>
    </Paper>
  );
};
