import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Paper } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router';

import {
  navigateToNextTransferStep,
  navigateToPreviousTransferStep,
  shouldHideBackButton
} from './transfer-navigation';

export const Footer = ({ isNextDisabled = false, nextButton = null }) => {
  const navigate = useNavigate();
  const stepNameParam = useParams().stepName;
  const handleNextStep = () =>
    navigateToNextTransferStep(navigate, stepNameParam);
  const handleBack = () =>
    navigateToPreviousTransferStep(navigate, stepNameParam);

  return (
    <Paper
      sx={{
        alignItems: 'center',
        bottom: 0,
        display: 'inline-flex',
        justifyContent: 'space-between',
        left: 0,
        position: 'fixed',
        px: 3,
        py: 2,
        right: 0
      }}
      variant="outlined"
    >
      {!shouldHideBackButton(stepNameParam) ? (
        <Button
          color="secondary"
          disabled={false}
          onClick={handleBack}
          variant="contained"
        >
          Back
        </Button>
      ) : (
        <span />
      )}
      {nextButton ? (
        nextButton
      ) : (
        <Button
          disabled={isNextDisabled}
          endIcon={<FontAwesomeIcon icon={faChevronRight} width={12} />}
          onClick={handleNextStep}
          variant="contained"
        >
          Next
        </Button>
      )}
    </Paper>
  );
};
