export const gridColumnWidths = Object.freeze({
  currency: {
    width: 100
  },
  domainName: {
    flex: 2,
    minWidth: 220
  },
  formattedDate: {
    width: 180
  },
  icon: {
    width: 80
  },
  labels: {
    flex: 2
  },
  price: {
    width: 120
  },
  tld: {
    width: 150
  }
});
