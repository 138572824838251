import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectCustomerName } from '../../common/store/customers-slice';
import { selectReseller } from '../../common/store/reseller';
import { useGetTldsPricingByCustomerQuery } from '../../common/store/tlds-api-slice';
import { getTldNameForDomain } from '../../common/utils/get-tld-name-for-domain';
import { updateById } from './transfer-slice';

const YearsCell = ({ domainName, id, value }) => {
  const dispatch = useDispatch();
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const [yearOptions, setYearOptions] = useState(['1']);

  const { data: tldPricingData, isLoading: isTldPricingLoading } =
    useGetTldsPricingByCustomerQuery({
      customerName,
      reseller
    });

  const validateAndDispatchYear = (yearOptions, value) => {
    const updateYearValue = value => {
      dispatch(
        updateById({
          changes: [{ key: 'years', value }],
          id
        })
      );
    };
    if (!yearOptions.some(year => year === value)) {
      updateYearValue(yearOptions[0]);
    } else {
      updateYearValue(value);
    }
  };

  useEffect(() => {
    if (tldPricingData) {
      const domainNameTld = getTldNameForDomain(domainName);
      const tld = tldPricingData.find(tld => tld.name === domainNameTld);
      if (tld) {
        const transferPrices = tld.prices[tld.phase].transfer;
        const yearOptions = Object.keys(transferPrices).sort(
          (yearA, yearB) => yearA - yearB
        );
        setYearOptions(yearOptions);
        validateAndDispatchYear(yearOptions, value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tldPricingData, domainName]);

  const handleValueChange = (event, value) =>
    dispatch(
      updateById({
        changes: [{ key: 'years', value }],
        id
      })
    );

  return isTldPricingLoading ? (
    <CircularProgress />
  ) : (
    <Autocomplete
      disableClearable
      getOptionLabel={value => value.toString()}
      loading={isTldPricingLoading}
      onChange={handleValueChange}
      options={yearOptions}
      renderInput={params => <TextField {...params} variant="outlined" />}
      size="small"
      sx={{ width: '100%' }}
      value={value ?? yearOptions[0]}
    />
  );
};

export const YearsCellMemoized = React.memo(YearsCell);
