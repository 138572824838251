const SEEN_COOKIE_KEY = 'tracerStoredCookie';

/**
 * Set user has seen cookie toast in local storage
 */
export const setSeenCookie = () => {
  window.localStorage.setItem(SEEN_COOKIE_KEY, 'yes');
};

/**
 * Function to determine if cookie toast has been seen
 * @returns {Boolean} cookie toast has been seen
 */
export const haveSeenCookieNotice = () =>
  window.localStorage.getItem(SEEN_COOKIE_KEY) === 'yes';
