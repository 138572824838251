import { apiSlice } from '../../common/store/api-slice';
import { entityTypes } from '../hooks/use-job-response';

export enum POLLING_INTERVAL {
  active = 1000,
  off = 0
}

export const POLLING_MAX_REQUESTS = 20;

export const POLLING_MAX_ITEMS_DISPLAYED = 4;

export enum LINE_ITEM_STATUSES {
  success = 'SUCCESS',
  failure = 'FAILURE',
  pending = 'PENDING'
}

const pendingMessage = (clientLineItemId: string) => ({
  status: LINE_ITEM_STATUSES.pending,
  clientLineItemId,
  missing: true
});

const getEntityNameFromLineItems = (
  lineItems: LineItem[],
  clientLineItemId: string,
  entityLabelPropName: entityTypes
): string | null => {
  const lineItemForClientLineItemId = lineItems.find(
    lineItem => lineItem.clientLineItemId === clientLineItemId
  );

  return lineItemForClientLineItemId?.[entityLabelPropName] || null;
};

export const jobResponseApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    pollJobResponse: builder.query<
      JobResponse,
      {
        clientId: string;
        clientLineItemIds: Array<string | number>;
        lineItems: LineItem[];
        entityLabelPropName: entityTypes;
      }
    >({
      query: ({
        clientId,
        clientLineItemIds,
        // eslint-disable-next-line no-unused-vars
        lineItems,
        // eslint-disable-next-line no-unused-vars
        entityLabelPropName = entityTypes.domain
      }): { url: string; params: URLSearchParams } => {
        const params = new URLSearchParams({
          after: '0',
          sessionId: clientId
        });
        // @ts-ignore
        if (import.meta.env.MODE === 'test') {
          params.append('clientLineItemIds', clientLineItemIds.join(','));
        }
        return {
          url: `job-response/poll`,
          params
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (
        { messages }: ApiJobResponse,
        meta,
        { clientLineItemIds, lineItems = [], entityLabelPropName }
      ): JobResponse => {
        /**
         * @description an array of messages by clientLineItemId. If message is not found in API response, add a pending message in it's place
         * @type {Object[]}
         */
        const messagesForClientLineItemIds = clientLineItemIds
          // API always returns clientLineItemId as a string
          .map(clientLineItemId => clientLineItemId.toString())
          .map(
            clientLineItemId =>
              messages.find(
                message =>
                  message.jobResponse.clientLineItemId === clientLineItemId
              )?.jobResponse || pendingMessage(clientLineItemId)
          )
          .map(message => ({
            ...message,
            entityName: getEntityNameFromLineItems(
              lineItems,
              message.clientLineItemId,
              entityLabelPropName
            )
          }));

        const completed = messagesForClientLineItemIds.filter(
          ({ status }) =>
            LINE_ITEM_STATUSES.failure === status ||
            LINE_ITEM_STATUSES.success === status
        );

        const allComplete =
          completed.length === messagesForClientLineItemIds.length;

        const response = {
          // Setting lastCheck to a new value for each response ensures that
          // a useEffect hook with this response as a dependency runs the
          // setup function each time this response is returned.
          lastCheck: Date.now(),
          messages: messagesForClientLineItemIds,
          successMessages: messagesForClientLineItemIds.filter(
            ({ status }) => status === LINE_ITEM_STATUSES.success
          ),
          successfulLineItemNames: messagesForClientLineItemIds
            .filter(({ status }) => status === LINE_ITEM_STATUSES.success)
            .map(({ entityName }) => entityName),
          failureMessages: messagesForClientLineItemIds.filter(
            ({ status }) => status === LINE_ITEM_STATUSES.failure
          ),
          failedLineItemNames: messagesForClientLineItemIds
            .filter(({ status }) => status === LINE_ITEM_STATUSES.failure)
            .map(({ entityName }) => entityName),
          pendingMessages: messagesForClientLineItemIds.filter(
            ({ status }) => status === LINE_ITEM_STATUSES.pending
          ),
          pendingLineItemNames: messagesForClientLineItemIds
            .filter(({ status }) => status === LINE_ITEM_STATUSES.pending)
            .map(({ entityName }) => entityName),
          allSuccess: messagesForClientLineItemIds.every(
            ({ status }) => status === LINE_ITEM_STATUSES.success
          ),
          anySuccess: messagesForClientLineItemIds.some(
            ({ status }) => status === LINE_ITEM_STATUSES.success
          ),
          anyFailures:
            messagesForClientLineItemIds.some(
              ({ status }) => status === LINE_ITEM_STATUSES.failure
            ) && allComplete,
          allComplete,
          anyPending: messagesForClientLineItemIds.some(
            ({ status }) => status === LINE_ITEM_STATUSES.pending
          ),
          progress:
            (completed.length / messagesForClientLineItemIds.length) * 100
        };

        return response;
      }
    })
  })
});

export const { usePollJobResponseQuery } = jobResponseApiSlice;
