import { Button, Dialog, Paper } from '@mui/material';
import {
  bindDialog,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React from 'react';
import { useSelector } from 'react-redux';

import { LabelsSelection } from '../../common/components/labels-selection/LabelsSelection';
import { selectSelectedTransfers } from './transfer-slice';

export const BulkApplyLabels = () => {
  const popupState = usePopupState({
    popupId: 'bulkApplyLabelsToTransfers',
    variant: 'dialog'
  });

  const selectedTransfers = useSelector(selectSelectedTransfers);

  return (
    <>
      <Button
        {...bindTrigger(popupState)}
        disabled={selectedTransfers.length === 0}
      >
        Labels
      </Button>
      <Dialog
        {...bindDialog(popupState)}
        PaperProps={{
          style: {
            overflowY: 'unset'
          }
        }}
      >
        <Paper sx={{ overflow: 'auto', p: 2 }} variant="outlined">
          <LabelsSelection selectedDomains={selectedTransfers} />
        </Paper>
      </Dialog>
    </>
  );
};
