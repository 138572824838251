import { faShoppingCart } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import {
  selectCanWrite,
  selectIsMemberOfCustomer,
  selectIsSystemAdmin
} from '../../../features/auth/auth-slice';
import { CustomerDropdown } from '../../../features/customer-selection/CustomerDropdown';
import { ProfileMenu } from '../../../features/profile-menu/ProfileMenu';
import { SettingsMenu } from '../../../features/settings-menu/SettingsMenu';
import { selectCustomerName } from '../../store/customers-slice';
import { Logo } from '../logo/Logo';
import { logoVariants } from '../logo/logos';
import styles from './Header.module.css';
import { HeaderPopover } from './HeaderPopover';
import { RegisterButton } from './RegisterButton';
import { TransferButton } from './TransferButton';

export const Header = () => {
  const isMemberOfCustomer = useSelector(selectIsMemberOfCustomer);
  const customerName = useSelector(selectCustomerName);
  const canWrite = useSelector(selectCanWrite);
  const isSystemAdmin = useSelector(selectIsSystemAdmin);

  const getNavLinkClassNames = ({ isActive }) =>
    isActive ? `Header-linkActive ${styles.primaryLink}` : styles.primaryLink;

  const getSecondaryNavLinkClassNames = ({ isActive }) =>
    isActive
      ? `Header-linkActive ${styles.secondaryLink}`
      : styles.secondaryLink;

  return (
    <>
      <AppBar position="static">
        <Toolbar className={styles.appToolbar}>
          <Logo
            alt="Logo"
            variant={logoVariants.HORIZONTAL_ON_DARK}
            width="206"
          />
          <Typography className={styles.appNameHeading} variant="h5">
            Registrar
          </Typography>
          {isMemberOfCustomer && (
            <Typography className={styles.customerNameHeader} variant="body1">
              {customerName}
            </Typography>
          )}
          {!isMemberOfCustomer && <CustomerDropdown isInHeader={true} />}
          <div className={styles.spacer}></div>
          <SettingsMenu />
          <div className={styles.spacer}></div>
          <ProfileMenu />
        </Toolbar>
      </AppBar>
      {!isSystemAdmin && (
        <Toolbar className={styles.navToolbar} disableGutters>
          <NavLink className={getNavLinkClassNames} to="/dashboard">
            Dashboard
          </NavLink>
          <NavLink className={getNavLinkClassNames} to="/domains">
            Domains
          </NavLink>
          <NavLink className={getNavLinkClassNames} to="/transfers">
            Transfers
          </NavLink>
          <HeaderPopover />
          <div className={styles.secondaryLinks}>
            {canWrite && (
              <NavLink className={getSecondaryNavLinkClassNames} to="/orders">
                <FontAwesomeIcon
                  className={styles.secondaryLinkIcon}
                  icon={faShoppingCart}
                />
                Orders
              </NavLink>
            )}
            <RegisterButton />
            {canWrite && <TransferButton />}
          </div>
        </Toolbar>
      )}
    </>
  );
};
