import { TextField } from '@mui/material';
import React from 'react';

export const DotEsForm = ({ specialRequirements }) => {
  const idTypes = Object.freeze({
    DNI_NIF: 'Spanish personal id or tax number',
    NIE: 'Spanish Foreign resident number',
    OTHER_IDENTIFICATION: 'Other Individual Number',
    VAT: 'Vat Number'
  });
  const legalFormOptions = Object.freeze({
    ASSOCIATION: 'Association',
    CENTRAL_GOVERNMENT_BODY: 'Central government body',
    CIVIL_SOCIETY: 'Civil society',
    COMMUNITY_OF_OWNERS: 'Community of owners',
    COMMUNITY_PROPERTY: 'Community property',
    CONSULATE: 'Consulate',
    COOPERATIVE: 'Cooperative',
    DESIGNATION_OF_ORIGIN_SUPERVISORY_COUNCIL:
      'Designation of origin supervisory council',
    ECONOMIC_INTEREST_GROUP: 'Economic interest group',
    EMBASSY: 'Embassy',
    ENTITY_MANAGING_NATURAL_AREAS: 'Entity managing natural areas',
    FARM_PARTNERSHIP: 'Farm partnership',
    FOUNDATION: 'Foundation',
    GENERAL_AND_LIMITED_PARTNERSHIP: 'General and limited partnership',
    GENERAL_PARTNERSHIP: 'General partnership',
    INDIVIDUAL: 'Individual',
    LIMITED_COMPANY: 'Limited company',
    LOCAL_AUTHORITY: 'Local authority',
    LOCAL_PUBLIC_ENTITY: 'Local public entity',
    MUTUAL_INSURANCE_COMPANY: 'Mutual insurance company',
    NATIONAL_PUBLIC_ENTITY: 'National public entity',
    ORDER_OR_RELIGIOUS_INSTITUTION: 'Order or religious institution',
    OTHERS: 'Others',
    POLITICAL_PARTY: 'Political party',
    PROFESSIONAL_ASSOCIATION: 'Professional association',
    PUBLIC_LAW_ASSOCIATION: 'Public law association',
    PUBLIC_LIMITED_COMPANY: 'Public limited company',
    REGIONAL_GOVERNMENT_BODY: 'Regional government body',
    REGIONAL_PUBLIC_ENTITY: 'Regional public entity',
    SAVINGS_BANK: 'Savings bank',
    SPANISH_OFFICE: 'Spanish office',
    SPORTS_ASSOCIATION: 'Sports association',
    SPORTS_FEDERATION: 'Sports federation',
    SPORTS_PUBLIC_LIMITED_COMPANY: 'Sports public limited company',
    TEMPORARY_ALLIANCE_OF_ENTERPRISES: 'Temporary alliance of enterprises',
    TRADE_UNION: 'Trade union',
    WORKER_OWNED_COMPANY: 'Worker owned company',
    WORKER_OWNED_LIMITED_COMPANY: 'Worker owned limited company'
  });

  const hasLegalForm = !!specialRequirements.DOTES.legalForm;

  return (
    <>
      <TextField
        disabled
        label="Identification Type"
        value={idTypes[specialRequirements.DOTES.identificationType]}
      />
      <TextField
        disabled
        label="Identification Number"
        value={specialRequirements.DOTES.identification}
      />
      {hasLegalForm && (
        <TextField
          disabled
          label="Legal Form"
          value={legalFormOptions[specialRequirements.DOTES.legalForm]}
        />
      )}
    </>
  );
};
