import { apiSlice } from '../../common/store/api-slice';
import { getCurrentDateTimeForFileName } from '../../common/utils/date-time';
import {
  bulkUpdateCart,
  updateDomainYearsSelected
} from './register-domains-slice';
import {
  getCartItemViewModel,
  getDomainCheckPayload,
  mapDomainObject
} from './register-domains-utils';

/* eslint-disable no-undef */
export const registerDomainsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addDomainToCart: builder.mutation({
      query: ({
        customerCartId,
        customerCartItemType,
        customerName,
        domainName,
        idnLanguage,
        reseller
      }) => ({
        body: {
          customerCartId,
          customerCartItemType,
          domainName,
          idnLanguage
        },
        method: 'POST',
        url: `/resellers/${reseller}/customers/${customerName}/cart-item`
      }),
      transformResponse: (cartItem, meta, { domain }) =>
        getCartItemViewModel(cartItem, domain)
    }),
    createNewCart: builder.mutation({
      query: ({ customerName, reseller }) => ({
        body: {
          accountName: customerName,
          name: `Current-Cart-${getCurrentDateTimeForFileName()}`
        },
        method: 'POST',
        url: `/resellers/${reseller}/customers/${customerName}/cart`
      })
    }),
    domainCheck: builder.mutation({
      query: ({
        customerName,
        idnLangsMap,
        onStreamValueCompleteHandler,
        onStreamValueNextHandler,
        reseller,
        searchString,
        supportedTlds,
        tldList,
        tldsData
      }) => {
        return {
          body: getDomainCheckPayload(
            tldList,
            searchString,
            supportedTlds,
            idnLangsMap
          ).responseBody,
          method: 'POST',
          // The api returns a JSON stream we have to parse
          responseHandler: response => {
            // Holds the array of domain info objects from the stream
            const returnDomainCheckValues = [];
            // Get array off unsupported domain objects searched by user
            const searchedUnsupportedDomains = getDomainCheckPayload(
              tldList,
              searchString,
              supportedTlds
            ).invalidDomainArray;

            // Splits NDJSON (New line delimited JSON) by new line
            const splitStream = splitOn => {
              let buffer = '';
              return new TransformStream({
                flush(controller) {
                  if (buffer) {
                    controller.enqueue(buffer);
                  }
                },
                transform(chunk, controller) {
                  buffer += chunk;
                  const parts = buffer.split(splitOn);
                  parts.slice(0, -1).forEach(part => controller.enqueue(part));
                  buffer = parts[parts.length - 1];
                }
              });
            };

            // Parses stream object to JSON
            const parseJSON = () => {
              return new TransformStream({
                transform(chunk, controller) {
                  controller.enqueue(JSON.parse(chunk));
                }
              });
            };

            // Recursively reads the stream
            const renderResults = async reader => {
              return await reader.read().then(({ done, value }) => {
                if (done) {
                  searchedUnsupportedDomains.forEach(domain =>
                    returnDomainCheckValues.push(domain)
                  );
                  onStreamValueCompleteHandler(returnDomainCheckValues);
                } else {
                  mapDomainObject(value, tldsData);
                  returnDomainCheckValues.push(value);
                  onStreamValueNextHandler(value);
                  renderResults(reader);
                  return returnDomainCheckValues;
                }
              });
            };

            const results = response.body
              .pipeThrough(new TextDecoderStream())
              .pipeThrough(splitStream('\n'))
              .pipeThrough(parseJSON());
            // Returns the array of domain info to store in redux once the stream has finished
            return renderResults(results.getReader());
          },
          url: `/resellers/${reseller}/customers/${customerName}/domain-check`
        };
      }
    }),
    getCustomerCategories: builder.query({
      query: ({ customerName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/domain-check-category`,
      transformResponse: (response, meta, arg) => {
        const mappedCategories = response.entity
          .map(category => ({
            defaultCategory: category.defaultCategory,
            id: category.id,
            label: category.name,
            mappedGrouping: category.systemCategory
              ? `${arg.reseller} Categories`
              : `${arg.customerName} Categories`,
            systemCategory: category.systemCategory,
            tlds: category.tlds,
            value: category.name
          }))
          .sort((a, b) => (a.systemCategory < b.systemCategory ? 1 : -1));
        const data = {
          customerCategories: mappedCategories.filter(
            category => !category.systemCategory
          ),
          customerDefaultCategory: mappedCategories.filter(
            category => category.defaultCategory && !category.systemCategory
          ),
          defaultSystemCategory: mappedCategories.filter(
            category => category.defaultCategory && category.systemCategory
          ),
          initialCategories: response.entity.filter(
            category => category.defaultCategory && !category.systemCategory
          ),
          mappedCategories,
          systemCategories: response.entity.filter(
            category => category.systemCategory
          )
        };
        if (data.customerDefaultCategory.length) {
          data.initialCategories = data.customerDefaultCategory;
        } else if (data.defaultSystemCategory.length) {
          data.initialCategories = data.defaultSystemCategory;
        } else {
          data.initialCategories = [data.mappedCategories[0]];
        }
        return data;
      }
    }),
    getIdnLanguages: builder.query({
      query: ({ tld }) => `/tlds/${tld}/idn`
    }),
    removeCart: builder.mutation({
      query: ({ cartId, customerName, reseller }) => ({
        method: 'DELETE',
        url: `/resellers/${reseller}/customers/${customerName}/cart/${cartId}`
      })
    }),
    removeDomainFromCart: builder.mutation({
      query: ({ customerName, domainId, reseller }) => ({
        method: 'DELETE',
        url: `/resellers/${reseller}/customers/${customerName}/cart-item/${domainId}`
      })
    }),
    updateCartItems: builder.mutation({
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const optimisticUpdateDispatch = dispatch(
          bulkUpdateCart(
            patch.cartItems.map((cartItem, index) =>
              getCartItemViewModel(cartItem, patch.cartItems[index])
            )
          )
        );
        try {
          await queryFulfilled;
        } catch {
          optimisticUpdateDispatch.undo();
        }
      },
      // eslint-disable-next-line no-unused-vars
      query: ({ cart, cartItems, customerName, reseller }) => ({
        body: cart,
        method: 'POST',
        url: `/resellers/${reseller}/customers/${customerName}/cart-item/update`
      })
    }),
    updateDomainInCart: builder.mutation({
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const optimisticUpdateDispatch = dispatch(
          updateDomainYearsSelected(patch.updatedDomainBody)
        );
        try {
          await queryFulfilled;
        } catch {
          optimisticUpdateDispatch.undo();
        }
      },
      query: ({ customerName, domainId, reseller, updatedDomainBody }) => ({
        body: updatedDomainBody,
        method: 'PUT',
        url: `/resellers/${reseller}/customers/${customerName}/cart-item/${domainId}`
      }),
      transformResponse: (cartItem, meta, { updatedDomainBody }) =>
        getCartItemViewModel(cartItem, updatedDomainBody)
    })
  })
});

export const {
  useAddDomainToCartMutation,
  useCreateNewCartMutation,
  useDomainCheckMutation,
  useGetCustomerCategoriesQuery,
  useGetIdnLanguagesQuery,
  useRemoveCartMutation,
  useRemoveDomainFromCartMutation,
  useUpdateCartItemsMutation,
  useUpdateDomainInCartMutation
} = registerDomainsApiSlice;
