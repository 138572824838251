import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import type { SignOffContextSignerFormSchema } from './schema';

import { selectActiveCustomer } from '../../common/store/customers-slice';
import { useUpdateSignOffContextPermissionMutation } from './sign-off-context-api-slice';
import { selectSelectedSignOffContext } from './sign-off-context-slice';

type SignOffContextPermissionSignersConfirmButtonProps = {
  handleClose: () => void;
  validateForm: () => Promise<SignOffContextSignerFormSchema>;
};

export const SignOffContextPermissionSignersConfirmButton = ({
  handleClose,
  validateForm
}: SignOffContextPermissionSignersConfirmButtonProps) => {
  const selectedSignOffContext = useSelector(selectSelectedSignOffContext);
  const selectedCustomer = useSelector(selectActiveCustomer);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [updateSignOffContextPermission] =
    useUpdateSignOffContextPermissionMutation();

  const handleSubmit = () => {
    setIsSubmitting(true);

    validateForm().then(formData =>
      updateSignOffContextPermission({
        context: selectedSignOffContext,
        customer: selectedCustomer,
        signers: formData.approvedSigners
      })
        .unwrap()
        .then(() => {
          handleClose();
          setIsSubmitting(false);

          enqueueSnackbar(
            'Sign-off approval permission updated successfully.',
            {
              variant: 'success'
            }
          );
        })
        .catch(() => {
          setIsSubmitting(false);

          enqueueSnackbar(
            'An unknown error occurred while updating sign-off approval permission. Please try again or contact support.',
            {
              variant: 'error'
            }
          );
        })
    );
  };

  return (
    <LoadingButton
      disabled={isSubmitting}
      loading={isSubmitting}
      onClick={handleSubmit}
    >
      Submit
    </LoadingButton>
  );
};
