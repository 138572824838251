import { camelCase } from 'lodash-es';

import { userStatuses } from '../../common/store/user-api-slice';
/**
 * Does array1 include any elements that are in array 2
 * @param {String[]} array1
 * @param {String[]} array2
 * @returns {Boolean} boolean
 */
const hasIntersection = (array1, array2) =>
  array1.some(value => array2.includes(value));

const SUPER_ADMIN_ROLES = ['SuperAdminRead', 'SuperAdminAll'];
const FINANCE_ROLES = ['Finance'];
const SYSTEM_ADMIN_ROLES = ['SystemAdmin'];
const CUSTOMER_ROLES = ['CustomerAdmin', 'CustomerAll', 'CustomerRead'];
const CUSTOMER_ADMIN_ROLES = ['CustomerAdmin'];

const ALWAYS_SEE_PENDING_SIGNOFFS_ROLES = [
  'CustomerAll',
  'CustomerAdmin',
  'SuperAdminAll'
];

const WRITE_ROLES = [
  'SuperAdminAll',
  'CustomerAdmin',
  'CustomerAll',
  'SystemAdmin'
];

const ADMIN_ROLES = [
  SUPER_ADMIN_ROLES,
  FINANCE_ROLES,
  SYSTEM_ADMIN_ROLES
].flat();

const isSuperAdmin = roles => hasIntersection(SUPER_ADMIN_ROLES, roles);
const isFinance = roles => hasIntersection(FINANCE_ROLES, roles);
const isSystemAdmin = roles => hasIntersection(SYSTEM_ADMIN_ROLES, roles);
const isCustomer = roles => hasIntersection(CUSTOMER_ROLES, roles);
const isCustomerAdmin = roles => hasIntersection(CUSTOMER_ADMIN_ROLES, roles);
const isAdmin = roles => hasIntersection(ADMIN_ROLES, roles);
const isSuperAdminOrFinance = roles =>
  hasIntersection([FINANCE_ROLES, SUPER_ADMIN_ROLES].flat(), roles);
// show the pending sign-offs card even if there's no pending sign offs
const canAlwaysSeePendingSignOffs = roles =>
  hasIntersection(ALWAYS_SEE_PENDING_SIGNOFFS_ROLES, roles);
const canWrite = roles => hasIntersection(WRITE_ROLES, roles);

// This transforms the auth response, and specifically the roles on the user obj.
// Could be renamed
export const transformUserResponse = response => {
  // if we are handling two factor auth, and we are validating
  // username + password, we don't have user/auth data yet
  if (response.user === undefined) {
    window.console.warn('Missing user. Cannot transform.');
    return response;
  }

  const user = response.user;
  // `transfer/${transferToken}` and `/authenticate/${reseller}/${userEmail}` return
  // different properties. Grab whichever one is present since this transform runs
  // on both of them. The user object is the same.
  user.accessToken = response.access_token || response.authenticationToken;
  const flatRoles = user.roles.map(role => role.roleName);

  user.active = user.status === userStatuses.active;

  user.$roles = {
    canAlwaysSeePendingSignOffs: canAlwaysSeePendingSignOffs(flatRoles),
    canWrite: canWrite(flatRoles),
    isAdmin: isAdmin(flatRoles),
    isCustomer: isCustomer(flatRoles),
    isCustomerAdmin: isCustomerAdmin(flatRoles),
    isFinance: isFinance(flatRoles),
    isMemberOfCustomer: Boolean(user?.customer?.accountName),
    isSuperAdmin: isSuperAdmin(flatRoles),
    isSuperAdminOrFinance: isSuperAdminOrFinance(flatRoles),
    isSystemAdmin: isSystemAdmin(flatRoles)
  };
  user.enforceTwoFactorAuth = response.enforceTwoFactorAuth;

  user.$links = {};
  Object.entries(user?._links || {})?.forEach(([key, value]) => {
    user.$links[camelCase(key)] = value?.href;
  });

  return user;
};
