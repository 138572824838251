import { formSchema } from './tld-cost-form.schema';
import { formSchemaMultiple } from './tld-cost-form-multiple.schema';

export const handleRequiredValidation =
  (forceTouch, formDataDispatch) =>
  ({ path }) => {
    formDataDispatch({
      errorMessage: 'This field is required',
      forceTouch,
      path,
      type: 'SET_ERROR'
    });
  };

export const handleDateValidation =
  (forceTouch, formDataDispatch) =>
  ({ path }) => {
    formDataDispatch({
      errorMessage: 'The date is invalid.',
      forceTouch,
      path,
      type: 'SET_ERROR'
    });
  };

export const handleNumberValidation =
  (forceTouch, formDataDispatch) =>
  ({ path }) => {
    formDataDispatch({
      errorMessage: 'Enter a valid number.',
      forceTouch,
      path,
      type: 'SET_ERROR'
    });
  };

export const handleChange = ({
  debouncedValidation,
  formDataDispatch,
  path,
  value
}) => {
  formDataDispatch({
    path,
    type: 'UPDATE',
    value
  });
  debouncedValidation(formSchema);
};

export const handleChangeMultiple = ({
  debouncedValidation,
  formDataDispatch,
  path,
  value
}) => {
  formDataDispatch({
    path,
    type: 'UPDATE',
    value
  });
  debouncedValidation(formSchemaMultiple);
};

export const handleRegistryCostChangeMultiple = (
  event,
  formDataDispatch,
  debouncedValidation
) => {
  formDataDispatch({
    path: 'registryCost',
    type: 'UPDATE',
    value: event.target.value
  });
  debouncedValidation(formSchemaMultiple);
};
export const handleRegistryCostChange = (
  event,
  formDataDispatch,
  debouncedValidation
) => {
  formDataDispatch({
    path: 'registryCost',
    type: 'UPDATE',
    value: event.target.value
  });
  debouncedValidation(formSchema);
};

export const handleDateChange = ({
  debouncedValidation,
  formDataDispatch,
  path,
  value
}) => {
  formDataDispatch({
    path,
    type: 'UPDATE',
    value
  });
  debouncedValidation(formSchema);
};

export const handleDateChangeMultiple = ({
  debouncedValidation,
  formDataDispatch,
  path,
  value
}) => {
  formDataDispatch({
    path,
    type: 'UPDATE',
    value
  });
  debouncedValidation(formSchemaMultiple);
};
