import registrarSafeLogo from '../../../images/logos/registrar-safe-logo.png';
import registrarSecLogo from '../../../images/logos/registrar-sec-logo.png';
import tracerLogo from '../../../images/logos/tracer-combined-horizontal-color.svg';
import tracerLogoOnDarkBackground from '../../../images/logos/tracer-combined-horizontal-color-dark-background.svg';

export enum logoVariants {
  HORIZONTAL = 'horizontal',
  HORIZONTAL_ON_DARK = 'horizontalOnDark'
}

export const logos = Object.freeze({
  REGISTRAR_SEC: {
    key: 'REGISTRAR_SEC',
    [logoVariants.HORIZONTAL]: registrarSecLogo,
    [logoVariants.HORIZONTAL_ON_DARK]: registrarSecLogo
  },
  REGISTRAR_SAFE: {
    key: 'REGISTRAR_SAFE',
    [logoVariants.HORIZONTAL]: registrarSafeLogo,
    [logoVariants.HORIZONTAL_ON_DARK]: registrarSafeLogo
  },
  TRACER: {
    key: 'TRACER',
    [logoVariants.HORIZONTAL]: tracerLogo,
    [logoVariants.HORIZONTAL_ON_DARK]: tracerLogoOnDarkBackground
  }
});

export const getLogo = (key: string): LogosAndKey => {
  const logosAndKey = Object.values(logos).find(logo => logo.key === key);

  if (logosAndKey) {
    return logosAndKey;
  } else {
    throw new Error(`Could not find logo for ${key}`);
  }
};
