import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Button } from '@mui/material';
import React from 'react';
import { CSVLink } from 'react-csv';

import { getCurrentDateTimeForFileName } from '../utils/date-time';
import styleClasses from './ExportButton.module.css';

export const ExportButton = ({
  color,
  data,
  filenamePostfix,
  headers,
  size,
  variant
}) =>
  data ? (
    <CSVLink
      className={styleClasses.csvLink}
      data={data}
      filename={`${getCurrentDateTimeForFileName()}${filenamePostfix}`}
      headers={headers}
    >
      <Button
        color={color}
        size={size}
        startIcon={<SaveAltIcon />}
        variant={variant}
      >
        Export
      </Button>
    </CSVLink>
  ) : (
    <Button
      color={color}
      disabled={true}
      startIcon={<SaveAltIcon />}
      variant="contained"
    >
      Export
    </Button>
  );
