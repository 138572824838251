export const tldCostFormReducer = (state, action) => {
  if (action.path) {
    action.path = action.path.split('.')[0];
  }
  switch (action.type) {
    case 'INIT':
      state = { ...action.value, formState: { isTouched: false } };
      break;
    case 'UPDATE':
      state[action.path].value = action.value;
      state[action.path].touched = true;
      state[action.path].errorMessage = '';
      state[action.path].hasError = false;
      state.formState.isTouched = true;
      break;
    case 'SET_ERROR':
      state[action.path].hasError =
        state[action.path].touched || action.forceTouch;
      state[action.path].errorMessage =
        state[action.path].hasError && action.errorMessage;
      break;
    default:
      break;
  }

  return { ...state };
};
