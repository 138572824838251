export const productTypeOptions = Object.freeze([
  { label: 'Registration', value: 'REGISTRATION' },
  { label: 'Renewal', value: 'RENEWAL' },
  { label: 'Transfer', value: 'TRANSFER' },
  {
    label: 'Local Presence',
    value: 'LOCAL_PRESENCE'
  },
  {
    label: 'Registry Lock',
    value: 'REGISTRY_LOCK'
  },
  {
    label: 'Web3 Registration',
    value: 'WEB3_REGISTRATION'
  }
]);
