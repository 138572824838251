import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

import { zoneManagementBasePaths } from './zone-management-base-paths';

export const ZoneManagementTabs = ({ index }) => {
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    switch (newValue) {
      // case 0:
      //   navigate(zoneManagementBasePaths.dashboard);
      //   break;
      case 0:
        navigate(zoneManagementBasePaths.zones);
        break;
      // case 2:
      //   navigate(zoneManagementBasePaths.webForwarding);
      //   break;
      default:
        break;
    }
  };

  return (
    <Tabs onChange={handleTabChange} sx={{ pl: 3 }} value={index}>
      {/*<Tab label="Dashboard" />*/}
      <Tab label="Zones" />
      {/*<Tab label="Web Forwarding" />*/}
    </Tabs>
  );
};
