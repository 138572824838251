import React from 'react';

import { AddTransfersStep } from './AddTransfersStep';
import { CompletedTransferStep } from './CompletedTransferStep';
import { Error } from './Error';
import { InitiateTransferStep } from './InitiateTransferStep';
import { TransferDetailsStep } from './TransferDetailsStep';

const transferInSteps = Object.freeze([
  {
    component: <AddTransfersStep />,
    hideBackButton: true,
    path: '/transfer/add-transfer',
    stepName: 'add-transfer'
  },
  {
    component: <TransferDetailsStep />,
    path: '/transfer/domain-details',
    stepName: 'domain-details'
  },
  {
    component: <InitiateTransferStep />,
    path: '/transfer/initiate-transfer',
    stepName: 'initiate-transfer'
  },
  {
    component: <CompletedTransferStep />,
    path: '/transfer/completed-transfer',
    stepName: 'completed-transfer'
  },
  {
    component: <Error />,
    path: '/transfer/error',
    stepName: 'error'
  }
]);

const getCurrentStepByName = stepNameParam =>
  transferInSteps.find(({ stepName }) => stepName === stepNameParam);
const getCurrentStepIndexByName = stepNameParam =>
  transferInSteps.findIndex(({ stepName }) => stepName === stepNameParam);

export const getTransferComponent = stepNameParam =>
  getCurrentStepByName(stepNameParam).component;

export const getActiveStepIndex = stepNameParam =>
  getCurrentStepIndexByName(stepNameParam);

export const navigateToNextTransferStep = (navigate, stepNameParam) =>
  navigate(transferInSteps[getCurrentStepIndexByName(stepNameParam) + 1].path);

export const navigateToPreviousTransferStep = (navigate, stepNameParam) =>
  navigate(transferInSteps[getCurrentStepIndexByName(stepNameParam) - 1].path);

export const navigateToFirstTransferStep = navigate =>
  navigate(transferInSteps[0].path);
export const navigateToErrorStep = navigate =>
  navigate(transferInSteps[4].path);

export const getFirstTransferStepPath = () => transferInSteps[0].path;
export const getFirstStepName = () => transferInSteps[0].stepName;

export const isCurrentStepByName = (name, stepNameParam) =>
  name === stepNameParam;

export const currentStepHasOwnFooter = stepNameParam =>
  getCurrentStepByName(stepNameParam).hasOwnFooter;

export const shouldHideBackButton = stepNameParam =>
  getCurrentStepByName(stepNameParam).hideBackButton;
