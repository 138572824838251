import React from 'react';
import { Stack, Tooltip, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';

export type BooleanDataGridCellProps = {
  fieldDisplayName: string;
  value: boolean;
};

export const BooleanDataGridCell = (props: BooleanDataGridCellProps) => {
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={1}>
      {props.value && (
        <Tooltip title={props.fieldDisplayName + ' is enabled'}>
          <FontAwesomeIcon
            icon={faCheck}
            color={theme.palette.success.main}
            data-testid="fa-check"
          />
        </Tooltip>
      )}
      {!props.value && (
        <Tooltip title={props.fieldDisplayName + ' is not enabled'}>
          <FontAwesomeIcon
            icon={faTimes}
            color={theme.palette.error.main}
            data-testid="fa-times"
          />
        </Tooltip>
      )}
    </Stack>
  );
};
