import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack
} from '@mui/material';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { selectReseller } from '../../common/store/reseller';
import { useGetTldPhaseByTldNameQuery } from '../../common/store/tld-phase-api-slice';
import { useGetSupportedYearsByTldNameQuery } from '../../common/store/tlds-api-slice';
import { CurrencyInput } from './CurrencyInput';
import { EffectiveDateInput } from './EffectiveDateInput';
import { ProductTypeInput } from './ProductTypeInput';
import { RegistryCostInput } from './RegistryCostInput';
import { TldCostsLoading } from './TldCostsLoading';
import { TldNameInput } from './TldNameInput';
import { TldPhaseInput } from './TldPhaseInput';
import { productTypeOptions } from './product-type-options';
import {
  useGetTldCostCurrencyQuery,
  useGetTldCostsQuery,
  useGetTldNamesQuery,
  useUpdateTldCostsMutation
} from './tld-cost-api-slice';
import { formSchema } from './tld-cost-form.schema';
import {
  handleChange,
  handleDateChange,
  handleDateValidation,
  handleRegistryCostChange,
  handleRequiredValidation
} from './tld-cost-form-functions';
import { tldCostFormReducer } from './tld-cost-form-reducer';

// eslint-disable-next-line complexity
export const TldCostEditOneForm = () => {
  const reseller = useSelector(selectReseller);
  const navigate = useNavigate();
  const { tldCostIds } = useParams();
  const tldCostIdsArray = tldCostIds?.split(',');
  const { enqueueSnackbar } = useSnackbar();

  const [validationErrors, setValidationErrors] = useState(false);

  const handleClose = () => navigate('/tld-costs');

  const [
    updateTldCosts,
    {
      isError: isUpdateTldCostsError,
      isLoading: isUpdateTldCostsLoading,
      isSuccess: isUpdateTldCostsSuccess
    }
  ] = useUpdateTldCostsMutation();

  const { tldCosts } = useGetTldCostsQuery(
    {
      reseller
    },
    {
      selectFromResult: ({ data = [] }) => ({
        tldCosts: data.filter(tldCost =>
          tldCostIdsArray.some(tldCostId => parseInt(tldCostId) === tldCost.id)
        )
      }),
      skip: !tldCostIdsArray?.length
    }
  );

  const { data: currencies } = useGetTldCostCurrencyQuery({
    reseller
  });

  const { data: tldNames } = useGetTldNamesQuery({
    reseller
  });

  const handleSubmit = event => {
    event.preventDefault();
    setValidationErrors(false);
    formSchema({
      handleDateValidation: handleDateValidation(true, formDataDispatch),
      handleRequiredValidation: handleRequiredValidation(true, formDataDispatch)
    })
      .validate(formData)
      .then(() => {
        const tldCostDataFromForm = {
          currency: formData.currency.value?.code,
          phase: formData.phase.value.id,
          productType: formData.productType.value.value,
          registryCost: parseFloat(formData.registryCost.value),
          startDate: formData.startDate.value?.valueOf(),
          tld: formData.tld.value.id
        };
        const payload = tldCostIdsArray.map(id => ({
          ...tldCostDataFromForm,
          id
        }));
        updateTldCosts({ payload, reseller });
      })
      .catch(() => setValidationErrors(true));
  };

  const [formData, formDataDispatch] = useReducer(
    tldCostFormReducer,
    formSchema({ handleDateValidation, handleRequiredValidation }).getDefault()
  );

  const {
    data: supportedYearsData = [],
    isSuccess: isGetSupportedYearsSuccess
  } = useGetSupportedYearsByTldNameQuery(
    {
      tldName: tldCosts[0]?.$tldName
    },
    {
      skip: !tldCosts[0]?.$tldName
    }
  );

  const { data: tldPhases } = useGetTldPhaseByTldNameQuery(
    {
      tldName: tldCosts[0]?.$tldName
    },
    {
      skip: !tldCosts[0]?.$tldName
    }
  );

  const debouncedValidation = useDebouncedCallback(
    formSchema =>
      formSchema({
        handleDateValidation: handleDateValidation(false, formDataDispatch),
        handleRequiredValidation: handleRequiredValidation(
          false,
          formDataDispatch
        )
      })
        .validate(formData)
        .catch(window.console.error),
    1500
  );

  useEffect(() => {
    if (isUpdateTldCostsSuccess) {
      enqueueSnackbar('Successfully updated cost', {
        variant: 'success'
      });
      navigate('/tld-costs');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateTldCostsSuccess]);

  useEffect(() => {
    if (isUpdateTldCostsError) {
      enqueueSnackbar('Could not update cost', {
        variant: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateTldCostsError]);

  const fieldsHavePopulated = formData.tld?.value && isGetSupportedYearsSuccess;

  const tldSupportsProductType =
    supportedYearsData?.supportedProductTypes?.includes(
      formData.productType?.value?.label
    );

  //Initialize form data when only one tld cost is selected
  useEffect(() => {
    // @ts-ignore
    formDataDispatch({
      path: '',
      type: 'INIT',
      value: {
        currency: {
          value: currencies?.find(
            currencyItem => currencyItem.code === tldCosts[0]?.currency
          )
        },
        phase: {
          value: tldPhases?.find(
            tldPhase => tldPhase.name === tldCosts[0]?.phase
          )
        },
        productType: {
          value: productTypeOptions?.find(
            productTypeOption =>
              productTypeOption.label === tldCosts[0]?.productType
          )
        },
        registryCost: { value: tldCosts[0]?.registryCost },
        startDate: { value: dayjs(tldCosts[0]?.startDate) },
        tld: {
          value: tldNames?.find(tld => tld.name === tldCosts[0]?.$tldName)
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tldNames, tldPhases, currencies]);

  return (
    <Dialog fullWidth maxWidth="md" open={true}>
      <form noValidate={true} onSubmit={handleSubmit}>
        <DialogTitle sx={{ pb: 0 }}>Edit Tld Cost</DialogTitle>
        {!fieldsHavePopulated && <TldCostsLoading />}
        {fieldsHavePopulated && (
          <DialogContent>
            <Stack spacing={2} sx={{ minHeight: '383px' }}>
              {validationErrors && (
                <Alert severity="error">
                  Fix form errors before submitting.
                </Alert>
              )}
              <Stack>
                <TldNameInput
                  debouncedValidation={debouncedValidation}
                  formData={formData}
                  formDataDispatch={formDataDispatch}
                  tldNames={tldNames}
                />
                <TldPhaseInput
                  debouncedValidation={debouncedValidation}
                  formData={formData}
                  formDataDispatch={formDataDispatch}
                  tldPhases={tldPhases}
                />
                <ProductTypeInput
                  debouncedValidation={debouncedValidation}
                  formData={formData}
                  formDataDispatch={formDataDispatch}
                />
                <Divider sx={{ mt: 1 }} />
              </Stack>

              {!tldSupportsProductType && (
                <Alert severity="warning">
                  The available years for your selection does not match, or a
                  TLD that you are editing does not have any available years
                  configured.
                </Alert>
              )}
              {tldSupportsProductType && (
                <Stack sx={{ pt: 2 }}>
                  <RegistryCostInput
                    debouncedValidation={debouncedValidation}
                    formData={formData}
                    formDataDispatch={formDataDispatch}
                    handleChange={handleRegistryCostChange}
                  />
                  <CurrencyInput
                    currencies={currencies}
                    debouncedValidation={debouncedValidation}
                    formData={formData}
                    formDataDispatch={formDataDispatch}
                    handleChange={handleChange}
                  />
                </Stack>
              )}
              <Divider sx={{ my: 1 }} />
              <EffectiveDateInput
                debouncedValidation={debouncedValidation}
                formData={formData}
                formDataDispatch={formDataDispatch}
                handleChange={handleDateChange}
              />
            </Stack>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={
              isUpdateTldCostsLoading || !formData?.formState?.isTouched
            }
            onClick={handleSubmit}
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
