import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

export const CurrencyInput = ({
  currencies,
  debouncedValidation,
  formData,
  formDataDispatch,
  handleChange
}) => {
  return (
    <Autocomplete
      disableClearable
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(event, value) =>
        handleChange({
          debouncedValidation,
          event,
          formDataDispatch,
          path: 'currency',
          value
        })
      }
      options={currencies}
      renderInput={params => (
        <TextField {...params} label="Currency" variant="outlined" />
      )}
      size="small"
      sx={{ pt: 2, width: '100%' }}
      value={formData.currency?.value}
    />
  );
};
