import { groupBy, sortBy } from 'lodash-es';

/**
 * Get associated labels for domain
 * @param {String} domainName
 * @param {Array} labels
 * @param {Array} associations
 * @returns {Array}
 */
export const getAssociatedLabelsForDomain = (
  domainName,
  labels,
  associations
) => {
  if ([associations, domainName, labels].includes(undefined)) {
    return [];
  }

  return associations
    .filter(association => association.domainName === domainName)
    .map(association => ({
      ...labels.find(label => label.id === association.label.id),
      association
    }));
};

/**
 * Get associations for domain
 * @param {String} domainName
 * @param {Array} associations
 * @returns {Array}
 */
export const getAssociationsForDomain = (domainName, associations) =>
  associations?.filter(association => association.domainName === domainName);

/**
 * Get labels for domain with associations
 * @param {String} domainName
 * @param {Array} labels
 * @param {Array} associations
 * @returns {Object}
 */
export const getLabelsForDomainWithAssociations = (
  domainName,
  labels,
  associations
) => {
  if (!domainName || !labels || !associations) {
    return {};
  }

  const associationsForLabel = associations.filter(
    association => association.domainName === domainName
  );

  const grouped = groupBy(
    sortBy(
      labels.map(label => {
        const associatedLabel = associationsForLabel.find(
          associationForLabel => associationForLabel.label.id === label.id
        );

        return {
          ...label,
          ...{
            $association: associatedLabel,
            $isChecked: !!associatedLabel
          }
        };
      }),
      label => label.labelGroupName.toLowerCase()
    ),
    label => label.labelGroupName
  );

  Object.keys(grouped).forEach(groupKey => {
    grouped[groupKey] = sortBy(grouped[groupKey], label =>
      label.label.toLowerCase()
    );
  });

  return grouped;
};

/**
 * Get labels for multiple domains with associations
 * @param {Array} domainNames
 * @param {Array} labels
 * @param {Array} associations
 * @returns {Object}
 */
export const getLabelsForMultipleDomainsWithAssociations = (
  domainNames,
  labels,
  associations
) => {
  if (!domainNames?.length || !labels || !associations) {
    return {};
  }

  const associationsForLabels = associations.filter(association =>
    domainNames.includes(association.domainName)
  );

  const grouped = groupBy(
    sortBy(
      labels.map(label => {
        const associatedLabels = associationsForLabels.filter(
          associationForLabel => associationForLabel.label.id === label.id
        );

        if (
          associatedLabels?.length > 0 &&
          associatedLabels?.length === domainNames.length
        ) {
          return {
            ...label,
            ...{
              $associations: associatedLabels,
              $isChecked: true,
              $isIndeterminate: false
            }
          };
        } else if (associatedLabels?.length > 0) {
          return {
            ...label,
            ...{
              $associations: associatedLabels,
              $isChecked: false,
              $isIndeterminate: true
            }
          };
        } else {
          return {
            ...label,
            ...{
              $associations: [],
              $isChecked: false,
              $isIndeterminate: false
            }
          };
        }
      }),
      label => label.labelGroupName.toLowerCase()
    ),
    label => label.labelGroupName
  );

  Object.keys(grouped).forEach(groupKey => {
    grouped[groupKey] = sortBy(grouped[groupKey], label =>
      label.label.toLowerCase()
    );
  });

  return grouped;
};

/**
 * Used to ignore 401s during login
 * @param {String} url
 * @returns {Boolean} is this not an auth error
 */
export const isNotAuthenticationError = (url = '') =>
  !url.startsWith('/authenticate/tracer/');
