import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { setAccessToken } from '../../features/auth/auth-slice';
import { config } from '../config/config';
import { isNotAuthenticationError } from './api-slice-utils';

/**
 *
 * @param {String} link
 * @returns {String} link without API path prefix
 */
export const removeApiPathPrefixFromHalLink = link =>
  link.replace(config.API_PATH_PREFIX, '');

export const DOMAIN_TAG = 'Domain';
export const DOMAIN_NOTE_TAG = 'DomainNote';
export const USER_TAG = 'User';
export const IP_ADDRESS_TAG = 'IpAddress';
export const LABEL_TAG = 'Label';
export const LABEL_ASSOCIATION_TAG = 'LabelAssociation';
export const CUSTOMER_TAG = 'Customer';
export const CUSTOMER_USERS_TAG = 'CustomerUsers';
export const ADMIN_USERS_TAG = 'AdminUsers';
export const CART_TAG = 'Cart';
export const TRANSFERS_TAG = 'Transfers';
export const RESTRICTED_USERS_TAG = 'RestrictedUsers';
export const USER_RESTRICTIONS_TAG = 'UserRestrictions';
export const WEB3_TAG = 'Web3';
export const SIGN_OFFS_TAG = 'SignOffs';
export const DNSSEC_TAG = 'Dnssec';
export const HOST_NAME_SERVER_TAG = 'HostNameServers';
export const RECORD_TYPES_TAG = 'RecordTypes';
export const ZONE_MANAGEMENT_TAG = 'ZoneManagement';
export const TRANSACTIONS_TAG = 'Transactions';
export const CUSTOMERS_TAG = 'Customers';
export const TLD_COSTS_TAG = 'TldCosts';
export const SMD_FILES_TAG = 'SmdFiles';
export const SEARCH_GROUPS_TAG = 'SearchGroups';
export const SIGN_OFF_CONTEXT_TAG = 'SignOffContext';
export const SIGN_OFF_CONTEXT_PERMISSION_TAG = 'SignOffContextPermission';

const rawBaseQuery = baseUrl =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, api) => {
      const accessToken = api.getState().auth.accessToken;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    }
  });

const baseQuery = async (args, api, extraOptions) => {
  const baseUrl = `${api.getState().config.API_HOST}${
    api.getState().config.API_PATH_PREFIX
  }`;
  const response = await rawBaseQuery(baseUrl)(args, api, extraOptions);

  if (response?.error?.status === 401 && isNotAuthenticationError(args?.url)) {
    api.dispatch(setAccessToken(null));
    return response;
  } else if (response?.error?.status === 'FETCH_ERROR') {
    // Probably should handle this as they're offline or not able to reach the host
    return response;
  } else {
    return response;
  }
};

export const apiSlice = createApi({
  baseQuery,
  endpoints: () => ({}),
  keepUnusedDataFor: import.meta.env.MODE === 'test' ? 0 : 60,
  tagTypes: [
    USER_TAG,
    IP_ADDRESS_TAG,
    DOMAIN_TAG,
    DOMAIN_NOTE_TAG,
    LABEL_TAG,
    LABEL_ASSOCIATION_TAG,
    CUSTOMER_TAG,
    CUSTOMER_USERS_TAG,
    ADMIN_USERS_TAG,
    CART_TAG,
    TRANSFERS_TAG,
    ZONE_MANAGEMENT_TAG,
    RESTRICTED_USERS_TAG,
    USER_RESTRICTIONS_TAG,
    WEB3_TAG,
    SIGN_OFFS_TAG,
    DNSSEC_TAG,
    HOST_NAME_SERVER_TAG,
    CUSTOMERS_TAG,
    RECORD_TYPES_TAG,
    TLD_COSTS_TAG,
    SMD_FILES_TAG,
    SEARCH_GROUPS_TAG,
    SIGN_OFF_CONTEXT_TAG,
    SIGN_OFF_CONTEXT_PERMISSION_TAG
  ]
});
