import { Autocomplete, TextField } from '@mui/material';
import React, { Dispatch, SyntheticEvent } from 'react';

import type { FormAction } from '../../common/types/form';
import type { ApiUserResponse } from '../../common/types/user';

type SignOffContextPermissionUserAutocompleteProps = {
  formDataDispatch: Dispatch<FormAction>;
  isLoading: boolean;
  options: ApiUserResponse[];
};

export const SignOffContextPermissionUserAutocomplete = ({
  formDataDispatch,
  isLoading,
  options
}: SignOffContextPermissionUserAutocompleteProps) => {
  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    value: ApiUserResponse | null
  ) => {
    if (value) {
      formDataDispatch({
        errorMessage: null,
        path: 'user',
        type: 'UPDATE',
        value: value
      });
    }
  };

  return (
    <Autocomplete
      disableClearable
      getOptionLabel={(user: ApiUserResponse) =>
        user.fullName + ' - ' + user.email
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={isLoading}
      onChange={handleChange}
      options={options}
      renderInput={params => (
        <TextField {...params} label="Users" variant="outlined" />
      )}
      value={undefined}
    />
  );
};
