/* eslint-disable no-unused-vars */
import { SMD_FILES_TAG, apiSlice } from '../../common/store/api-slice';
import { getLocalizedDateTime } from '../utils/date-time';

const smdApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    checkRevokedSmdFiles: builder.query({
      providesTags: [SMD_FILES_TAG],
      query: ({ customerName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/smd/revoked`
    }),
    deleteSmdFile: builder.mutation({
      invalidatesTags: [SMD_FILES_TAG],
      query: ({ customerName, reseller, smdFileId }) => ({
        method: 'DELETE',
        url: `resellers/${reseller}/customers/${customerName}/smd/${smdFileId}`
      })
    }),

    getSmdFiles: builder.query({
      providesTags: [SMD_FILES_TAG],

      query: ({ customerName, reseller, revokedFiles }) =>
        `resellers/${reseller}/customers/${customerName}/smd`,
      transformResponse: (response, meta, { revokedFiles }) =>
        response.map(smdFile => {
          const isRevoked = revokedFiles?.some(
            revokedFile => revokedFile.id === smdFile.id
          );
          return {
            ...smdFile,
            $createdFormatted: getLocalizedDateTime(smdFile.created),
            $isRevoked: isRevoked,
            label: smdFile.filename
          };
        })
    }),
    uploadSmdFile: builder.mutation({
      invalidatesTags: [SMD_FILES_TAG],
      query: ({ customerName, fileToUpload, reseller }) => {
        const formData = new FormData();
        formData.append('data', fileToUpload);
        return {
          body: formData,
          method: 'POST',
          url: `resellers/${reseller}/customers/${customerName}/smd`
        };
      }
    })
  })
});

export const {
  useCheckRevokedSmdFilesQuery,
  useDeleteSmdFileMutation,
  useGetSmdFilesQuery,
  useUploadSmdFileMutation
} = smdApiSlice;
