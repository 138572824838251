import { pick } from 'lodash-es';

import { transformUserResponse } from '../../features/auth/transform-user-response';
import {
  ADMIN_USERS_TAG,
  CUSTOMER_USERS_TAG,
  IP_ADDRESS_TAG,
  USER_TAG,
  apiSlice,
  removeApiPathPrefixFromHalLink
} from './api-slice';

export const userStatuses = Object.freeze({
  active: 'ACTIVE',
  disabled: 'DISABLED'
});

const getPathForUserType = ({ customerName, isAdmin, reseller, userEmail }) => {
  const basePath = `/resellers/${reseller}/`;

  return isAdmin
    ? `${basePath}admin-users/${userEmail}`
    : `${basePath}customers/${customerName}/users/${userEmail}`;
};

const actionToPath = new Map();
actionToPath.set('create', 'create-pending-totp-key');
actionToPath.set('clear', 'clear-pending-totp-key');
actionToPath.set('reset', 'reset-totp-key');
actionToPath.set('verify', 'verify-pending-totp-key');

const getPathForTotpAction = action => {
  if (actionToPath.has(action)) {
    return actionToPath.get(action);
  } else {
    throw new Error('Invalid action');
  }
};

const userApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    changePassword: builder.mutation({
      query: ({
        changePasswordUrl,
        existingPassword,
        plainPassword,
        twoFactorCode
      }) => ({
        body: {
          existingPassword,
          plainPassword,
          twoFactorCode
        },
        method: 'POST',
        url: removeApiPathPrefixFromHalLink(changePasswordUrl)
      })
    }),
    clearPendingTotpKeyForUser: builder.mutation({
      query: ({ customerName, isAdmin, reseller, userEmail }) => ({
        method: 'POST',
        url: `${getPathForUserType({
          customerName,
          isAdmin,
          reseller,
          userEmail
        })}/${getPathForTotpAction('clear')}`
      })
    }),
    createPendingTotpKeyForUser: builder.mutation({
      query: ({
        // eslint-disable-next-line no-unused-vars
        apiHost,
        customerName,
        isAdmin,
        // eslint-disable-next-line no-unused-vars
        isMemberOfCustomer,
        reseller,
        userEmail
      }) => ({
        method: 'POST',
        url: `${getPathForUserType({
          customerName,
          isAdmin,
          reseller,
          userEmail
        })}/${getPathForTotpAction('create')}`
      }),
      transformResponse: (
        response,
        meta,
        { apiHost, customerName, isMemberOfCustomer, userEmail }
      ) => {
        const key = response.key;

        const issuer = new URL(apiHost).host;

        const encodedAccountPrefixAndEmail = isMemberOfCustomer
          ? encodeURIComponent(`${customerName}-${userEmail}`)
          : encodeURIComponent(userEmail);

        const url = `otpauth://totp/${issuer}:${encodedAccountPrefixAndEmail}?secret=${key}&issuer=${issuer}`;

        return {
          key,
          url
        };
      }
    }),
    forgotPassword: builder.mutation({
      query: ({ reseller, userEmail }) => ({
        method: 'POST',
        url: `resellers/${reseller}/password-reset/${userEmail}`
      })
    }),
    getIpAddresses: builder.query({
      providesTags: (result, error, { userEmail }) => [
        { id: userEmail, type: IP_ADDRESS_TAG }
      ],
      query: ({ customerName, isAdmin, reseller, userEmail }) =>
        `${getPathForUserType({
          customerName,
          isAdmin,
          reseller,
          userEmail
        })}/allowedIpAddresses`
    }),
    getUser: builder.query({
      providesTags: (result, error, { userEmail }) => [
        { id: userEmail, type: USER_TAG }
      ],
      query: ({ customerName, isAdmin, reseller, userEmail }) =>
        getPathForUserType({ customerName, isAdmin, reseller, userEmail }),
      transformResponse: response => transformUserResponse({ user: response })
    }),
    logout: builder.mutation({
      query: ({ customerName, isAdmin, reseller, userEmail }) => ({
        method: 'PUT',
        url: `${getPathForUserType({
          customerName,
          isAdmin,
          reseller,
          userEmail
        })}/revoke-access-tokens`
      })
    }),
    newUser: builder.mutation({
      invalidatesTags: [ADMIN_USERS_TAG, CUSTOMER_USERS_TAG],
      query: ({ customerName, newUser, reseller }) => {
        const url = newUser.isAdmin
          ? `resellers/${reseller}/admin-users`
          : `resellers/${reseller}/customers/${customerName}/users`;
        return {
          body: newUser,
          method: 'POST',
          url
        };
      }
    }),
    resetPassword: builder.mutation({
      query: ({ resetPasswordLink }) => ({
        method: 'POST',
        url: removeApiPathPrefixFromHalLink(resetPasswordLink)
      })
    }),
    resetPasswordRespond: builder.mutation({
      query: ({ code, plainPassword, reseller, userEmail }) => ({
        body: {
          code,
          plainPassword
        },
        method: 'PUT',
        url: `resellers/${reseller}/users/${userEmail}/password/reset/respond`
      }),
      transformResponse: (response, meta) => ({
        ...response,
        status: meta.response.status
      })
    }),
    resetTotpKeyForUser: builder.mutation({
      query: ({ customerName, isAdmin, reseller, userEmail }) => ({
        method: 'POST',
        url: `${getPathForUserType({
          customerName,
          isAdmin,
          reseller,
          userEmail
        })}/${getPathForTotpAction('reset')}`
      })
    }),
    updateIpAddresses: builder.mutation({
      // TODO this should invalidate based on the user email or some other unique identifier
      invalidatesTags: [IP_ADDRESS_TAG],
      query: ({ body, customerName, isAdmin, reseller, userEmail }) => {
        return {
          body,
          method: 'PUT',
          url: `${getPathForUserType({
            customerName,
            isAdmin,
            reseller,
            userEmail
          })}/allowedIpAddresses`
        };
      }
    }),
    updateUser: builder.mutation({
      invalidatesTags: ({ userEmail }) => [
        { id: userEmail, type: USER_TAG },
        ADMIN_USERS_TAG,
        CUSTOMER_USERS_TAG
      ],
      query: ({ body, customerName, isAdmin, reseller, userEmail }) => {
        // API wants the object shaped differently on PUT/POST compared to
        // what it returns with GET.
        body.roles = body.roles.map(role => role.roleName);
        body.emailOnLoginFailure = body.emailOnLoginFailure.map(email => ({
          email
        }));
        body.emailOnLoginSuccess = body.emailOnLoginSuccess.map(email => ({
          email
        }));
        body.status = body.active ? userStatuses.active : userStatuses.disabled;
        body = pick(body, [
          'screenName',
          'roles',
          'allowedIpAddresses',
          'enableIpAddressEnforcement',
          'status',
          'email',
          'firstName',
          'lastName',
          'emailOnLoginSuccess',
          'emailOnLoginFailure',
          'phoneNumber',
          'signOffsRequired',
          'verificationInfo',
          'fullName',
          'totpConfigured'
        ]);

        return {
          body,
          method: 'PUT',
          url: getPathForUserType({
            customerName,
            isAdmin,
            reseller,
            userEmail
          })
        };
      }
    }),
    verifyPasswordReset: builder.mutation({
      query: ({ code, phoneVerificationCode, reseller, userEmail }) => ({
        body: {
          code,
          phoneVerificationCode
        },
        method: 'POST',
        url: `resellers/${reseller}/users/${userEmail}/password/reset/phone-verification`
      })
    }),
    verifyPendingTotpKeyForUser: builder.mutation({
      query: ({
        customerName,
        isAdmin,
        reseller,
        totpVerificationCode,
        userEmail
      }) => ({
        body: { totpVerificationCode },
        method: 'PUT',
        url: `${getPathForUserType({
          customerName,
          isAdmin,
          reseller,
          userEmail
        })}/${getPathForTotpAction('verify')}`
      })
    })
  })
});

export const {
  useChangePasswordMutation,
  useClearPendingTotpKeyForUserMutation,
  useCreatePendingTotpKeyForUserMutation,
  useForgotPasswordMutation,
  useGetIpAddressesQuery,
  useGetUserQuery,
  useLogoutMutation,
  useNewUserMutation,
  useResetPasswordMutation,
  useResetPasswordRespondMutation,
  useResetTotpKeyForUserMutation,
  useUpdateIpAddressesMutation,
  useUpdateUserMutation,
  useVerifyPasswordResetMutation,
  useVerifyPendingTotpKeyForUserMutation
} = userApiSlice;
