import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectCustomerName } from '../../common/store/customers-slice';
import { selectReseller } from '../../common/store/reseller';
import { useLogoutMutation } from '../../common/store/user-api-slice';
import {
  selectIsAdmin,
  selectUserEmail,
  setAccessToken,
  setHasLoggedOut
} from '../auth/auth-slice';

export const ProfileMenu = () => {
  const navigate = useNavigate();
  const [logout, { isError, isSuccess }] = useLogoutMutation();
  const isAdmin = useSelector(selectIsAdmin);
  const reseller = useSelector(selectReseller);
  const userEmail = useSelector(selectUserEmail);
  const customerName = useSelector(selectCustomerName);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess || isError) {
      dispatch(setAccessToken(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  const handleLogout = logoutParams => {
    logout(logoutParams);
    dispatch(setHasLoggedOut(true));
  };

  return (
    <PopupState popupId="profile-menu" variant="popover">
      {popupState => (
        <>
          <IconButton
            aria-label="Profile Menu"
            color="inherit"
            size="small"
            {...bindTrigger(popupState)}
          >
            <FontAwesomeIcon icon={faUser} />
          </IconButton>
          <Menu {...bindMenu(popupState)}>
            <MenuItem disabled={true}>{userEmail}</MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                popupState.close();
                navigate('/profile/account-information');
              }}
            >
              Account Details
            </MenuItem>
            <MenuItem
              onClick={() => {
                popupState.close();
                navigate('/profile/security');
              }}
            >
              Security
            </MenuItem>
            <MenuItem
              onClick={() => {
                popupState.close();
                navigate('/profile/change-password');
              }}
            >
              Change Password
            </MenuItem>
            <MenuItem
              onClick={() => {
                popupState.close();
                handleLogout({
                  customerName,
                  isAdmin,
                  reseller,
                  userEmail
                });
              }}
            >
              Sign Out
            </MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  );
};
