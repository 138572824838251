import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

import { handleChange } from './tld-cost-form-functions';

export const TldPhaseInput = ({
  debouncedValidation,
  formData,
  formDataDispatch,
  tldPhases
}) => {
  return (
    <Autocomplete
      disableClearable
      disabled={!tldPhases?.length}
      getOptionLabel={option => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, value) =>
        handleChange({
          debouncedValidation,
          formDataDispatch,
          path: 'phase',
          value
        })
      }
      options={tldPhases}
      renderInput={params => (
        <TextField {...params} label={'Phase'} variant="outlined" />
      )}
      size="small"
      sx={{ pt: 2, width: '100%' }}
      value={formData.phase.value}
    />
  );
};
