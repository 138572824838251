import { array, boolean, number, object, string } from 'yup';

import { zoneRecordSetFormDefaults } from './zone-record-set-form-defaults';

export const defaultFieldSchema = handleRequiredValidation =>
  object({
    errorMessage: string().default(''),
    hasError: boolean().default(false),
    touched: boolean().default(false),
    value: string().required(handleRequiredValidation).default('')
  });

export const zoneFormSchema = (
  handleRequiredValidation,
  handleDomainNameValidation
) =>
  object({
    description: defaultFieldSchema(handleRequiredValidation),
    dnsName: object({
      errorMessage: string().default(''),
      hasError: boolean().default(false),
      touched: boolean().default(false),
      value: string()
        .required(handleRequiredValidation)
        .matches(domainNameRegexWithRoot, handleDomainNameValidation)
        .default('')
    }),
    zoneName: defaultFieldSchema(handleRequiredValidation)
  });

export const subDomainRegex = /^([a-zA-Z0-9-_]+(\.[a-zA-Z0-9-_]+)*)?$/;

export const domainNameRegexWithRoot =
  /^([a-zA-Z0-9-_]+(\.[a-zA-Z0-9-_]+)+\.)$/;

export const defaultRecordDataSchema = (
  handleRequiredValidation,
  handleRecordDataValidation,
  regex
) =>
  object({
    errorMessage: string().default(''),
    hasError: boolean().default(false),
    key: string().required().default(Math.random().toString().slice(2)),
    touched: boolean().default(false),
    value: string()
      .required(handleRequiredValidation)
      .matches(regex, handleRecordDataValidation)
      .min(1)
      .default('')
  });
export const zoneRecordFormSchema = ({
  handleDomainNameValidation,
  handleRecordDataValidation,
  handleRequiredValidation,
  handleSubDomainNameValidation,
  handleTtlValidation,
  isRecordSetUsingBareDomainName,
  recordType
}) => {
  const regexForDnsNameField = isRecordSetUsingBareDomainName
    ? subDomainRegex
    : domainNameRegexWithRoot;
  const validationForDnsNameField = isRecordSetUsingBareDomainName
    ? handleSubDomainNameValidation
    : handleDomainNameValidation;

  const recordDataRegex =
    recordType === undefined ? null : new RegExp(recordType.recordDataRegex);

  const dnsNameValue =
    recordType === undefined || recordType?.bareDomainNameAllowedForDnsName
      ? string()
          .matches(regexForDnsNameField, validationForDnsNameField)
          .default('')
      : string()
          .required(handleRequiredValidation)
          .matches(regexForDnsNameField, validationForDnsNameField)
          .default('');

  return object({
    dnsName: object({
      errorMessage: string().default(''),
      hasError: boolean().default(false),
      touched: boolean().default(false),
      value: dnsNameValue
    }),
    recordData: object({
      errorMessage: string().default(''),
      hasError: boolean().default(false),
      touched: boolean().default(false),
      value: array()
        .required(handleRequiredValidation)
        .nullable(true)
        .of(
          defaultRecordDataSchema(
            handleRequiredValidation,
            handleRecordDataValidation,
            recordDataRegex
          )
        )
        .default([
          defaultRecordDataSchema(
            handleRequiredValidation,
            handleRecordDataValidation,
            recordDataRegex
          ).getDefault()
        ])
    }),
    recordType: defaultFieldSchema(handleRequiredValidation),
    ttl: object({
      errorMessage: string().default(''),
      hasError: boolean().default(false),
      touched: boolean().default(false),
      value: number()
        .typeError(handleTtlValidation)
        .nullable(false)
        .default(zoneRecordSetFormDefaults.DEFAULT_TTL)
    })
  });
};
