import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useSearchParams } from 'react-router-dom';

import { selectIsMemberOfCustomer } from '../../features/auth/auth-slice';
import { CustomerSelection } from '../../features/customer-selection/CustomerSelection';
import styles from './Layout.module.css';
import { Header } from './header/Header';

export const Layout = () => {
  const isMemberOfCustomer = useSelector(selectIsMemberOfCustomer);
  const [searchParams] = useSearchParams();
  const transferTokenParam = searchParams.get('transferToken');

  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.main}>
        <Outlet />
      </div>
      {!isMemberOfCustomer && !transferTokenParam && <CustomerSelection />}
    </div>
  );
};
