import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { SignOffDialog } from '../../common/components/sign-offs/SignOffDialog';
import {
  entityTypes,
  useJobResponse
} from '../../common/hooks/use-job-response';
import { ZONE_MANAGEMENT_TAG } from '../../common/store/api-slice';
import { selectCustomerName } from '../../common/store/customers-slice';
import { useCreateOrderMutation } from '../../common/store/orders-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { generateId } from '../../common/utils/generate-id';
import { selectClientId } from '../auth/auth-slice';
import { selectSelectedRecords } from './selected-record-slice';
import { selectSelectedZones } from './selected-zone-slice';

export const ZoneRecordSetAddConfirmButton = ({
  disabled,
  formData,
  formDataDispatch,
  handleCancel,
  isEdit,
  popupState,
  setValidationErrors,
  validateForm
}) => {
  const reseller = useSelector(selectReseller);
  const customerName = useSelector(selectCustomerName);
  const clientId = useSelector(selectClientId);
  const selectedZone = useSelector(selectSelectedZones)[0];
  const selectedRecord = useSelector(selectSelectedRecords)[0];

  const [needsForceUpdate, setNeedsForceUpdate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const clientLineItemIds = useRef([generateId()]);
  const lineItems = useRef([]);

  const shouldStartPolling = useRef(false);

  const { enqueueSnackbar } = useSnackbar();

  const orderOptions = {
    currency: 'USD',
    paymentType: 'NONE'
  };

  const [
    createOrder,
    {
      data: orderCreationResponse,
      isError: isCreateOrderError,
      isSuccess: isCreateOrderSuccess
    }
  ] = useCreateOrderMutation();

  useJobResponse({
    entityType: entityTypes.record,
    failureMessage: `There was an unexpected error while trying to ${
      isEdit ? 'apply changes to' : 'create'
    } the record. Please contact support.`,
    lineItems: lineItems.current,
    onComplete: () => {
      setIsSubmitting(false);

      popupState.close();
    },
    shouldStartPolling: shouldStartPolling.current,
    skipCondition: !isCreateOrderSuccess,
    successMessage: `Successfully ${
      isEdit ? 'applied changes to record.' : 'created new record.'
    }`,
    tagsToInvalidate: [ZONE_MANAGEMENT_TAG]
  });

  useEffect(() => {
    if (isCreateOrderSuccess && orderCreationResponse) {
      const { forceUpdateRequired, signOffRequired } = orderCreationResponse;

      if (signOffRequired && forceUpdateRequired) {
        // prompt for re-sending order request
        setNeedsForceUpdate(true);
      } else if (signOffRequired) {
        // sign off required toast
        handleSignOff();
      } else {
        shouldStartPolling.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOrderSuccess, orderCreationResponse]);

  useEffect(() => {
    if (isCreateOrderError) {
      const updateType = isEdit ? 'update' : 'add';

      enqueueSnackbar(
        'An error occurred while trying to ' + updateType + ' the record set.',
        { variant: 'error' }
      );
      popupState.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOrderError]);

  const handleSignOff = () => {
    enqueueSnackbar(
      `Your ${isEdit ? 'edit' : 'add'} request is now pending sign-off`,
      {
        variant: 'info'
      }
    );

    formDataDispatch({
      type: 'INIT',
      value: isEdit ? selectedRecord : undefined
    });

    setIsSubmitting(false);

    popupState.close();
  };

  const handleRecordSetAddSubmit = ({ forceUpdate = false }) => {
    setIsSubmitting(true);

    validateForm()
      .then(() => {
        const zoneRecordDataFromForm = Object.keys(formData).reduce(
          (reducingRecordData, formDataKey) => {
            if (formDataKey === 'recordData') {
              reducingRecordData[formDataKey] = formData[formDataKey].value.map(
                recordDataEntry => recordDataEntry.value
              );
            } else {
              reducingRecordData[formDataKey] = formData[formDataKey].value;
            }

            return reducingRecordData;
          },
          {}
        );

        lineItems.current = [
          {
            clientLineItemId: clientLineItemIds.current[0],
            customerZoneId: selectedZone.id,
            customerZoneRecordId: isEdit ? selectedRecord.id : undefined,
            data: zoneRecordDataFromForm.recordData,
            dnsName: isEdit
              ? selectedRecord.dnsName
              : formattedRecordDnsName(zoneRecordDataFromForm),
            lineItemNum: 0,
            lineItemType: isEdit ? 'ZONE_RECORD_UPDATE' : 'ZONE_RECORD_CREATE',
            recordType: zoneRecordDataFromForm.recordType,
            serviceProviderName: selectedZone.serviceProviderName,
            ttl: zoneRecordDataFromForm.ttl,
            typeWithDnsName: `${
              zoneRecordDataFromForm.recordType
            } - ${formattedRecordDnsName(zoneRecordDataFromForm)}`
          }
        ];

        createOrder({
          clientId,
          customerName,
          lineItems: lineItems.current,
          orderOptions,
          reseller,
          signOffForceUpdate: forceUpdate
        });
      })
      .catch(() => {
        setIsSubmitting(false);

        setValidationErrors(true);
      });
  };

  const handleForceUpdate = () =>
    handleRecordSetAddSubmit({ forceUpdate: true });

  // if the field was left blank, return parent dns name, otherwise prepend to parent
  const formattedRecordDnsName = zoneRecordDataFromForm =>
    zoneRecordDataFromForm.dnsName
      ? `${zoneRecordDataFromForm.dnsName}.${selectedZone.dnsName}`
      : selectedZone.dnsName;

  return (
    <>
      <LoadingButton
        disabled={disabled || isSubmitting}
        loading={isSubmitting}
        onClick={handleRecordSetAddSubmit}
        variant="contained"
      >
        Submit
      </LoadingButton>
      <SignOffDialog
        handleClose={handleCancel}
        handleSubmit={handleForceUpdate}
        isOpen={needsForceUpdate}
      />
    </>
  );
};
