import { Stack } from '@mui/material';
import React from 'react';

import { ContactSetValidityMessage } from './ContactSetValidityMessage';
import { DnssecValidityMessage } from './DnssecValidityMessage';
import { NameServerValidityMessage } from './NameServerValidityMessage';
import { SpecialRequirementsValidityMessage } from './SpecialRequirementsValidityMessage';

export const ValidityMessage = ({
  contactValidationError,
  domain,
  nameServerValidationError
}) => {
  const shouldShowContactErrors =
    contactValidationError && !domain.localPresence;

  const shouldShowDnssecErrors =
    domain.$tldObject.dnssecRequired && !domain.digest;

  const shouldShowSpecialRequirementsError =
    domain.specialRequirements !== undefined &&
    !domain.specialRequirements?.isValid;

  return (
    <Stack direction="column" spacing={2}>
      <NameServerValidityMessage
        errorMessage={
          nameServerValidationError?.message ??
          nameServerValidationError?.reason
        }
        isVisible={!!nameServerValidationError}
      />
      <ContactSetValidityMessage
        errorMessage={
          contactValidationError?.data ?? contactValidationError?.reasons
        }
        isVisible={shouldShowContactErrors}
      />
      <SpecialRequirementsValidityMessage
        isVisible={shouldShowSpecialRequirementsError}
      />
      <DnssecValidityMessage isVisible={shouldShowDnssecErrors} />
    </Stack>
  );
};
