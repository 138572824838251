import { getTldNameForDomain } from '../../common/utils/get-tld-name-for-domain';

export const specialRequirements = [
  { component: 'SetNameServers', tld: 'be' },
  { component: 'SetNameServers', tld: 'de' },
  { component: 'SetNameServers', tld: 'lu' },
  { component: 'SetNameServers', tld: 'ie' },
  { component: 'SetDotNoSpecialRequirements', tld: 'no' }
];

export const getSpecialRequirements = domainName => {
  const tld = getTldNameForDomain(domainName);

  return specialRequirements.find(
    specialRequirement => specialRequirement.tld === tld
  );
};
