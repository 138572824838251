import { Typography } from '@mui/material';
import React from 'react';

export const NameServerValidityMessage = ({ errorMessage, isVisible }) => {
  return (
    isVisible && (
      <div>
        <Typography variant="body2">Name Server Group Invalid</Typography>
        <Typography variant="caption">{errorMessage}.</Typography>
      </div>
    )
  );
};
