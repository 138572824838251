import { Button } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectDisplayedContactSetId } from '../../../../common/components/contact-sets/contact-sets-slice';

export const ContactSetsActions = ({
  bindToggle,
  dialogState,
  domains,
  handleApplyContactSet
}) => {
  const contactSetId = useSelector(selectDisplayedContactSetId);

  const handleSubmitClick = () => {
    dialogState.close();
    handleApplyContactSet({ item: domains[0], value: contactSetId });
  };

  return (
    <>
      <Button {...bindToggle(dialogState)}>Cancel</Button>
      <Button
        {...bindToggle(dialogState)}
        onClick={handleSubmitClick}
        variant="contained"
      >
        Apply contact set to {domains[0]}
      </Button>
    </>
  );
};
